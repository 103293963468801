import { Link } from 'react-router-dom';

export const DocumentLink = ({ link }) => {
    return (
        <>
            <Link to={link} className="text-xs italic transition-colors hover:text-green hover:underline">
                Перейти к системе
            </Link>
        </>
    );
};
