import { t } from 'i18next';
import { useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useCookies } from 'react-cookie';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAbsoluteLayout, useColumnOrder, useTable } from 'react-table';
import ReactTooltip from 'react-tooltip';

import {
    applicationsFetch,
    applications_fetching as applications_fetching_state,
    applications as applications_state,
    filter as filter_state,
    getApplicationFetch,
    set_filter_page,
    set_sort,
    sort as sort_state,
} from '../../redux/slices/applicationsSlice';
import { auth_key_calendar as auth_key_calendar_state, username as username_state } from '../../redux/slices/loginSlice';
import { Loader } from '../common/Loader';
import { SignsIcons } from './detail/SignsIcons';
import getRenderDate from './detail/helpers/getRenderDate';

export const Table = () => {
    const sortRef = useRef(true);

    const [selectedApplications, setSelectedApplications] = useState([]);
    const [allSelectedCheckbox, setAllSelectedCheckbox] = useState(false);
    const [upArrowStart, setUpArrowStart] = useState('#BFBFBF');
    const [downArrowStart, setDownArrowStart] = useState('#BFBFBF');
    const [upArrowEnd, setUpArrowEnd] = useState('#BFBFBF');
    const [downArrowEnd, setDownArrowEnd] = useState('#BFBFBF');

    const dispatch = useDispatch();

    const sort = useSelector(sort_state);
    const filter = useSelector(filter_state);
    const username = useSelector(username_state);
    const applications = useSelector(applications_state);
    const auth_key_calendar = useSelector(auth_key_calendar_state);
    const applications_fetching = useSelector(applications_fetching_state);

    const [cookie] = useCookies();
    const lang = cookie.i18next;

    const navigate = useNavigate();

    const handlerShowApplication = async (e, id) => {
        if (e.target.type !== 'checkbox') {
            await dispatch(getApplicationFetch({ username, auth_key_calendar, id, lang }));
            navigate(`/applications/${id}`);
        }
    };

    // <-- sort click & color sort arrow
    const handlerClickSort = date => {
        if (date === 'start_date') {
            if (sort === '-start_date') {
                dispatch(set_sort('start_date'));
                dispatch(set_filter_page(1));
            } else {
                dispatch(set_sort('-start_date'));
                dispatch(set_filter_page(1));
            }
        } else {
            if (date === 'expected_end_date') {
                if (sort === '-expected_end_date') {
                    dispatch(set_sort('expected_end_date'));
                    dispatch(set_filter_page(1));
                } else {
                    dispatch(set_sort('-expected_end_date'));
                    dispatch(set_filter_page(1));
                }
            }
        }
    };

    const getColorArrowSort = sort => {
        if (sort === '-start_date') {
            setUpArrowEnd('#BFBFBF');
            setDownArrowStart('#BFBFBF');
            setDownArrowEnd('#BFBFBF');
            setUpArrowStart('rgba(24, 144, 255, 1)');
        }
        if (sort === 'start_date') {
            setUpArrowEnd('#BFBFBF');
            setDownArrowEnd('#BFBFBF');
            setDownArrowStart('rgba(24, 144, 255, 1)');
            setUpArrowStart('#BFBFBF');
        }
        if (sort === '-expected_end_date') {
            setUpArrowStart('#BFBFBF');
            setDownArrowStart('#BFBFBF');
            setDownArrowEnd('#BFBFBF');
            setUpArrowEnd('rgba(24, 144, 255, 1)');
        }
        if (sort === 'expected_end_date') {
            setUpArrowStart('#BFBFBF');
            setDownArrowStart('#BFBFBF');
            setDownArrowEnd('rgba(24, 144, 255, 1)');
            setUpArrowEnd('#BFBFBF');
        }
    };

    useEffect(() => {
        getColorArrowSort(sort);

        if (sortRef.current) {
            sortRef.current = false;
            return;
        }
        dispatch(applicationsFetch({ username, auth_key_calendar, filter, sort, lang }));
    }, [username, auth_key_calendar, filter, lang, sort, dispatch]);

    // -->

    // <-- dnd columns
    const getItemStyle = ({ isDragging, isDropAnimating }, draggableStyle) => ({
        ...draggableStyle,
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',

        // change background colour if dragging
        background: isDragging ? 'transpanent' : 'transpanent',

        ...(!isDragging && { transform: 'translate(0,0)' }),
        ...(isDropAnimating && { transitionDuration: '0.001s' }),

        // styles we need to apply on draggables
    });
    // -->

    // <-- tooltops
    useEffect(() => {
        ReactTooltip.rebuild();
    }, [applications]);
    // -->

    // <-- Заголовки таблицы
    const columns = useMemo(
        () => [
            {
                Header: '',
                accessor: 'select',
                width: '18',
            },
            {
                Header: t('APPLICATIONS_SEARCH_STATUS'),
                accessor: 'status',
                width: '80',
            },
            {
                Header: t('APPLICATIONS_ID'),
                accessor: 'id',
                width: '64',
            },
            {
                Header: t('APPLICATIONS_OBJ'),
                accessor: 'venue_name',
            },
            {
                Header: t('APPLICATIONS_DESCRIPTION'),
                accessor: 'description',
            },
            {
                Header: t('APPLICATIONS_EXECUTOR'),
                accessor: 'user_name',
            },
            {
                Header: t('APPLICATIONS_FORM_APPLICANT'),
                accessor: 'creator_name',
            },
            {
                Header: t('APPLICATIONS_CREATION_DATE'),
                accessor: 'start_date',
                width: '100',
            },
            {
                Header: t('APPLICATIONS_COMPLETE_DATE'),
                accessor: 'expected_end_date',
                width: '100',
            },
            // {
            //     Header: '',
            //     accessor: 'edit',
            //     width: '18',
            // },
        ],
        [lang]
    );
    // -->

    // <-- Работа с чекбоксами
    const applicationSelect = e => {
        setAllSelectedCheckbox(false);
        if (selectedApplications.length > 0) {
            const newsSelectedApplications = selectedApplications.filter(el => el.id !== Number(e.target.value));

            e.target.checked
                ? setSelectedApplications([...newsSelectedApplications, { id: Number(e.target.value), selected: e.target.checked }])
                : setSelectedApplications([...newsSelectedApplications]);
        } else {
            setSelectedApplications([...selectedApplications, { id: Number(e.target.value), selected: e.target.checked }]);
        }
    };

    const setSelected = id => {
        const select = selectedApplications?.find(el => el.id === id);

        return select ? select.selected : false;
    };

    const selectedAll = e => {
        if (e.target.checked) {
            setAllSelectedCheckbox(!allSelectedCheckbox);
            const allSelectedCheckboxObj = applications.list.map(el => {
                return { id: Number(el.id), selected: true };
            });
            setSelectedApplications(allSelectedCheckboxObj);
        } else {
            setSelectedApplications([]);
            setAllSelectedCheckbox(!allSelectedCheckbox);
        }
    };
    // -->

    const applications_list = useMemo(() => applications?.list.map(application => application) || [], [applications]);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, allColumns, setColumnOrder } = useTable(
        { columns, data: applications_list },
        useColumnOrder,
        useAbsoluteLayout
    );

    const currentColOrder = useRef();

    if (applications_fetching && filter.page === 1) {
        return (
            <div className="flex h-20 w-full items-center justify-center">
                <Loader />
            </div>
        );
    }

    if (!applications_list.length) {
        return <div>{t('CALENDAR_NOTHING_FOUND')}</div>;
    }

    return (
        <>
            <div className="w-full">
                <InfiniteScroll
                    next={() => dispatch(set_filter_page(filter.page + 1))}
                    hasMore={applications.count > 0 && applications.total_count > 20}
                    loader={
                        <div className="flex h-20 w-full items-center justify-center">
                            <Loader />
                        </div>
                    }
                    dataLength={rows.length}
                    scrollableTarget="wrapper"
                >
                    <table {...getTableProps()} className="w-full border-separate border-spacing-0 text-sm">
                        <thead className="absolute top-16 overflow-hidden text-left uppercase text-black md:sticky md:z-10 md:w-full md:overflow-visible">
                            {headerGroups.map(headerGroup => (
                                <DragDropContext
                                    key={'thead'}
                                    onDragStart={() => {
                                        currentColOrder.current = allColumns.map(o => o.id);
                                    }}
                                    onDragUpdate={dragUpdateObj => {
                                        const colOrder = [...currentColOrder.current];
                                        const sIndex = dragUpdateObj.source.index;
                                        const dIndex = dragUpdateObj.destination && dragUpdateObj.destination.index;

                                        if (typeof sIndex === 'number' && typeof dIndex === 'number') {
                                            colOrder.splice(sIndex, 1);
                                            colOrder.splice(dIndex, 0, dragUpdateObj.draggableId);
                                            setColumnOrder(colOrder);
                                        }
                                    }}
                                >
                                    <Droppable droppableId="droppable" direction="horizontal" key={headerGroup.headers.id}>
                                        {droppableProvided => (
                                            <tr {...headerGroup.getHeaderGroupProps()} ref={droppableProvided.innerRef} className="h-16">
                                                {headerGroup.headers.map((column, index) => (
                                                    <Draggable key={column.id} draggableId={column.id} index={index} isDragDisabled={!column.accessor}>
                                                        {(provided, snapshot) => {
                                                            let additionally = '';
                                                            // let styles = ' ';
                                                            //select
                                                            if (column.id === 'select') {
                                                                additionally = (
                                                                    // скрыт чекбокс
                                                                    <div className="hidden w-5">
                                                                        <input
                                                                            type="checkbox"
                                                                            name="select_all_venues"
                                                                            onChange={e => {
                                                                                selectedAll(e);
                                                                            }}
                                                                            className="rounded-sm border-stone-300"
                                                                            checked={allSelectedCheckbox}
                                                                        />
                                                                    </div>
                                                                );
                                                                // styles = 'md:w-10';
                                                            }

                                                            // start date
                                                            if (column.id === 'start_date') {
                                                                additionally = (
                                                                    <div className="flex">
                                                                        <span className="mr-1">{t('APPLICATIONS_CREATION_DATE')}</span>
                                                                        <div className="cursor-pointer" onClick={() => handlerClickSort('start_date')}>
                                                                            <svg width="11" height="22" viewBox="0 0 11 22" fill="none">
                                                                                <path
                                                                                    d="M9.46854 12.9382H1.53146C1.2934 12.9382 1.16047 13.1896 1.3079 13.3612L5.27644 17.963C5.39003 18.0947 5.60876 18.0947 5.72356 17.963L9.6921 13.3612C9.83953 13.1896 9.7066 12.9382 9.46854 12.9382Z"
                                                                                    fill={upArrowStart}
                                                                                />
                                                                                <path
                                                                                    d="M9.22662 9.4011L5.69888 5.31047C5.59791 5.19338 5.40347 5.19338 5.30142 5.31047L1.77369 9.4011C1.64263 9.55364 1.7608 9.77707 1.97242 9.77707H9.02789C9.23951 9.77707 9.35767 9.55364 9.22662 9.4011Z"
                                                                                    fill={downArrowStart}
                                                                                />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }

                                                            // expected end date
                                                            if (column.id === 'expected_end_date') {
                                                                additionally = (
                                                                    <div className="flex">
                                                                        <span className="mr-1">{t('APPLICATIONS_COMPLETE_DATE')}</span>
                                                                        <div className="cursor-pointer" onClick={() => handlerClickSort('expected_end_date')}>
                                                                            <svg width="11" height="22" viewBox="0 0 11 22" fill="none">
                                                                                <path
                                                                                    d="M9.46854 12.9382H1.53146C1.2934 12.9382 1.16047 13.1896 1.3079 13.3612L5.27644 17.963C5.39003 18.0947 5.60876 18.0947 5.72356 17.963L9.6921 13.3612C9.83953 13.1896 9.7066 12.9382 9.46854 12.9382Z"
                                                                                    fill={upArrowEnd}
                                                                                />
                                                                                <path
                                                                                    d="M9.22662 9.4011L5.69888 5.31047C5.59791 5.19338 5.40347 5.19338 5.30142 5.31047L1.77369 9.4011C1.64263 9.55364 1.7608 9.77707 1.97242 9.77707H9.02789C9.23951 9.77707 9.35767 9.55364 9.22662 9.4011Z"
                                                                                    fill={downArrowEnd}
                                                                                />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }

                                                            //tasks
                                                            if (column.id === 'edit') {
                                                                additionally = (
                                                                    <div className="w-6">
                                                                        <button type="button">
                                                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                                <path
                                                                                    d="M8 18V12H10V14H18V16H10V18H8ZM0 16V14H6V16H0ZM4 12V10H0V8H4V6H6V12H4ZM8 10V8H18V10H8ZM12 6V0H14V2H18V4H14V6H12ZM0 4V2H10V4H0Z"
                                                                                    fill="#1C1B1F"
                                                                                />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                );
                                                                // styles = 'md:w-10';
                                                            }
                                                            const style = { position: 'static' };
                                                            return (
                                                                <th
                                                                    {...column.getHeaderProps({ style })}
                                                                    className="border-y border-gray-10 bg-white px-2 py-5 font-bold first:rounded-tl-lg first:border-l first:pl-4 last:rounded-tr-lg last:border-r last:pr-4"
                                                                >
                                                                    <div
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        ref={provided.innerRef}
                                                                        style={{
                                                                            ...getItemStyle(snapshot, provided.draggableProps.style),
                                                                        }}
                                                                    >
                                                                        {additionally || column.render('Header')}
                                                                    </div>
                                                                </th>
                                                            );
                                                        }}
                                                    </Draggable>
                                                ))}
                                            </tr>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            ))}
                        </thead>

                        <tbody className="w-full" {...getTableBodyProps()}>
                            {rows.map((row, idx) => {
                                prepareRow(row);
                                return (
                                    <tr
                                        {...row.getRowProps({ style: { position: 'static' } })}
                                        onClick={e => handlerShowApplication(e, row.original.id)}
                                        className="mb-4 block h-20 cursor-pointer overflow-hidden rounded-lg border border-gray-10 bg-white hover:bg-sky-100 md:mb-0 md:table-row md:overflow-visible md:border-0"
                                    >
                                        {row.cells.map(cell => {
                                            let additionally = '';
                                            let styles = ' ';
                                            // select
                                            if (cell.column.id === 'select') {
                                                additionally = (
                                                    // скрыт чекбокс
                                                    <div className="hidden w-5">
                                                        <input
                                                            type="checkbox"
                                                            name="select"
                                                            value={cell.row.original.id}
                                                            className="rounded-sm border-stone-300"
                                                            onChange={e => applicationSelect(e)}
                                                            checked={allSelectedCheckbox ? allSelectedCheckbox : setSelected(cell.row.original.id)}
                                                        />
                                                    </div>
                                                );
                                                styles = 'sm:hidden';
                                            }

                                            // status
                                            if (cell.column.id === 'status') {
                                                let statusJSX = '';
                                                switch (cell.row.original.status_id) {
                                                    // new
                                                    case 2:
                                                        statusJSX = (
                                                            <div className="text-status_text-new">
                                                                <div>
                                                                    <div className="mb-2 flex w-20 items-center justify-center rounded-[100px] border border-status_border-new bg-status_bg-new px-2 py-1 text-center text-xs font-normal lowercase leading-3">
                                                                        {t('APPLICATIONS_STATUS_NEW')}
                                                                    </div>
                                                                    <SignsIcons data={cell.row.original} />
                                                                </div>
                                                            </div>
                                                        );
                                                        break;
                                                    // deferredдень дней дня
                                                    case 1:
                                                        statusJSX = (
                                                            <div className="text-status_text-deferred">
                                                                <div>
                                                                    <div className="mb-2 flex w-20 items-center justify-center rounded-[100px] border border-status_border-deferred bg-status_bg-deferred px-2 py-1 text-center text-xs font-normal lowercase leading-3">
                                                                        {t('APPLICATIONS_STATUS_DEFERRED')}
                                                                    </div>
                                                                    <SignsIcons data={cell.row.original} />
                                                                </div>
                                                            </div>
                                                        );
                                                        break;
                                                    // in work
                                                    case 3:
                                                        statusJSX = (
                                                            <div className="whitespace-nowrap text-status_text-in_work">
                                                                <div>
                                                                    <div className="mb-2 flex w-20 items-center justify-center rounded-[100px] border border-status_border-in_work bg-status_bg-in_work px-2 py-1 text-center text-xs font-normal lowercase leading-3">
                                                                        {t('APPLICATIONS_STATUS_IN_WORK')}
                                                                    </div>
                                                                    <SignsIcons data={cell.row.original} />
                                                                </div>
                                                            </div>
                                                        );
                                                        break;
                                                    // finished
                                                    case 8:
                                                        statusJSX = (
                                                            <div className="text-status_text-finished">
                                                                <div>
                                                                    <div className="mb-2 flex w-20 items-center justify-center rounded-[100px] border border-status_border-finished bg-status_bg-finished px-2 py-1 text-xs font-normal lowercase leading-3">
                                                                        {t('APPLICATIONS_STATUS_FINISHED')}
                                                                    </div>
                                                                    <SignsIcons data={cell.row.original} />
                                                                </div>
                                                            </div>
                                                        );
                                                        break;
                                                    // completed
                                                    case 5:
                                                        statusJSX = (
                                                            <div className="text-status_text-completed">
                                                                <div>
                                                                    <div className="mb-2 flex w-20 items-center justify-center rounded-[100px] border border-status_border-completed bg-status_bg-completed px-2 py-1 text-center text-xs font-normal lowercase leading-3">
                                                                        {t('APPLICATIONS_STATUS_COMPLETED')}
                                                                    </div>
                                                                    <SignsIcons data={cell.row.original} />
                                                                </div>
                                                            </div>
                                                        );
                                                        break;
                                                    // canceled
                                                    case 6:
                                                        statusJSX = (
                                                            <div className="text-status_text-canceled">
                                                                <div>
                                                                    <div className="mb-2 flex w-20 items-center justify-center rounded-[100px] border border-status_border-canceled bg-status_bg-canceled px-2 py-1 text-center text-xs font-normal lowercase leading-3">
                                                                        {t('APPLICATIONS_STATUS_CANCELED')}
                                                                    </div>
                                                                    <SignsIcons data={cell.row.original} />
                                                                </div>
                                                            </div>
                                                        );
                                                        break;
                                                    default:
                                                        break;
                                                }

                                                additionally = <div className="font-semibold"> {statusJSX}</div>;
                                            }

                                            // venue_name
                                            if (cell.column.id === 'venue_name') {
                                                let statusJSX = '';

                                                switch (cell.row.original.department_id) {
                                                    case 2:
                                                        statusJSX = <span>{t('ADM_PLANNER_EXPLOTATIO')}</span>;
                                                        break;
                                                    case 3:
                                                        statusJSX = <span>{t('ADM_PLANNER_CLEANING')}</span>;
                                                        break;
                                                    // completed
                                                    case 5:
                                                        statusJSX = <span>{t('ADM_PLANNER_CONTROL')}</span>;
                                                        break;
                                                    // canceled
                                                    case 6:
                                                        statusJSX = <span>{t('ADM_PLANNER_IT_DEPAR')}</span>;
                                                        break;

                                                    default:
                                                        break;
                                                }
                                                additionally = (
                                                    <>
                                                        <p className="line-clamp-2 overflow-hidden text-ellipsis font-semibold text-black">
                                                            {cell.row.original.venue_name}
                                                        </p>
                                                        {statusJSX}
                                                    </>
                                                );
                                            }

                                            // start_date
                                            if (cell.column.id === 'start_date') {
                                                additionally = <div>{getRenderDate(cell.row.original.start_date, lang)}</div>;
                                            }

                                            // expected_end_date
                                            if (cell.column.id === 'expected_end_date') {
                                                additionally = <div>{getRenderDate(cell.row.original.expected_end_date, lang)}</div>;
                                            }

                                            // description
                                            if (cell.column.id === 'description') {
                                                additionally = (
                                                    <div data-tip={cell.row.original.description} className="line-clamp-3 h-full overflow-hidden text-ellipsis">
                                                        {cell.row.original.description}
                                                    </div>
                                                );
                                            }

                                            // executor
                                            if (cell.column.id === 'user_name') {
                                                additionally = (
                                                    <div>
                                                        {cell.row.original.user_name === '  ' ? t('CALENDAR_DETAILS_EXEC_STATUS') : cell.row.original.user_name}
                                                    </div>
                                                );
                                            }

                                            // edit
                                            // if (cell.column.id === 'edit') {
                                            //     additionally = <span className='className="w-6'></span>;
                                            //     styles = 'md:w-10';
                                            // }

                                            return (
                                                <td
                                                    key={cell.row.original.id + cell.column.id}
                                                    {...cell.getCellProps({ style: { position: 'static' } })}
                                                    className={`${styles} flex justify-between border-b border-gray-10 px-2 text-right font-medium text-zinc-500 before:font-medium before:uppercase before:content-[attr(data-label)] last:border-b-0 md:table-cell md:text-left md:before:hidden md:first:border-l md:first:pl-4 md:last:border-b md:last:border-r md:last:pr-4 ${
                                                        rows.length === idx + 1 ? 'first:rounded-bl-lg last:rounded-br-lg' : ''
                                                    } `}
                                                >
                                                    {additionally || cell.render('Cell')}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </InfiniteScroll>
            </div>
            <ReactTooltip textColor="#FCFCFC" backgroundColor="#707183E5" effect="solid" className="!rounded-md !px-2.5 !py-2" />
        </>
    );
};
