import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { url } from '../../../../../api/config';
import { toastOptions } from '../../../../../functions/toastOptions';
import { PicsLoader } from '../../../../admin_scheduler/detail/PicsLoader';

const SignupSchema = Yup.object().shape({
    title: Yup.string().min(2, 'Поле "Название" слишком короткое').max(100, 'Поле "Название" слишком длинное').required('Поле "Название" обязательное'),
    description: Yup.string().min(2, 'Поле "Описание" слишком короткое').max(1000, 'Поле "Описание" слишком длинное'),
});

export const DocumentInformationForm = ({ info, formRef }) => {
    const [files, setFiles] = useState(
        info.background_image
            ? [
                  {
                      preview: `${url}/${info.background_image}`,
                      type: 'image/jpeg',
                      name: info.background_image,
                      flag: true,
                  },
              ]
            : []
    );
    const accept = { 'image/*': [] };

    return (
        <div className="mb-6">
            <Formik
                initialValues={{ title: info.title, description: info.description, background_image: files }}
                validationSchema={SignupSchema}
                innerRef={formRef}
            >
                {({ errors, isValidating, isSubmitting, setFieldValue }) => {
                    if (isSubmitting && !isValidating) {
                        for (const err in errors) {
                            toast.error(errors[err].toString(), toastOptions);
                        }
                    }

                    return (
                        <Form>
                            <div className="mb-6">
                                <div className="mb-1 text-sm font-bold">Название BMS системы*</div>
                                <Field
                                    name="title"
                                    type="text"
                                    placeholder="Введите название BMS системы"
                                    className="box-border h-12 w-[33rem] rounded border border-gray-10 p-2 text-sm"
                                />
                            </div>
                            <div className="mb-6">
                                <div className="mb-1 text-sm font-bold">Описание BMS системы</div>
                                <Field
                                    name="description"
                                    as="textarea"
                                    placeholder="Введите описание BMS системы"
                                    className="box-border h-32 w-[33rem] resize-none rounded border border-gray-10 p-2 text-sm"
                                />
                            </div>

                            <div className="mb-6 mt-4 w-full">
                                <div className="mb-1 text-sm font-bold">Фон системы</div>
                                <div className="flex gap-6">
                                    <PicsLoader
                                        disabled={isSubmitting}
                                        files={files}
                                        setFiles={setFiles}
                                        maxFiles={1}
                                        setFieldValue={setFieldValue}
                                        accept={accept}
                                    />
                                    <div>
                                        <div className="mb-2 text-sm">Параметры фона:</div>
                                        <ul className="text-xs">
                                            <li> Форматы: jpg, jpeg, png, svg.</li>
                                            <li> Объем: 20 Мб</li>
                                            <li>Размер картинки: 16 Х 9</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};
