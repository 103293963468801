import { useMatches } from 'react-router-dom';

import { Breadcrumbs } from '../breadcrumbs/Breadcrumbs';

export const SubHeaderLeft = ({ children }) => {
    const matches = useMatches();
    const crumbs = matches
        .filter(match => Boolean(match.handle?.crumb))
        .map((match, index, arr) => (arr[index + 1] ? match.handle.crumb(true) : match.handle.crumb()));

    return (
        <div className="flex min-w-fit flex-col justify-center">
            <Breadcrumbs crumbs={crumbs} />
            <div className="flex min-h-[2rem] items-center">{children}</div>
        </div>
    );
};
