import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { ErrorPopup } from '../../components/bms/mainPage/ErrorPopup';
import { Loader } from '../../components/common/Loader';
import { useCheckRole } from '../../hooks/useCheckRole';
import { getBMSServersStats, getBMSServersStatsLP } from '../../redux/slices/bms/bmsSliceServers';
import { getUserInfo, userInfoFetchingState } from '../../redux/slices/bms/bmsSliceVenues';

export const BMSPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const userFetching = useSelector(userInfoFetchingState);
    const isObjectView = useCheckRole('bms', 'bms.object.view');

    useEffect(() => {
        const controller = new AbortController();

        // Получить роли
        dispatch(getUserInfo());
        if (isObjectView) {
            // Получить статус серверов
            dispatch(getBMSServersStats());

            // Получить статус серверов long polling
            dispatch(getBMSServersStatsLP(controller.signal));
        }

        return () => {
            controller.abort();
        };
    }, [isObjectView]);

    useEffect(() => {
        if (location.pathname === '/bms') {
            navigate('/bms/venues');
        }
    }, [location]);

    if (userFetching) {
        return (
            <div className="flex h-full w-full items-center justify-center">
                <Loader />
            </div>
        );
    }

    return (
        <>
            <Outlet />
            <ErrorPopup />
        </>
    );
};
