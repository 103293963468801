export const DataInput = ({ data, handlerInputChange, type, title }) => {
    return (
        <>
            <span className="mr-2 text-sm">{title}:</span>
            <input
                type="number"
                value={data[type]}
                className="h-8 w-44 text-sm"
                onChange={e => handlerInputChange(e.target.value, type)}
                disabled={!data.writePoint}
            />
        </>
    );
};
