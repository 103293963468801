import { NavLink, useLocation } from 'react-router-dom';

import { SvgComponent } from '../../svgComponent/SvgComponent';

export const MenuItem = ({ link, svgName = 'check-all', title }) => {
    const { pathname } = useLocation();

    return (
        <NavLink
            to={link}
            className={`flex items-center gap-3 py-3 pl-4 pr-3 transition duration-150 hover:border-r-2 hover:border-blue hover:bg-blue-10 hover:font-semibold hover:text-blue ${
                pathname.includes(link) ? 'border-r-2 border-blue bg-blue-10 font-semibold text-blue' : 'text-gray'
            }`}
        >
            <div className="w-5 shrink-0">
                <SvgComponent name={svgName} />
            </div>

            <div className="line-clamp-1 w-full text-sm duration-150 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100">{title}</div>
        </NavLink>
    );
};
