import en from 'date-fns/locale/en-US';
import ru from 'date-fns/locale/ru';
import { Field, Form, Formik, useField, useFormikContext } from 'formik';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import SlidingPane from 'react-sliding-pane';

import {
    additional_filter as additional_filter_state,
    clear_filter,
    filter_fetch as filter_fetch_state,
    filter_is_open as filter_is_open_state,
    filter as filter_state,
    set_addit_filter,
    set_additional_filter,
    set_filter_open,
    usersListFetch,
    venues as venues_state,
} from '../../redux/slices/applicationsSlice';
import { auth_key_calendar as auth_key_calendar_state, username as username_state } from '../../redux/slices/loginSlice';
import { Loader } from '../common/Loader';

export const AdditionalFilter = () => {
    const initialValues = {
        subtask: '',
        filter_signs: [],
        filter_source: [],
        filter_department: [],
        filter_priority: [],
        filter_venue: [],
        executor: '',
        creator: '',
        filter_id: '',
        createdAtMin: '',
        createdAtMax: '',
        startDateMin: '',
        startDateMax: '',
        expectedEndDateMin: '',
        expectedEndDateMax: '',
        endDateMin: '',
        endDateMax: '',
    };
    const [openSource, setOpenSource] = useState(false);
    const [initValue, setInitValue] = useState(initialValues);
    const dispatch = useDispatch();
    const additFilterRef = useRef(true);

    const filter = useSelector(filter_state);
    const username = useSelector(username_state);
    const venues_options = useSelector(venues_state);
    const filter_fetch = useSelector(filter_fetch_state);
    const filter_is_open = useSelector(filter_is_open_state);
    const additional_filter = useSelector(additional_filter_state);
    const auth_key_calendar = useSelector(auth_key_calendar_state);

    const [cookie] = useCookies();
    const lang = cookie.i18next;

    const users_options = filter_fetch.users;
    const status_options = filter_fetch.status;
    const source_options = filter_fetch.category;
    const priority_options = filter_fetch.priority;
    const executors_options = filter_fetch.executors;
    const departments_options = filter_fetch.departments;

    const closeHandler = async () => {
        dispatch(set_filter_open(false));
        setOpenSource(false);
    };
    useEffect(() => {
        if (filter_is_open && executors_options.length === 0) {
            const type = 2;
            dispatch(usersListFetch({ username, auth_key_calendar, lang, type }));
        }
        if (filter_is_open && users_options.length === 0) {
            const type = 1;
            dispatch(usersListFetch({ username, auth_key_calendar, lang, type }));
        }
    }, [username, auth_key_calendar, lang, filter_is_open, dispatch, executors_options.length, users_options.length]);

    useEffect(() => {
        if (additFilterRef.current) {
            additFilterRef.current = false;
            return;
        }
        if (filter && filter_is_open) {
            setInitValue({
                ...filter,
                createdAtMin: filter.createdAtMin
                    ? new Date(filter.createdAtMin.split('.')[2], filter.createdAtMin.split('.')[1] - 1, filter.createdAtMin.split('.')[0])
                    : '',
                createdAtMax: filter.createdAtMax
                    ? new Date(filter.createdAtMax.split('.')[2], filter.createdAtMax.split('.')[1] - 1, filter.createdAtMax.split('.')[0])
                    : '',
                startDateMin: filter.startDateMin
                    ? new Date(filter.startDateMin.split('.')[2], filter.startDateMin.split('.')[1] - 1, filter.startDateMin.split('.')[0])
                    : '',
                startDateMax: filter.startDateMax
                    ? new Date(filter.startDateMax.split('.')[2], filter.startDateMax.split('.')[1] - 1, filter.startDateMax.split('.')[0])
                    : '',
                expectedEndDateMin: filter.expectedEndDateMin
                    ? new Date(filter.expectedEndDateMin.split('.')[2], filter.expectedEndDateMin.split('.')[1] - 1, filter.expectedEndDateMin.split('.')[0])
                    : '',
                expectedEndDateMax: filter.expectedEndDateMax
                    ? new Date(filter.expectedEndDateMax.split('.')[2], filter.expectedEndDateMax.split('.')[1] - 1, filter.expectedEndDateMax.split('.')[0])
                    : '',
                endDateMin: filter.endDateMin
                    ? new Date(filter.endDateMin.split('.')[2], filter.endDateMin.split('.')[1] - 1, filter.endDateMin.split('.')[0])
                    : '',
                endDateMax: filter.endDateMax
                    ? new Date(filter.endDateMax.split('.')[2], filter.endDateMax.split('.')[1] - 1, filter.endDateMax.split('.')[0])
                    : '',
            });
        }
    }, [filter, filter_is_open]);

    const formSubmit = async (values, { setSubmitting }) => {
        const filter = {
            ...values,
            createdAtMin: values.createdAtMin
                ? `${String(values.createdAtMin.getDate()).padStart(2, 0)}.${String(values.createdAtMin.getMonth() + 1).padStart(2, 0)}.${String(
                      values.createdAtMin.getFullYear()
                  )}`
                : '',
            createdAtMax: values.createdAtMax
                ? `${String(values.createdAtMax.getDate()).padStart(2, 0)}.${String(values.createdAtMax.getMonth() + 1).padStart(2, 0)}.${String(
                      values.createdAtMax.getFullYear()
                  )}`
                : '',
            startDateMin: values.startDateMin
                ? `${String(values.startDateMin.getDate()).padStart(2, 0)}.${String(values.startDateMin.getMonth() + 1).padStart(2, 0)}.${String(
                      values.startDateMin.getFullYear()
                  )}`
                : '',
            startDateMax: values.startDateMax
                ? `${String(values.startDateMax.getDate()).padStart(2, 0)}.${String(values.startDateMax.getMonth() + 1).padStart(2, 0)}.${String(
                      values.startDateMax.getFullYear()
                  )}`
                : '',
            expectedEndDateMin: values.expectedEndDateMin
                ? `${String(values.expectedEndDateMin.getDate()).padStart(2, 0)}.${String(values.expectedEndDateMin.getMonth() + 1).padStart(2, 0)}.${String(
                      values.expectedEndDateMin.getFullYear()
                  )}`
                : '',
            expectedEndDateMax: values.expectedEndDateMax
                ? `${String(values.expectedEndDateMax.getDate()).padStart(2, 0)}.${String(values.expectedEndDateMax.getMonth() + 1).padStart(2, 0)}.${String(
                      values.expectedEndDateMax.getFullYear()
                  )}`
                : '',
            endDateMin: values.endDateMin
                ? `${String(values.endDateMin.getDate()).padStart(2, 0)}.${String(values.endDateMin.getMonth() + 1).padStart(2, 0)}.${String(
                      values.endDateMin.getFullYear()
                  )}`
                : '',
            endDateMax: values.endDateMax
                ? `${String(values.endDateMax.getDate()).padStart(2, 0)}.${String(values.endDateMax.getMonth() + 1).padStart(2, 0)}.${String(
                      values.endDateMax.getFullYear()
                  )}`
                : '',
            page: 1,
        };

        setSubmitting(false);
        setOpenSource(false);
        await dispatch(set_addit_filter({ ...filter }));
        await dispatch(set_filter_open(false));
        await dispatch(set_additional_filter(true));
    };
    const CustomSelectField = props => {
        const [, , helpers] = useField(props);
        const { setValue } = helpers;
        const value = initValue?.[props.name];
        return (
            <Select
                defaultValue={
                    value
                        ? props.options.map(item => {
                              for (const el of value) {
                                  if (item.value === el) {
                                      return item;
                                  }
                              }
                          })
                        : ''
                }
                onChange={item => {
                    setValue(item.map(el => el.value));
                }}
                placeholder={t('ADM_PLANNER_PLACEHOLD_DEPART_PL')}
                {...props}
            />
        );
    };

    const CustomDatePickerField = props => {
        const { setFieldValue } = useFormikContext();
        const [field] = useField(props);

        return (
            <DatePicker
                {...field}
                {...props}
                selected={field.value || null}
                onChange={val => {
                    setFieldValue(field.name, val);
                }}
            />
        );
    };

    const handlerClearClick = async resetForm => {
        if (additional_filter) {
            await dispatch(clear_filter());
            await dispatch(set_additional_filter(false));
            resetForm({ values: initialValues });
        }
        resetForm({ values: initialValues });
    };
    return (
        <>
            <SlidingPane
                isOpen={filter_is_open}
                hideHeader={true}
                onRequestClose={closeHandler}
                overlayClassName="z-40 bg-slate-900 bg-opacity-30 transition-opacity duration-200 opacity-100"
                width="26rem"
            >
                <Formik initialValues={initValue} onSubmit={formSubmit}>
                    {({ values, isSubmitting, resetForm }) => (
                        <Form className="flex flex-col">
                            <div className="sticky -top-6 z-50 border-b-2 bg-white">
                                <div className="z-20 mb-2 flex w-full items-start justify-between bg-white">
                                    <div className="flex flex-col text-2xl font-bold text-black">
                                        <span>{t('APPLICATIONS_ADDIT_FILTER')}</span>
                                        {/* <span className="font-normal text-sm mt-2">{application ? '' : t('APPLICATIONS_NEW_NOTTIF')}</span> */}
                                    </div>
                                    <button
                                        type="button"
                                        className="flex h-9 w-9 items-center justify-center rounded-full bg-transparent p-0 hover:bg-gray-100"
                                        onClick={closeHandler}
                                    >
                                        <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
                                            <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
                                        </svg>
                                    </button>
                                </div>
                                <button
                                    type="reset"
                                    onClick={() => {
                                        handlerClearClick(resetForm);
                                    }}
                                    className="mb-3 h-8 w-24 rounded-lg bg-blue px-2 text-sm font-medium text-white transition-colors duration-200 hover:bg-blue-500"
                                >
                                    {t('APPLICATIONS_CLEAR')}
                                </button>
                            </div>
                            {/* subtask */}
                            <div className="border-b">
                                <label className="my-6 ml-2 flex items-center">
                                    <Field type="checkbox" name="subtask" />
                                    <p className="ml-3">{t('APPLICATIONS_SUB_TASK')}</p>
                                </label>
                            </div>

                            {/* status */}
                            <div className="ml-2 mt-6 border-b text-lg font-medium">
                                <p className="text-black">{t('APPLICATIONS_SEARCH_STATUS')}</p>
                                <div>
                                    {status_options.map(el => {
                                        return (
                                            <label key={el.label} className="my-4 flex items-center text-base font-normal">
                                                <Field type="checkbox" name="filter_status" value={`${el.value}`} />
                                                <p className="ml-3">{el.label}</p>
                                            </label>
                                        );
                                    })}
                                </div>
                            </div>

                            {/* signs */}
                            <div className="ml-2 mt-6 border-b text-lg font-medium">
                                <p className="text-black">{t('APPLICATIONS_SEARCH_SIGN')}</p>
                                <div>
                                    <label className="my-4 flex items-center text-base font-normal">
                                        <Field type="checkbox" name="filter_signs" value="repeating" />
                                        <p className="ml-3">{t('APPLICATIONS_SIGNS_REPEATING')}</p>
                                    </label>
                                    <label className="my-4 flex items-center text-base font-normal">
                                        <Field type="checkbox" name="filter_signs" value="overdue" />
                                        <p className="ml-3">{t('APPLICATIONS_SIGNS_OVERDUE')}</p>
                                    </label>
                                    <label className="my-4 mb-6 flex items-center text-base font-normal">
                                        <Field type="checkbox" name="filter_signs" value="sabbath" />
                                        <p className="ml-3">{t('APPLICATIONS_SIGNS_SABBATH')}</p>
                                    </label>
                                </div>
                            </div>

                            {/* departments */}
                            <div className="ml-2 mt-6 border-b text-lg font-medium">
                                <p className="text-black">{t('APPLICATIONS_FORM_DEPARTMENT')}</p>
                                <div>
                                    {departments_options.map(el => (
                                        <label key={el.label} className="my-4 flex items-center text-base font-normal">
                                            <Field type="checkbox" name="filter_department" value={`${el.value}`} />
                                            <p className="ml-3">{el.label}</p>
                                        </label>
                                    ))}
                                </div>
                            </div>

                            {/* sources */}
                            <div className="ml-2 mt-6 border-b pb-6 text-lg font-medium">
                                <p className="text-black">{t('APPLICATIONS_FORM_SOURCE')}</p>
                                <div>
                                    {source_options.slice(0, 4).map(el => {
                                        return (
                                            <label key={el.label} className="my-4 flex items-center text-base font-normal">
                                                <Field type="checkbox" name="filter_source" value={`${el.value}`} />
                                                <p className="ml-3">{el.label}</p>
                                            </label>
                                        );
                                    })}
                                    <button
                                        className="ml-9 text-base font-normal text-status_text-new"
                                        type="button"
                                        onClick={() => setOpenSource(!openSource)}
                                    >
                                        {openSource ? t('APPLICATIONS_HIDE_ALL') : t('APPLICATIONS_SHOW_ALL')}
                                    </button>
                                    {openSource &&
                                        source_options.slice(4).map(el => {
                                            return (
                                                <label key={el.label} className="my-4 flex items-center text-base font-normal last:mb-0">
                                                    <Field type="checkbox" name="filter_source" value={`${el.value}`} />
                                                    <p className="ml-3">{el.label}</p>
                                                </label>
                                            );
                                        })}
                                </div>
                            </div>

                            {/* priority */}
                            <div className="ml-2 mt-6 border-b text-lg font-medium">
                                <p className="text-black">{t('APPLICATIONS_FORM_PRIORITY')}</p>
                                <div>
                                    {priority_options.map(el => (
                                        <label key={el.label} className="my-4 flex items-center text-base font-normal">
                                            <Field type="checkbox" name="filter_priority" value={`${el.value}`} />
                                            <p className="ml-3">{el.label}</p>
                                        </label>
                                    ))}
                                </div>
                            </div>

                            {/* description */}
                            <div className="mb-4 ml-2 mt-6">
                                <p className="text-sm">{t('ADM_PLANNER_TASK_DESCRIPTION')}</p>
                                <Field
                                    // as="input"
                                    component="textarea"
                                    name="filter_id"
                                    value={values.filter_id}
                                    // onChange={e => setDescription(e.target.value)}
                                    className="descriptionAddit font-base mt-1 h-11 w-full pl-2"
                                />
                            </div>

                            {/* venues */}
                            <div className="additFilter mb-4 ml-2">
                                <p className="text-sm">{t('ADM_PLANNER_OBJECTS')}</p>
                                <CustomSelectField
                                    name="filter_venue"
                                    options={venues_options}
                                    placeholder={t('APPLICATIONS_DETAILS_PLH')}
                                    className="select addFilterPlh mt-1 w-full text-sm"
                                    isClearable={true}
                                    isSearchable={true}
                                    isMulti
                                    classNamePrefix="filter"
                                />
                            </div>

                            {/* executors */}
                            <div className="mb-4 ml-2">
                                <p className="text-sm">{t('APPLICATIONS_DETAILS_EXEC')}</p>
                                <CustomSelectField
                                    name="filter_executor"
                                    options={executors_options}
                                    placeholder={t('APPLICATIONS_DETAILS_PLH')}
                                    className="select addFilterPlh mt-1 h-9 w-full text-sm"
                                    isClearable={true}
                                    isSearchable={true}
                                    isMulti
                                    classNamePrefix="filter"
                                />
                            </div>

                            {/* creators */}
                            <div className="mb-4 ml-2">
                                <p className="text-sm">{t('APPLICATIONS_FORM_APPLICANT')}</p>
                                <CustomSelectField
                                    name="filter_creators"
                                    options={users_options}
                                    placeholder={t('APPLICATIONS_DETAILS_PLH')}
                                    className="select addFilterPlh mt-1 h-9 w-full text-sm"
                                    isClearable={true}
                                    isSearchable={true}
                                    isMulti
                                    classNamePrefix="filter"
                                />
                            </div>

                            {/* date of creation*/}
                            <div className="mb-4 ml-2">
                                <p className="text-sm">{t('APPLICATIONS_CREATION_DATE')}</p>
                                <div className="mt-1 flex text-sm">
                                    <CustomDatePickerField
                                        name="createdAtMin"
                                        className="mr-3 w-1/2"
                                        disabledKeyboardNavigation
                                        locale={lang === 'ru' ? ru : en}
                                        dateFormat="dd.MM.yyyy"
                                        fixedHeight
                                    />
                                    <CustomDatePickerField
                                        name="createdAtMax"
                                        className="ml-3 w-1/2"
                                        disabledKeyboardNavigation
                                        locale={lang === 'ru' ? ru : en}
                                        dateFormat="dd.MM.yyyy"
                                        fixedHeight
                                        minDate={values?.date_creation_start}
                                    />
                                </div>
                            </div>

                            {/* date of start */}
                            <div className="mb-4 ml-2">
                                <p className="text-sm">{t('CALENDAR_DETAILS_DATA_OF_START')}</p>
                                <div className="mt-1 flex text-sm">
                                    <CustomDatePickerField
                                        name="startDateMin"
                                        className="mr-3 w-1/2"
                                        disabledKeyboardNavigation
                                        locale={lang === 'ru' ? ru : en}
                                        dateFormat="dd.MM.yyyy"
                                        fixedHeight
                                    />
                                    <CustomDatePickerField
                                        name="startDateMax"
                                        className="ml-3 w-1/2"
                                        disabledKeyboardNavigation
                                        locale={lang === 'ru' ? ru : en}
                                        dateFormat="dd.MM.yyyy"
                                        fixedHeight
                                        minDate={values?.date_start_start}
                                    />
                                </div>
                            </div>

                            {/* date of completed */}
                            <div className="mb-4 ml-2">
                                <p className="text-sm">{t('APPLICATIONS_DETAILS_END_DATE')}</p>
                                <div className="mt-1 flex text-sm">
                                    <CustomDatePickerField
                                        name="expectedEndDateMin"
                                        className="mr-3 w-1/2"
                                        disabledKeyboardNavigation
                                        locale={lang === 'ru' ? ru : en}
                                        dateFormat="dd.MM.yyyy"
                                        fixedHeight
                                    />
                                    <CustomDatePickerField
                                        name="expectedEndDateMax"
                                        className="ml-3 w-1/2"
                                        disabledKeyboardNavigation
                                        locale={lang === 'ru' ? ru : en}
                                        dateFormat="dd.MM.yyyy"
                                        fixedHeight
                                        minDate={values?.date_end_start}
                                    />
                                </div>
                            </div>

                            {/* date of finish */}
                            <div className="mb-4 ml-2">
                                <p className="text-sm">{t('APPLICATIONS_DATE_OF_FINISH')}</p>
                                <div className="mt-1 flex text-sm">
                                    <CustomDatePickerField
                                        name="endDateMin"
                                        className="mr-3 w-1/2"
                                        disabledKeyboardNavigation
                                        locale={lang === 'ru' ? ru : en}
                                        dateFormat="dd.MM.yyyy"
                                        fixedHeight
                                    />
                                    <CustomDatePickerField
                                        name="endDateMax"
                                        className="ml-3 w-1/2"
                                        disabledKeyboardNavigation
                                        locale={lang === 'ru' ? ru : en}
                                        dateFormat="dd.MM.yyyy"
                                        fixedHeight
                                        minDate={values?.date_finish_start}
                                    />
                                </div>
                            </div>

                            <button
                                type="submit"
                                className="ml-2 mt-6 h-10 w-28 rounded-lg bg-blue px-4 text-sm font-medium text-white transition-colors duration-200 hover:bg-blue-500"
                                disabled={isSubmitting}
                            >
                                <span className="relative text-center">
                                    {isSubmitting ? (
                                        <span className="absolute bottom-[-10px] left-[-10px]">
                                            <Loader height={20} width={20} color="#ffffff" />
                                        </span>
                                    ) : (
                                        t('ADM_PLANNER_BTN_SAVE')
                                    )}
                                </span>
                            </button>
                        </Form>
                    )}
                </Formik>
            </SlidingPane>
        </>
    );
};
