import React, { memo, useMemo } from 'react';
import { useTable } from 'react-table';

const TrendsTableComponent = ({ data }) => {
    const columns = useMemo(
        () => [
            {
                Header: 'Время',
                accessor: 'time',
            },
            ...data.values.map(el => ({
                Header: el.label.replace(/^Root\/Objects\/Data\//, ''),
                accessor: el.label,
            })),
        ],
        [data]
    );

    const tableData = useMemo(
        () =>
            data?.data?.map((el, id) => {
                const res = {};
                res.time = el;
                res.id = data?.values[0]?.data[id][0]; // Присваиваем id один раз

                data?.values?.forEach(el => {
                    if (typeof el.data[id][1] === 'number') {
                        res[el.label] = el.data[id][1].toFixed(2);
                    } else {
                        res[el.label] = 'данных нет';
                    }
                });

                return res;
            }),
        [data]
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data: tableData });

    return (
        <div className="mt-5 h-96 w-full overflow-x-auto" id="chartTable">
            <table {...getTableProps()} className="relative w-full border-separate border-spacing-0 text-sm">
                <thead className="text-left text-black" id="chartTableHead">
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps()}
                                    className="sticky top-0 border-y border-gray-10 bg-white px-2 py-3 font-medium first:rounded-tl-lg first:border-l first:pl-4 last:rounded-tr-lg last:border-r last:pr-4"
                                >
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, index) => {
                        prepareRow(row);
                        return (
                            <tr
                                id={row.original.id}
                                {...row.getRowProps()}
                                className="mb-4 block overflow-hidden rounded-lg border border-gray-10 md:mb-0 md:table-row md:overflow-visible md:border-0"
                            >
                                {row.cells.map(cell => {
                                    let additionally = '';
                                    let styles = {};
                                    if (cell.column.id === 'time') {
                                        additionally = <div>{cell.value}</div>;
                                        styles = '!bg-slate-300 ';
                                    }
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            data-label={cell.column.Header}
                                            className={`flex justify-between border-b border-gray-10 px-2 py-1.5 text-right font-normal before:font-medium before:uppercase before:content-[attr(data-label)] last:border-b-0 md:table-cell md:text-left md:before:hidden md:first:border-l md:first:pl-4 md:last:border-b md:last:border-r md:last:pr-4 ${
                                                rows.length === index + 1 ? 'first:rounded-bl-lg last:rounded-br-lg' : ''
                                            } ${styles}`}
                                        >
                                            {additionally || cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export const TrendsTable = memo(TrendsTableComponent);
