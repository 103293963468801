export const Output = ({ el }) => {
    let outputValue;
    if (el && el.value && el.value.includes('.') && el.value.split('.').length > 1) {
        outputValue = Number(el.value).toFixed(2);
    } else if (el && el.value && el.value.includes(',') && el.value.split(',').length > 1) {
        outputValue = Number(el.value).toFixed(2);
    } else {
        outputValue = el.value;
    }
    return (
        <div
            data-tip={el.data.states[0].writePoint.label ? el.data.states[0].writePoint.label : ''}
            className={`${
                Number(el.value) >= Number(el.data.states[0].min) && Number(el.value) <= Number(el.data.states[0].max) ? 'bg-green-500' : 'bg-red-500'
            } border border-black pl-1 text-white`}
        >
            <span>{outputValue}</span>
            <span className="ml-0.5 whitespace-nowrap">{el.data.states[0].unit.label ? el.data.states[0].unit.label : el.data.states[0].unitUser}</span>
        </div>
    );
};
