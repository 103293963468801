export const OperatorAcceptButton = ({ setPopupState, id }) => {
    const handlerButtonClick = () => {
        setPopupState({ id, isOpen: true });
    };
    return (
        <button
            type="button"
            onClick={() => handlerButtonClick()}
            className="transition-color rounded-lg border bg-slate-50 px-1 py-0.5 text-xs hover:bg-green hover:text-white"
        >
            Подтвердить
        </button>
    );
};
