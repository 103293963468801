import { ButtonRectangular } from '../../common/btn/ButtonRectangular';
import { Popup } from '../../common/popup/Popup';

export const PopupActionConfirmation = ({ isOpen, setIsOpen, callback, element, title }) => {
    return (
        <Popup isOpen={isOpen} setIsOpen={setIsOpen}>
            <div className="text-center">
                {title}: {element.title}?
            </div>
            <div className="ml-auto mr-auto mt-3 flex">
                <ButtonRectangular className="mr-6" onClick={() => callback(element.id)}>
                    Да
                </ButtonRectangular>
                <ButtonRectangular className="border-blue bg-blue hover:bg-blue-500" onClick={() => setIsOpen(false)}>
                    Отмена
                </ButtonRectangular>
            </div>
        </Popup>
    );
};
