import { SimpleSelect } from '../../../../../../../common/widgets/SimpleSelect';

export const DataPointSelect = ({ data, setWritePoint, nodeOptions, setValues, setData, initialState }) => {
    return (
        <SimpleSelect
            isClearable={false}
            options={nodeOptions}
            placeholder="Выберите точку"
            value={data.writePoint}
            isSearchable={true}
            callback={val => {
                if (val === null) {
                    return;
                }
                setWritePoint(val.value);
                setValues([{ ...initialState }]);
                setData({ ...initialState, writePoint: val });
            }}
            className="mr-5 w-56"
        />
    );
};
