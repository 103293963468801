import { useState } from 'react';

export const TriggerControl = ({ control, handlerTriggerClick }) => {
    const [durationBg, setDurationBg] = useState(false);

    const handlerButtonClick = () => {
        const duration = control.data.values[0].duration;

        handlerTriggerClick(control);
        setDurationBg(true);

        setTimeout(() => {
            setDurationBg(false);
        }, Number(duration));
    };
    return (
        <button onClick={() => handlerButtonClick()} className="flex items-center" type="button">
            <div className="border-2 border-gray-30 bg-stone-200 p-1">
                <div className={`border border-gray-50 p-1 ${durationBg ? 'bg-red-500' : 'bg-white'}`}></div>
            </div>
        </button>
    );
};
