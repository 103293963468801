import { useState } from 'react';

export const PresetValueControl = ({ presetValueArr, setPresetValueArr, handlerPresetValueCompleat, control }) => {
    const [value, setValue] = useState(control.value);
    const [focused, setFocused] = useState(false);

    const handlerPresetValueChange = (value, control) => {
        // проверка на ввод числа больше чем с десятыми
        if (control.data.values[0].writePoint.datatype === 'float') {
            const floatCheck = value.split('.');
            if (floatCheck.length === 2 && floatCheck[1].length > 1) {
                return;
            }
        }
        // проверка на целое число
        if (control.data.values[0].writePoint.datatype === 'int') {
            const floatCheck = value.split('.');
            if (floatCheck.length === 2 && floatCheck[1].length >= 1) {
                setPresetValueArr(prev => prev.map(el => (el.cn_id === control.cn_id ? { ...el, value: floatCheck[0] } : el)));
                return;
            }
        }
        //   проверка на более двух нулей подряд
        if (value.length > 1 && value[0] === '0' && value[1] !== '.') {
            setPresetValueArr(prev => prev.map(el => (el.cn_id === control.cn_id ? { ...el, value: value[1] } : el)));
            setValue(value[1]);
            return;
        }
        // проверка отрицательного числа на ввод более двух нулей подряд
        if (value.length === 3 && value[0] === '-' && value[1] === '0' && value[2] !== '.') {
            const val = `${value[0]}${value[2]}`;
            setPresetValueArr(prev => prev.map(el => (el.cn_id === control.cn_id ? { ...el, value: val } : el)));
            setValue(val);
            return;
        }
        setPresetValueArr(prev => prev.map(el => (el.cn_id === control.cn_id ? { ...el, value: value } : el)));
        setValue(value);
    };

    return (
        <input
            data-tip={`min: ${control.data.values[0].min} / max: ${control.data.values[0].max}`}
            type="number"
            value={focused ? value : presetValueArr ? presetValueArr.find(el => el.cn_id === control.cn_id).value : ''}
            onChange={e => handlerPresetValueChange(e.target.value, control)}
            onFocus={() => setFocused(true)}
            onBlur={e => {
                const data = {
                    control,
                    value: e.target.value,
                };
                setValue('');
                handlerPresetValueCompleat(data);
                setFocused(false);
            }}
            onKeyDown={e => {
                if (e.key === 'Enter') {
                    const data = {
                        control,
                        value: e.target.value,
                    };
                    handlerPresetValueCompleat(data);
                    setValue('');
                    setFocused(false);
                }
            }}
            className="h-6 w-20 pl-1.5 text-xs"
        />
    );
};
