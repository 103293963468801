import { useEffect, useMemo, useState } from 'react';
import CustomScroll from 'react-custom-scroll';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { cleanersFetchingState, cleanersState, getCleaners, objectState } from '../../../../../redux/slices/hospitalitySlice';
import { Loader } from '../../../../common/Loader';
import { Draggable } from '../../../../common/dnd/Draggable';
import { Droppable } from '../../../../common/dnd/Droppable';
import { Card } from '../../../card/Card';

export const CleanersSection = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const object = useSelector(objectState);
    const cleaners = useSelector(cleanersState);
    const cleanersFetching = useSelector(cleanersFetchingState);

    const [searchLine, setSearchLine] = useState('');

    const unallocatedCleaners = useMemo(
        () => cleaners.filter(cleaner => !cleaner.supervisorId && cleaner.name.toLowerCase().indexOf(searchLine.toLowerCase()) >= 0),
        [cleaners, searchLine]
    );

    useEffect(() => {
        if (object) {
            dispatch(getCleaners());
        }
    }, [object]);

    useEffect(() => {
        if (unallocatedCleaners.length) {
            ReactTooltip.rebuild();
        }
    }, [unallocatedCleaners]);

    return (
        <div className="w-52 shrink-0 bg-white">
            <div className="px-3 py-3">
                <div className="relative w-full bg-white">
                    <svg width="18" height="18" viewBox="0 0 18 18" className="absolute left-2.5 top-3 fill-gray">
                        <path d="M16.6 18L10.3 11.7C9.8 12.1 9.225 12.4167 8.575 12.65C7.925 12.8833 7.23333 13 6.5 13C4.68333 13 3.14583 12.3708 1.8875 11.1125C0.629167 9.85417 0 8.31667 0 6.5C0 4.68333 0.629167 3.14583 1.8875 1.8875C3.14583 0.629167 4.68333 0 6.5 0C8.31667 0 9.85417 0.629167 11.1125 1.8875C12.3708 3.14583 13 4.68333 13 6.5C13 7.23333 12.8833 7.925 12.65 8.575C12.4167 9.225 12.1 9.8 11.7 10.3L18 16.6L16.6 18ZM6.5 11C7.75 11 8.8125 10.5625 9.6875 9.6875C10.5625 8.8125 11 7.75 11 6.5C11 5.25 10.5625 4.1875 9.6875 3.3125C8.8125 2.4375 7.75 2 6.5 2C5.25 2 4.1875 2.4375 3.3125 3.3125C2.4375 4.1875 2 5.25 2 6.5C2 7.75 2.4375 8.8125 3.3125 9.6875C4.1875 10.5625 5.25 11 6.5 11Z" />
                    </svg>
                    <input
                        type="text"
                        placeholder={t('HOSPITALITY_SEARCH_BY_MAIDS')}
                        className="box-border h-10 w-full rounded border-gray-10 bg-gray-100 pl-9 text-xs"
                        value={searchLine}
                        onChange={e => setSearchLine(e.target.value)}
                    />
                </div>
            </div>

            {cleanersFetching ? (
                <div className="flex w-full items-center justify-center p-6">
                    <Loader height={30} width={30} />
                </div>
            ) : (
                <>
                    <div className="px-3 pb-1 text-sm font-medium text-gray-500">
                        {unallocatedCleaners.length ? (
                            <div className="whitespace-nowrap">
                                {t('HOSPITALITY_EMPLOYEES')}: <span className="font-semibold text-gray-20">{unallocatedCleaners.length}</span>
                            </div>
                        ) : (
                            t('HOSPITALITY_NO_MAIDS_TO_DISTRIBUTE')
                        )}
                    </div>

                    <CustomScroll allowOuterScroll={true}>
                        <Droppable id="default">
                            <div className="box-border flex flex-col gap-2 overflow-hidden bg-white px-3 pb-[75px] pt-1">
                                {!!unallocatedCleaners.length &&
                                    unallocatedCleaners.map(cleaner => (
                                        <Draggable id={cleaner.id} key={cleaner.id}>
                                            <Card description={cleaner.name}>{cleaner.name}</Card>
                                        </Draggable>
                                    ))}
                            </div>
                        </Droppable>
                    </CustomScroll>
                </>
            )}
        </div>
    );
};
