import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { application as application_state } from '../../../redux/slices/applicationsSlice';
import ReactCarousel from '../../common/ReactCarousel';
import helpers from '../detail/helpers';
import { ApplicationPhotoCreator } from './ApplicationPhotoCreator';
import { ApplicationPhotoExecutor } from './ApplicationPhotoExecutor';

export const ApplicationFiles = ({ photo }) => {
    const [createrPhoto, setCreatorPhoto] = useState(true);
    const [executorPhoto, setExecutorPhoto] = useState(false);
    const [newPhoto, setNewPhoto] = useState({});
    const [uploadPhoto, setUploadPhoto] = useState({});

    const application = useSelector(application_state);

    const admFiles = helpers.getImgFiles(application.adm_files);

    useEffect(() => {
        photo(newPhoto);
    }, [newPhoto]);

    const getSelectFile = e => {
        if (e.target.getAttribute('name') === 'creator') {
            setUploadPhoto({ preview: URL.createObjectURL(e.target.files[0]) });
            setNewPhoto({ ...newPhoto, ...{ creatorPhoto: e.target.files } });
        }
        if (e.target.getAttribute('name') === 'executor') {
            setNewPhoto({ ...newPhoto, ...{ executorPhoto: e.target.files } });
        }
    };

    return (
        <div className="mb-6">
            <div className="mb-6 h-[24rem] rounded-xl bg-white">
                <div className="flex cursor-pointer text-center text-base font-semibold">
                    <div
                        className={`flex w-1/2 items-center justify-center py-3 ${
                            createrPhoto ? 'border-b-4 border-status_text-new text-status_text-new opacity-100' : 'border-b-4 border-white opacity-50'
                        } `}
                        onClick={() => {
                            setCreatorPhoto(true);
                            setExecutorPhoto(false);
                        }}
                    >
                        {t('APPLICATIONS_FORM_APPLICANT')}

                        <div>
                            <input type="file" className="hidden" id="inputCreatorPhoto" name="creator" onChange={e => getSelectFile(e)} />
                            <label htmlFor="inputCreatorPhoto" className="cursor-pointer">
                                <svg
                                    width="12"
                                    height="12"
                                    viewBox="0 0 12 12"
                                    className={`ml-2.5 ${createrPhoto ? 'fill-status_text-new' : 'fill-status_text_deffered'}`}
                                >
                                    <path d="M5.16602 11.8334V6.83342H0.166016V5.16675H5.16602V0.166748H6.83268V5.16675H11.8327V6.83342H6.83268V11.8334H5.16602Z" />
                                </svg>
                            </label>
                        </div>
                    </div>
                    <div
                        className={`flex w-1/2 items-center justify-center py-3 ${
                            executorPhoto ? 'border-b-4 border-status_text-new text-status_text-new opacity-100' : 'border-b-4 border-white opacity-50'
                        }`}
                        onClick={() => {
                            setExecutorPhoto(true);
                            setCreatorPhoto(false);
                        }}
                    >
                        {t('APPLICATIONS_EXECUTOR')}
                        <div>
                            <input type="file" className="hidden" id="inputExecutorPhoto" name="executor" onChange={e => getSelectFile(e)} />
                            <label htmlFor="inputExecutorPhoto" className="cursor-pointer">
                                <svg
                                    width="12"
                                    height="12"
                                    viewBox="0 0 12 12"
                                    className={`ml-2.5 ${executorPhoto ? 'fill-status_text-new' : 'fill-status_text_deffered'}`}
                                >
                                    <path d="M5.16602 11.8334V6.83342H0.166016V5.16675H5.16602V0.166748H6.83268V5.16675H11.8327V6.83342H6.83268V11.8334H5.16602Z" />
                                </svg>
                            </label>
                        </div>
                    </div>
                </div>
                {createrPhoto ? <ApplicationPhotoCreator uploadPhoto={uploadPhoto} /> : <ApplicationPhotoExecutor />}
            </div>
            <div className="min-h-[8rem] rounded-xl bg-white">
                <h2 className="border-b p-4 text-sm font-semibold uppercase">{t('APPLICATIONS_FILES')}</h2>
                {createrPhoto ? (
                    <div className="flex">
                        {application.attached_files.length !== 0 || admFiles !== null ? (
                            <ReactCarousel
                                content={application.attached_files.length !== 0 ? application.attached_files : admFiles.otherFiles}
                                options={{
                                    infinite: false,
                                    Dots: false,
                                }}
                            />
                        ) : (
                            <div className="mt-5 w-full text-center text-base font-semibold opacity-50">{t('APPLICATIONS_ATTACHED_FILES')}</div>
                        )}
                    </div>
                ) : (
                    <div className="mt-5 w-full text-center text-base font-semibold opacity-50">{t('APPLICATIONS_ATTACHED_FILES')}</div>
                )}
            </div>
        </div>
    );
};
