import { useTranslation } from 'react-i18next';

import { Button } from '../../ui/button/Button.tsx';

export const SlidingPaneFooter = ({ closePaneHandler, callback, loading = false, children }) => {
    const { t } = useTranslation();

    return (
        <div className="fixed bottom-0 left-0 z-20 h-16 w-full border-t border-t-gray-10 bg-white">
            <div className="flex h-full w-full flex-row items-center px-4">
                {!!children ? (
                    children
                ) : (
                    <>
                        <Button type="success" loading={loading} onClick={callback} width={144}>
                            {t('HOSPITALITY_APPLY')}
                        </Button>

                        <Button type="link" onClick={closePaneHandler} width={144}>
                            {t('HOSPITALITY_CANCEL')}
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
};
