import { SimpleSelect } from '../../../common/widgets/SimpleSelect';

export const SelectPeriod = ({ type, setData, data, options }) => {
    return (
        <div className="ml-2">
            <SimpleSelect
                value={type.period ? type.period : ''}
                options={options}
                callback={val =>
                    setData(prevState => {
                        return { ...prevState, data: { ...prevState.data, typeSettings: data.map(el => (el.id === type.id ? { ...el, period: val } : el)) } };
                    })
                }
                isClearable={false}
                className="controlSelect w-28 text-sm"
                placeholder="Выбор"
                isDisabled={type.typeOptions === 'step' || !type.typeOptions}
            />
        </div>
    );
};
