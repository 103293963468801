// Создать файл из url
export const getFileFromUrl = async (url, name = 'temp.jpg', defaultType = 'image/jpeg') => {
    try {
        const response = await fetch(url, { cache: 'no-store' });
        const data = await response.blob();
        const file = new File([data], name, {
            type: data.type || defaultType,
        });

        return Object.assign(file, {
            preview: url,
            key: `${file.name}`,
        });
    } catch (error) {
        return null;
    }
};
