import { t } from 'i18next';

import { EventLogTable } from '../../components/bms/eventLog/EventLogTable';
import { FilterList } from '../../components/bms/eventLog/filters/FilterList';
import { ContentContainer } from '../../components/common/ContentContainer';
import { SubHeader } from '../../components/common/subheader/SubHeader';
import { SubHeaderLeft } from '../../components/common/subheader/SubHeaderLeft';
import { Title } from '../../components/common/subheader/Title';
import { book } from '../../navigation/book';

export const BMSEventLogPage = () => {
    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <Title>{t(book.bms_event_log.key)}</Title>
                </SubHeaderLeft>
            </SubHeader>

            <ContentContainer>
                <FilterList />
                <EventLogTable />
            </ContentContainer>
        </>
    );
};
