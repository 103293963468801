import { createSlice } from '@reduxjs/toolkit';

import { initApi } from '../../api/modules/initApi';
import { store } from '../store.ts';

export const loginSlice = createSlice({
    name: 'login',
    initialState: {
        username: '',
        auth_key_calendar: null,
        accessToken: null,
        refreshToken: null,
        fullName: '',
        lang: '',
        roles: {
            bms: {
                role: '',
                actions: [],
            },
        },
        id: '',
    },
    reducers: {
        setUser: (state, action) => ({
            ...state,
            ...action.payload,
        }),
        setUserRoles: (state, action) => {
            state.roles = { ...state.roles, ...action.payload };
        },
        logout: state => {
            state.accessToken = null;
            state.refreshToken = null;
            state.auth_key_calendar = null;
            state.username = '';
        },
    },
});

export const { setUser, setUserRoles, logout } = loginSlice.actions;

// Изменение языка
export const setLanguageFetch =
    ({ lang }) =>
    async dispatch => {
        const state = store.getState();

        try {
            if (lang) {
                const response = await initApi.setLanguage.fetch(state.login.username, state.login.auth_key_calendar, lang);
                const res = await response.json();

                if (res.code === 0) {
                    dispatch(setUser({ lang }));
                }
            }
        } catch (e) {
            console.error('setLangFetching error:', e);
        }
    };

export const username = state => state.login.username;
export const auth_key_calendar = state => state.login.auth_key_calendar;
export const userRolesState = state => state.login.roles;
export const refreshTokenState = state => state.login.refreshToken;
export const accessTokenState = state => state.login.accessToken;
export default loginSlice.reducer;
