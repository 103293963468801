export const UseRangeCheckbox = ({ data, setData, setValues, initialState }) => {
    return (
        <div className="flex items-center">
            <span className="mr-2 text-sm">Диапазон</span>
            <input
                disabled={!data.dataPointControl.dataPointControlObj || data.dataPointControl.dataPointControlObj.datatype === 'boolean'}
                className="cursor-pointer hover:border-gray-30"
                name="range"
                type="checkbox"
                value={data.dataPointControl.range}
                checked={data.dataPointControl.range}
                onChange={() => {}}
                onClick={e => {
                    setData({
                        ...data,
                        dataPointControl: {
                            ...initialState.dataPointControl,
                            dataPointControlObj: data.dataPointControl.dataPointControlObj,
                            range: e.target.checked,
                        },
                    });
                    setValues([
                        {
                            ...data,
                            dataPointControl: {
                                ...initialState.dataPointControl,
                                dataPointControlObj: data.dataPointControl.dataPointControlObj,
                                range: e.target.checked,
                            },
                        },
                    ]);
                }}
            />
        </div>
    );
};
