import { createSlice } from '@reduxjs/toolkit';

import { hospitalityAPI } from '../../api/modules/hospitalityAPI';
import { store } from '../store.ts';

export const hospitalitySlice = createSlice({
    name: 'hospitality',
    initialState: {
        // Объекты
        objects: [],
        objectsFetching: false,
        object: null,

        // Слоты
        slots: [],
        slotsFetching: false,

        // Горничные
        cleaners: [],
        cleanersFetching: false,

        // Супервайзеры
        supervisors: [],
        supervisorsFetching: false,

        // Применение изменений
        applyFetching: false,

        // Сброс изменений
        resetFetching: false,

        // Задачи на горничных
        tasks: [],
        tasksFetching: false,

        // Задачи на супервайзеров
        tasksForSupervisors: [],
        tasksForSupervisorsFetching: false,

        // Статусы задач
        tasksStatuses: [],
        tasksStatusesFetching: false,

        // Фильтр задач на горничных
        filterTasks: {
            isTouched: false,
            clearTypes: [],
            statuses: [],
            rooms: {
                from: '',
                to: '',
            },
        },

        // Настройки
        settings: {
            isAuto: 0,
        },

        // Комнаты
        rooms: [],

        // Типовые задачи
        typicalTasks: [],
    },

    reducers: {
        // Объекты
        setObjects: (state, action) => {
            state.objects = action.payload;
        },
        setObjectsFetching: (state, action) => {
            state.objectsFetching = action.payload;
        },
        setObject: (state, action) => {
            state.object = action.payload;
        },

        // Слоты
        setSlots: (state, action) => {
            state.slots = action.payload;
        },
        updateSlots: (state, action) => {
            state.slots = state.slots.map(slot => (slot.id === action.payload.id ? { ...slot, ...action.payload } : slot));
        },
        setSlotsFetching: (state, action) => {
            state.slotsFetching = action.payload;
        },

        // Горничные
        setCleaners: (state, action) => {
            state.cleaners = action.payload;
        },
        updateCleaners: (state, action) => {
            state.cleaners = state.cleaners.map(cleaner => (cleaner.id === action.payload.id ? { ...cleaner, ...action.payload } : cleaner));
        },
        setCleanersFetching: (state, action) => {
            state.cleanersFetching = action.payload;
        },

        // Супервайзеры
        setSupervisors: (state, action) => {
            state.supervisors = action.payload;
        },
        updateSupervisors: (state, action) => {
            state.supervisors = state.supervisors.map(supervisor => (supervisor.id === action.payload.id ? { ...supervisor, ...action.payload } : supervisor));
        },
        setSupervisorsFetching: (state, action) => {
            state.supervisorsFetching = action.payload;
        },

        // Приминение изменений
        setApplyFetching: (state, action) => {
            state.applyFetching = action.payload;
        },

        // Сброс изменений
        setResetFetching: (state, action) => {
            state.resetFetching = action.payload;
        },

        // Задачи на горничных
        setTasks: (state, action) => {
            state.tasks = action.payload;
        },
        updateTasks: (state, action) => {
            state.tasks = state.tasks.map(task => (task.id === action.payload.id ? { ...task, ...action.payload } : task));
        },
        setTasksFetching: (state, action) => {
            state.tasksFetching = action.payload;
        },

        // Фильтр для задач на горничных
        setFilterTasks: (state, action) => {
            state.filterTasks = { ...state.filterTasks, ...action.payload };
        },

        // Задачи на супервайзеров
        setTasksForSupervisors: (state, action) => {
            state.tasksForSupervisors = action.payload;
        },
        updateTasksForSupervisors: (state, action) => {
            state.tasksForSupervisors = state.tasksForSupervisors.map(task => (task.id === action.payload.id ? { ...task, ...action.payload } : task));
        },
        setTasksForSupervisorsFetching: (state, action) => {
            state.tasksForSupervisorsFetching = action.payload;
        },

        // Статусы задач
        setTasksStatuses: (state, action) => {
            state.tasksStatuses = action.payload;
        },
        setTasksStatusesFetching: (state, action) => {
            state.tasksStatusesFetching = action.payload;
        },

        // Настройки
        setSettings: (state, action) => {
            state.settings = { ...state.settings, ...action.payload };
        },

        // Комнаты
        setRooms: (state, action) => {
            state.rooms = action.payload;
        },

        // Типовые задачи
        setTypicalTasks: (state, action) => {
            state.typicalTasks = action.payload;
        },
    },
});

export const {
    setObjects,
    setObjectsFetching,
    setObject,
    setSlots,
    updateSlots,
    setSlotsFetching,
    setCleaners,
    updateCleaners,
    setCleanersFetching,
    setSupervisors,
    updateSupervisors,
    setSupervisorsFetching,
    setTasks,
    updateTasks,
    setTasksFetching,
    setFilterTasks,
    setTasksForSupervisors,
    updateTasksForSupervisors,
    setTasksForSupervisorsFetching,
    setTasksStatuses,
    setTasksStatusesFetching,
    setApplyFetching,
    setResetFetching,
    setSettings,
    setRooms,
    setTypicalTasks,
} = hospitalitySlice.actions;

// Получить список объектов
export const getHospitalityObjects = () => async dispatch => {
    dispatch(setObjectsFetching(true));

    const state = store.getState();

    try {
        const response = await hospitalityAPI.getHospitalityObjects.fetch(state.login.username, state.login.auth_key_calendar, state.login.lang);

        if (response.status === 200) {
            const result = await response.json();

            if (result && result.code === 0 && result.data && result.data.objects && result.data.objects.length) {
                dispatch(setObjects(result.data.objects));
                dispatch(setObject(result.data.objects[0]));
            } else {
                dispatch(setObjects([]));
                dispatch(setObject(null));
            }
        }
    } catch (e) {
        console.log('getHospitalityObjects error: ', e);
    } finally {
        dispatch(setObjectsFetching(false));
    }
};

// Получить список статусов задач
export const getTasksStatuses = () => async dispatch => {
    dispatch(setTasksStatusesFetching(true));

    const state = store.getState();

    try {
        const response = await hospitalityAPI.getTasksStatuses.fetch(state.login.username, state.login.auth_key_calendar, state.login.lang);

        if (response.status === 200) {
            const result = await response.json();

            if (result && result.code === 0 && result.data) {
                dispatch(setTasksStatuses(result.data));
            } else {
                dispatch(setTasksStatuses([]));
            }
        }
    } catch (e) {
        console.log('getTasksStatuses error: ', e);
    } finally {
        dispatch(setTasksStatusesFetching(false));
    }
};

// Получить список помещений (комнат)
export const getRooms = () => async dispatch => {
    const state = store.getState();

    try {
        const response = await hospitalityAPI.getRooms.fetch(state.login.username, state.login.auth_key_calendar, state.login.lang, {
            object_id: state.hospitality.object.id,
        });

        if (response.status === 200) {
            const result = await response.json();

            if (result && result.code === 0 && result.data) {
                const rooms = result.data.map(room => ({ room_number: room.office_title, room_id: room.office_id }));

                dispatch(setRooms(rooms));
            } else {
                dispatch(setRooms([]));
            }
        }
    } catch (e) {
        console.log('getRooms error: ', e);
    }
};

// Получить список типовых работ
export const getTypicalTasks = () => async dispatch => {
    const state = store.getState();

    try {
        const response = await hospitalityAPI.getTypicalTasks.fetch(state.login.username, state.login.auth_key_calendar, state.login.lang, {
            object_id: state.hospitality.object.id,
        });

        if (response.status === 200) {
            const result = await response.json();

            if (result && result.code === 0 && result.data) {
                const typicalTasks = result.data.map(task => ({
                    needTranslate: task.need_translate,
                    id: task.id.toString(),
                    title: task.title,
                    code: task.code || '',
                    type: task.type_type,
                    isNumerical: task.is_numerical,
                }));

                dispatch(setTypicalTasks(typicalTasks));
            } else {
                dispatch(setTypicalTasks([]));
            }
        }
    } catch (e) {
        console.log('getTypicalTasks error: ', e);
    }
};

// Получить список настроек для объекта
export const getSettings = () => async dispatch => {
    const state = store.getState();

    try {
        const response = await hospitalityAPI.getSettings.fetch(state.login.username, state.login.auth_key_calendar, state.login.lang, {
            object_id: state.hospitality.object.id,
        });

        if (response.status === 200) {
            const result = await response.json();

            if (result && result.code === 0 && result.data) {
                dispatch(setSettings(result.data));
            }
        }
    } catch (e) {
        console.log('getSettings error: ', e);
    }
};

// Получить список горничных
export const getCleaners = () => async dispatch => {
    dispatch(setCleanersFetching(true));

    const state = store.getState();

    try {
        const response = await hospitalityAPI.getCleaners.fetch(state.login.username, state.login.auth_key_calendar, state.login.lang, {
            object_id: state.hospitality.object.id,
        });

        if (response.status === 200) {
            const result = await response.json();

            if (result && result.code === 0 && result.data && result.data.users) {
                const cleaners = result.data.users.map(cleaner => ({
                    id: cleaner.id.toString(),
                    name: `${cleaner.last_name} ${cleaner.first_name} ${cleaner.middle_name}`.trim(),
                    supervisorId: cleaner.supervisor_id ? cleaner.supervisor_id.toString() : '',
                    isApplied: cleaner.supervisor_applied, // сотрудник привязан к супервайзеру
                    isAssigned: cleaner.worker_assigned, // сотрудник назначен на слот
                    isTouched: cleaner.is_touched, // сотрудник перемещен в шахматке
                    slotId: cleaner.slot_id ? cleaner.slot_id.toString() : '', // id слота в шахматке
                }));

                dispatch(setCleaners(cleaners));
            } else {
                dispatch(setCleaners([]));
            }
        }
    } catch (e) {
        console.log('getCleaners error: ', e);
    } finally {
        dispatch(setCleanersFetching(false));
    }
};

// Получить список супервайзеров
export const getSupervisors = () => async dispatch => {
    dispatch(setSupervisorsFetching(true));

    const state = store.getState();

    try {
        const response = await hospitalityAPI.getSupervisors.fetch(state.login.username, state.login.auth_key_calendar, state.login.lang, {
            object_id: state.hospitality.object.id,
        });

        if (response.status === 200) {
            const result = await response.json();

            if (result && result.code === 0 && result.data && result.data.users) {
                const supervisors = result.data.users.map(supervisor => ({
                    id: supervisor.id.toString(),
                    name: `${supervisor.last_name} ${supervisor.first_name} ${supervisor.middle_name}`.trim(),
                    state: supervisor.state, // назначен на работу сегодня или нет
                }));

                dispatch(setSupervisors(supervisors));
            } else {
                dispatch(setSupervisors([]));
            }
        }
    } catch (e) {
        console.log('getSupervisors error: ', e);
    } finally {
        dispatch(setSupervisorsFetching(false));
    }
};

// Получить список слотов
export const getSlots = () => async dispatch => {
    dispatch(setSlotsFetching(true));

    const state = store.getState();

    try {
        const response = await hospitalityAPI.getSlots.fetch(state.login.username, state.login.auth_key_calendar, {
            object_id: state.hospitality.object.id,
        });

        if (response.status === 200) {
            const result = await response.json();

            if (result && result.code === 0 && result.data) {
                dispatch(
                    setSlots(
                        result.data.map(slot => ({
                            id: slot.id.toString(),
                            key: slot.id.toString(),
                            isTouched: slot.is_touched,
                            cleanerId: slot.current_user_id ? slot.current_user_id.toString() : '',
                            cleanerName: slot.current_user_id ? `${slot.cu_last_name} ${slot.cu_first_name} ${slot.cu_middle_name}`.trim() : '',
                            supervisorId: slot.current_supervisor_id ? slot.current_supervisor_id.toString() : '',
                            supervisorName: slot.current_supervisor_id ? `${slot.cs_last_name} ${slot.cs_first_name} ${slot.cs_middle_name}`.trim() : '',
                        }))
                    )
                );
            } else {
                dispatch(setSlots([]));
            }
        }
    } catch (e) {
        console.log('getSlots error: ', e);
    } finally {
        dispatch(setSlotsFetching(false));
    }
};

// Получить список задач для горничных
export const getTasksForCleaners =
    (isBackgroundRefresh = false) =>
    async dispatch => {
        if (!isBackgroundRefresh) {
            dispatch(setTasksFetching(true));
        }

        const state = store.getState();

        try {
            const filter = state.hospitality.filterTasks;
            const filterData = {
                clear_status_id: filter.clearTypes.length ? JSON.stringify(filter.clearTypes) : '',
                status_id: filter.statuses.length ? JSON.stringify(filter.statuses) : '',
                room_from: filter.rooms.from,
                room_to: filter.rooms.to,
            };

            const response = await hospitalityAPI.getTasksForCleaners.fetch(state.login.username, state.login.auth_key_calendar, {
                object_id: state.hospitality.object.id,
                ...filterData,
            });

            if (response.status === 200) {
                const result = await response.json();

                if (result && result.code === 0 && result.data) {
                    const tasks = result.data.map(task => ({
                        clearTypeId: task.clear_type_id ? task.clear_type_id.toString() : '',
                        description: task.description || '',
                        isApplied: task.is_applied,
                        isTouched: task.is_touched,
                        statusId: task.status_id ? task.status_id.toString() : '',
                        id: task.task_id.toString(),
                        userId: task.user_id ? task.user_id.toString() : '',
                        title: task.room ? task.room.toString() : task.room_str ? task.room_str.toString() : '',
                        slotId: task.slot_id ? task.slot_id.toString() : '',
                    }));

                    dispatch(setTasks(tasks));
                } else {
                    dispatch(setTasks([]));
                }
            }
        } catch (e) {
            console.log('getTasksForCleaners error: ', e);
        } finally {
            if (!isBackgroundRefresh) {
                dispatch(setTasksFetching(false));
            }
        }
    };

// Получить список задач для супервайзеров
export const getTasksForSupervisors =
    (isBackgroundRefresh = false) =>
    async dispatch => {
        if (!isBackgroundRefresh) {
            dispatch(setTasksForSupervisorsFetching(true));
        }

        const state = store.getState();

        try {
            const response = await hospitalityAPI.getTasksForSupervisors.fetch(state.login.username, state.login.auth_key_calendar, {
                object_id: state.hospitality.object.id,
            });

            if (response.status === 200) {
                const result = await response.json();

                if (result && result.code === 0 && result.data) {
                    const tasks = result.data.map(task => ({
                        clearTypeId: task.clear_type_id ? task.clear_type_id.toString() : '',
                        description: task.description || '',
                        isApplied: task.is_applied,
                        isTouched: task.is_touched,
                        statusId: task.status_id ? task.status_id.toString() : '',
                        id: task.task_id.toString(),
                        userId: task.user_id ? task.user_id.toString() : '',
                        title: task.room ? task.room.toString() : task.room_str ? task.room_str.toString() : '',
                    }));

                    dispatch(setTasksForSupervisors(tasks));
                } else {
                    dispatch(setTasksForSupervisors([]));
                }
            }
        } catch (e) {
            console.log('getTasksForSupervisors error: ', e);
        } finally {
            if (!isBackgroundRefresh) {
                dispatch(setTasksForSupervisorsFetching(false));
            }
        }
    };

// Назначить супервайзеров на объект
export const setSupervisorsAtWork =
    ({ supervisorIds }) =>
    async dispatch => {
        dispatch(setSupervisorsFetching(true));

        const state = store.getState();

        try {
            const response = await hospitalityAPI.setSupervisorsAtWork.fetch(state.login.username, state.login.auth_key_calendar, {
                user_ids: JSON.stringify(supervisorIds),
                object_id: state.hospitality.object.id,
            });

            if (response.status === 200) {
                const result = await response.json();

                if (result) {
                    if (result.code === 0) {
                        state.hospitality.supervisors.forEach(supervisor => {
                            if (supervisorIds.findIndex(id => id === supervisor.id) > -1) {
                                dispatch(updateSupervisors({ id: supervisor.id, state: 1 }));
                            } else {
                                dispatch(updateSupervisors({ id: supervisor.id, state: 0 }));

                                // Сбрасываем горничных с супервайзера (если они были)
                                state.hospitality.cleaners
                                    .filter(cleaner => cleaner.supervisorId === supervisor.id)
                                    .forEach(cleaner => {
                                        dispatch(updateCleaners({ id: cleaner.id, supervisorId: '', isTouched: true }));
                                    });
                            }
                        });

                        return {
                            error: 0,
                            message: '',
                        };
                    } else {
                        return {
                            error: 1,
                            message: result.msg,
                        };
                    }
                }
            }
        } catch (e) {
            console.log('setSupervisorsAtWork error: ', e);
        } finally {
            dispatch(setSupervisorsFetching(false));
        }

        return {
            error: 1,
            message: 'Что-то пошло не так. Пожалуйста, попробуйте еще раз.',
        };
    };

// Назначить горничную на слот
export const assignWorkerToSlot =
    ({ userId, slotId }) =>
    async dispatch => {
        const state = store.getState();

        try {
            const response = await hospitalityAPI.assignWorkerToSlot.fetch(state.login.username, state.login.auth_key_calendar, {
                user_id: userId,
                slot_id: slotId,
            });

            if (response.status === 200) {
                const result = await response.json();

                if (result) {
                    if (result.code === 0) {
                        const cleanerId = result.data.user_id.toString();

                        dispatch(
                            updateSlots({
                                id: slotId,
                                cleanerId,
                                cleanerName: result.data.user_name,
                                supervisorId: result.data.supervisor_id.toString(),
                                supervisorName: result.data.supervisor_name,
                                isTouched: true,
                            })
                        );

                        dispatch(updateCleaners({ id: cleanerId, isAssigned: true, slotId }));

                        state.hospitality.cleaners
                            .filter(cleaner => cleaner.slotId === slotId && cleaner.id !== cleanerId)
                            .forEach(cleaner => {
                                dispatch(updateCleaners({ id: cleaner.id, isAssigned: false, slotId: '' }));
                            });

                        return {
                            error: 0,
                            message: '',
                        };
                    } else {
                        return {
                            error: 1,
                            message: result.msg,
                            messageKey: result.msg_key,
                        };
                    }
                }
            }
        } catch (e) {
            console.log('assignWorkerToSlot error: ', e);
        }

        return {
            error: 1,
            message: 'Что-то пошло не так. Пожалуйста, попробуйте еще раз.',
        };
    };

// Перенос горничной на супервайзера
export const moveCleaners =
    ({ data }) =>
    async dispatch => {
        const state = store.getState();

        dispatch(updateCleaners({ ...data, isTouched: true, isApplied: false }));

        try {
            const response = await hospitalityAPI.moveCleaners.fetch(state.login.username, state.login.auth_key_calendar, {
                user_id: data.id,
                supervisor_id: data.supervisorId,
                object_id: state.hospitality.object.id,
            });

            if (response.status === 200) {
                const result = await response.json();

                if (result) {
                    if (result.code === 0) {
                        return {
                            error: 0,
                            message: '',
                        };
                    } else {
                        dispatch(getCleaners());

                        return {
                            error: 1,
                            message: result.msg,
                        };
                    }
                }
            }
        } catch (e) {
            console.log('moveCleaners error: ', e);
        }

        return {
            error: 1,
            message: 'Что-то пошло не так. Пожалуйста, попробуйте еще раз.',
        };
    };

// Перенос задач на слот
export const moveTask =
    ({ data }) =>
    async dispatch => {
        dispatch(updateTasks({ ...data, isTouched: true }));

        const state = store.getState();

        try {
            const response = await hospitalityAPI.moveTask.fetch(state.login.username, state.login.auth_key_calendar, {
                task_id: data.id,
                slot_id: data.slotId,
                object_id: state.hospitality.object.id,
                for_supervisor: false,
            });

            if (response.status === 200) {
                const result = await response.json();

                if (result) {
                    if (result.code === 0) {
                        return {
                            error: 0,
                            message: '',
                        };
                    } else {
                        dispatch(getTasksForCleaners());

                        return {
                            error: 1,
                            message: result.msg,
                        };
                    }
                }
            }
        } catch (e) {
            console.log('moveTask error: ', e);
        }

        return {
            error: 1,
            message: 'Что-то пошло не так. Пожалуйста, попробуйте еще раз.',
        };
    };

// Перенос задач на супервайзера
export const moveTaskForSupervisors =
    ({ data }) =>
    async dispatch => {
        dispatch(updateTasksForSupervisors({ ...data, isTouched: true }));

        const state = store.getState();

        try {
            const response = await hospitalityAPI.moveTask.fetch(state.login.username, state.login.auth_key_calendar, {
                task_id: data.id,
                user_id: data.userId,
                object_id: state.hospitality.object.id,
                for_supervisor: true,
            });

            if (response.status === 200) {
                const result = await response.json();

                if (result) {
                    if (result.code === 0) {
                        return {
                            error: 0,
                            message: '',
                        };
                    } else {
                        dispatch(getTasksForSupervisors());

                        return {
                            error: 1,
                            message: result.msg,
                        };
                    }
                }
            }
        } catch (e) {
            console.log('moveTaskForSupervisors error: ', e);
        }

        return {
            error: 1,
            message: 'Что-то пошло не так. Пожалуйста, попробуйте еще раз.',
        };
    };

// Применить распределение горничных на супервайзеров
export const applyWorkersAndSupervisors = () => async dispatch => {
    dispatch(setApplyFetching(true));

    const state = store.getState();

    try {
        const response = await hospitalityAPI.applyWorkersAndSupervisors.fetch(state.login.username, state.login.auth_key_calendar, {
            object_id: state.hospitality.object.id,
        });

        if (response.status === 200) {
            const result = await response.json();

            if (result) {
                if (result.code === 0) {
                    state.hospitality.cleaners
                        .filter(cleaner => cleaner.isTouched)
                        .forEach(cleaner => {
                            dispatch(updateCleaners({ id: cleaner.id, isTouched: false, isApplied: true }));
                        });

                    return {
                        error: 0,
                        message: '',
                    };
                } else {
                    return {
                        error: 1,
                        message: result.msg,
                    };
                }
            }
        }
    } catch (e) {
        console.log('applyWorkersAndSupervisors error: ', e);
    } finally {
        dispatch(setApplyFetching(false));
    }

    return {
        error: 1,
        message: 'Что-то пошло не так. Пожалуйста, попробуйте еще раз.',
    };
};

// Применить распределение задач на слоты
export const applyTasksAndSlots = () => async dispatch => {
    dispatch(setApplyFetching(true));

    const state = store.getState();

    try {
        const response = await hospitalityAPI.applyTasksAndSlots.fetch(state.login.username, state.login.auth_key_calendar, {
            object_id: state.hospitality.object.id,
        });

        if (response.status === 200) {
            const result = await response.json();

            if (result) {
                if (result.code === 0) {
                    state.hospitality.tasks
                        .filter(task => task.isTouched)
                        .forEach(task => {
                            dispatch(updateTasks({ id: task.id, isTouched: false }));
                        });
                    state.hospitality.slots
                        .filter(slot => slot.isTouched)
                        .forEach(slot => {
                            dispatch(updateSlots({ id: slot.id, isTouched: false }));
                        });

                    return {
                        error: 0,
                        message: '',
                    };
                } else {
                    return {
                        error: 1,
                        message: result.msg,
                    };
                }
            }
        }
    } catch (e) {
        console.log('applyTasksAndSlots error: ', e);
    } finally {
        dispatch(setApplyFetching(false));
    }

    return {
        error: 1,
        message: 'Что-то пошло не так. Пожалуйста, попробуйте еще раз.',
    };
};

// Применить распределение задач на супервайзеров
export const applyTasksAndSupervisors = () => async dispatch => {
    dispatch(setApplyFetching(true));

    const state = store.getState();

    try {
        const response = await hospitalityAPI.applyTasksAndSupervisors.fetch(state.login.username, state.login.auth_key_calendar, {
            object_id: state.hospitality.object.id,
        });

        if (response.status === 200) {
            const result = await response.json();

            if (result) {
                if (result.code === 0) {
                    state.hospitality.tasksForSupervisors
                        .filter(task => task.isTouched)
                        .forEach(task => {
                            dispatch(updateTasksForSupervisors({ id: task.id, isTouched: false }));
                        });

                    return {
                        error: 0,
                        message: '',
                    };
                } else {
                    return {
                        error: 1,
                        message: result.msg,
                    };
                }
            }
        }
    } catch (e) {
        console.log('applyTasksAndSupervisors error: ', e);
    } finally {
        dispatch(setApplyFetching(false));
    }

    return {
        error: 1,
        message: 'Что-то пошло не так. Пожалуйста, попробуйте еще раз.',
    };
};

// Сбросить распределение горничных на супервайзеров
export const resetWorkersAndSupervisors = () => async dispatch => {
    dispatch(setResetFetching(true));

    const state = store.getState();

    try {
        const response = await hospitalityAPI.resetWorkersAndSupervisors.fetch(state.login.username, state.login.auth_key_calendar, {
            object_id: state.hospitality.object.id,
        });

        if (response.status === 200) {
            const result = await response.json();

            if (result) {
                if (result.code === 0) {
                    await dispatch(getCleaners());
                    await dispatch(getSupervisors());

                    return {
                        error: 0,
                        message: '',
                    };
                } else {
                    return {
                        error: 1,
                        message: result.msg,
                    };
                }
            }
        }
    } catch (e) {
        console.log('resetWorkersAndSupervisors error: ', e);
    } finally {
        dispatch(setResetFetching(false));
    }

    return {
        error: 1,
        message: 'Что-то пошло не так. Пожалуйста, попробуйте еще раз.',
    };
};

// Сбросить распределение задач на слоты
export const resetTasksAndSlots = () => async dispatch => {
    dispatch(setResetFetching(true));

    const state = store.getState();

    try {
        const response = await hospitalityAPI.resetTasksAndSlots.fetch(state.login.username, state.login.auth_key_calendar, {
            object_id: state.hospitality.object.id,
        });

        if (response.status === 200) {
            const result = await response.json();

            if (result) {
                if (result.code === 0) {
                    await dispatch(getTasksForCleaners(true));

                    return {
                        error: 0,
                        message: '',
                    };
                } else {
                    return {
                        error: 1,
                        message: result.msg,
                    };
                }
            }
        }
    } catch (e) {
        console.log('resetTasksAndSlots error: ', e);
    } finally {
        dispatch(setResetFetching(false));
    }

    return {
        error: 1,
        message: 'Что-то пошло не так. Пожалуйста, попробуйте еще раз.',
    };
};

// Сбросить распределение задач на супервайзеров
export const resetTasksAndSupervisors = () => async dispatch => {
    dispatch(setResetFetching(true));

    const state = store.getState();

    try {
        const response = await hospitalityAPI.resetTasksAndSupervisors.fetch(state.login.username, state.login.auth_key_calendar, {
            object_id: state.hospitality.object.id,
        });

        if (response.status === 200) {
            const result = await response.json();

            if (result) {
                if (result.code === 0) {
                    await dispatch(getTasksForSupervisors(true));

                    return {
                        error: 0,
                        message: '',
                    };
                } else {
                    return {
                        error: 1,
                        message: result.msg,
                    };
                }
            }
        }
    } catch (e) {
        console.log('resetTasksAndSupervisors error: ', e);
    } finally {
        dispatch(setResetFetching(false));
    }

    return {
        error: 1,
        message: 'Что-то пошло не так. Пожалуйста, попробуйте еще раз.',
    };
};

// Автоматическое распределение задач на слоты
export const autoDistributeTaskForSlots = () => async dispatch => {
    const state = store.getState();

    try {
        const filter = state.hospitality.filterTasks;
        const filterData = {
            clear_status_id: filter.clearTypes.length ? JSON.stringify(filter.clearTypes) : '',
            status_id: filter.statuses.length ? JSON.stringify(filter.statuses) : '',
            room_from: filter.rooms.from,
            room_to: filter.rooms.to,
        };

        const response = await hospitalityAPI.autoDistributeTaskForSlots.fetch(state.login.username, state.login.auth_key_calendar, {
            object_id: state.hospitality.object.id,
            ...filterData,
        });

        if (response.status === 200) {
            const result = await response.json();

            if (result) {
                if (result.code === 0) {
                    await dispatch(getTasksForCleaners(true));

                    return {
                        error: 0,
                        message: '',
                    };
                } else {
                    return {
                        error: 1,
                        message: result.msg,
                    };
                }
            }
        }
    } catch (e) {
        console.log('autoDistributeTaskForSlots error: ', e);
    }

    return {
        error: 1,
        message: 'Что-то пошло не так. Пожалуйста, попробуйте еще раз.',
    };
};

// Автоматическое распределение задач на супервайзеров
export const autoDistributeTaskForSupervisors = () => async dispatch => {
    const state = store.getState();

    try {
        const response = await hospitalityAPI.autoDistributeTaskForSupervisors.fetch(state.login.username, state.login.auth_key_calendar, {
            object_id: state.hospitality.object.id,
        });

        if (response.status === 200) {
            const result = await response.json();

            if (result) {
                if (result.code === 0) {
                    await dispatch(getTasksForSupervisors(true));

                    return {
                        error: 0,
                        message: '',
                    };
                } else {
                    return {
                        error: 1,
                        message: result.msg,
                    };
                }
            }
        }
    } catch (e) {
        console.log('autoDistributeTaskForSupervisors error: ', e);
    }

    return {
        error: 1,
        message: 'Что-то пошло не так. Пожалуйста, попробуйте еще раз.',
    };
};

// Сбросить распределение горничных для супервайзера
export const resetWorkersForSupervisor =
    ({ id }) =>
    async dispatch => {
        const state = store.getState();

        try {
            const response = await hospitalityAPI.resetWorkersForSupervisor.fetch(state.login.username, state.login.auth_key_calendar, {
                object_id: state.hospitality.object.id,
                supervisor_id: id,
            });

            if (response.status === 200) {
                const result = await response.json();

                if (result) {
                    if (result.code === 0) {
                        state.hospitality.cleaners
                            .filter(cleaner => cleaner.supervisorId === id && !cleaner.isApplied)
                            .forEach(cleaner => {
                                dispatch(updateCleaners({ id: cleaner.id, supervisorId: '', isTouched: true }));
                            });

                        return {
                            error: 0,
                            message: '',
                        };
                    } else {
                        return {
                            error: 1,
                            message: result.msg,
                        };
                    }
                }
            }
        } catch (e) {
            console.log('resetWorkersForSupervisor error: ', e);
        }

        return {
            error: 1,
            message: 'Что-то пошло не так. Пожалуйста, попробуйте еще раз.',
        };
    };

// Сбросить распределение задач для слота
export const resetTasksForSlot =
    ({ id }) =>
    async dispatch => {
        const state = store.getState();

        try {
            const response = await hospitalityAPI.resetTasksForSlot.fetch(state.login.username, state.login.auth_key_calendar, {
                object_id: state.hospitality.object.id,
                slot_id: id,
            });

            if (response.status === 200) {
                const result = await response.json();

                if (result) {
                    if (result.code === 0) {
                        state.hospitality.tasks
                            .filter(task => task.slotId === id && task.statusId !== '3' && task.statusId !== '8')
                            .forEach(task => {
                                dispatch(updateTasks({ id: task.id, slotId: '', isTouched: true }));
                            });

                        return {
                            error: 0,
                            message: '',
                        };
                    } else {
                        return {
                            error: 1,
                            message: result.msg,
                        };
                    }
                }
            }
        } catch (e) {
            console.log('resetTasksForSlot error: ', e);
        }

        return {
            error: 1,
            message: 'Что-то пошло не так. Пожалуйста, попробуйте еще раз.',
        };
    }; // Сбросить распределение задач для слота

// Сбросить распределение задач для супервайзера
export const resetTasksForSupervisor =
    ({ id }) =>
    async dispatch => {
        const state = store.getState();

        try {
            const response = await hospitalityAPI.resetTasksForSupervisor.fetch(state.login.username, state.login.auth_key_calendar, {
                object_id: state.hospitality.object.id,
                user_id: id,
            });

            if (response.status === 200) {
                const result = await response.json();

                if (result) {
                    if (result.code === 0) {
                        state.hospitality.tasksForSupervisors
                            .filter(task => task.userId === id && task.statusId !== '3' && task.statusId !== '8')
                            .forEach(task => {
                                dispatch(updateTasksForSupervisors({ id: task.id, userId: '', isTouched: true }));
                            });

                        return {
                            error: 0,
                            message: '',
                        };
                    } else {
                        return {
                            error: 1,
                            message: result.msg,
                        };
                    }
                }
            }
        } catch (e) {
            console.log('resetTasksForSupervisor error: ', e);
        }

        return {
            error: 1,
            message: 'Что-то пошло не так. Пожалуйста, попробуйте еще раз.',
        };
    };

// Обновить список настроек для объекта
export const updateSettings =
    ({ data }) =>
    async dispatch => {
        const state = store.getState();

        try {
            const response = await hospitalityAPI.updateSettings.fetch(state.login.username, state.login.auth_key_calendar, {
                object_id: state.hospitality.object.id,
                ...data,
            });

            if (response.status === 200) {
                const result = await response.json();

                if (result && result.code === 0) {
                    dispatch(setSettings(JSON.parse(data.options)));
                }
            }
        } catch (e) {
            console.log('updateSettings error: ', e);
        }
    };

// Сортировка типов работ
export const sortTypesOfWorks =
    ({ data }) =>
    async dispatch => {
        const object = store.getState().hospitality.object;

        try {
            const response = await hospitalityAPI.sortTypesOfWorks.fetch({ object_id: object.id, sort_order: data });
            if (response.status === 200) {
                await response.json();
                dispatch(getTasksForCleaners());
            }
        } catch (e) {
            console.log('sortTypesOfWorks error: ', e);
        }
    };

// Получить статус номера (чистый/грязный)
export const getRoomStatus =
    ({ data }) =>
    async () => {
        const state = store.getState();

        try {
            const response = await hospitalityAPI.getRoomStatus.fetch({ object_id: state.hospitality.object.id, number_name: data.roomName });

            if (response.status === 200) {
                const result = await response.json();

                if (result) {
                    if (result.code === 0 && result.data) {
                        return result.data;
                    }
                }
            }
        } catch (e) {
            console.log('getRoomStatus error: ', e);
        }

        return false;
    };

// Создать заявку
export const createTask =
    ({ data }) =>
    async dispatch => {
        const state = store.getState();

        try {
            const response = await hospitalityAPI.createTask.fetch(state.login.username, state.login.auth_key_calendar, {
                object_id: state.hospitality.object.id,
                ...data,
            });

            if (response.status === 200) {
                const result = await response.json();

                if (result) {
                    if (result.code === 0) {
                        dispatch(getTasksForCleaners());
                        dispatch(getTasksForSupervisors());

                        return {
                            error: 0,
                            message: '',
                        };
                    } else {
                        return {
                            error: 1,
                            message: result.msg,
                        };
                    }
                }
            }
        } catch (e) {
            console.log('createTask error: ', e);
        }

        return {
            error: 1,
            message: 'Что-то пошло не так. Пожалуйста, попробуйте еще раз.',
        };
    };

export const objectsState = state => state.hospitality.objects;
export const objectsFetchingState = state => state.hospitality.objectsFetching;
export const objectState = state => state.hospitality.object;
export const tasksStatusesState = state => state.hospitality.tasksStatuses;
export const tasksStatusesFetchingState = state => state.hospitality.tasksStatusesFetching;
export const cleanersState = state => state.hospitality.cleaners;
export const cleanersFetchingState = state => state.hospitality.cleanersFetching;
export const tasksState = state => state.hospitality.tasks;
export const tasksFetchingState = state => state.hospitality.tasksFetching;
export const filterTasksState = state => state.hospitality.filterTasks;
export const tasksForSupervisorsState = state => state.hospitality.tasksForSupervisors;
export const tasksForSupervisorsFetchingState = state => state.hospitality.tasksForSupervisorsFetching;
export const settingsState = state => state.hospitality.settings;
export const applyFetchingState = state => state.hospitality.applyFetching;
export const resetFetchingState = state => state.hospitality.resetFetching;
export const supervisorsState = state => state.hospitality.supervisors;
export const supervisorsFetchingState = state => state.hospitality.supervisorsFetching;
export const roomsState = state => state.hospitality.rooms;
export const typicalTasksState = state => state.hospitality.typicalTasks;
export const slotsState = state => state.hospitality.slots;
export const slotsFetchingState = state => state.hospitality.slotsFetching;

export default hospitalitySlice.reducer;
