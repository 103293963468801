export const VenueTitleInput = ({ setVenue, venue }) => {
    return (
        <input
            type="text"
            className="mr-2 h-10 w-96 border-gray-70 px-2 text-sm hover:border-gray-30"
            onChange={e => setVenue({ ...venue, title: e.target.value })}
            value={venue.title}
            placeholder="Наименование объекта"
        />
    );
};
