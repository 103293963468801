import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';

import { book } from '../../navigation/book';

export const ListQualificationButton = () => {
    const navigate = useNavigate();
    const handlerBtnClick = () => {
        navigate(book.qualification_list.link);
    };
    return (
        <div className="flex items-center">
            <button
                onClick={() => handlerBtnClick()}
                className="flex items-center gap-2 rounded-[30px] border bg-blue px-6 py-2 text-sm font-normal text-white transition-colors duration-200 hover:border hover:border-blue hover:bg-white hover:text-blue"
            >
                <svg width="12" height="15" viewBox="0 0 12 15" fill="none">
                    <path
                        d="M6 15.0001C5.2 14.2445 4.28333 13.6667 3.25 13.2667C2.21667 12.8667 1.13333 12.6667 0 12.6667V5.33341C1.12222 5.33341 2.2 5.53608 3.23333 5.94141C4.26667 6.34719 5.18889 6.93341 6 7.70008C6.81111 6.93341 7.73333 6.34719 8.76667 5.94141C9.8 5.53608 10.8778 5.33341 12 5.33341V12.6667C10.8556 12.6667 9.76956 12.8667 8.742 13.2667C7.714 13.6667 6.8 14.2445 6 15.0001ZM6 13.2667C6.7 12.7445 7.44445 12.3279 8.23333 12.0167C9.02222 11.7056 9.83333 11.5001 10.6667 11.4001V6.80008C9.85556 6.94453 9.05844 7.23608 8.27533 7.67475C7.49178 8.11386 6.73333 8.70008 6 9.43341C5.26667 8.70008 4.50844 8.11386 3.72533 7.67475C2.94178 7.23608 2.14444 6.94453 1.33333 6.80008V11.4001C2.16667 11.5001 2.97778 11.7056 3.76667 12.0167C4.55556 12.3279 5.3 12.7445 6 13.2667ZM6 6.00008C5.26667 6.00008 4.63889 5.73897 4.11667 5.21675C3.59444 4.69453 3.33333 4.06675 3.33333 3.33341C3.33333 2.60008 3.59444 1.9723 4.11667 1.45008C4.63889 0.927859 5.26667 0.666748 6 0.666748C6.73333 0.666748 7.36111 0.927859 7.88333 1.45008C8.40556 1.9723 8.66667 2.60008 8.66667 3.33341C8.66667 4.06675 8.40556 4.69453 7.88333 5.21675C7.36111 5.73897 6.73333 6.00008 6 6.00008ZM6 4.66675C6.36667 4.66675 6.68067 4.53608 6.942 4.27475C7.20289 4.01386 7.33333 3.70008 7.33333 3.33341C7.33333 2.96675 7.20289 2.65275 6.942 2.39141C6.68067 2.13053 6.36667 2.00008 6 2.00008C5.63333 2.00008 5.31956 2.13053 5.05867 2.39141C4.79733 2.65275 4.66667 2.96675 4.66667 3.33341C4.66667 3.70008 4.79733 4.01386 5.05867 4.27475C5.31956 4.53608 5.63333 4.66675 6 4.66675Z"
                        className="fill-current"
                    />
                </svg>
                {t('QUALIFICATION_LIST')}
            </button>
        </div>
    );
};
