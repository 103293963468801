import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { tasksForSupervisorsFetchingState, tasksForSupervisorsState } from '../../../../../../redux/slices/hospitalitySlice';
import { Loader } from '../../../../../common/Loader';

export const TasksCounter = () => {
    const { t } = useTranslation();

    const tasksFetching = useSelector(tasksForSupervisorsFetchingState);
    const tasks = useSelector(tasksForSupervisorsState);

    const allocatedTasks = useMemo(() => tasks.filter(task => task.userId), [tasks]);

    return (
        <div className="flex flex-row items-center gap-1 px-6 pb-1 pt-3 text-sm font-medium text-gray-500">
            {t('HOSPITALITY_ALLOCATED_TASKS')}:{' '}
            {tasksFetching ? <Loader width={12} height={12} /> : <span className="font-semibold text-gray-20">{allocatedTasks.length}</span>}
        </div>
    );
};
