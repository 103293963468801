import { Link } from 'react-router-dom';

export const OpenEventLogPageButton = () => {
    return (
        <Link
            to={'/bms/eventLog'}
            target="_blank"
            className="flex h-10 w-auto items-center justify-around whitespace-nowrap rounded-md border border-green bg-green px-6 text-white transition-colors hover:bg-green-600"
        >
            Журнал событий
        </Link>
    );
};
