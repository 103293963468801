import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { toastOptions } from '../../../../../../functions/toastOptions';
import { documentState as documentState_state } from '../../../../../../redux/slices/bms/bmsSliceDocuments';
import { SimpleSelect } from '../../../../../common/widgets/SimpleSelect';

export const PresetValueControl = ({ nodeOptions, setValues, setWritePoint, setCheckPopupFields, control }) => {
    const documentState = useSelector(documentState_state);

    const [data, setData] = useState({ writePoint: null, value: '', min: '', max: '' });

    // исключение уже используемых точек данных в уставках
    const usedWritePoint = documentState.controls.filter(control => control.lib_data.subtype === 'input').map(control => control.data.write_point);
    const node_options = nodeOptions.filter(node => !usedWritePoint.includes(node.value));

    useEffect(() => {
        try {
            JSON.parse(control.data);
        } catch (e) {
            const values = control.data.values[0];

            setData({ ...values });

            setValues([...control.data.values]);
            setWritePoint(values.writePoint.value);
        }
    }, [control, nodeOptions]);

    useEffect(() => {
        if (data.writePoint?.datatype === 'boolean') {
            toast.error('для булевой точки  используйте  выпадающий список', toastOptions);
        }
        data.value.length !== 0 && data.min.length !== 0 && data.max.length !== 0 ? setCheckPopupFields(true) : setCheckPopupFields(false);
    }, [data]);

    const handlerInputChange = (value, type) => {
        // проверка на ввод числа больше чем с десятыми
        if (data.writePoint && data.writePoint.datatype === 'float') {
            const floatCheck = value.split('.');
            if (floatCheck.length === 2 && floatCheck[1].length > 1) {
                return;
            }
        }
        // проверка на целое число
        if (data.writePoint && data.writePoint.datatype === 'int') {
            const floatCheck = value.split('.');
            if (floatCheck.length === 2 && floatCheck[1].length >= 1) {
                setData({ ...data, [type]: floatCheck[0] });
                return;
            }
        }

        //   проверка на более двух нулей подряд
        if (value.length > 1 && value[0] === '0' && value[1] !== '.') {
            setValues([{ ...data, [type]: value[1] }]);
            setData({ ...data, [type]: value[1] });
            return;
        }
        // проверка отрицательного числа на ввод более двух нулей подряд
        if (value.length === 3 && value[0] === '-' && value[1] === '0' && value[2] !== '.') {
            const val = `${value[0]}${value[2]}`;

            setData({ ...data, [type]: val });
            setValues([{ ...data, [type]: val }]);
            return;
        }

        setData({ ...data, [type]: value });
        setValues([{ ...data, [type]: value }]);
    };

    const handlerInputBlur = () => {
        if (Number(data.max) <= Number(data.min) && data.min.length > 0 && data.max.length > 0) {
            toast.error('Минимум не может быть больше или равен максимуму', toastOptions);
            setCheckPopupFields(false);
        }
    };

    return (
        <div className="flex items-center">
            <SimpleSelect
                isClearable={false}
                options={node_options}
                placeholder="Выберите точку"
                value={data.writePoint}
                isSearchable={true}
                callback={val => {
                    if (val === null) {
                        return;
                    }
                    setWritePoint(val.value);
                    setValues([]);
                    setData({ value: val.value_from_data_point, min: '', max: '', writePoint: val });
                }}
                className="mr-5 w-56"
            />
            <div className="flex flex-col gap-3">
                <label className="flex flex-col">
                    <span className="text-sm">Текущее значение</span>
                    <input
                        type="number"
                        value={data.writePoint?.datatype === 'boolean' ? '' : data.value}
                        className="h-10 w-40 text-sm"
                        disabled={true}
                        onChange={e => handlerInputChange(e.target.value, 'value')}
                        onBlur={() => handlerInputBlur()}
                    />
                </label>
                <label className="flex flex-col">
                    <span className="text-sm"> min</span>
                    <input
                        type="number"
                        value={data.min}
                        className="h-10 w-40 text-sm"
                        disabled={data.writePoint?.datatype === 'boolean' || !data.writePoint}
                        onChange={e => handlerInputChange(e.target.value, 'min')}
                        onBlur={() => handlerInputBlur()}
                    />
                </label>
                <label className="flex flex-col">
                    <span className="text-sm"> max</span>
                    <input
                        type="number"
                        value={data.max}
                        className="h-10 w-40 text-sm"
                        disabled={data.writePoint?.datatype === 'boolean' || !data.writePoint}
                        onChange={e => handlerInputChange(e.target.value, 'max')}
                        onBlur={() => handlerInputBlur()}
                    />
                </label>
            </div>
        </div>
    );
};
