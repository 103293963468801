import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { listenForOutsideClick } from '../../../../../functions/listenForOutsideClick';
import { getSupervisors, objectState, supervisorsFetchingState, supervisorsState } from '../../../../../redux/slices/hospitalitySlice';
import { Loader } from '../../../../common/Loader';
import { SupervisorWithTasksSlot } from '../../../slots/supervisorWithTasksSlot/SupervisorWithTasksSlot';
import { TasksCounter } from '../tasksSection/tasksCounter/TasksCounter';

export const SupervisorsSection = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const object = useSelector(objectState);
    const supervisors = useSelector(supervisorsState);
    const supervisorsFetching = useSelector(supervisorsFetchingState);

    const allocatedSupervisors = useMemo(() => supervisors.filter(supervisor => supervisor.state), [supervisors]);

    const [isOpenSupervisorMenu, setIsOpenSupervisorMenu] = useState(null);
    const [listeningSupervisorMenu, setListeningSupervisorMenu] = useState(false);

    const activeSupervisorMenuRef = useRef(null);

    useEffect(listenForOutsideClick(listeningSupervisorMenu, setListeningSupervisorMenu, activeSupervisorMenuRef, () => setIsOpenSupervisorMenu(null)));

    useEffect(() => {
        if (object) {
            dispatch(getSupervisors());
        }
    }, [object]);

    return (
        <>
            {supervisorsFetching ? (
                <div className="flex w-full items-center justify-center">
                    <Loader height={30} width={30} />
                </div>
            ) : (
                <div className="flex h-full w-full flex-col">
                    <TasksCounter />

                    <div className="box-border flex w-full flex-row flex-wrap gap-6 overflow-y-auto px-6 pb-10 pt-3">
                        {allocatedSupervisors.length ? (
                            allocatedSupervisors.map(supervisor => (
                                <SupervisorWithTasksSlot
                                    key={supervisor.id}
                                    id={supervisor.id}
                                    name={supervisor.name}
                                    isOpenMenu={isOpenSupervisorMenu}
                                    setIsOpenMenu={setIsOpenSupervisorMenu}
                                    activeMenuRef={activeSupervisorMenuRef}
                                />
                            ))
                        ) : (
                            <p className="text-sm font-medium text-gray-500">{t('HOSPITALITY_NO_EMPLOYEES')}</p>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};
