import { Link, useParams } from 'react-router-dom';

export const CreateDocumentButton = () => {
    const { venueId, systemId } = useParams();

    return (
        <Link
            to={`/bms/venues/${venueId}/system/${systemId}/documents/create`}
            className="flex h-28 w-28 flex-col items-center justify-end overflow-hidden rounded-md border-2 border-gray-10 bg-white p-2 pb-5 text-center text-xs transition-colors hover:border-blue hover:bg-blue-10"
        >
            <svg width="25" height="25" viewBox="0 0 12 12" className="mb-2 fill-current">
                <path d="M5.16602 11.8332V6.83317H0.166016V5.1665H5.16602V0.166504H6.83268V5.1665H11.8327V6.83317H6.83268V11.8332H5.16602Z" />
            </svg>
            <span>Новая система</span>
        </Link>
    );
};
