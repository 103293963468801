import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { application as application_state } from '../../../redux/slices/applicationsSlice';
import { FancyboxTask } from '../../common/FancyboxTask';
import ReactCarouselPhoto from '../../common/ReactCarouselPhoto';
import helpers from '../detail/helpers';

export const ApplicationPhotoCreator = ({ uploadPhoto = {} }) => {
    const application = useSelector(application_state);
    const admFiles = helpers.getImgFiles(application.adm_files);

    const [selectPhoto, setSelectPhoto] = useState(admFiles ? admFiles.images[0] : application.attached_images[0]);
    const [renderPhoto, setRenderPhoto] = useState([]);
    const [photoArr, setPhotoArr] = useState(null);

    const skipFirstRenderPhoto = useRef(true);

    useEffect(() => {
        if (application.attached_images.length > 0) {
            setRenderPhoto(application.attached_images.filter(el => el !== selectPhoto));
        }
        if (admFiles?.images?.length > 0) {
            setRenderPhoto(admFiles.images.filter(el => el !== selectPhoto));
        }
    }, [selectPhoto]);

    if (application?.attached_images.length !== 0 && photoArr === null) {
        setPhotoArr(application.attached_images);
    }
    if (admFiles !== null && photoArr === null) {
        setPhotoArr(admFiles.images);
    }

    useEffect(() => {
        if (skipFirstRenderPhoto.current) {
            skipFirstRenderPhoto.current = false;
            return;
        }
        photoArr ? setPhotoArr([...photoArr, uploadPhoto.preview]) : setPhotoArr([uploadPhoto.preview]);
        setSelectPhoto(uploadPhoto.preview);
        setRenderPhoto([...renderPhoto, uploadPhoto.preview]);
    }, [uploadPhoto]);

    return (
        <>
            {application.attached_images.length === 0 && application.adm_files.length === 0 && Object.keys(uploadPhoto).length === 0 ? (
                <div className="mt-10 text-center text-base font-semibold opacity-50">{t('APPLICATIONS_ATTACHED_PHOTO')}</div>
            ) : (
                <>
                    <div className="cursor-pointer">
                        <FancyboxTask photo={selectPhoto} setPhoto={setSelectPhoto}>
                            <>
                                <img
                                    data-caption={t('APPLICATIONS_FORM_APPLICANT')}
                                    data-fancybox="gallery"
                                    width={'100%'}
                                    className="h-64 object-cover"
                                    src={selectPhoto}
                                    alt="select-photo"
                                />

                                <div className="hidden">
                                    {renderPhoto?.map((image, idx) => (
                                        <div data-caption={t('APPLICATIONS_FORM_APPLICANT')} data-fancybox="gallery" data-src={`${image}`} key={`image${idx}`}>
                                            <img src={`${image}`} alt="" className="h-full object-cover" />
                                        </div>
                                    ))}
                                </div>
                            </>
                        </FancyboxTask>
                    </div>
                    <div id="myCarousel" className="carousel carouselPhoto">
                        <ReactCarouselPhoto
                            content={photoArr?.length !== 0 ? photoArr : uploadPhoto.preview}
                            setSelect={setSelectPhoto}
                            options={{
                                infinite: false,
                                Dots: false,
                            }}
                            selectedFancyPhoto={selectPhoto}
                        />
                    </div>
                </>
            )}
        </>
    );
};
