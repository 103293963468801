// Функция-слушатель для кликов вне блока
export const listenForOutsideClick = (listening, setListening, blockRef, callback) => () => {
    if (listening) return;
    setListening(true);

    document.addEventListener('click', e => {
        const cur = blockRef.current;
        if (!blockRef.current) return;

        const node = e.target;
        if (cur.contains(node)) return;
        callback();
    });
};
