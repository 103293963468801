import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import { twMerge } from 'tailwind-merge';

import { SvgComponent } from '../../common/svgComponent/SvgComponent';

const Option = props => (
    <components.Option {...props}>
        <div className="flex items-center gap-2">
            <input type="checkbox" checked={props.isSelected} onChange={() => null} />
            <div className="flex flex-row items-center gap-3">
                {!!props.data.svgName && (
                    <div className="w-4">
                        <SvgComponent name={props.data.svgName} />
                    </div>
                )}
                {props.label}
            </div>
        </div>
    </components.Option>
);

export const MultiSelect = ({
    options,
    value,
    callback,
    placeholder = 'Выберите опцию',
    isClearable = true,
    isSearchable = false,
    isLoading = false,
    isDisabled = false,
    className = '',
    menuPosition = 'absolute',
    menuPlacement = 'auto',
    isOptionDisabled = () => false,
}) => {
    const { t } = useTranslation();

    const [values, setValues] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setValues(value);
    }, [value]);

    const classes = twMerge(`select text-sm w-48 ${className}`);

    const changeHandler = (value, meta) => {
        setValues(value);

        if (meta.action !== 'select-option' && meta.action !== 'deselect-option') {
            callback(value);
        }
    };

    const openMenuHandler = () => {
        setIsOpen(true);
    };

    const closeMenuHandler = () => {
        if (isOpen) {
            setIsOpen(false);
            callback(values);
        }
    };

    return (
        <Select
            isMulti
            options={options}
            value={values}
            onChange={changeHandler}
            isLoading={isLoading}
            isDisabled={isDisabled}
            onMenuOpen={openMenuHandler}
            onMenuClose={closeMenuHandler}
            noOptionsMessage={() => t('SELECT_NO_DATA')}
            isClearable={isClearable}
            isSearchable={isSearchable}
            placeholder={placeholder}
            classNamePrefix="filter"
            className={classes}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{ Option }}
            menuPosition={menuPosition}
            menuPlacement={menuPlacement}
            isOptionDisabled={isOptionDisabled}
        />
    );
};
