import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { toastOptions } from '../../../../functions/toastOptions';
import {
    cleanersState,
    resetFetchingState,
    resetTasksAndSlots,
    resetTasksAndSupervisors,
    resetWorkersAndSupervisors,
    slotsState,
    tasksForSupervisorsState,
    tasksState,
} from '../../../../redux/slices/hospitalitySlice';
import { Button } from '../../../ui/button/Button.tsx';

export const ResetButton = ({ sectionId }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const cleaners = useSelector(cleanersState);
    const tasksForCleaners = useSelector(tasksState);
    const tasksForSupervisors = useSelector(tasksForSupervisorsState);
    const slots = useSelector(slotsState);
    const resetFetching = useSelector(resetFetchingState);

    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        if (sectionId === 1) {
            setIsDisabled(!cleaners.find(cleaner => cleaner.isTouched));
        }
        if (sectionId === 2) {
            setIsDisabled(!tasksForCleaners.find(task => task.isTouched) && !slots.find(slot => slot.isTouched));
        }
        if (sectionId === 3) {
            setIsDisabled(!tasksForSupervisors.find(task => task.isTouched));
        }

        return () => setIsDisabled(true);
    }, [sectionId, cleaners, slots, tasksForCleaners, tasksForSupervisors]);

    const resetHandler = async () => {
        let result;

        if (sectionId === 1) {
            result = await dispatch(resetWorkersAndSupervisors());
        }
        if (sectionId === 2) {
            result = await dispatch(resetTasksAndSlots());
        }
        if (sectionId === 3) {
            result = await dispatch(resetTasksAndSupervisors());
        }

        if (result) {
            if (result.error) {
                toast.error(result.message, toastOptions);
            } else {
                toast.success(t('HOSPITALITY_CHANGES_RESET'), toastOptions);
            }
        }
    };

    return (
        <Button type="cancel" disabled={isDisabled} loading={resetFetching} onClick={resetHandler}>
            {t('CANCEL')}
        </Button>
    );
};
