export const PopupTitle = ({ setLibElemTitle, title }) => {
    return (
        <div className="mb-10 flex justify-center">
            <input
                type="text"
                className="h-10 w-96 rounded border border-gray-10 text-center text-base"
                defaultValue={title}
                onChange={e => setLibElemTitle(e.target.value)}
            />
        </div>
    );
};
