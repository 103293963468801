import { SimpleSelect } from '../../../../common/widgets/SimpleSelect';

export const RoleSelect = ({ options, employee, callback }) => {
    return (
        <SimpleSelect
            isDisabled={!employee.employee_data}
            closeMenuOnSelect={true}
            isSearchable={true}
            isClearable={false}
            options={options}
            placeholder="Выберите роль"
            value={employee.employee_role_id}
            callback={val => callback(val, employee.employeeId)}
            className="ml-3 w-56 text-sm"
        />
    );
};
