import { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';

import { Header } from '../components/newsAndPolls/header/Header';
import { MainSection } from '../components/newsAndPolls/mainSection/MainSection';
import { CreateNewsPane } from '../components/newsAndPolls/panes/createNewsPane/CreateNewsPane';
import { CreatePollPane } from '../components/newsAndPolls/panes/createPollPane/CreatePollPane';
import { SubHeader } from '../components/newsAndPolls/subheader/SubHeader';

export const NewsAndPollsPage = () => {
    const [activeSectionId, setActiveSectionId] = useState(1);
    const [createNewsPaneIsOpen, setCreateNewsPaneIsOpen] = useState(false);
    const [createPollPaneIsOpen, setCreatePollPaneIsOpen] = useState(false);
    const [item, setItem] = useState(null);

    useEffect(() => {
        if (item && item.id) {
            if (item.type === 'news' || item.type === 'agreement') {
                setCreateNewsPaneIsOpen(true);
            }
            if (item.type === 'poll') {
                setCreatePollPaneIsOpen(true);
            }
        }
    }, [item]);

    return (
        <>
            <div className="sticky top-16">
                <Header />
                <SubHeader
                    activeSectionId={activeSectionId}
                    setActiveSectionId={setActiveSectionId}
                    setCreateNewsPaneIsOpen={setCreateNewsPaneIsOpen}
                    setCreatePollPaneIsOpen={setCreatePollPaneIsOpen}
                />
            </div>

            <MainSection activeSectionId={activeSectionId} setItem={setItem} />

            <CreateNewsPane isOpen={createNewsPaneIsOpen} setIsOpen={setCreateNewsPaneIsOpen} item={item} setItem={setItem} />
            <CreatePollPane isOpen={createPollPaneIsOpen} setIsOpen={setCreatePollPaneIsOpen} item={item} setItem={setItem} />

            <ReactTooltip textColor="#FCFCFC" backgroundColor="#707183E5" effect="solid" />
        </>
    );
};
