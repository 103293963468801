// Добавить уникальные элементы в массив объектов
export const addUniqueElements = (originalArray, newElements, key) => {
    const existingKeys = originalArray.map(item => item[key]);

    newElements.forEach(element => {
        if (!existingKeys.includes(element[key])) {
            originalArray.push(element);
            existingKeys.push(element[key]);
        }
    });

    return originalArray;
};
