import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const WorkTypeItem = ({ item, setWorkTypes }) => {
    const { t } = useTranslation();

    const [isEdit, setIsEdit] = useState(false);
    const inputRef = useRef(null);

    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: item.id });
    const style = {
        cursor: 'pointer!',
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div className="flex flex-row gap-3 bg-white" ref={setNodeRef} style={style}>
            <div className="relative flex h-10 w-72 flex-row items-center rounded border border-gray-200 bg-white">
                <input
                    type="text"
                    value={item.use_title}
                    onChange={e => {
                        setWorkTypes(prevWorkTypes =>
                            prevWorkTypes.map(type => {
                                if (type.id === item.id) {
                                    return { ...type, use_title: e.target.value };
                                }

                                return type;
                            })
                        );
                    }}
                    className={`h-full border-none text-xs text-black ${isEdit ? 'w-full px-4' : 'w-0 p-0'}`}
                    ref={inputRef}
                />

                <div className={`mr-auto w-full select-none pl-4 text-xs text-black ${!isEdit ? 'line-clamp-2' : 'hidden'}`}>{item.use_title}</div>

                <button
                    type="button"
                    onClick={() => {
                        setIsEdit(!isEdit);
                        if (!isEdit && inputRef.current) {
                            inputRef.current.focus();
                        }
                    }}
                    className="flex h-full w-10 flex-shrink-0 items-center justify-center hover:opacity-80"
                >
                    <svg width="20" height="20" viewBox="0 0 20 20" className="fill-gray">
                        <path d="M16.1875 7.54165L12.4583 3.81248L13.4792 2.79165C13.8264 2.44442 14.2396 2.27081 14.7188 2.27081C15.1979 2.27081 15.6111 2.44442 15.9583 2.79165L17.2083 4.04165C17.5417 4.37498 17.7083 4.78817 17.7083 5.28123C17.7083 5.77429 17.5417 6.18748 17.2083 6.52081L16.1875 7.54165ZM14.9583 8.77081L6.22917 17.5H2.5V13.7708L11.2292 5.04165L14.9583 8.77081Z" />
                    </svg>
                </button>
                <div className="h-7 w-[1px] flex-shrink-0 bg-gray-200"></div>

                <button
                    type="button"
                    onClick={() => {
                        setWorkTypes(prevWorkTypes =>
                            prevWorkTypes.map(type => {
                                if (type.id === item.id) {
                                    return { ...type, used: 0 };
                                }

                                return type;
                            })
                        );
                    }}
                    className="flex h-full w-10 flex-shrink-0 items-center justify-center hover:opacity-80"
                >
                    <svg width="20" height="20" viewBox="0 0 20 20" className="fill-red-600">
                        <path d="M8.33333 13.2292L10 11.5625L11.6667 13.2292L12.7292 12.1667L11.0625 10.5L12.7292 8.83333L11.6667 7.77083L10 9.4375L8.33333 7.77083L7.27083 8.83333L8.9375 10.5L7.27083 12.1667L8.33333 13.2292ZM6.5 17C6.08333 17 5.72917 16.8542 5.4375 16.5625C5.14583 16.2708 5 15.9167 5 15.5V5.5H4V4H8V3H12V4H16V5.5H15V15.5C15 15.9167 14.8542 16.2708 14.5625 16.5625C14.2708 16.8542 13.9167 17 13.5 17H6.5Z" />
                    </svg>
                </button>
            </div>

            <div className="flex h-10 w-10 items-center justify-center rounded-full border border-gray-200">
                <div className="h-8 w-8 overflow-hidden rounded-full">
                    <input
                        type="color"
                        className="relative -left-2 -top-2 h-12 w-12 cursor-pointer bg-white"
                        value={item.color}
                        onChange={e => {
                            setWorkTypes(prevWorkTypes =>
                                prevWorkTypes.map(type => {
                                    if (type.id === item.id) {
                                        return { ...type, color: e.target.value };
                                    }

                                    return type;
                                })
                            );
                        }}
                    />
                </div>
            </div>

            <label className="ml-3 inline-flex cursor-pointer select-none items-center gap-2">
                <input
                    type="checkbox"
                    checked={item.use_supervisors}
                    onChange={e => {
                        setWorkTypes(prevWorkTypes =>
                            prevWorkTypes.map(type => {
                                if (type.id === item.id) {
                                    return { ...type, use_supervisors: +e.target.checked };
                                }

                                return type;
                            })
                        );
                    }}
                    className="cursor-pointer bg-white"
                />
                <div className="text-sm font-normal leading-snug text-black text-opacity-60">{t('HOSPITALITY_REQUIRES_VERIFICATION')}</div>
            </label>

            <div {...attributes} {...listeners} className="flex w-7 items-center justify-center rounded-md hover:bg-slate-100">
                <svg viewBox="0 0 20 20" width="12">
                    <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path>
                </svg>
            </div>
        </div>
    );
};
