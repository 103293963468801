import { useRef } from 'react';

import { ControlBox } from './ControlBox';
import { ButtonControl } from './controlTypes/ButtonControl';
import { FeedbackButtonControl } from './controlTypes/FeedbackButtonControl';
import { ListControl } from './controlTypes/ListControl';
import { PresetValueControl } from './controlTypes/PresetValueControl';
import { ToggleControl } from './controlTypes/ToggleControl';
import { TriggerControl } from './controlTypes/TriggerControl';

export const ControlsSection = ({ controls, editNodeHandler }) => {
    const containerSize = useRef({
        width: 1920,
        height: 540,
    });

    return (
        <div
            className="mb-1 flex flex-wrap place-content-start gap-4 border bg-red-200 p-2.5"
            style={{ width: containerSize.current.width, maxHeight: containerSize.current.height, minHeight: '100px' }}
        >
            {controls &&
                controls.length !== 0 &&
                controls.map(control => (
                    <ControlBox editNodeHandler={editNodeHandler} key={control.cn_id} control={control}>
                        <span className="text-sm">{control.data.title}</span>

                        {control.lib_data.subtype === 'list' && <ListControl control={control} />}
                        {control.lib_data.subtype === 'button' && <ButtonControl control={control} />}
                        {control.lib_data.subtype === 'input' && <PresetValueControl control={control} />}
                        {control.lib_data.subtype === 'toggle' && <ToggleControl control={control} />}
                        {control.lib_data.subtype === 'trigger' && <TriggerControl />}
                        {control.lib_data.subtype === 'button_with_feedback' && <FeedbackButtonControl />}
                    </ControlBox>
                ))}
        </div>
    );
};
