export const UseBitCheckbox = ({ data, setData, setValues, initialState }) => {
    return (
        <div className="flex items-center">
            <span className="mr-2 text-sm">Побитная маска</span>
            <input
                disabled={
                    !data.dataPointControl.dataPointControlObj ||
                    data.dataPointControl.dataPointControlObj.datatype === 'boolean' ||
                    data.dataPointControl.dataPointControlObj.type === 'float' ||
                    !data.dataPointControl.dataPointControlObj.bitSize
                }
                className="cursor-pointer hover:border-gray-30"
                name="range"
                type="checkbox"
                value={data.dataPointControl.bit}
                checked={data.dataPointControl.bit}
                onChange={() => {}}
                onClick={e => {
                    setData({
                        ...data,
                        dataPointControl: {
                            ...initialState.dataPointControl,
                            dataPointControlObj: data.dataPointControl.dataPointControlObj,
                            bit: e.target.checked,
                        },
                    });
                    setValues([
                        {
                            ...data,
                            dataPointControl: {
                                ...initialState.dataPointControl,
                                dataPointControlObj: data.dataPointControl.dataPointControlObj,
                                bit: e.target.checked,
                            },
                        },
                    ]);
                }}
            />
        </div>
    );
};
