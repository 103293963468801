import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { departmentsFetchingState, departmentsState } from '../../../../../redux/slices/newsAndPollsSlice';
import { MultiSelect } from '../../../../ui/multiSelect/MultiSelect';

export const DepartmentsSection = ({ selectedDepartments, setSelectedDepartments, selectedUsers, isDisabled }) => {
    const departments = useSelector(departmentsState);
    const departmentsFetching = useSelector(departmentsFetchingState);

    const departmentsMemo = useMemo(() => {
        return departments.map(item => ({ value: item.id, label: item.name }));
    }, [departments]);

    useEffect(() => {
        if (departmentsMemo) {
            setSelectedDepartments(departmentsMemo);
        }
    }, [departmentsMemo]);

    return (
        <div>
            <div className="mb-3 text-xs font-medium text-neutral-900">Выбрать департамент</div>

            <MultiSelect
                value={selectedDepartments}
                options={departmentsMemo}
                callback={setSelectedDepartments}
                className="w-96"
                isDisabled={!selectedUsers.length || isDisabled}
                isLoading={departmentsFetching}
            />
        </div>
    );
};
