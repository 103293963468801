export const InputValue = ({ type, setData, data }) => {
    const handlerInput = e => {
        let inputValue = e.target.value;

        inputValue = inputValue.replace(/[^0-9.]/g, '');

        if (inputValue !== '' && inputValue !== '.') {
            inputValue = Math.round(parseFloat(inputValue) * 10) / 10;
        }

        if (!isNaN(inputValue)) {
            setData(prevState => {
                return {
                    ...prevState,
                    data: {
                        ...prevState.data,
                        typeSettings: data.map(el => (el.id === type.id ? { ...el, value: inputValue.toString() } : el)),
                    },
                };
            });
        }
    };
    return (
        <div className="ml-2">
            <input
                type="number"
                onChange={e => handlerInput(e)}
                value={type.value ? type.value : ''}
                placeholder="значение"
                className="h-7 w-20 border-gray-70 px-1 text-sm hover:border-gray-30"
                disabled={!type.typeOptions || type.typeOptions === '' || type.typeOptions === 'period'}
            />
        </div>
    );
};
