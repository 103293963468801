import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { Header } from './Header';
import { ScrollToTop } from './ScrollToTop';
import { Sidebar } from './sidebar/Sidebar';

export const MainLayout = () => {
    const { pathname } = useLocation();

    const sidebarTrigger = useRef(null);

    const [sidebarOpen, setSidebarOpen] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className="flex h-screen overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} sidebarTrigger={sidebarTrigger} />

            <div className="relative flex w-full flex-col overflow-x-hidden overflow-y-scroll" id="wrapper">
                <Header setSidebarOpen={setSidebarOpen} sidebarTrigger={sidebarTrigger} />
                <Outlet />
                <ScrollToTop />
            </div>

            <ToastContainer />
        </div>
    );
};
