import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTable } from 'react-table';

import { set_bms_doc_list } from '../../../redux/slices/bms/bmsSliceDocuments';
import {
    bmsEventLogFiltersState,
    bmsEventLogListFetchState,
    bmsEventLogListState,
    getBMSEventLogList,
    getBMSEventLogListTimeout,
    setBMSEventLogFilters,
} from '../../../redux/slices/bms/bmsSliceEventLog';
import { Loader } from '../../common/Loader';
import { AcceptEventPopup } from './AcceptEventPopup';
import { DocumentLink } from './DocumentLink';
import { OperatorAcceptButton } from './OperatorAcceptButton';
import { Pagination } from './Pagination';

const initialState = { isOpen: false, id: null };

export const EventLogTable = () => {
    const dispatch = useDispatch();
    const bmsEventLogFilters = useSelector(bmsEventLogFiltersState);
    const bmsEventLogList = useSelector(bmsEventLogListState);
    const bmsEventLogListFetch = useSelector(bmsEventLogListFetchState);

    const [popupState, setPopupState] = useState(initialState);

    useEffect(() => {
        dispatch(set_bms_doc_list([]));
        dispatch(getBMSEventLogList({ data: bmsEventLogFilters }));
        const interval = setInterval(async () => {
            dispatch(getBMSEventLogListTimeout({ data: bmsEventLogFilters }));
        }, 15000);
        return () => clearInterval(interval);
    }, [bmsEventLogFilters]);

    const columns = useMemo(() => (bmsEventLogList ? bmsEventLogList.tHead.filter(item => item.accessor !== 'operator') : []), [bmsEventLogList]);

    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({
        columns,
        data: bmsEventLogList ? bmsEventLogList.tBody : [],
        initialState: { pageSize: bmsEventLogFilters ? bmsEventLogFilters.page_size : 100 },
    });

    if (bmsEventLogListFetch) {
        return (
            <div className="flex h-20 w-full items-center justify-center">
                <Loader />
            </div>
        );
    }
    if (bmsEventLogList && !bmsEventLogList.tBody.length) {
        return <div className="mt-3 w-full text-center">Данных нет</div>;
    }

    return (
        <div className="w-full overflow-x-auto">
            <table {...getTableProps()} className="relative w-full border-separate border-spacing-0 text-sm">
                <thead className="text-left text-black">
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => {
                                return (
                                    <th
                                        {...column.getHeaderProps()}
                                        className="sticky top-0 border-y border-gray-10 bg-white px-2 py-3 font-medium first:rounded-tl-lg first:border-l first:pl-4 last:rounded-tr-lg last:border-r last:pr-4"
                                    >
                                        <div className="flex">
                                            {column.render('Header')}
                                            <button
                                                type="button"
                                                onClick={() =>
                                                    dispatch(
                                                        setBMSEventLogFilters({ type: 'sort', value: bmsEventLogFilters.sort === '-date' ? 'date' : '-date' })
                                                    )
                                                }
                                                className="ml-1"
                                            >
                                                {column.id === 'date' ? (bmsEventLogFilters.sort === '-date' ? ' 🔽' : ' 🔼') : ''}
                                            </button>
                                        </div>
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, index) => {
                        prepareRow(row);
                        return (
                            <tr
                                {...row.getRowProps()}
                                className="mb-4 block overflow-hidden rounded-lg border border-gray-10 md:mb-0 md:table-row md:overflow-visible md:border-0"
                            >
                                {row.cells.map(cell => {
                                    let additionally = '';
                                    let styles = {};

                                    if (cell.column.id === 'link') {
                                        additionally = <DocumentLink link={cell.value} />;
                                    }
                                    if (cell.column.id === 'operator') {
                                        additionally =
                                            cell.value === '' ? <OperatorAcceptButton setPopupState={setPopupState} id={cell.row.original.id} /> : cell.value;
                                    }
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            data-label={cell.column.Header}
                                            className={`flex justify-between border-b border-gray-10 bg-white px-2 py-1.5 text-right font-normal before:font-medium before:uppercase before:content-[attr(data-label)] last:border-b-0 md:table-cell md:text-left md:before:hidden md:first:border-l md:first:pl-4 md:last:border-b md:last:border-r md:last:pr-4 ${
                                                rows.length === index + 1 ? 'first:rounded-bl-lg last:rounded-br-lg' : ''
                                            } ${styles}`}
                                        >
                                            {additionally || cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <Pagination searchParams={bmsEventLogFilters} />
            <AcceptEventPopup popupState={popupState} setPopupState={setPopupState} />
        </div>
    );
};
