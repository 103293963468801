import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { toastOptions } from '../../../../../../functions/toastOptions';
import { SimpleSelect } from '../../../../../common/widgets/SimpleSelect';

export const ListControl = ({ nodeOptions, setValues, setWritePoint, setCheckPopupFields, control }) => {
    const [data, setData] = useState({
        writePoint: null,
        values: [{ label: '', value: '', idx: Date.now() }],
        boolean: { true: '', false: '' },
    });

    useEffect(() => {
        try {
            JSON.parse(control.data);
        } catch (e) {
            const writePoint = nodeOptions.length > 0 && nodeOptions.find(el => el.value === control.data.write_point);

            setData({ writePoint, values: control.data.values, boolean: control.data.values });
            setValues([...control.data.values]);
            setWritePoint(writePoint.value);
        }
    }, [control, nodeOptions]);

    useEffect(() => {
        if (data.writePoint) {
            if (data.writePoint.datatype === 'boolean') {
                // проверка на не пустые значения
                const value = Object.entries(data.boolean).map(el => {
                    return {
                        value: el[0],
                        label: el[1],
                    };
                });
                value[0].label.length !== 0 && value[1].label.length !== 0 ? setCheckPopupFields(true) : setCheckPopupFields(false);

                // проверка на совпадение названий
                const valuesArr = Object.values(data.boolean);
                if (valuesArr[0] === valuesArr[1] && valuesArr[0].length > 0 && valuesArr[1].length > 0) {
                    toast.error('Названия точек должны быть разными', toastOptions);
                    setCheckPopupFields(false);
                }
            } else {
                // проверка на не пустые значения
                for (const val of data.values) {
                    if (val.value.length === 0 || val.label.length === 0) {
                        setCheckPopupFields(false);
                        return;
                    } else {
                        setCheckPopupFields(true);
                    }
                }

                // проверка на совпадение названий
                const valuesArr = data.values.map(el => el.value);
                const labelsArr = data.values.map(el => el.label);
                if (new Set(valuesArr).size !== valuesArr.length || new Set(labelsArr).size !== labelsArr.length) {
                    toast.error('Нельзя добавить два или более одинаковых значения или названия', toastOptions);
                    setCheckPopupFields(false);
                }
            }
        }
    }, [data]);

    return (
        <>
            <SimpleSelect
                isClearable={false}
                options={nodeOptions}
                placeholder="Выберите точку"
                value={data.writePoint}
                isSearchable={true}
                callback={val => {
                    if (val === null) {
                        return;
                    }
                    setWritePoint(val.value);
                    setData({
                        values: [{ label: '', value: '', idx: Date.now() }],
                        boolean: { true: '', false: '' },
                        writePoint: val,
                    });
                    setValues([]);
                }}
                className="mr-5 w-56"
            />
            <div>
                <div className="w-72 border p-2">
                    <div className="m-2 flex justify-around border-b">
                        <span>Значение</span>
                        <span>Название</span>
                    </div>
                    <div>
                        {data.writePoint?.datatype === 'boolean' ? (
                            <>
                                <div className="relative mb-1">
                                    <div className="flex justify-around">
                                        <span className="text-sm">true</span>

                                        <input
                                            type="text"
                                            value={data.boolean.true}
                                            placeholder="название"
                                            className="ml-10 h-6 w-24 border-0 p-1 text-xs"
                                            onChange={e => {
                                                setValues({ ...data.boolean, true: e.target.value.trim() });
                                                setData({
                                                    ...data,
                                                    boolean: { ...data.boolean, true: e.target.value },
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="relative mb-1">
                                    <div className="flex justify-around">
                                        <span className="text-sm">false</span>

                                        <input
                                            type="text"
                                            value={data.boolean.false}
                                            placeholder="название"
                                            className="ml-10 h-6 w-24 border-0 p-1 text-xs"
                                            onChange={e => {
                                                setValues({ ...data.boolean, false: e.target.value.trim() });
                                                setData({
                                                    ...data,
                                                    boolean: { ...data.boolean, false: e.target.value },
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </>
                        ) : (
                            data.writePoint?.datatype !== 'boolean' &&
                            data.values.map((value, idx) => (
                                <div className="relative mb-1" key={value.idx}>
                                    <div className="flex justify-around">
                                        <input
                                            type="text"
                                            value={data.values[idx].value}
                                            disabled={!data.writePoint}
                                            placeholder="значение"
                                            className="h-6 w-20 border-0 p-1 text-xs"
                                            onChange={e => {
                                                setValues(data.values.map(el => (el.idx === value.idx ? { ...el, value: e.target.value.trim() } : el)));
                                                setData({
                                                    ...data,
                                                    values: data.values.map(el => (el.idx === value.idx ? { ...el, value: e.target.value } : el)),
                                                });
                                            }}
                                        />
                                        <input
                                            type="text"
                                            value={data.values[idx].label}
                                            disabled={!data.writePoint}
                                            placeholder="название"
                                            className="mr-1 h-6 w-24 border-0 p-1 text-xs"
                                            onChange={e => {
                                                setValues(data.values.map(el => (el.idx === value.idx ? { ...el, label: e.target.value.trim() } : el)));
                                                setData({
                                                    ...data,
                                                    values: data.values.map(el => (el.idx === value.idx ? { ...el, label: e.target.value } : el)),
                                                });
                                            }}
                                        />
                                        {data.values.length > 1 && (
                                            <div
                                                className="absolute right-3 top-0.5 cursor-pointer text-red-600"
                                                onClick={() => {
                                                    setValues(data.values.filter(el => el.idx !== value.idx));
                                                    setData({
                                                        ...data,
                                                        values: data.values.filter(el => el.idx !== value.idx),
                                                    });
                                                }}
                                            >
                                                –
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
                {data.values.length < 6 && data.writePoint?.datatype !== 'boolean' && (
                    <span
                        className="cursor-pointer text-sm underline"
                        onClick={() => setData({ ...data, values: [...data.values, { value: '', label: '', idx: Date.now() }] })}
                    >
                        Добавить поле
                    </span>
                )}
            </div>
        </>
    );
};
