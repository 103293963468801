import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { url } from '../../../../../api/config';
import { toastOptions } from '../../../../../functions/toastOptions';
import {
    addBMSDocNode,
    getBMSNodesList,
    nodes_list as nodes_list_state,
    set_control,
    updateControlData,
} from '../../../../../redux/slices/bms/bmsSliceDocuments';
import { ButtonRectangular } from '../../../../common/btn/ButtonRectangular';
import { Popup } from '../../../../common/popup/Popup';
import { PopupTitle } from './PopupTitle';
import { ButtonControl } from './controlTypes/ButtonControl';
import { ListControl } from './controlTypes/ListControl';
import { PresetValueControl } from './controlTypes/PresetValueControl';
import { ToggleControl } from './controlTypes/ToggleControl';
import { TriggerControl } from './controlTypes/TriggerControl';
import { FeedbackButtonControl } from './controlTypes/feedbackButtonControl/FeedbackButtonControl';

export const CreateControlPopup = ({ setIsOpen, control, docId }) => {
    const dispatch = useDispatch();

    const nodes_list = useSelector(nodes_list_state);

    const [controlTitle, setControlTitle] = useState(control.name);
    const [values, setValues] = useState([]);
    const [writePoint, setWritePoint] = useState(null);
    const [isDisabled, setIsDisabled] = useState(true);
    const [checkPopupFields, setCheckPopupFields] = useState(false);
    const [nodeOptions, setNodeOptions] = useState([]);
    const [nodeControlOptions, setNodeControlOptions] = useState([]);

    useEffect(() => {
        if (!nodes_list.length) {
            dispatch(getBMSNodesList({ docId }));
        }

        if (nodes_list.length) {
            const node = nodes_list.filter(node => node.access === 'RW' || node.access === 'W');
            const nodeControl = nodes_list.filter(node => node.access === 'RW' || node.access === 'R');
            setNodeOptions(
                node.map(node => ({ value: node.id, label: node.name, datatype: node.datatype, value_from_data_point: node.value, bitSize: node.bitsize }))
            );
            setNodeControlOptions(
                nodeControl.map(node => ({
                    value: node.id,
                    label: node.name,
                    datatype: node.datatype,
                    value_from_data_point: node.value,
                    bitSize: node.bitsize,
                }))
            );
        }
    }, [nodes_list]);

    useEffect(() => {
        if (controlTitle.length !== 0 && checkPopupFields) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [controlTitle, checkPopupFields]);

    const clickHandler = async () => {
        const correctValues = values.hasOwnProperty('true')
            ? Object.entries(values).map(el => {
                  return {
                      value: el[0],
                      label: el[1],
                  };
              })
            : values;

        const data = {
            title: controlTitle,
            values: correctValues,
            write_point: writePoint,
        };
        const nodeObj = {
            doc_id: docId,
            node_id: control.id,
            data: JSON.stringify(data),
        };

        if (control.hasOwnProperty('cn_id')) {
            const updateNode = {
                ...control,
                title: controlTitle,
                data: JSON.stringify(data),
            };

            const result = await dispatch(updateControlData({ updateNode }));
            if (result.error) {
                toast.error('Что-то пошло не так. Пожалуйста, обновите страницу и попробуйте еще раз.', toastOptions);

                return null;
            }
        } else {
            const { cn_id } = await dispatch(addBMSDocNode({ nodeObj }));

            const newControl = {
                ...control,
                cn_id,
                title: controlTitle,
                name: controlTitle,
                data: { ...control.lib_data, write_point: writePoint, values: correctValues, title: controlTitle },
                lib_data: { ...control.lib_data, write_point: writePoint, values: correctValues, title: controlTitle },
            };

            dispatch(set_control(newControl));
        }

        setIsOpen(false);
    };
    const { subtype, default_pic } = control.lib_data;

    return (
        <Popup isOpen={true} setIsOpen={setIsOpen} className="w-[750px]">
            <PopupTitle title={controlTitle} setControlTitle={setControlTitle} />

            <div className="mt-6 flex items-center justify-center">
                {default_pic && <img src={`${url}${default_pic}`} alt={controlTitle} className="mr-5 h-12 w-12" />}
                {subtype === 'button' && (
                    <ButtonControl
                        control={control}
                        nodeOptions={nodeOptions}
                        setValues={setValues}
                        setWritePoint={setWritePoint}
                        setCheckPopupFields={setCheckPopupFields}
                    />
                )}
                {subtype === 'list' && (
                    <ListControl
                        control={control}
                        nodeOptions={nodeOptions}
                        setValues={setValues}
                        setWritePoint={setWritePoint}
                        setCheckPopupFields={setCheckPopupFields}
                    />
                )}
                {subtype === 'input' && (
                    <PresetValueControl
                        control={control}
                        nodeOptions={nodeOptions}
                        setValues={setValues}
                        setWritePoint={setWritePoint}
                        setCheckPopupFields={setCheckPopupFields}
                    />
                )}
                {subtype === 'toggle' && (
                    <ToggleControl
                        control={control}
                        nodeOptions={nodeOptions}
                        setValues={setValues}
                        setWritePoint={setWritePoint}
                        setCheckPopupFields={setCheckPopupFields}
                    />
                )}
                {subtype === 'trigger' && (
                    <TriggerControl
                        control={control}
                        nodeOptions={nodeOptions}
                        setValues={setValues}
                        setWritePoint={setWritePoint}
                        setCheckPopupFields={setCheckPopupFields}
                    />
                )}
                {subtype === 'button_with_feedback' && (
                    <FeedbackButtonControl
                        control={control}
                        nodeOptions={nodeOptions}
                        nodeControlOptions={nodeControlOptions}
                        setValues={setValues}
                        setWritePoint={setWritePoint}
                        setCheckPopupFields={setCheckPopupFields}
                    />
                )}
            </div>

            <ButtonRectangular disabled={isDisabled} className="ml-auto mr-auto mt-6 w-40 justify-center" onClick={clickHandler}>
                Применить
            </ButtonRectangular>
        </Popup>
    );
};
