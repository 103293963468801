import { useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import { SvgComponent } from '../../../../common/svgComponent/SvgComponent';
import { Checkbox } from '../../../../ui/checkbox/Checkbox';
import { RadioButton } from '../../../../ui/radioButton/RadioButton';
import { SimpleSelect } from '../../../../ui/simpleSelect/SimpleSelect';

const rangeValues = [
    {
        value: '0',
        label: '0',
    },
    {
        value: '1',
        label: '1',
    },
    {
        value: '2',
        label: '2',
    },
    {
        value: '3',
        label: '3',
    },
    {
        value: '4',
        label: '4',
    },
    {
        value: '5',
        label: '5',
    },
    {
        value: '6',
        label: '6',
    },
    {
        value: '7',
        label: '7',
    },
    {
        value: '8',
        label: '8',
    },
    {
        value: '9',
        label: '9',
    },
    {
        value: '10',
        label: '10',
    },
];

export const QuestionsSection = ({ questions, setQuestions, isDisabled }) => {
    const { t } = useTranslation();
    const [cookies] = useCookies();

    const lang = useMemo(() => cookies.i18next, [cookies]);

    const questionsType = useMemo(
        () => [
            {
                value: '1',
                label: t('NEWS_AND_POLLS_QUESTION_TYPE_1'),
                svgName: 'radio-button',
            },
            {
                value: '2',
                label: t('NEWS_AND_POLLS_QUESTION_TYPE_2'),
                svgName: 'checkbox',
            },
            {
                value: '3',
                label: t('NEWS_AND_POLLS_QUESTION_TYPE_3'),
                svgName: 'linear-scale',
            },
        ],
        [lang]
    );

    const addQuestionHandler = () => {
        setQuestions(prevState => [
            ...prevState,
            {
                id: Date.now(),
                questionType: '',
                title: '',
                options: [],
                optionsRange: [
                    {
                        value: '0',
                        title: '',
                    },
                    {
                        value: '10',
                        title: '',
                    },
                ],
                required: false,
            },
        ]);
    };

    return (
        <div>
            <div className="mb-3 text-base font-medium text-neutral-900">{t('NEWS_AND_POLLS_QUESTIONS')}</div>

            {!!questions.length && (
                <div className="mb-11 flex flex-col gap-8">
                    {questions.map(question => (
                        <div key={question.id} className="flex flex-col gap-3">
                            <div className="flex flex-row gap-4">
                                <input
                                    type="text"
                                    className="h-10 w-full rounded border-neutral-200 bg-neutral-100 text-sm"
                                    placeholder={t('NEWS_AND_POLLS_QUESTION_WITHOUT_TITLE')}
                                    value={question.title}
                                    onChange={e =>
                                        setQuestions(prevState =>
                                            prevState.map(item =>
                                                item.id === question.id
                                                    ? {
                                                          ...item,
                                                          title: e.target.value,
                                                      }
                                                    : item
                                            )
                                        )
                                    }
                                    disabled={isDisabled}
                                />

                                <SimpleSelect
                                    className="w-80"
                                    options={questionsType}
                                    value={questionsType.find(item => item.value === question.questionType)}
                                    callback={value =>
                                        setQuestions(prevState =>
                                            prevState.map(item => (item.id === question.id ? { ...item, questionType: value ? value.value : '' } : item))
                                        )
                                    }
                                    placeholder={t('NEWS_AND_POLLS_SELECT_QUESTION_TYPE')}
                                    isClearable={false}
                                    isDisabled={isDisabled}
                                />
                            </div>

                            {/*radio*/}
                            {question.questionType === '1' && (
                                <div className="flex flex-col gap-3">
                                    {question.options.map(option => (
                                        <div key={option.id} className="flex h-[38px] flex-row items-center gap-2 border-b border-b-neutral-200">
                                            <RadioButton checked={false} />
                                            <input
                                                type="text"
                                                className="h-full flex-1 border-0 p-0 text-sm focus:outline-0 focus:ring-0 disabled:bg-transparent disabled:text-gray-30 hover:disabled:bg-transparent"
                                                placeholder={t('NEWS_AND_POLLS_ANSWER_OPTION')}
                                                value={option.title}
                                                disabled={option.title === t('NEWS_AND_POLLS_ANSWER_OTHER') || isDisabled}
                                                onChange={e =>
                                                    setQuestions(prevState =>
                                                        prevState.map(item =>
                                                            item.id === question.id
                                                                ? {
                                                                      ...item,
                                                                      options: item.options.map(optionItem =>
                                                                          optionItem.id === option.id ? { ...optionItem, title: e.target.value } : optionItem
                                                                      ),
                                                                  }
                                                                : item
                                                        )
                                                    )
                                                }
                                            />
                                            {!isDisabled && (
                                                <button
                                                    className="h-6 w-6"
                                                    onClick={() =>
                                                        setQuestions(prevState =>
                                                            prevState.map(item =>
                                                                item.id === question.id
                                                                    ? {
                                                                          ...item,
                                                                          options: item.options.filter(optionItem => optionItem.id !== option.id),
                                                                      }
                                                                    : item
                                                            )
                                                        )
                                                    }
                                                >
                                                    <SvgComponent name="close" />
                                                </button>
                                            )}
                                        </div>
                                    ))}

                                    {!isDisabled && (
                                        <div className="flex h-[38px] flex-row items-center gap-6">
                                            <div className="flex flex-row items-center gap-2 text-sm text-gray-500 transition-colors duration-200">
                                                <button
                                                    className="inline-flex flex-row items-center gap-2 hover:text-blue"
                                                    onClick={() =>
                                                        setQuestions(prevState =>
                                                            prevState.map(item =>
                                                                item.id === question.id
                                                                    ? {
                                                                          ...item,
                                                                          options: [
                                                                              ...item.options,
                                                                              {
                                                                                  id: Date.now(),
                                                                                  title: '',
                                                                              },
                                                                          ],
                                                                      }
                                                                    : item
                                                            )
                                                        )
                                                    }
                                                >
                                                    <div className="w-[18px]">
                                                        <SvgComponent name="plus" />
                                                    </div>
                                                    {t('NEWS_AND_POLLS_ADD_OPTION')}
                                                </button>

                                                {!question.options.find(item => item.title === t('NEWS_AND_POLLS_ANSWER_OTHER')) && (
                                                    <>
                                                        <span>{t('OR')}</span>
                                                        <button
                                                            className="text-blue hover:text-blue-600"
                                                            onClick={() =>
                                                                setQuestions(prevState =>
                                                                    prevState.map(item =>
                                                                        item.id === question.id
                                                                            ? {
                                                                                  ...item,
                                                                                  options: [
                                                                                      ...item.options,
                                                                                      {
                                                                                          id: (item.options.length + 1).toString(),
                                                                                          title: t('NEWS_AND_POLLS_ANSWER_OTHER'),
                                                                                      },
                                                                                  ],
                                                                              }
                                                                            : item
                                                                    )
                                                                )
                                                            }
                                                        >
                                                            {t('NEWS_AND_POLLS_ADD_OPTION_OTHER')}
                                                        </button>
                                                    </>
                                                )}
                                            </div>

                                            <div className="ml-auto flex items-center justify-center text-sm">
                                                <Checkbox
                                                    title={t('NEWS_AND_POLLS_REQUIRED_QUESTION')}
                                                    checked={question.required}
                                                    onClick={() =>
                                                        setQuestions(prevState =>
                                                            prevState.map(item =>
                                                                item.id === question.id
                                                                    ? {
                                                                          ...item,
                                                                          required: !item.required,
                                                                      }
                                                                    : item
                                                            )
                                                        )
                                                    }
                                                />
                                            </div>

                                            <div className="flex flex-row gap-4">
                                                <button
                                                    className="w-5 hover:text-blue"
                                                    onClick={() =>
                                                        setQuestions(prevState => [
                                                            ...prevState,
                                                            {
                                                                ...question,
                                                                id: Date.now(),
                                                            },
                                                        ])
                                                    }
                                                >
                                                    <SvgComponent name="copy" />
                                                </button>

                                                <button
                                                    className="w-5 hover:text-red-600"
                                                    onClick={() => setQuestions(prevState => prevState.filter(item => item.id !== question.id))}
                                                >
                                                    <SvgComponent name="delete" />
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}

                            {/*checkbox*/}
                            {question.questionType === '2' && (
                                <div className="flex flex-col gap-3">
                                    {question.options.map(option => (
                                        <div key={option.id} className="flex h-[38px] flex-row items-center gap-2 border-b border-b-neutral-200 text-sm">
                                            <Checkbox checked={false} />
                                            <input
                                                type="text"
                                                className="h-full flex-1 border-0 p-0 text-sm focus:outline-0 focus:ring-0 disabled:bg-transparent disabled:text-gray-30 hover:disabled:bg-transparent"
                                                placeholder={t('NEWS_AND_POLLS_ANSWER_OPTION')}
                                                value={option.title}
                                                disabled={option.title === t('NEWS_AND_POLLS_ANSWER_OTHER') || isDisabled}
                                                onChange={e =>
                                                    setQuestions(prevState =>
                                                        prevState.map(item =>
                                                            item.id === question.id
                                                                ? {
                                                                      ...item,
                                                                      options: item.options.map(optionItem =>
                                                                          optionItem.id === option.id ? { ...optionItem, title: e.target.value } : optionItem
                                                                      ),
                                                                  }
                                                                : item
                                                        )
                                                    )
                                                }
                                            />
                                            {!isDisabled && (
                                                <button
                                                    className="h-6 w-6"
                                                    onClick={() =>
                                                        setQuestions(prevState =>
                                                            prevState.map(item =>
                                                                item.id === question.id
                                                                    ? {
                                                                          ...item,
                                                                          options: item.options.filter(optionItem => optionItem.id !== option.id),
                                                                      }
                                                                    : item
                                                            )
                                                        )
                                                    }
                                                >
                                                    <SvgComponent name="close" />
                                                </button>
                                            )}
                                        </div>
                                    ))}

                                    {!isDisabled && (
                                        <div className="flex h-[38px] flex-row items-center gap-6">
                                            <div className="flex flex-row items-center gap-2 text-sm text-gray-500 transition-colors duration-200">
                                                <button
                                                    className="inline-flex flex-row items-center gap-2 hover:text-blue"
                                                    onClick={() =>
                                                        setQuestions(prevState =>
                                                            prevState.map(item =>
                                                                item.id === question.id
                                                                    ? {
                                                                          ...item,
                                                                          options: [
                                                                              ...item.options,
                                                                              {
                                                                                  id: Date.now(),
                                                                                  title: '',
                                                                              },
                                                                          ],
                                                                      }
                                                                    : item
                                                            )
                                                        )
                                                    }
                                                >
                                                    <div className="w-[18px]">
                                                        <SvgComponent name="plus" />
                                                    </div>
                                                    {t('NEWS_AND_POLLS_ADD_OPTION')}
                                                </button>

                                                {!question.options.find(item => item.title === t('NEWS_AND_POLLS_ANSWER_OTHER')) && (
                                                    <>
                                                        <span>{t('OR')}</span>
                                                        <button
                                                            className="text-blue hover:text-blue-600"
                                                            onClick={() =>
                                                                setQuestions(prevState =>
                                                                    prevState.map(item =>
                                                                        item.id === question.id
                                                                            ? {
                                                                                  ...item,
                                                                                  options: [
                                                                                      ...item.options,
                                                                                      {
                                                                                          id: (item.options.length + 1).toString(),
                                                                                          title: t('NEWS_AND_POLLS_ANSWER_OTHER'),
                                                                                      },
                                                                                  ],
                                                                              }
                                                                            : item
                                                                    )
                                                                )
                                                            }
                                                        >
                                                            {t('NEWS_AND_POLLS_ADD_OPTION_OTHER')}
                                                        </button>
                                                    </>
                                                )}
                                            </div>

                                            <div className="ml-auto flex items-center justify-center text-sm">
                                                <Checkbox
                                                    title={t('NEWS_AND_POLLS_REQUIRED_QUESTION')}
                                                    checked={question.required}
                                                    onClick={() =>
                                                        setQuestions(prevState =>
                                                            prevState.map(item =>
                                                                item.id === question.id
                                                                    ? {
                                                                          ...item,
                                                                          required: !item.required,
                                                                      }
                                                                    : item
                                                            )
                                                        )
                                                    }
                                                />
                                            </div>

                                            <div className="flex flex-row gap-4">
                                                <button
                                                    className="w-5 hover:text-blue"
                                                    onClick={() =>
                                                        setQuestions(prevState => [
                                                            ...prevState,
                                                            {
                                                                ...question,
                                                                id: Date.now(),
                                                            },
                                                        ])
                                                    }
                                                >
                                                    <SvgComponent name="copy" />
                                                </button>

                                                <button
                                                    className="w-5 hover:text-red-600"
                                                    onClick={() => setQuestions(prevState => prevState.filter(item => item.id !== question.id))}
                                                >
                                                    <SvgComponent name="delete" />
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}

                            {/*range*/}
                            {question.questionType === '3' && (
                                <div className="flex flex-col gap-3">
                                    <div className="flex flex-row items-center gap-2 border-b border-b-neutral-200 pb-2">
                                        <SimpleSelect
                                            options={rangeValues}
                                            value={rangeValues.find(item => item.value === question.optionsRange[0].value)}
                                            isClearable={false}
                                            className="w-20"
                                            isOptionDisabled={option => +option.value >= +question.optionsRange[1].value}
                                            callback={value =>
                                                setQuestions(prevState =>
                                                    prevState.map(item =>
                                                        item.id === question.id
                                                            ? {
                                                                  ...item,
                                                                  optionsRange: [{ ...item.optionsRange[0], value: value.value }, item.optionsRange[1]],
                                                              }
                                                            : item
                                                    )
                                                )
                                            }
                                            isDisabled={isDisabled}
                                        />

                                        <div className="w-4">
                                            <SvgComponent name="minus" />
                                        </div>

                                        <SimpleSelect
                                            options={rangeValues}
                                            value={rangeValues.find(item => item.value === question.optionsRange[1].value)}
                                            isClearable={false}
                                            className="w-20"
                                            isOptionDisabled={option => +option.value <= +question.optionsRange[0].value}
                                            callback={value =>
                                                setQuestions(prevState =>
                                                    prevState.map(item =>
                                                        item.id === question.id
                                                            ? {
                                                                  ...item,
                                                                  optionsRange: [item.optionsRange[0], { ...item.optionsRange[1], value: value.value }],
                                                              }
                                                            : item
                                                    )
                                                )
                                            }
                                            isDisabled={isDisabled}
                                        />
                                    </div>

                                    <div className="flex flex-row gap-4">
                                        <div className="flex-1">
                                            <div className="flex h-[38px] flex-row items-center gap-2 border-b border-b-neutral-200">
                                                <div className="text-sm text-black">{question.optionsRange[0].value}</div>
                                                <input
                                                    type="text"
                                                    className="h-full flex-1 border-0 p-0 text-sm focus:outline-0 focus:ring-0"
                                                    placeholder={t('NEWS_AND_POLLS_QUESTION_TITLE_OPTIONAL')}
                                                    value={question.optionsRange[0].title}
                                                    onChange={e =>
                                                        setQuestions(prevState =>
                                                            prevState.map(item =>
                                                                item.id === question.id
                                                                    ? {
                                                                          ...item,
                                                                          optionsRange: [
                                                                              { ...item.optionsRange[0], title: e.target.value },
                                                                              item.optionsRange[1],
                                                                          ],
                                                                      }
                                                                    : item
                                                            )
                                                        )
                                                    }
                                                    disabled={isDisabled}
                                                />
                                            </div>
                                        </div>

                                        <div className="flex-1">
                                            <div className="flex h-[38px] flex-row items-center gap-2 border-b border-b-neutral-200">
                                                <div className="text-sm text-black">{question.optionsRange[1].value}</div>
                                                <input
                                                    type="text"
                                                    className="h-full flex-1 border-0 p-0 text-sm focus:outline-0 focus:ring-0"
                                                    placeholder={t('NEWS_AND_POLLS_QUESTION_TITLE_OPTIONAL')}
                                                    value={question.optionsRange[1].title}
                                                    onChange={e =>
                                                        setQuestions(prevState =>
                                                            prevState.map(item =>
                                                                item.id === question.id
                                                                    ? {
                                                                          ...item,
                                                                          optionsRange: [
                                                                              item.optionsRange[0],
                                                                              { ...item.optionsRange[1], title: e.target.value },
                                                                          ],
                                                                      }
                                                                    : item
                                                            )
                                                        )
                                                    }
                                                    disabled={isDisabled}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {!isDisabled && (
                                        <div className="flex h-[38px] flex-row items-center gap-6">
                                            <div className="ml-auto flex items-center justify-center text-sm">
                                                <Checkbox
                                                    title={t('NEWS_AND_POLLS_REQUIRED_QUESTION')}
                                                    checked={question.required}
                                                    onClick={() =>
                                                        setQuestions(prevState =>
                                                            prevState.map(item =>
                                                                item.id === question.id
                                                                    ? {
                                                                          ...item,
                                                                          required: !item.required,
                                                                      }
                                                                    : item
                                                            )
                                                        )
                                                    }
                                                />
                                            </div>

                                            <div className="flex flex-row gap-4">
                                                <button
                                                    className="w-5 hover:text-blue"
                                                    onClick={() => setQuestions(prevState => [...prevState, { ...question, id: Date.now() }])}
                                                >
                                                    <SvgComponent name="copy" />
                                                </button>

                                                <button
                                                    className="w-5 hover:text-red-600"
                                                    onClick={() => setQuestions(prevState => prevState.filter(item => item.id !== question.id))}
                                                >
                                                    <SvgComponent name="delete" />
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}

            {!isDisabled && (
                <button
                    className="flex flex-row items-center gap-2 text-sm font-medium text-blue transition-colors duration-200 hover:text-blue-600"
                    onClick={addQuestionHandler}
                >
                    <div className="w-[18px]">
                        <SvgComponent name="plus-circle" />
                    </div>
                    {t('NEWS_AND_POLLS_ADD_QUESTION')}
                </button>
            )}
        </div>
    );
};
