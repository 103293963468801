import { createSlice } from '@reduxjs/toolkit';
import { t } from 'i18next';

import { calendarAPI } from '../../api/modules/calendarAPI';

export const calendarSlice = createSlice({
    name: 'calendar',

    initialState: {
        fetching: false,
        filter_fetch: {},
        filter: {
            venue: null,
            building: null,
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
        },
        data: null,
        print_fetching: false,
        task: {
            id: null,
        },
        detail_is_open: false,
    },

    reducers: {
        fetching_start: state => {
            state.fetching = true;
        },
        fetching_end: state => {
            state.fetching = false;
        },

        //fetch filter
        set_filter_fetch_venue: (state, action) => {
            state.filter_fetch.venue = action.payload;
        },
        set_filter_fetch_building: (state, action) => {
            state.filter_fetch.building = action.payload;
        },
        set_filter_fetch_floor: (state, action) => {
            state.filter_fetch.floor = action.payload;
        },
        set_filter_fetch_office: (state, action) => {
            state.filter_fetch.office = action.payload;
        },
        set_filter_fetch_direction: (state, action) => {
            state.filter_fetch.direction = action.payload;
        },
        set_filter_fetch_user: (state, action) => {
            state.filter_fetch.user = action.payload;
        },

        //list
        set_data: (state, action) => {
            state.data = action.payload;
        },

        //local filter
        set_filter: (state, action) => {
            state.filter[action.payload.type] = action.payload.value;
        },

        //print
        print_fetching_start: state => {
            state.print_fetching = true;
        },
        print_fetching_end: state => {
            state.print_fetching = false;
        },

        //detail
        set_detail_open: (state, action) => {
            state.detail_is_open = action.payload;
        },
        set_task: (state, action) => {
            state.task = action.payload;
        },
    },
});

export const {
    fetching_start,
    fetching_end,
    set_filter_fetch_venue,
    set_filter_fetch_building,
    set_filter_fetch_floor,
    set_filter_fetch_office,
    set_filter_fetch_direction,
    set_filter_fetch_user,
    set_data,
    set_filter,
    print_fetching_start,
    print_fetching_end,
    set_detail_open,
    set_task,
} = calendarSlice.actions;

export const venueFetch =
    ({ username, auth_key_calendar, lang }) =>
    async dispatch => {
        // await dispatch(fetching_start());

        try {
            const response = await calendarAPI.venueList.fetch(username, auth_key_calendar, lang);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    const arr = res.data.items.map(item => ({
                        value: item.ID,
                        label: item.Name,
                    }));
                    await dispatch(set_filter_fetch_venue(arr));
                } else {
                    await dispatch(set_filter_fetch_venue([]));
                }
            } else {
                await dispatch(set_filter_fetch_venue([]));
            }
        } catch (e) {
            console.log('venueFetch error: ', e);
        } finally {
            await dispatch(set_filter({ type: 'venue', value: null }));
            // await dispatch(fetching_end());
        }
    };

export const buildingFetch =
    ({ username, auth_key_calendar, venue_id, lang }) =>
    async dispatch => {
        try {
            if (venue_id) {
                const response = await calendarAPI.buildingList.fetch(username, auth_key_calendar, venue_id, lang);

                if (response.status === 200) {
                    const res = await response.json();

                    if (res.code === 0) {
                        const arr = res.data.map(item => ({
                            value: item.ID,
                            label: item.Name,
                        }));
                        await dispatch(set_filter_fetch_building(arr));
                    } else {
                        await dispatch(set_filter_fetch_building([]));
                    }
                }
            } else {
                await dispatch(set_filter_fetch_building([]));
            }
        } catch (e) {
            console.log('buildingFetch error: ', e);
        } finally {
            await dispatch(set_filter({ type: 'building', value: null }));
        }
    };

export const floorFetch =
    ({ username, auth_key_calendar, building_id, lang }) =>
    async dispatch => {
        try {
            if (building_id) {
                const response = await calendarAPI.floorList.fetch(username, auth_key_calendar, building_id, lang);

                if (response.status === 200) {
                    const res = await response.json();

                    if (res.code === 0) {
                        const arr = res.data.map(item => ({
                            value: item.ID,
                            label: item.FloorName,
                        }));
                        await dispatch(set_filter_fetch_floor(arr));
                    }
                } else {
                    await dispatch(set_filter_fetch_floor([]));
                }
            } else {
                await dispatch(set_filter_fetch_floor([]));
            }
        } catch (e) {
            console.log('floorFetch error: ', e);
        } finally {
            await dispatch(set_filter({ type: 'floor', value: null }));
        }
    };

export const officeFetch =
    ({ username, auth_key_calendar, building_id, floor_id, lang }) =>
    async dispatch => {
        try {
            if (building_id) {
                const response = await calendarAPI.officeList.fetch(username, auth_key_calendar, building_id, floor_id, lang);

                if (response.status === 200) {
                    const res = await response.json();

                    if (res.code === 0) {
                        const arr = res.data.map(item => ({
                            value: item.ID,
                            label: item.OfficeNum,
                        }));
                        await dispatch(set_filter_fetch_office(arr));
                    }
                } else {
                    await dispatch(set_filter_fetch_office([]));
                }
            } else {
                await dispatch(set_filter_fetch_office([]));
            }
        } catch (e) {
            console.log('officeFetch error: ', e);
        } finally {
            await dispatch(set_filter({ type: 'office', value: null }));
        }
    };

export const directionFetch =
    ({ username, auth_key_calendar, building_id, floor_id, office_id, lang }) =>
    async dispatch => {
        try {
            if (building_id) {
                const response = await calendarAPI.directionList.fetch(username, auth_key_calendar, building_id, floor_id, office_id, lang);

                if (response.status === 200) {
                    const res = await response.json();

                    if (res.code === 0) {
                        const arr = res.data.map(item => ({
                            value: item.DirectionID,
                            label: item.Name,
                        }));
                        await dispatch(set_filter_fetch_direction(arr));
                    }
                } else {
                    await dispatch(set_filter_fetch_direction([]));
                }
            } else {
                await dispatch(set_filter_fetch_direction([]));
            }
        } catch (e) {
            console.log('directionFetch error: ', e);
        } finally {
            await dispatch(set_filter({ type: 'direction', value: null }));
        }
    };

export const userFetch =
    ({ username, auth_key_calendar, venue_id, building_id, floor_id, office_id, lang }) =>
    async dispatch => {
        try {
            if (venue_id && building_id) {
                const response = await calendarAPI.userList.fetch(username, auth_key_calendar, venue_id, building_id, floor_id, office_id, lang);

                if (response.status === 200) {
                    const res = await response.json();

                    if (res.code === 0) {
                        const arr = res.data.map(item => ({
                            value: item.user_id,
                            label: `${item.last_name} ${item.first_name}`,
                        }));
                        await dispatch(set_filter_fetch_user(arr));
                    }
                } else {
                    await dispatch(set_filter_fetch_user([]));
                }
            } else {
                await dispatch(set_filter_fetch_user([]));
            }
        } catch (e) {
            console.log('userFetch error: ', e);
        } finally {
            await dispatch(set_filter({ type: 'user', value: null }));
        }
    };

export const dataFetch =
    ({ username, auth_key_calendar, filter, lang }) =>
    async dispatch => {
        await dispatch(fetching_start());

        try {
            if (filter.venue?.value && filter.building?.value) {
                const response = await calendarAPI.objectList.fetch(username, auth_key_calendar, filter, lang);
                if (response.status === 200) {
                    const res = await response.json();

                    if (res.code === 0) {
                        let result;
                        //equipments result
                        if (filter.task_category_id === 9) {
                            result =
                                Array.isArray(res.data.direction) &&
                                res.data.direction.map(direction => ({
                                    item: direction.item,
                                    id: direction.id,
                                    device:
                                        direction.device &&
                                        direction.device.map(device => {
                                            let tasks_formatted = null;

                                            //for days
                                            if (res.data.calendar_type_view === 2) {
                                                const days_in_month = new Date(res.data.filter_year, res.data.filter_month, 0).getDate();
                                                tasks_formatted = [...Array(days_in_month)].map(() => []);
                                                device.tasks &&
                                                    device.tasks.forEach(task => {
                                                        tasks_formatted[task.start_day - 1].push(task);
                                                    });
                                            }

                                            //for weeks
                                            if (res.data.calendar_type_view === 3) {
                                                const weeks_in_year = res.data.week_in_year_count;
                                                tasks_formatted = [...Array(weeks_in_year)].map(() => []);
                                                device.tasks &&
                                                    device.tasks.forEach(task => {
                                                        const current_item = tasks_formatted[task.start_week - 1].find(
                                                            el => el.period_type_short_name === task.period_type_short_name
                                                        );
                                                        if (current_item) {
                                                            if (task.overdue) {
                                                                current_item.overdue = task.overdue;
                                                            }
                                                            if (task.status_id === 2) {
                                                                current_item.status_id = task.status_id;
                                                            }
                                                        } else {
                                                            tasks_formatted[task.start_week - 1].push(task);
                                                        }
                                                    });
                                            }

                                            //for year
                                            if (res.data.calendar_type_view === 7) {
                                                tasks_formatted = [...Array(12)].map(() => []);
                                                device.tasks &&
                                                    device.tasks.forEach(task => {
                                                        const current_item = tasks_formatted[task.start_month - 1].find(
                                                            el => el.period_type_short_name === task.period_type_short_name
                                                        );
                                                        if (current_item) {
                                                            if (task.overdue) {
                                                                current_item.overdue = task.overdue;
                                                            }
                                                            if (task.status_id === 2) {
                                                                current_item.status_id = task.status_id;
                                                            }
                                                        } else {
                                                            tasks_formatted[task.start_month - 1].push(task);
                                                        }
                                                    });
                                            }

                                            return {
                                                item: device.item,
                                                id: device.id,
                                                tasks_formatted: tasks_formatted || null,
                                            };
                                        }),
                                }));
                        }

                        //apartments result
                        if (filter.task_category_id === 11) {
                            result = Object.entries(res.data.venue).map(venue => ({
                                id: venue[0],
                                item: venue[1].item,
                                building: Object.entries(venue[1].building).map(building => {
                                    let tasks = null;

                                    //for days
                                    if (res.data.calendar_type_view === 2) {
                                        const days_in_month = new Date(res.data.filter_year, res.data.filter_month, 0).getDate();
                                        tasks = [...Array(days_in_month)].map(() => []);
                                        building[1].tasks &&
                                            building[1].tasks.forEach(task => {
                                                tasks[task.start_day - 1].push(task);
                                            });
                                    }

                                    //for weeks
                                    if (res.data.calendar_type_view === 3) {
                                        const weeks_in_year = res.data.week_in_year_count;
                                        tasks = [...Array(weeks_in_year)].map(() => []);
                                        building[1].tasks &&
                                            building[1].tasks.forEach(task => {
                                                const current_item = tasks[task.start_week - 1].find(
                                                    el => el.period_type_short_name === task.period_type_short_name
                                                );
                                                if (current_item) {
                                                    if (task.overdue) {
                                                        current_item.overdue = task.overdue;
                                                    }
                                                    if (task.status_id === 2) {
                                                        current_item.status_id = task.status_id;
                                                    }
                                                } else {
                                                    tasks[task.start_week - 1].push(task);
                                                }
                                            });
                                    }

                                    //for year
                                    if (res.data.calendar_type_view === 7) {
                                        tasks = [...Array(12)].map(() => []);
                                        building[1].tasks &&
                                            building[1].tasks.forEach(task => {
                                                const current_item = tasks[task.start_month - 1].find(
                                                    el => el.period_type_short_name === task.period_type_short_name
                                                );
                                                if (current_item) {
                                                    if (task.overdue) {
                                                        current_item.overdue = task.overdue;
                                                    }
                                                    if (task.status_id === 2) {
                                                        current_item.status_id = task.status_id;
                                                    }
                                                } else {
                                                    tasks[task.start_month - 1].push(task);
                                                }
                                            });
                                    }

                                    const floorArr = [];
                                    if (typeof building[1].floor === 'object') {
                                        for (const floor of Object.values(building[1].floor)) {
                                            floorArr.push(floor);
                                        }
                                    } else if (building[1].floor && building[1].floor.length) {
                                        building[1].floor.forEach(floor => floorArr.push(floor));
                                    }

                                    return {
                                        id: building[0],
                                        item: building[1].item,
                                        tasks,
                                        floor:
                                            floorArr.map(floor => {
                                                let tasks = null;

                                                //for days
                                                if (res.data.calendar_type_view === 2) {
                                                    const days_in_month = new Date(res.data.filter_year, res.data.filter_month, 0).getDate();
                                                    tasks = [...Array(days_in_month)].map(() => []);
                                                    floor.tasks &&
                                                        floor.tasks.forEach(task => {
                                                            tasks[task.start_day - 1].push(task);
                                                        });
                                                }

                                                //for weeks
                                                if (res.data.calendar_type_view === 3) {
                                                    const weeks_in_year = res.data.week_in_year_count;
                                                    tasks = [...Array(weeks_in_year)].map(() => []);
                                                    floor.tasks &&
                                                        floor.tasks.forEach(task => {
                                                            const current_item = tasks[task.start_week - 1].find(
                                                                el => el.period_type_short_name === task.period_type_short_name
                                                            );
                                                            if (current_item) {
                                                                if (task.overdue) {
                                                                    current_item.overdue = task.overdue;
                                                                }
                                                                if (task.status_id === 2) {
                                                                    current_item.status_id = task.status_id;
                                                                }
                                                            } else {
                                                                tasks[task.start_week - 1].push(task);
                                                            }
                                                        });
                                                }

                                                //for year
                                                if (res.data.calendar_type_view === 7) {
                                                    tasks = [...Array(12)].map(() => []);
                                                    floor.tasks &&
                                                        floor.tasks.forEach(task => {
                                                            const current_item = tasks[task.start_month - 1].find(
                                                                el => el.period_type_short_name === task.period_type_short_name
                                                            );
                                                            if (current_item) {
                                                                if (task.overdue) {
                                                                    current_item.overdue = task.overdue;
                                                                }
                                                                if (task.status_id === 2) {
                                                                    current_item.status_id = task.status_id;
                                                                }
                                                            } else {
                                                                tasks[task.start_month - 1].push(task);
                                                            }
                                                        });
                                                }

                                                const officeArr = [];
                                                if (typeof floor.office === 'object') {
                                                    for (const office of Object.values(floor.office)) {
                                                        officeArr.push(office);
                                                    }
                                                } else if (floor.office && floor.office.length) {
                                                    floor.office.forEach(office => officeArr.push(office));
                                                }

                                                return {
                                                    item: floor.item,
                                                    tasks,
                                                    office:
                                                        officeArr.map(office => {
                                                            let tasks = null;

                                                            //for days
                                                            if (res.data.calendar_type_view === 2) {
                                                                const days_in_month = new Date(res.data.filter_year, res.data.filter_month, 0).getDate();
                                                                tasks = [...Array(days_in_month)].map(() => []);
                                                                office.tasks &&
                                                                    office.tasks.forEach(task => {
                                                                        tasks[task.start_day - 1].push(task);
                                                                    });
                                                            }

                                                            //for weeks
                                                            if (res.data.calendar_type_view === 3) {
                                                                const weeks_in_year = res.data.week_in_year_count;
                                                                tasks = [...Array(weeks_in_year)].map(() => []);
                                                                office.tasks &&
                                                                    office.tasks.forEach(task => {
                                                                        const current_item = tasks[task.start_week - 1].find(
                                                                            el => el.period_type_short_name === task.period_type_short_name
                                                                        );
                                                                        if (current_item) {
                                                                            if (task.overdue) {
                                                                                current_item.overdue = task.overdue;
                                                                            }
                                                                            if (task.status_id === 2) {
                                                                                current_item.status_id = task.status_id;
                                                                            }
                                                                        } else {
                                                                            tasks[task.start_week - 1].push(task);
                                                                        }
                                                                    });
                                                            }

                                                            //for year
                                                            if (res.data.calendar_type_view === 7) {
                                                                tasks = [...Array(12)].map(() => []);
                                                                office.tasks &&
                                                                    office.tasks.forEach(task => {
                                                                        const current_item = tasks[task.start_month - 1].find(
                                                                            el => el.period_type_short_name === task.period_type_short_name
                                                                        );
                                                                        if (current_item) {
                                                                            if (task.overdue) {
                                                                                current_item.overdue = task.overdue;
                                                                            }
                                                                            if (task.status_id === 2) {
                                                                                current_item.status_id = task.status_id;
                                                                            }
                                                                        } else {
                                                                            tasks[task.start_month - 1].push(task);
                                                                        }
                                                                    });
                                                            }

                                                            return {
                                                                item: office.item,
                                                                tasks,
                                                            };
                                                        }) || null,
                                                };
                                            }) || null,
                                    };
                                }),
                            }));
                        }

                        const data = {
                            result,
                            first_week_in_year: res.data.first_week_in_year,
                            week_in_year_count: res.data.week_in_year_count,
                        };

                        await dispatch(set_data(data));
                    }
                } else {
                    await dispatch(set_data(null));
                }
            } else {
                await dispatch(set_data(null));
            }
        } catch (e) {
            console.log('userFetch error: ', e);
        } finally {
            await dispatch(fetching_end());
        }
    };

export const printExcel =
    ({ username, auth_key_calendar, filter, lang }) =>
    async dispatch => {
        await dispatch(print_fetching_start());

        try {
            const response = await calendarAPI.printExcel.fetch(username, auth_key_calendar, filter, lang);
            if (response.status === 200) {
                const blob = await response.blob();
                if (window.navigator.msSaveOrOpenBlob) {
                    //Internet Explorer
                    window.navigator.msSaveOrOpenBlob(
                        new Blob([blob], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        }),
                        'calendar.xlsx'
                    );
                } else {
                    const link = document.createElement('a');
                    document.body.appendChild(link);
                    link.download = 'calendar.xlsx';
                    link.href = window.URL.createObjectURL(blob);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        } catch (e) {
            console.log('printExcel error: ', e);
        } finally {
            await dispatch(print_fetching_end());
        }
    };

export const taskFetch =
    ({ username, auth_key_calendar, params, lang }) =>
    async dispatch => {
        try {
            if (params.detail_id) {
                const response = await calendarAPI.task.fetch(username, auth_key_calendar, params, lang);

                if (response.status === 200) {
                    const res = await response.json();

                    if (res.code === 0) {
                        const target = {
                            right: params.target.getBoundingClientRect().right,
                            left: params.target.getBoundingClientRect().left,
                            top: params.target.getBoundingClientRect().top,
                        };

                        const data = {
                            id: res.data.detail.virtual ? res.data.detail.ID : res.data.detail.id,
                            active: res.data.detail.active,
                            week: res.data.detail.week_number || '',
                            hash: params.hash,
                            target,
                            virtual: res.data.detail.virtual,
                            changed: res.data.detail.changed,
                            user_name: res.data.detail.user_name || t('CALENDAR_DETAILS_EXEC_STATUS'),
                            user_id: res.data.detail.user_id || null,
                            period_type_s_name: res.data.detail.period_type_s_name,
                            period_type_l_name: res.data.detail.period_type_l_name,
                            start_date: res.data.detail.start_date ? res.data.detail.start_date.slice(0, 10) : t('CALENDAR_DETAILS_NOT_SET_A'),
                            end_date: res.data.detail.end_date ? res.data.detail.end_date.slice(0, 10) : '...',
                            operation_list: res.data.detail.virtual
                                ? res.data.operation_list.map(operation => ({
                                      ...operation,
                                      id: operation.ID,
                                  }))
                                : res.data.operation_list,
                        };

                        dispatch(set_task(data));
                    }
                } else {
                    dispatch(set_task({ id: null }));
                }
            } else {
                dispatch(set_task({ id: null }));
            }
        } catch (e) {
            console.log('taskFetch error: ', e);
        } finally {
        }
    };

export const taskEdit =
    ({ username, auth_key_calendar, params, lang }) =>
    async () => {
        try {
            if (params.detail_id) {
                const response = await calendarAPI.editTask.fetch(username, auth_key_calendar, params, lang);

                if (response.status === 200) {
                    const res = await response.json();

                    if (res.code === 0) {
                        console.log('res', res);
                    }
                } else {
                }
            } else {
            }
        } catch (e) {
            console.log('taskEdit error: ', e);
        }
    };

export const fetching = state => state.calendar.fetching;
export const filter = state => state.calendar.filter;
export const filter_fetch = state => state.calendar.filter_fetch;
export const data = state => state.calendar.data;
export const print_fetching = state => state.calendar.print_fetching;
export const detail_is_open = state => state.calendar.detail_is_open;
export const task = state => state.calendar.task;

export default calendarSlice.reducer;
