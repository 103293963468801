import { Link } from 'react-router-dom';

import { ContentContainer } from '../components/common/ContentContainer';
import { SubHeader } from '../components/common/subheader/SubHeader';
import { SubHeaderLeft } from '../components/common/subheader/SubHeaderLeft';
import { Title } from '../components/common/subheader/Title';

export const NotFoundPage = () => {
    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <Title>404 page</Title>
                </SubHeaderLeft>
            </SubHeader>
            <ContentContainer>
                <Link to="/" className="text-blue underline hover:no-underline">
                    Вернуться на главную
                </Link>
            </ContentContainer>
        </>
    );
};
