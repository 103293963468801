import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { filterTasksState, tasksFetchingState, tasksState } from '../../../../../../redux/slices/hospitalitySlice';
import { Loader } from '../../../../../common/Loader';

export const TasksCounter = () => {
    const { t } = useTranslation();

    const tasksFetching = useSelector(tasksFetchingState);
    const tasks = useSelector(tasksState);
    const filter = useSelector(filterTasksState);

    const allocatedTasks = useMemo(() => tasks.filter(task => task.slotId), [tasks]);
    const isFilter = useMemo(() => !!(filter.clearTypes.length || filter.statuses.length || filter.rooms.from || filter.rooms.to), [filter]);

    return (
        <div className="flex flex-row items-center gap-1 px-6 pb-1 pt-3 text-sm font-medium text-gray-500">
            {isFilter ? t('HOSPITALITY_ALLOCATED_TASKS_WITH_FILTERS') : t('HOSPITALITY_ALLOCATED_TASKS')}:{' '}
            {tasksFetching ? <Loader width={12} height={12} /> : <span className="font-semibold text-gray-20">{allocatedTasks.length}</span>}
        </div>
    );
};
