import { t } from 'i18next';
import { useState } from 'react';

import { CreateVenueButton } from '../../components/bms/venues/CreateVenueButton';
import { OpenEventLogPageButton } from '../../components/bms/venues/OpenEventLogPageButton';
import { OpenServersPageButton } from '../../components/bms/venues/OpenServersPageButton';
import { VenueList } from '../../components/bms/venues/VenueList';
import { CreateVenuePopup } from '../../components/bms/venues/createPopup/CreateVenuePopup';
import { ContentContainer } from '../../components/common/ContentContainer';
import { NoAccessPage } from '../../components/common/noAccess/NoAccessPage';
import { SubHeader } from '../../components/common/subheader/SubHeader';
import { SubHeaderLeft } from '../../components/common/subheader/SubHeaderLeft';
import { SubHeaderRight } from '../../components/common/subheader/SubHeaderRight';
import { Title } from '../../components/common/subheader/Title';
import { useCheckRole } from '../../hooks/useCheckRole';
import { book } from '../../navigation/book';

export const BMSVenuesPage = () => {
    const [popupIsOpen, setPopupIsOpen] = useState(false);
    const [venueId, setVenueId] = useState(null);

    const isObjectView = useCheckRole('bms', 'bms.object.view');
    const isObjectAdd = useCheckRole('bms', 'bms.object.add');
    const isServersView = useCheckRole('bms', 'bms.servers.view');

    if (!isObjectView) {
        return <NoAccessPage />;
    }

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <Title>{t(book.bms_venue.key)}</Title>
                </SubHeaderLeft>

                <SubHeaderRight>
                    <OpenEventLogPageButton />
                    {isServersView && <OpenServersPageButton />}
                </SubHeaderRight>
            </SubHeader>

            <ContentContainer>
                <div className="flex flex-wrap gap-8">
                    {isObjectAdd && <CreateVenueButton setPopupIsOpen={setPopupIsOpen} />}

                    <VenueList setVenueId={setVenueId} setIsOpen={setPopupIsOpen} />
                </div>
            </ContentContainer>

            {isObjectAdd && <CreateVenuePopup isOpen={popupIsOpen} setIsOpen={setPopupIsOpen} venueId={venueId} setVenueId={setVenueId} />}
        </>
    );
};
