import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Detail } from '../../components/bms/document/detail/Detail';
import { ContentContainer } from '../../components/common/ContentContainer';
import { NoAccessPage } from '../../components/common/noAccess/NoAccessPage';
import { BackButton } from '../../components/common/subheader/BackButton';
import { SubHeader } from '../../components/common/subheader/SubHeader';
import { SubHeaderLeft } from '../../components/common/subheader/SubHeaderLeft';
import { Title } from '../../components/common/subheader/Title';
import { useCheckRole } from '../../hooks/useCheckRole';
import { documentState } from '../../redux/slices/bms/bmsSliceDocuments';

export const BMSDetailDocPage = () => {
    const { venueId, systemId } = useParams();
    const document = useSelector(documentState);

    const isDocumentView = useCheckRole('bms', 'bms.document.view');

    if (!isDocumentView) {
        return <NoAccessPage />;
    }

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <BackButton link={`/bms/venues/${venueId}/system/${systemId}/documents`} />
                    <Title>{document.title}</Title>
                </SubHeaderLeft>
            </SubHeader>
            <ContentContainer>
                <Detail />
            </ContentContainer>
        </>
    );
};
