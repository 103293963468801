import { useState } from 'react';

import { DocumentEdit } from '../edit/DocumentEdit';
import { DocumentCreateForm } from './DocumentCreateForm';

export const DocumentCreate = () => {
    const [docId, setDocId] = useState(null);

    if (!docId) {
        return <DocumentCreateForm setDocId={setDocId} />;
    }

    return <DocumentEdit />;
};
