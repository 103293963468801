import { createApi } from '@reduxjs/toolkit/query/react';

import { logout, setUser } from '../../redux/slices/loginSlice';
import { baseQuery } from '../utils.ts';

export const initApi = createApi({
    reducerPath: 'initApi',
    baseQuery,
    endpoints: builder => ({
        // Получить пару токенов по refresh token
        verifyToken: builder.query({
            query: (refreshToken: string) => ({
                url: '/rest/auth/refresh',
                method: 'POST',
                body: { ['refresh_token']: refreshToken },
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    if (data.access_token && data.refresh_token) {
                        dispatch(setUser({ accessToken: data.access_token, refreshToken: data.refresh_token, auth_key_calendar: data.access_token }));
                    } else {
                        dispatch(logout());
                    }
                } catch ({ error }) {
                    if (error.status === 401) {
                        dispatch(logout());
                    } else {
                        console.error('verifyToken failed:', error.data?.detail);
                    }
                }
            },
        }),

        // Получить пару токенов по access token
        getTokens: builder.query<any, void>({
            query: () => ({
                url: '/rest/auth/tokens',
                method: 'GET',
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    if (data.access_token && data.refresh_token) {
                        dispatch(setUser({ accessToken: data.access_token, refreshToken: data.refresh_token, auth_key_calendar: data.access_token }));
                    } else {
                        dispatch(logout());
                    }
                } catch ({ error }) {
                    if (error.status === 401) {
                        dispatch(logout());
                    } else {
                        console.error('getTokens failed:', error.data?.detail);
                    }
                }
            },
        }),
    }),
});

export const { useLazyVerifyTokenQuery, useLazyGetTokensQuery } = initApi;
