export const Text = ({ node }) => {
    return (
        <div
            style={{ fontSize: node.data.states[0].fontSize, color: node.data.states[0].textColor, background: node.data.states[0].bgColor }}
            className="border border-black pl-1"
        >
            {node.data.states[0].value}
        </div>
    );
};
