export const getUrlParams = url => {
    // Если URL не передан, используем текущий URL из window.location
    const queryString = url ? new URL(url).search.substring(1) : window.location.search.substring(1);

    // Если строка запроса пустая, возвращаем пустой объект
    if (!queryString) return {};

    // Разбиваем строку на пары ключ=значение
    const params = queryString.split('&');

    // Преобразуем массив пар в объект
    const result = {};
    params.forEach(param => {
        const firstEqualIndex = param.indexOf('=');
        if (firstEqualIndex !== -1) {
            const key = param.substring(0, firstEqualIndex);
            const value = param.substring(firstEqualIndex + 1);
            if (key) {
                // Сохраняем значение как есть, включая все символы после первого "="
                result[key] = value !== undefined ? decodeURIComponent(value) : '';
            }
        }
    });

    return result;
};
