import { SimpleSelect } from '../../../../../common/widgets/SimpleSelect';

export const PeriodSelect = ({ options, data, setData }) => {
    return (
        <div className="flex items-center text-sm">
            <span className="mr-2">Период</span>
            <SimpleSelect
                value={data.period}
                options={options}
                callback={val => setData({ ...data, period: val })}
                isClearable={false}
                className="controlSelect w-28 text-sm"
                placeholder="Период"
                isDisabled={data.calendar.length}
            />
            <div className="ml-2" data-tip="При выборе от недели и больше, доступны интервалы: от часа">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                        stroke="#1890FF"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path d="M12 11V16" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M11.9502 8H12.0502V8.1H11.9502V8Z" stroke="#1890FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
        </div>
    );
};
