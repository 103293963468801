import { root } from '../config';

const timeInMs = Date.now();

export const initApi = Object.freeze({
    // Сохранение языка пользователя в БД
    setLanguage: {
        fetch: (username, auth_key_calendar, lang) => {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('auth_key_calendar', auth_key_calendar);
            formData.append('lang', lang);

            return fetch(`${root}?r=api/api/set-lang&app_id=123&timestamp=${timeInMs}&gui=f1ebb3f236206a73a0b5a1a91d6f6a47033ff5c27b40fc8f088cb789bd3aeb0b`, {
                method: 'POST',
                body: formData,
            });
        },
    },
});
