import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { setPollsFilter } from '../../../../redux/slices/newsAndPollsSlice';
import { SvgComponent } from '../../../common/svgComponent/SvgComponent';

export const PollsSearchSection = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [searchLine, setSearchLine] = useState('');
    const [isTouched, setIsTouched] = useState(false);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (isTouched) {
                dispatch(setPollsFilter({ searchLine }));
            }
        }, 500);

        return () => clearTimeout(timeOutId);
    }, [searchLine]);

    return (
        <div className="relative h-10 w-44">
            <div className="absolute left-2.5 top-3 h-4 w-4 text-gray">
                <SvgComponent name="search" />
            </div>

            <input
                type="text"
                placeholder={t('SEARCH')}
                className="box-border h-full w-full rounded border-gray-10 bg-gray-100 pl-9 text-sm"
                value={searchLine}
                onChange={e => {
                    setSearchLine(e.target.value);
                    setIsTouched(true);
                }}
            />
        </div>
    );
};
