import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

export const DescriptionSection = ({ description, setDescription, disabled }) => {
    const { t } = useTranslation();
    const textareaRef = useRef(null);

    return (
        <div>
            <div className="mb-3 text-base font-medium text-neutral-900">{t('DESCRIPTION')}</div>

            <textarea
                ref={textareaRef}
                onChange={e => {
                    setDescription(e.target.value);

                    textareaRef.current.style.height = '0px';
                    const scrollHeight = textareaRef.current.scrollHeight;

                    if (scrollHeight > 126) {
                        if (scrollHeight < 280) {
                            textareaRef.current.style.height = `${scrollHeight + 2}px`;
                        } else {
                            textareaRef.current.style.height = '282px';
                        }
                    } else {
                        textareaRef.current.style.height = '128px';
                    }
                }}
                className="box-border block h-32 w-full resize-none rounded border-neutral-200 text-sm"
                placeholder={t('ENTER_DESCRIPTION')}
                value={description}
                disabled={disabled}
            ></textarea>
        </div>
    );
};
