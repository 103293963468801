import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { toastOptions } from '../../../../functions/toastOptions';
import { useCheckRole } from '../../../../hooks/useCheckRole';
import { bms_servers_list as bms_servers_list_state } from '../../../../redux/slices/bms/bmsSliceServers';
import { addBMSVenue, bms_venues_list as bms_venues_list_state, editBMSVenue, removeBMSVenue } from '../../../../redux/slices/bms/bmsSliceVenues';
import { Popup } from '../../../common/popup/Popup';
import { PopupActionConfirmation } from '../../document/edit/PopupActionConfirmation';
import { CreateVenuePopupButton } from './CreateVenuePopupButton';
import { DeleteVenuePopupButton } from './DeleteVenuePopupButton';
import { KaizenSection } from './KaizenSection/KaizenSection';
import { VenueKaizenCheckbox } from './VenueKaizenCheckbox';
import { VenueServersSelect } from './VenueServersSelect';
import { VenueTitle } from './VenueTitle';
import { VenueTitleInput } from './VenueTitleInput';

const initValues = { title: '', servers: [], isKaizen: false, newVenue: false, kaizen_venue: '', employees: [] };

export const CreateVenuePopup = ({ isOpen, setIsOpen, venueId, setVenueId }) => {
    const dispatch = useDispatch();
    const bms_servers_list = useSelector(bms_servers_list_state);
    const bms_venues_list = useSelector(bms_venues_list_state);

    const [venue, setVenue] = useState(initValues);
    const [isOpenActionPopup, setIsOpenActionPopup] = useState(false);

    const isObjectDelete = useCheckRole('bms', 'bms.object.del');

    useEffect(() => {
        setIsOpen(isOpen);

        if (!isOpen) {
            setVenue(initValues);
            setVenueId(null);
        }
    }, [setIsOpen, isOpen]);

    useEffect(() => {
        if (venueId && bms_venues_list.length !== 0) {
            setVenue(bms_venues_list.find(venue => venue.id === venueId));
        }
    }, [venueId]);

    const server_options = bms_servers_list.map(server => {
        return { label: server.title, value: server.id };
    });

    const handleCreateVenue = () => {
        const checkEmptyUserFields = venue.employees.find(employee => !employee.employee_data || !employee.employee_role_id);
        if (checkEmptyUserFields) {
            toast.error('Поля Сотрудник и Роль - обязательны', toastOptions);
            return;
        }
        venueId ? dispatch(editBMSVenue({ data: venue })) : dispatch(addBMSVenue({ data: venue }));

        setIsOpen(false);
        setVenue(initValues);
    };

    const removeVenueHandler = () => {
        dispatch(removeBMSVenue({ id: venueId, title: venue.title }));

        setIsOpen(false);
        setVenueId(null);
        setVenue(initValues);
        setIsOpenActionPopup(false);
    };

    const setIsKaizenHandler = () => {
        setVenue({ ...venue, isKaizen: !venue.isKaizen, newVenue: false, kaizen_venue: '', employees: [] });
    };

    return (
        <>
            <Popup isOpen={isOpen} setIsOpen={setIsOpen} className={`w-[750px] ${venue.isKaizen ? 'h-[95%]' : ''} overflow-auto`}>
                <VenueTitle venueId={venueId} venue={venue} />
                <div className="mb-5 mt-10 flex justify-around">
                    <VenueTitleInput setVenue={setVenue} venue={venue} />
                    <VenueServersSelect options={server_options} setVenue={setVenue} venue={venue} />
                </div>

                <VenueKaizenCheckbox venue={venue} onClickHandler={setIsKaizenHandler} />

                {venue.isKaizen && <KaizenSection venue={venue} setVenue={setVenue} />}

                <div className={`flex ${venue.isKaizen ? 'mt-auto' : 'mt-24'}`}>
                    <CreateVenuePopupButton title={venueId ? 'Сохранить' : 'Создать'} disabled={venue.title.length === 0} onClick={handleCreateVenue} />
                    {isObjectDelete && venueId && <DeleteVenuePopupButton disabled={false} onClick={() => setIsOpenActionPopup(true)} />}
                </div>
            </Popup>
            {isOpenActionPopup && (
                <PopupActionConfirmation
                    isOpen={isOpenActionPopup}
                    setIsOpen={setIsOpenActionPopup}
                    callback={removeVenueHandler}
                    element={venue.title}
                    title="Вы действительно хотите удалить объект"
                />
            )}
        </>
    );
};
