import { useEffect, useState } from 'react';

export const Toggle = ({ children, onChange, checked = false, disabled = false }) => {
    const [checkedState, setCheckedState] = useState(checked);

    useEffect(() => {
        setCheckedState(checked);
    }, [checked]);

    const changeHandler = () => {
        onChange(+!checkedState);
        setCheckedState(!checkedState);
    };

    return (
        <label className="relative flex cursor-pointer select-none items-center">
            <input disabled={disabled} type="checkbox" className="peer sr-only" checked={checkedState} onChange={changeHandler} />
            <div className="peer flex h-6 w-11 flex-row items-center justify-around rounded-full bg-gray-30 after:absolute after:left-0.5 after:top-0.5 after:h-5 after:w-5 after:rounded-full after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue peer-checked:after:translate-x-full peer-checked:after:border-white">
                {children}
            </div>
        </label>
    );
};
