import { CreateNewsButton } from './news/CreateNewsButton';
import { NewsSearchSection } from './news/NewsSearchSection';
import { NewsTypeFilter } from './news/NewsTypeFilter';
import { CreatePollButton } from './polls/CreatePollButton';
import { PollsSearchSection } from './polls/PollsSearchSection';
import { ReportsSearchSection } from './reports/ReportsSearchSection';
import { ReportsTypeFilter } from './reports/ReportsTypeFilter';
import { SwitchPanel } from './switchPanel/SwitchPanel';

export const SubHeader = ({ activeSectionId, setActiveSectionId, setCreateNewsPaneIsOpen, setCreatePollPaneIsOpen }) => {
    return (
        <div className="flex w-full shrink-0 flex-row justify-between gap-4 overflow-y-auto border-b border-l border-b-gray-10 border-l-gray-10 bg-white px-4 sm:px-6 lg:gap-8">
            <SwitchPanel activeSectionId={activeSectionId} setActiveSectionId={setActiveSectionId} />

            <div className="flex items-center gap-3.5">
                {activeSectionId === 1 && (
                    <>
                        <NewsTypeFilter />
                        <NewsSearchSection />
                        <CreateNewsButton onClick={() => setCreateNewsPaneIsOpen(true)} />
                    </>
                )}

                {activeSectionId === 2 && (
                    <>
                        <PollsSearchSection />
                        <CreatePollButton onClick={() => setCreatePollPaneIsOpen(true)} />
                    </>
                )}

                {activeSectionId === 3 && (
                    <>
                        <ReportsTypeFilter />
                        <ReportsSearchSection />
                    </>
                )}
            </div>
        </div>
    );
};
