import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { settingsState } from '../../../../redux/slices/hospitalitySlice';
import { PopupFixed } from '../../../common/popup/PopupFixed';
import { Card } from '../../card/Card';

const statusIdArray = ['2', '3', '8'];

export const InformationPopup = ({ isOpen, setIsOpen, buttonRef }) => {
    const { t } = useTranslation();

    const settings = useSelector(settingsState);

    const usedTaskTypes = useMemo(
        () => (settings && settings.pms_task_types ? settings.pms_task_types.filter(type => type.used).map(type => ({ ...type, id: type.id.toString() })) : []),
        [settings]
    );

    return (
        <PopupFixed isOpen={isOpen} setIsOpen={setIsOpen} buttonRef={buttonRef}>
            <div className="mb-4 font-medium text-black">{t('HOSPITALITY_TYPES_OF_CLEANING')}</div>

            <div className="mb-8 flex max-h-64 flex-col gap-4 overflow-y-auto text-sm text-black">
                {usedTaskTypes.length ? (
                    usedTaskTypes.map((type, index) => (
                        <div className="flex flex-row items-center gap-6" key={type.id}>
                            <div className="w-44 shrink-0">
                                <Card statusId={statusIdArray[index]} typeId={type.id}>
                                    А{index + 1000}
                                </Card>
                            </div>
                            <div>{type.use_title}</div>
                        </div>
                    ))
                ) : (
                    <p>{t('HOSPITALITY_NO_TYPES_OF_CLEANING')}</p>
                )}
            </div>

            <div className="mb-4 font-medium text-black">{t('HOSPITALITY_REQUESTS_STATUS')}</div>

            <div className="flex flex-col gap-4 text-sm text-black">
                <div className="flex flex-row items-center gap-6">
                    <div className="h-2.5 w-2.5 rounded-full bg-status-new"></div>
                    <div>{t('HOSPITALITY_REQUEST_STATUS_NEW')}</div>
                </div>
                <div className="flex flex-row items-center gap-6">
                    <div className="h-2.5 w-2.5 rounded-full bg-status-at-work"></div>
                    <div>{t('HOSPITALITY_REQUEST_STATUS_ON_PROGRESS')}</div>
                </div>
                <div className="flex flex-row items-center gap-6">
                    <div className="h-2.5 w-2.5 rounded-full bg-status-completed"></div>
                    <div>{t('HOSPITALITY_REQUEST_STATUS_FINISH')}</div>
                </div>
            </div>
        </PopupFixed>
    );
};
