import { ChangeLanguage } from './header/ChangeLanguage';
import { LogoutButton } from './header/LogoutButton';
import { UserInfo } from './header/UserInfo';
import { SvgComponent } from './svgComponent/SvgComponent';

export const Header = ({ setSidebarOpen, sidebarTrigger }) => {
    return (
        <header className="sticky top-0 z-30 border-b border-gray-10 bg-white">
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="-mb-px flex h-16 items-center">
                    <div className="flex">
                        <button className="text-gray-20 hover:text-slate-600 lg:hidden" onClick={() => setSidebarOpen(true)} ref={sidebarTrigger}>
                            <span className="sr-only">Open sidebar</span>
                            <SvgComponent name="burger" />
                        </button>
                    </div>

                    <div className="ml-auto flex h-full items-center gap-2 py-4">
                        <UserInfo />
                        <ChangeLanguage />

                        <div className="hidden">
                            <div className="mx-4 h-full w-px bg-gray-10"></div>
                            <LogoutButton />
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};
