import { t } from 'i18next';

export const SignsIcons = ({ data }) => {
    const signs = {
        overdue: t('APPLICATIONS_SIGNS_OVERDUE'),
        sabbath: t('APPLICATIONS_SIGNS_SABBATH'),
        repeated: t('APPLICATIONS_SIGNS_REPEATING'),
    };

    if (data) {
        return (
            <>
                <div className="flex w-20 justify-between">
                    <div data-tip={signs.overdue}>
                        {data.overdue ? (
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
                                <circle cx="11" cy="11" r="10.5" fill="#FFCCC7" stroke="#FF7875" />
                                <path
                                    d="M10.75 11.9998V6.24976H11.25V11.9998H10.75ZM10.75 15.7498V15.2498H11.25V15.7498H10.75Z"
                                    fill="#F5222D"
                                    stroke="#FF7875"
                                />
                            </svg>
                        ) : (
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
                                <circle cx="11" cy="11" r="10.5" fill="#FAFAFA" stroke="#F0F0F0" />

                                <path d="M10.25 12.4998V5.74976H11.75V12.4998H10.25ZM10.25 16.2498V14.7498H11.75V16.2498H10.25Z" fill="#BFBFBF" />
                            </svg>
                        )}
                    </div>
                    <div data-tip={signs.sabbath}>
                        {data.sabbath ? (
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
                                <circle cx="11" cy="11" r="10.5" fill="#D9F7BE" stroke="#95DE64" />
                                <path
                                    d="M10.2691 17.75V16.1375C9.60664 15.9875 9.03489 15.7 8.55389 15.275C8.07239 14.85 7.71914 14.25 7.49414 13.475L8.88164 12.9125C9.06914 13.5125 9.34739 13.9688 9.71639 14.2812C10.0849 14.5938 10.5691 14.75 11.1691 14.75C11.6816 14.75 12.1161 14.6345 12.4726 14.4035C12.8286 14.172 13.0066 13.8125 13.0066 13.325C13.0066 12.8875 12.8691 12.5405 12.5941 12.284C12.3191 12.028 11.6816 11.7375 10.6816 11.4125C9.60664 11.075 8.86914 10.672 8.46914 10.2035C8.06914 9.7345 7.86914 9.1625 7.86914 8.4875C7.86914 7.675 8.13164 7.04375 8.65664 6.59375C9.18164 6.14375 9.71914 5.8875 10.2691 5.825V4.25H11.7691V5.825C12.3941 5.925 12.9099 6.153 13.3164 6.509C13.7224 6.8655 14.0191 7.3 14.2066 7.8125L12.8191 8.4125C12.6691 8.0125 12.4566 7.7125 12.1816 7.5125C11.9066 7.3125 11.5316 7.2125 11.0566 7.2125C10.5066 7.2125 10.0879 7.3345 9.80039 7.5785C9.51289 7.822 9.36914 8.125 9.36914 8.4875C9.36914 8.9 9.55664 9.225 9.93164 9.4625C10.3066 9.7 10.9566 9.95 11.8816 10.2125C12.7441 10.4625 13.3974 10.8592 13.8414 11.4028C14.2849 11.9467 14.5066 12.575 14.5066 13.2875C14.5066 14.175 14.2441 14.85 13.7191 15.3125C13.1941 15.775 12.5441 16.0625 11.7691 16.175V17.75H10.2691Z"
                                    fill="#52C41A"
                                />
                            </svg>
                        ) : (
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
                                <circle cx="11" cy="11" r="10.5" fill="#FAFAFA" stroke="#F0F0F0" />

                                <path
                                    d="M10.2691 17.75V16.1375C9.60664 15.9875 9.03489 15.7 8.55389 15.275C8.07239 14.85 7.71914 14.25 7.49414 13.475L8.88164 12.9125C9.06914 13.5125 9.34739 13.9688 9.71639 14.2812C10.0849 14.5938 10.5691 14.75 11.1691 14.75C11.6816 14.75 12.1161 14.6345 12.4726 14.4035C12.8286 14.172 13.0066 13.8125 13.0066 13.325C13.0066 12.8875 12.8691 12.5405 12.5941 12.284C12.3191 12.028 11.6816 11.7375 10.6816 11.4125C9.60664 11.075 8.86914 10.672 8.46914 10.2035C8.06914 9.7345 7.86914 9.1625 7.86914 8.4875C7.86914 7.675 8.13164 7.04375 8.65664 6.59375C9.18164 6.14375 9.71914 5.8875 10.2691 5.825V4.25H11.7691V5.825C12.3941 5.925 12.9099 6.153 13.3164 6.509C13.7224 6.8655 14.0191 7.3 14.2066 7.8125L12.8191 8.4125C12.6691 8.0125 12.4566 7.7125 12.1816 7.5125C11.9066 7.3125 11.5316 7.2125 11.0566 7.2125C10.5066 7.2125 10.0879 7.3345 9.80039 7.5785C9.51289 7.822 9.36914 8.125 9.36914 8.4875C9.36914 8.9 9.55664 9.225 9.93164 9.4625C10.3066 9.7 10.9566 9.95 11.8816 10.2125C12.7441 10.4625 13.3974 10.8592 13.8414 11.4028C14.2849 11.9467 14.5066 12.575 14.5066 13.2875C14.5066 14.175 14.2441 14.85 13.7191 15.3125C13.1941 15.775 12.5441 16.0625 11.7691 16.175V17.75H10.2691Z"
                                    fill="#BFBFBF"
                                />
                            </svg>
                        )}
                    </div>
                    <div data-tip={signs.repeated}>
                        {data.repeated ? (
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
                                <circle cx="11" cy="11" r="10.5" fill="#FFE7BA" stroke="#FFD666" />
                                <path
                                    d="M10.25 17.7125C8.7375 17.525 7.4845 16.8655 6.491 15.734C5.497 14.603 5 13.275 5 11.75C5 10.925 5.1625 10.1342 5.4875 9.37775C5.8125 8.62175 6.275 7.9625 6.875 7.4L7.94375 8.46875C7.46875 8.89375 7.1095 9.3875 6.866 9.95C6.622 10.5125 6.5 11.1125 6.5 11.75C6.5 12.85 6.85 13.8217 7.55 14.6652C8.25 15.5092 9.15 16.025 10.25 16.2125V17.7125ZM11.75 17.7125V16.2125C12.8375 16.0125 13.7343 15.4937 14.4403 14.6562C15.1467 13.8188 15.5 12.85 15.5 11.75C15.5 10.5 15.0625 9.4375 14.1875 8.5625C13.3125 7.6875 12.25 7.25 11 7.25H10.9438L11.7687 8.075L10.7188 9.125L8.09375 6.5L10.7188 3.875L11.7687 4.925L10.9438 5.75H11C12.675 5.75 14.0937 6.33125 15.2562 7.49375C16.4187 8.65625 17 10.075 17 11.75C17 13.2625 16.5033 14.5845 15.5098 15.716C14.5157 16.847 13.2625 17.5125 11.75 17.7125Z"
                                    fill="#FA8C16"
                                />
                            </svg>
                        ) : (
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="11" cy="11" r="10.5" fill="#FAFAFA" stroke="#F0F0F0" />

                                <path
                                    d="M10.25 17.7125C8.7375 17.525 7.4845 16.8655 6.491 15.734C5.497 14.603 5 13.275 5 11.75C5 10.925 5.1625 10.1342 5.4875 9.37775C5.8125 8.62175 6.275 7.9625 6.875 7.4L7.94375 8.46875C7.46875 8.89375 7.1095 9.3875 6.866 9.95C6.622 10.5125 6.5 11.1125 6.5 11.75C6.5 12.85 6.85 13.8217 7.55 14.6652C8.25 15.5092 9.15 16.025 10.25 16.2125V17.7125ZM11.75 17.7125V16.2125C12.8375 16.0125 13.7342 15.4938 14.4402 14.6562C15.1467 13.8187 15.5 12.85 15.5 11.75C15.5 10.5 15.0625 9.4375 14.1875 8.5625C13.3125 7.6875 12.25 7.25 11 7.25H10.9438L11.7687 8.075L10.7188 9.125L8.09375 6.5L10.7188 3.875L11.7687 4.925L10.9438 5.75H11C12.675 5.75 14.0937 6.33125 15.2562 7.49375C16.4187 8.65625 17 10.075 17 11.75C17 13.2625 16.5033 14.5845 15.5098 15.716C14.5158 16.847 13.2625 17.5125 11.75 17.7125Z"
                                    fill="#BFBFBF"
                                />
                            </svg>
                        )}
                    </div>
                </div>
            </>
        );
    }
};
