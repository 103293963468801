import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { reportsFilterState, setReportsFilter } from '../../../../redux/slices/newsAndPollsSlice';
import { SimpleSelect } from '../../../ui/simpleSelect/SimpleSelect';

export const ReportsTypeFilter = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const filter = useSelector(reportsFilterState);

    const options = [
        {
            value: 'p',
            label: t('NEWS_AND_POLLS_POLLS'),
        },
        {
            value: 'n',
            label: t('NEWS_AND_POLLS_NEWS'),
        },
        {
            value: 'a',
            label: t('NEWS_AND_POLLS_AGREEMENTS'),
        },
    ];

    return (
        <SimpleSelect
            options={options}
            value={filter.type.length > 1 ? [] : options.filter(option => option.value === filter.type[0])}
            callback={value => {
                if (value) {
                    dispatch(setReportsFilter({ type: [value.value] }));
                } else {
                    dispatch(setReportsFilter({ type: ['n', 'a', 'p'] }));
                }
            }}
            placeholder={t('NEWS_AND_POLLS_SELECT_TYPE')}
            menuPosition="fixed"
        />
    );
};
