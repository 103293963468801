import moment from 'moment/moment';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    bmsDocumentDataPointsState,
    getBMSDataChart,
    getBMSDocDataPointsList,
    nodesFetchingState,
    set_bms_chart_points,
} from '../../../../../redux/slices/bms/bmsSliceDocuments';
import { Loader } from '../../../../common/Loader';
import { DocumentChart } from './DocumentChart';
import { ReportButton } from './ReportButton';
import { SettingsPane } from './settingsPane/SettingsPane';

const initialState = {
    step: { value: 5, label: '1 час' },
    period: { value: 9, label: '1 дн' },
    calendar: [],
    dataPoint: [],
};

const TrendsChartComponent = ({ name, bmsChartPoints, docId }) => {
    const dispatch = useDispatch();
    const bmsDocumentDataPoints = useSelector(bmsDocumentDataPointsState);
    const nodesFetching = useSelector(nodesFetchingState);

    const chartRef = useRef(null);

    const [chartSettings, setChartSettings] = useState(initialState);

    useEffect(() => {
        if (docId) {
            dispatch(getBMSDocDataPointsList({ docId }));
        }
    }, [docId]);

    useEffect(() => {
        if (chartSettings.dataPoint.length) {
            const data = {
                step: chartSettings.step.value,
                period: chartSettings.period.value,
                calendar: [moment(chartSettings.calendar[0]).format('YYYY-MM-DD'), moment(chartSettings.calendar[1]).format('YYYY-MM-DD')],
                dataPoint: chartSettings.dataPoint.map(el => el.value),
            };

            // проверка на значения диапазона дат в календаре
            if (chartSettings.calendar.length === 2 && chartSettings.calendar[1]) {
                dispatch(getBMSDataChart({ data }));
            } else {
                if (!(chartSettings.calendar.length === 2 && !chartSettings.calendar[1])) {
                    dispatch(getBMSDataChart({ data: { ...data, calendar: [] } }));
                }
            }
        } else {
            dispatch(set_bms_chart_points({}));
        }
    }, [chartSettings]);

    return (
        <div id="trends">
            <div className="mb-2 flex items-center justify-between">
                <div className="text-xl font-semibold uppercase text-black">Тренды</div>
                <ReportButton isDisabled={Object.keys(bmsChartPoints).length} chartSettings={chartSettings} />
            </div>
            <div className="border border-black">
                <SettingsPane data={chartSettings} setData={setChartSettings} dataPoints={bmsDocumentDataPoints} />
                {nodesFetching ? (
                    <div className="flex h-96 w-full items-center justify-center">
                        <Loader />
                    </div>
                ) : (
                    <DocumentChart title={name} chartRef={chartRef} dataChart={bmsChartPoints} />
                )}
            </div>
        </div>
    );
};

export const TrendsChart = memo(TrendsChartComponent);
