import { FaCopy } from 'react-icons/fa6';

export const CopyStatusButton = ({ setNodeStates, node }) => {
    return (
        <button
            type="button"
            className="flex h-5 w-5 items-center justify-center text-gray-800 hover:text-black"
            onClick={() => {
                setNodeStates(prev => [...prev, { ...node, id: Date.now() }]);
            }}
        >
            <FaCopy color="inherit" size={18} />
        </button>
    );
};
