export const NotificationButton = ({ title, callback, employee }) => {
    return (
        <button
            onClick={() => callback(title, employee.employeeId)}
            className={`mb-1 rounded-full border px-1 ${employee.notification.includes(title) ? 'bg-green-400' : 'bg-slate-200'} `}
            type="button"
        >
            {title}
        </button>
    );
};
