export const TitleInput = ({ nodeStatusInfo, callback }) => {
    return (
        <div className="w-[14%] shrink-0 px-1">
            <input
                type="text"
                className="h-10 w-full rounded border border-gray-10 text-sm"
                value={nodeStatusInfo.title}
                disabled={!nodeStatusInfo.node}
                onChange={e => callback(e.target.value)}
            />
        </div>
    );
};
