import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useCheckRole } from '../../../../../hooks/useCheckRole';
import { removeBMSDocControl } from '../../../../../redux/slices/bms/bmsSliceDocuments';
import { PopupContextMenu } from '../../../../common/popup/PopupContextMenu';
import { PopupActionConfirmation } from '../PopupActionConfirmation';

export const EditControlContextMenu = ({ isOpen, setIsOpen, nodeId, buttonRef, node, editNodeHandler }) => {
    const dispatch = useDispatch();

    const [isOpenActionPopup, setIsOpenActionPopup] = useState(false);

    const isDocumentNodeEdit = useCheckRole('bms', 'bms.document_nodes.edit');
    const isDocumentNodeDelete = useCheckRole('bms', 'bms.document_nodes.del');

    const handlerEditNode = () => {
        setIsOpen(false);
        editNodeHandler(node);
    };

    const handlerRemoveNode = () => {
        dispatch(removeBMSDocControl({ cn_id: nodeId }));
        setIsOpenActionPopup(false);
    };
    return (
        <>
            <PopupContextMenu className="w-auto pr-2" isOpen={isOpen} setIsOpen={setIsOpen} buttonRef={buttonRef}>
                {isDocumentNodeEdit && (
                    <>
                        <button onClick={handlerEditNode} className="w-full cursor-pointer rounded-md p-1 pl-2 text-left text-xs hover:bg-slate-200">
                            <span>Редактировать</span>
                        </button>
                    </>
                )}

                {isDocumentNodeDelete && (
                    <button
                        onClick={() => setIsOpenActionPopup(true)}
                        className="w-full cursor-pointer rounded-md p-1 pl-2 text-left text-xs hover:bg-slate-200"
                    >
                        <span>Удалить</span>
                    </button>
                )}
            </PopupContextMenu>
            <PopupActionConfirmation
                isOpen={isOpenActionPopup}
                setIsOpen={setIsOpenActionPopup}
                callback={handlerRemoveNode}
                element={node.name}
                title="Вы действительно хотите удалить"
            />
        </>
    );
};
