import { t } from 'i18next';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useTable } from 'react-table';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

import { auth_key_calendar as authKeyCalendarState, username as usernameState } from '../../redux/slices/loginSlice';
import {
    addUserQualificationFetch,
    deleteUserQualificationFetch,
    filter as filterState,
    qualificationError as qualificationErrorState,
    set_error_qualification,
    set_filter_page,
    updateUserQualificationFetch,
    userQualificationRole as userQualificationRoleState,
    usersFetching as usersFetchingState,
    usersQualifications as usersQualificationsState,
} from '../../redux/slices/qualificationSlice';
import { Loader } from '../common/Loader';
import { CustomDatePicker } from './CustomDatePicker';

export const Table = () => {
    const dispatch = useDispatch();
    const usersQualifications = useSelector(usersQualificationsState);
    const filter = useSelector(filterState);
    const usersFetching = useSelector(usersFetchingState);
    const username = useSelector(usernameState);
    const authKeyCalendar = useSelector(authKeyCalendarState);
    const userQualificationRole = useSelector(userQualificationRoleState);
    const qualificationError = useSelector(qualificationErrorState);

    const [cookie] = useCookies();
    const lang = cookie.i18next;

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [usersQualifications]);

    useEffect(() => {
        if (qualificationError === 83) {
            toast.error('Данная квалификация уже назначена на сотрудника', {
                position: 'bottom-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });

            dispatch(set_error_qualification(null));
        }
    }, [qualificationError]);

    const columns = useMemo(() => {
        const columns = [
            {
                Header: t('EMPLOYEES'),
                accessor: 'user_name',
            },
        ];
        if (filter.qualifications !== null) {
            columns.push(...filter.qualifications);
        }

        return columns;
    }, [filter.qualifications, lang]);

    const usersQualificationsList = usersQualifications.list.map(el => {
        const userQual = el.qualifications.reduce((acc, el) => {
            acc[el.qualification_dict_id] = `${el.date_end} ${el.qualification_id}`;
            acc['venue_id'] = el.venue_id;
            acc['department_id'] = el.department_id;
            return acc;
        }, {});

        const status_dead = el.qualifications.find(el => el.date_end_status === 'LEFT_LESS_THAN_0');
        return {
            user_name: `${el.last_name} ${el.first_name} ${el.middle_name}`,
            ...userQual,
            status: status_dead === undefined ? 0 : 1,
            user_id: el.user_id,
        };
    });

    const handlerQualificationDelete = async dataAllInfo => {
        const qualification_id = dataAllInfo.userQualificationId;
        const user_id = dataAllInfo.data.user_id;

        await dispatch(deleteUserQualificationFetch({ username, auth_key_calendar: authKeyCalendar, qualification_id, user_id }));
    };
    const handlerQualificationAddUpdate = async (date, dataAllInfo) => {
        const dataQualification = {
            user_id: dataAllInfo.data.user_id,
            qualification_dict_id: dataAllInfo.qualificationTypeId,
            date_end: moment(date).format('YYYY-MM-DD'),
            qualification_id: dataAllInfo.userQualificationId,
            venue_id: dataAllInfo.venue_id,
        };

        dataQualification.qualification_id === ''
            ? await dispatch(addUserQualificationFetch({ username, auth_key_calendar: authKeyCalendar, dataQualification }))
            : await dispatch(updateUserQualificationFetch({ username, auth_key_calendar: authKeyCalendar, dataQualification }));
    };

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data: usersQualificationsList.length === 0 ? [1] : usersQualificationsList,
    });

    if (columns.length === 1) {
        return <div className="flex h-20 w-full items-center justify-center">{t('QUALIFICATION_TYPE')}</div>;
    }

    return (
        <>
            <InfiniteScroll
                next={() => dispatch(set_filter_page(filter.page + 1))}
                hasMore={usersQualifications.count > 0 && usersQualifications.total_count > 20}
                loader={
                    <div className="-mt-4 flex h-20 w-full items-center justify-center">
                        <Loader />
                    </div>
                }
                dataLength={rows.length}
                scrollableTarget="qualificationWrapper"
            >
                <table {...getTableProps()} className="mb-4 w-full border-separate border-spacing-0 text-sm">
                    <thead className="absolute w-0 overflow-hidden text-left uppercase text-black md:sticky md:top-0 md:z-10 md:w-full md:overflow-visible">
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => {
                                    let additionally = '';
                                    let styles = '';
                                    if (column.id === 'user_name') {
                                        additionally = <div className="flex items-center gap-1">{column.Header}</div>;
                                    }
                                    if (column.id !== 'user_name') {
                                        additionally = (
                                            <div className="flex items-center gap-1">
                                                <div>{column.Header}</div>
                                                <div data-tip={column.name}>
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path
                                                            d="M7.9625 12.5C8.225 12.5 8.447 12.4092 8.6285 12.2277C8.8095 12.0467 8.9 11.825 8.9 11.5625C8.9 11.3 8.8095 11.0782 8.6285 10.8973C8.447 10.7157 8.225 10.625 7.9625 10.625C7.7 10.625 7.478 10.7157 7.2965 10.8973C7.1155 11.0782 7.025 11.3 7.025 11.5625C7.025 11.825 7.1155 12.0467 7.2965 12.2277C7.478 12.4092 7.7 12.5 7.9625 12.5ZM7.2875 9.6125H8.675C8.675 9.2 8.722 8.875 8.816 8.6375C8.9095 8.4 9.175 8.075 9.6125 7.6625C9.9375 7.3375 10.1938 7.028 10.3813 6.734C10.5688 6.4405 10.6625 6.0875 10.6625 5.675C10.6625 4.975 10.4062 4.4375 9.89375 4.0625C9.38125 3.6875 8.775 3.5 8.075 3.5C7.3625 3.5 6.7845 3.6875 6.341 4.0625C5.897 4.4375 5.5875 4.8875 5.4125 5.4125L6.65 5.9C6.7125 5.675 6.85325 5.43125 7.07225 5.16875C7.29075 4.90625 7.625 4.775 8.075 4.775C8.475 4.775 8.775 4.88425 8.975 5.10275C9.175 5.32175 9.275 5.5625 9.275 5.825C9.275 6.075 9.2 6.30925 9.05 6.52775C8.9 6.74675 8.7125 6.95 8.4875 7.1375C7.9375 7.625 7.6 7.99375 7.475 8.24375C7.35 8.49375 7.2875 8.95 7.2875 9.6125ZM8 15.5C6.9625 15.5 5.9875 15.303 5.075 14.909C4.1625 14.5155 3.36875 13.9813 2.69375 13.3063C2.01875 12.6313 1.4845 11.8375 1.091 10.925C0.697 10.0125 0.5 9.0375 0.5 8C0.5 6.9625 0.697 5.9875 1.091 5.075C1.4845 4.1625 2.01875 3.36875 2.69375 2.69375C3.36875 2.01875 4.1625 1.48425 5.075 1.09025C5.9875 0.69675 6.9625 0.5 8 0.5C9.0375 0.5 10.0125 0.69675 10.925 1.09025C11.8375 1.48425 12.6313 2.01875 13.3063 2.69375C13.9813 3.36875 14.5155 4.1625 14.909 5.075C15.303 5.9875 15.5 6.9625 15.5 8C15.5 9.0375 15.303 10.0125 14.909 10.925C14.5155 11.8375 13.9813 12.6313 13.3063 13.3063C12.6313 13.9813 11.8375 14.5155 10.925 14.909C10.0125 15.303 9.0375 15.5 8 15.5Z"
                                                            fill="#1C1B1F"
                                                            fillOpacity="0.2"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                        );
                                    }
                                    return (
                                        <th
                                            {...column.getHeaderProps()}
                                            className={`${styles} sticky top-0 border-y border-r border-gray-10 bg-white px-2 py-5 font-medium first:rounded-tl-lg first:border-l first:pl-4 last:rounded-tr-lg last:border-r`}
                                        >
                                            {additionally || column.render('Header')}
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>

                    {filter.venues.length === 0 ? (
                        <tbody>
                            <tr>
                                <td>
                                    <div className="flex h-20 w-full items-center justify-center">{t('QUALIFICATION_SELECT_OBJ')} </div>
                                </td>
                            </tr>
                        </tbody>
                    ) : usersFetching && usersQualificationsList.length === 0 ? (
                        <tbody>
                            <tr>
                                <td>
                                    <div className="flex h-20 w-full items-center justify-center">
                                        <Loader />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    ) : (
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row, index) => {
                                prepareRow(row);
                                if (row.original === 1) {
                                    return (
                                        <tr key={'123'}>
                                            <td>
                                                <div className="flex h-20 w-full items-center justify-center">{t('CALENDAR_NOTHING_FOR_REQUEST')}</div>
                                            </td>
                                        </tr>
                                    );
                                }
                                return (
                                    <tr
                                        {...row.getRowProps()}
                                        className="mb-4 block overflow-hidden rounded-lg border border-gray-10 md:mb-0 md:table-row md:overflow-visible md:border-0"
                                    >
                                        {row.cells.map(cell => {
                                            let additionally = '';
                                            let styles = '';
                                            const options = {
                                                year: 'numeric',
                                                month: 'short',
                                                day: 'numeric',
                                                timezone: 'UTC',
                                            };
                                            let correctDate = '';
                                            let statusStyle = '';
                                            let userQualificationId = '';
                                            if (cell.column.id !== 'user_name') {
                                                if (cell.value !== undefined) {
                                                    userQualificationId = cell.value.split(' ')[2];
                                                    // correct date
                                                    const date = cell.value.split(' ')[0].split('-');
                                                    const dateChange = new Date(date[0], date[1] - 1, date[2]).toLocaleString('ru', options).split(' ');
                                                    dateChange.pop();
                                                    correctDate = dateChange.join(' ');
                                                    //
                                                    const deadLineDays = (Date.parse(new Date(date[0], date[1] - 1, date[2])) - Date.now()) / 86400000;
                                                    if (deadLineDays < -1) {
                                                        statusStyle = 'border-status_border-dead_date text-status_text-dead_date bg-status_bg-dead_date';
                                                    }
                                                    if (deadLineDays > -1 && deadLineDays <= 29) {
                                                        statusStyle = 'border-status_border-orange text-status_text-orange bg-status_bg-orange';
                                                    }
                                                    if (deadLineDays > 29 && deadLineDays <= 59) {
                                                        statusStyle = 'border-status_border-completed text-status_text-completed bg-status_bg-completed';
                                                    }
                                                    if (deadLineDays > 59) {
                                                        statusStyle = 'border-status_border-deferred text-status_text-deferred bg-status_bg-deferred';
                                                    }
                                                }
                                                styles = `${userQualificationRole === 'editor' && cell.value !== undefined ? '' : 'hover:bg-[#e6f7ff]'}`;
                                                const dataAllInfo = {
                                                    data: cell.row.original,
                                                    qualificationTypeId: cell.column.id,
                                                    dateOfQual: cell.value,
                                                    userQualificationId: userQualificationId,
                                                    venue_id: filter.venues.value,
                                                };
                                                additionally = (
                                                    <>
                                                        <div className="flex items-center px-2 py-3">
                                                            <span className={`${cell.value !== undefined ? `${statusStyle} rounded border p-1 text-xs` : ''}`}>
                                                                {cell.value !== undefined ? correctDate : '-'}
                                                            </span>
                                                            <CustomDatePicker
                                                                disabledKeyboardNavigation
                                                                name="qualification_date"
                                                                dateFormat="dd.MM.yyyy"
                                                                fixedHeight
                                                                inline
                                                                handlerClickAddUpdate={handlerQualificationAddUpdate}
                                                                handlerClickDelete={handlerQualificationDelete}
                                                                dataAllInfo={dataAllInfo}
                                                                date_end={cell.value}
                                                            />
                                                        </div>
                                                    </>
                                                );
                                            }

                                            if (cell.column.id === 'user_name') {
                                                styles = `${cell.row.original.status === 0 ? '' : 'text-status_text-dead_date bg-status_bg-dead_date'}`;
                                                additionally = <div className="sm:p-4">{cell.row.original.user_name}</div>;
                                            }
                                            return (
                                                <td
                                                    {...cell.getCellProps()}
                                                    data-label={cell.column.Header}
                                                    className={`${styles} ${
                                                        userQualificationRole === 'editor' && cell.value !== undefined ? '' : 'path_hover'
                                                    } svg_hover flex justify-between border-b border-r border-gray-10 bg-white text-right font-medium before:p-2 before:pt-4 before:font-medium before:uppercase before:content-[attr(data-label)] last:border-b-0 md:table-cell md:text-left md:before:hidden md:first:border-l md:last:border-b md:last:border-r ${
                                                        rows.length === index + 1 ? 'first:rounded-bl-lg last:rounded-br-lg' : ''
                                                    }`}
                                                >
                                                    {additionally || cell.render('Cell')}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    )}
                </table>
            </InfiniteScroll>

            <ReactTooltip textColor="#FCFCFC" backgroundColor="#707183E5" effect="solid" className="!rounded-md !px-2.5 !py-2" />
        </>
    );
};
