import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import {
    copyBMSDocNode,
    historyRemovedNodesState,
    returnRemovedNode,
    selectedNodesForCopyState,
    setSelectedNodesForCopy,
} from '../../../../../redux/slices/bms/bmsSliceDocuments';
import { InsertNodeWithDataPointAndPosition } from './InsertNodeWithDataPointAndPosition';
import { InsertNodeWithDataPoints } from './InsertNodeWithDataPoints';
import { InsertNodeWithPosition } from './InsertNodeWithPosition';
import { InsertNodeWithoutInfo } from './InsertNodeWithoutInfo';
import { ReturnRemovedNodeButton } from './ReturnRemovedNodeButton';

export const ActionPane = ({}) => {
    const dispatch = useDispatch();
    const historyRemovedNodes = useSelector(historyRemovedNodesState);
    const selectedNodesForCopy = useSelector(selectedNodesForCopyState);

    const { documentId } = useParams();

    const handlerReturnRemovedNode = () => {
        dispatch(returnRemovedNode());
    };
    const handlerInsertWithoutInfo = () => {
        const options = ['reset_points', 'reset_positions', 'mark_as_not_checked'];
        const data = { cnId: selectedNodesForCopy, docId: documentId, options };
        dispatch(copyBMSDocNode({ data }));
        dispatch(setSelectedNodesForCopy([]));
    };
    const handlerInsertWithPosition = () => {
        const options = ['reset_points', 'mark_as_not_checked'];
        const data = { cnId: selectedNodesForCopy, docId: documentId, options };
        dispatch(copyBMSDocNode({ data }));
        dispatch(setSelectedNodesForCopy([]));
    };
    const handlerInsertWithDataPoints = () => {
        const options = ['reset_positions', 'mark_as_not_checked'];
        const data = { cnId: selectedNodesForCopy, docId: documentId, options };
        dispatch(copyBMSDocNode({ data }));
        dispatch(setSelectedNodesForCopy([]));
    };
    const handlerInsertWithDataPointAndPosition = () => {
        const options = ['mark_as_not_checked'];
        const data = { cnId: selectedNodesForCopy, docId: documentId, options };
        dispatch(copyBMSDocNode({ data }));
        dispatch(setSelectedNodesForCopy([]));
    };
    return (
        <>
            <div className="flex px-2 pb-2">
                <ReturnRemovedNodeButton callback={handlerReturnRemovedNode} isDisabled={historyRemovedNodes.length} />
                <InsertNodeWithoutInfo callback={handlerInsertWithoutInfo} isDisabled={selectedNodesForCopy.length} />
                <InsertNodeWithPosition callback={handlerInsertWithPosition} isDisabled={selectedNodesForCopy.length} />
                <InsertNodeWithDataPoints callback={handlerInsertWithDataPoints} isDisabled={selectedNodesForCopy.length} />
                <InsertNodeWithDataPointAndPosition callback={handlerInsertWithDataPointAndPosition} isDisabled={selectedNodesForCopy.length} />
            </div>
            <ReactTooltip textColor="#FCFCFC" backgroundColor="#707183E5" effect="solid" className="max-w-prose !rounded-md !px-2.5 !py-2" />
        </>
    );
};
