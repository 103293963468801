import { useEffect } from 'react';
import CustomScroll from 'react-custom-scroll';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import { Loader } from '../../../../common/Loader';
import { Draggable } from '../../../../common/dnd/Draggable';
import { SvgComponent } from '../../../../common/svgComponent/SvgComponent';
import { Card } from '../../../card/Card';

export const List = ({ isOpen, setIsOpen, items, itemsTitle = '', loading = false }) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (items.length) {
            ReactTooltip.rebuild();
        }
    }, [items]);

    return (
        <div
            className={`relative -top-10 -mb-10 block rounded-b bg-white transition-opacity duration-200 ${isOpen ? 'visible h-full w-full opacity-100' : 'invisible h-0 w-0 opacity-0'}`}
        >
            <div className="h-full max-h-80">
                <CustomScroll allowOuterScroll={true}>
                    <div className="flex flex-col gap-2 overflow-hidden px-3 pb-4 pt-3">
                        {loading ? (
                            <Loader width={12} height={12} />
                        ) : items.length ? (
                            items.map(item => (
                                <Draggable id={item.id} key={item.id} disabled={item.statusId === '3' || item.statusId === '8'}>
                                    <Card typeId={item.clearTypeId || ''} statusId={item.statusId || ''} description={item.description}>
                                        {item.title}
                                    </Card>
                                </Draggable>
                            ))
                        ) : (
                            <div className="text-xs font-medium text-gray">{t('HOSPITALITY_NO_DATA')}</div>
                        )}
                    </div>
                </CustomScroll>
            </div>

            <button onClick={() => setIsOpen(false)} className="flex h-10 w-full flex-row items-center border-t border-t-gray-10 px-3 text-blue">
                <div className="flex text-xs">
                    {itemsTitle}:{' '}
                    {loading ? (
                        <div className="ml-1">
                            <Loader height={12} width={12} />
                        </div>
                    ) : (
                        items.length
                    )}
                </div>

                <div className="ml-auto h-4 w-4">
                    <SvgComponent name="chevron-up" />
                </div>
            </button>
        </div>
    );
};
