import { useEffect, useRef } from 'react';

export const EditPopup = ({ children, isOpen }) => {
    const menuRef = useRef(null);

    useEffect(() => {
        if (isOpen && menuRef && menuRef.current.getBoundingClientRect().right > window.innerWidth) {
            menuRef.current.style.top = '2.5rem';
            menuRef.current.style.right = '0';
            menuRef.current.style.left = 'auto';
            menuRef.current.style.marginLeft = '0';
        }
    }, [isOpen]);

    return (
        <div
            className={`${isOpen ? 'flex' : 'hidden'} absolute left-full top-0 z-40 ml-1 w-48 flex-col rounded bg-white text-sm text-black shadow-3xl`}
            ref={menuRef}
        >
            {children}
        </div>
    );
};
