import { SimpleSelect } from '../../../../../common/widgets/SimpleSelect';

export const DataPointSelect = ({ callback, nodeOptions, selectValue, isUseWithoutDataPoint }) => {
    return (
        <SimpleSelect
            isClearable={false}
            options={nodeOptions ? nodeOptions : []}
            placeholder="Выберите точку"
            value={selectValue ? selectValue : ''}
            isSearchable={true}
            callback={val => callback(val)}
            className="nodeSelectSize w-[18%] shrink-0 px-1"
            isDisabled={isUseWithoutDataPoint}
        />
    );
};
