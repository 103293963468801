import { SimpleSelect } from '../../../../../../../common/widgets/SimpleSelect';

export const DataPointSelect = ({ nodeControlOptions, data, setData, setValues, initialState }) => {
    return (
        <SimpleSelect
            isClearable={false}
            options={nodeControlOptions}
            placeholder="Выберите точку"
            value={data.dataPointControl.dataPointControlObj}
            isSearchable={true}
            callback={val => {
                if (val === null) {
                    return;
                }
                setValues([{ ...data, dataPointControl: { ...initialState.dataPointControl, dataPointControlObj: val } }]);
                setData({ ...data, dataPointControl: { ...initialState.dataPointControl, dataPointControlObj: val } });
            }}
            className="mr-5 w-56"
        />
    );
};
