import ReactDatePicker from 'react-datepicker';

export const EventLogCalendar = ({ data, setData, type, setFilter }) => {
    const [startDate, endDate] = data.calendar ? data.calendar : [];

    return (
        <div className="chartCalendar flex items-center text-sm">
            <ReactDatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={update => {
                    if (!update[0]) {
                        setData({ ...data, calendar: [] });
                        setFilter({ type, value: null });
                    } else {
                        setData({ ...data, calendar: update });
                        if (update[1]) {
                            setFilter({ type, value: update });
                        }
                    }
                }}
                isClearable={true}
                dateFormat="dd.MM.yyyy"
                placeholderText="Дата"
                className="!h-10"
            />
        </div>
    );
};
