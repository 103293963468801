import { t } from 'i18next';
import { useParams } from 'react-router-dom';

import { CreateDocumentButton } from '../../components/bms/documents/CreateDocumentButton';
import { DocumentList } from '../../components/bms/documents/DocumentList';
import { SystemName } from '../../components/bms/systems/SystemName';
import { VenueName } from '../../components/bms/systems/VenueName';
import { ContentContainer } from '../../components/common/ContentContainer';
import { NoAccessPage } from '../../components/common/noAccess/NoAccessPage';
import { BackButton } from '../../components/common/subheader/BackButton';
import { SubHeader } from '../../components/common/subheader/SubHeader';
import { SubHeaderLeft } from '../../components/common/subheader/SubHeaderLeft';
import { Title } from '../../components/common/subheader/Title';
import { useCheckRole } from '../../hooks/useCheckRole';
import { book } from '../../navigation/book';

export const BMSDocumentsPage = () => {
    const { venueId } = useParams();

    const isDocumentView = useCheckRole('bms', 'bms.document.view');
    const isDocumentAdd = useCheckRole('bms', 'bms.document.add');

    if (!isDocumentView) {
        return <NoAccessPage />;
    }

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <BackButton link={`/bms/venues/${venueId}/systems`} />
                    <Title>{t(book.bms_document.key)}</Title>
                </SubHeaderLeft>
            </SubHeader>
            <ContentContainer>
                <VenueName />
                <SystemName />

                <div className="flex flex-wrap gap-8">
                    {isDocumentAdd && <CreateDocumentButton />}

                    <DocumentList />
                </div>
            </ContentContainer>
        </>
    );
};
