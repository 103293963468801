import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { bms_venues_list as bms_venues_list_state, getBMSUserVenuesList } from '../../../redux/slices/bms/bmsSliceVenues';

export const VenueName = () => {
    const dispatch = useDispatch();
    const bms_venues_list = useSelector(bms_venues_list_state);
    const { venueId } = useParams();
    useEffect(() => {
        if (bms_venues_list.length === 0) {
            dispatch(getBMSUserVenuesList());
        }
    }, [bms_venues_list, dispatch]);

    const venueName = bms_venues_list.find(venue => venue.id === Number(venueId));
    return venueName && <div className="mb-3">Объект: {venueName.title}</div>;
};
