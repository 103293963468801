import { Link } from 'react-router-dom';

import { ContentContainer } from '../ContentContainer';

export const NoAccessPage = () => {
    return (
        <ContentContainer>
            <p className="mb-3 mt-3 text-left text-base font-semibold uppercase text-black">У Вас нет доступа в этот раздел</p>
            <p>
                <Link to="/" className="text-blue hover:underline">
                    Вернуться на главную
                </Link>
            </p>
        </ContentContainer>
    );
};
