import React from 'react';

export const Breadcrumbs = ({ crumbs }) => {
    return (
        <div className="text-xs uppercase text-gray">
            {crumbs.map((crumb, index) => (
                <React.Fragment key={index}>
                    {crumb}
                    {crumbs[index + 1] && ' / '}
                </React.Fragment>
            ))}
        </div>
    );
};
