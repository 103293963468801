export const ValueInput = ({ nodeStatusInfo, callback }) => {
    return (
        <input
            type="number"
            value={nodeStatusInfo.value}
            disabled={!nodeStatusInfo.node || nodeStatusInfo.bit}
            className="h-10 w-full rounded border border-gray-10"
            onChange={e => {
                callback(e.target.value, 'value');
            }}
        />
    );
};
