import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { application as application_state } from '../../../redux/slices/applicationsSlice';
import { FancyboxTask } from '../../common/FancyboxTask';
import ReactCarouselPhoto from '../../common/ReactCarouselPhoto';

export const ApplicationPhotoExecutor = () => {
    const application = useSelector(application_state);
    const executorPhotos = [...application.start_photo_list, ...application.end_photo_list];
    const [selectPhoto, setSelectPhoto] = useState(executorPhotos[0]);

    const [renderPhoto, setRenderPhoto] = useState(null);

    useEffect(() => {
        if (executorPhotos.length > 0) {
            setRenderPhoto(executorPhotos.filter(el => el !== selectPhoto));
        }
    }, [selectPhoto]);

    return (
        <>
            {executorPhotos.length === 0 ? (
                <div className="mt-10 text-center text-base font-semibold opacity-50">{t('APPLICATIONS_ATTACHED_PHOTO')}</div>
            ) : (
                <>
                    <div className="w-full cursor-pointer">
                        <FancyboxTask photo={selectPhoto} setPhoto={setSelectPhoto}>
                            <>
                                <img
                                    data-caption={t('APPLICATIONS_EXECUTOR')}
                                    data-fancybox="gallery"
                                    width={'100%'}
                                    className="h-64 object-cover"
                                    src={selectPhoto}
                                    alt="select-photo"
                                />

                                <div className="hidden">
                                    {renderPhoto?.map((image, idx) => (
                                        <div data-caption={t('APPLICATIONS_EXECUTOR')} data-fancybox="gallery" data-src={`${image}`} key={`image${idx}`}>
                                            <img src={`${image}`} alt="" className="h-full w-full object-cover" />
                                        </div>
                                    ))}
                                </div>
                            </>
                        </FancyboxTask>
                    </div>
                    <div id="myCarousel" className="carousel carouselPhoto">
                        <ReactCarouselPhoto
                            content={executorPhotos}
                            setSelect={setSelectPhoto}
                            options={{
                                infinity: false,
                                Dots: false,
                            }}
                            selectedFancyPhoto={selectPhoto}
                        />
                    </div>
                </>
            )}
        </>
    );
};
