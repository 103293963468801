import { SimpleSelect } from '../../../../common/widgets/SimpleSelect';

const state = {
    bit: false,
    bitValue: '',
    bitsize: '',
    name: '',
    node: '',
    operation: '=',
    pic: '',
    range: false,
    title: '',
    type: '',
    value: '',
    valueMax: '',
};
export const SelectNodeStatus = ({ setNodeStates, states, isDisabled }) => {
    const options = states.map((el, id) => ({ value: id, label: el.name, pic: el.pic }));

    const handlerClick = data => {
        setNodeStates(prev => [...prev, { ...state, name: data.label, pic: data.pic, id: Date.now() }]);
    };
    return (
        <div className="mb-10 flex justify-center">
            <SimpleSelect
                isClearable={false}
                options={options ? options : []}
                placeholder="Выберите состояние"
                value=""
                isSearchable={true}
                callback={val => handlerClick(val)}
                className="w-48"
                isDisabled={isDisabled}
            />
        </div>
    );
};
