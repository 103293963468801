import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTable } from 'react-table';

import { useCheckRole } from '../../../hooks/useCheckRole';
import {
    bms_servers_list as bms_servers_list_state,
    getBMSServersList,
    servers_fetching as servers_fetching_state,
} from '../../../redux/slices/bms/bmsSliceServers';
import { Loader } from '../../common/Loader';

export const TableServers = ({ setServerId }) => {
    const dispatch = useDispatch();
    const bms_servers_list = useSelector(bms_servers_list_state);
    const servers_fetching = useSelector(servers_fetching_state);

    const [serverData, setServerData] = useState([]);

    const isServersEdit = useCheckRole('bms', 'bms.servers.edit');

    useEffect(() => {
        if (bms_servers_list.length === 0) {
            dispatch(getBMSServersList());
        }
    }, [bms_servers_list.length, dispatch]);

    useEffect(() => {
        if (bms_servers_list.length !== 0) {
            const data = bms_servers_list.map(server => {
                return {
                    status: server.is_enabled,
                    title: server.title,
                    objects: server.objects,
                    port: server.data.port,
                    server: server.data.server,
                    cur_state: server.cur_state,
                    id: server.id,
                };
            });
            setServerData(data);
        }
    }, [bms_servers_list]);

    const columns = useMemo(
        () => [
            {
                Header: 'Активность',
                accessor: 'status',
            },
            {
                Header: 'Название сервера',
                accessor: 'title',
            },
            {
                Header: 'Объекты',
                accessor: 'objects',
            },
            {
                Header: 'Порт',
                accessor: 'port',
            },
            {
                Header: 'IP',
                accessor: 'server',
            },
            {
                Header: 'Статус',
                accessor: 'cur_state',
            },
            {
                Header: '',
                accessor: 'id',
            },
        ],
        []
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data: serverData,
    });

    const handlerServerEdit = id => {
        setServerId(id);
    };
    if (servers_fetching) {
        return (
            <div className="flex w-full justify-center">
                <Loader />
            </div>
        );
    }
    return (
        <>
            <div className="w-full overflow-x-auto">
                {bms_servers_list.length !== 0 ? (
                    <table {...getTableProps()} className="w-full border-separate border-spacing-0 text-sm">
                        <thead className="absolute w-0 overflow-hidden text-left uppercase text-black md:sticky md:z-10 md:w-full md:overflow-visible">
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => {
                                        return (
                                            <th
                                                {...column.getHeaderProps()}
                                                className={`sticky top-0 border-y border-r border-gray-10 bg-white px-2 py-5 font-medium first:rounded-tl-lg first:border-l first:pl-4 last:rounded-tr-lg last:border-r`}
                                            >
                                                {column.render('Header')}
                                            </th>
                                        );
                                    })}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row, index) => {
                                prepareRow(row);
                                return (
                                    <tr
                                        {...row.getRowProps()}
                                        className="mb-4 block overflow-hidden rounded-lg border border-gray-10 md:mb-0 md:table-row md:overflow-visible md:border-0"
                                    >
                                        {row.cells.map(cell => {
                                            let additionally = '';
                                            let styles = {};

                                            // status
                                            if (cell.column.id === 'status') {
                                                additionally = (
                                                    <div
                                                        className={`ml-auto mr-auto h-3 w-3 rounded-full ${cell.value ? 'bg-green-600' : 'bg-red-700'} `}
                                                    ></div>
                                                );
                                            }

                                            //edit
                                            if (cell.column.id === 'id') {
                                                additionally = isServersEdit ? (
                                                    <button
                                                        className="ml-auto mr-auto w-6 rounded-full transition-colors hover:bg-green-600"
                                                        onClick={() => handlerServerEdit(cell.row.original.id)}
                                                    >
                                                        <svg className="h-6 w-6 cursor-pointer fill-current hover:fill-white" viewBox="0 0 24 24">
                                                            <path d="M6 10C4.9 10 4 10.9 4 12C4 13.1 4.9 14 6 14C7.1 14 8 13.1 8 12C8 10.9 7.1 10 6 10ZM18 10C16.9 10 16 10.9 16 12C16 13.1 16.9 14 18 14C19.1 14 20 13.1 20 12C20 10.9 19.1 10 18 10ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z" />
                                                        </svg>
                                                    </button>
                                                ) : (
                                                    <span></span>
                                                );
                                            }

                                            // accessibility
                                            if (cell.column.id === 'cur_state') {
                                                let statusJSX = '';

                                                switch (cell.row.original.cur_state) {
                                                    case 1:
                                                        statusJSX = <span>Не доступен</span>;
                                                        break;
                                                    case 2:
                                                        statusJSX = <span>Доступен</span>;
                                                        break;
                                                    case 3:
                                                        statusJSX = <span>Ошибка соединения</span>;
                                                        break;
                                                    case 4:
                                                        statusJSX = <span>Подключен</span>;
                                                        break;
                                                    case 5:
                                                        statusJSX = <span>Есть уведомления</span>;
                                                        break;

                                                    default:
                                                        break;
                                                }
                                                additionally = <>{statusJSX}</>;
                                            }
                                            return (
                                                <td
                                                    {...cell.getCellProps()}
                                                    data-label={cell.column.Header}
                                                    className={`${styles} flex justify-between border-b border-r border-gray-10 bg-white px-2 py-5 text-right font-normal before:font-medium before:uppercase before:content-[attr(data-label)] last:border-b-0 md:table-cell md:text-left md:before:hidden md:first:border-l md:first:pl-4 md:last:border-b md:last:border-r md:last:pr-4 ${
                                                        rows.length === index + 1 ? 'first:rounded-bl-lg last:rounded-br-lg' : ''
                                                    }`}
                                                >
                                                    {additionally || cell.render('Cell')}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                ) : (
                    <div>Нет доступных серверов</div>
                )}
            </div>
        </>
    );
};
