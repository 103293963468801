import { useEffect, useState } from 'react';

export const ScrollToTop = () => {
    const [showTopBtn, setShowTopBtn] = useState(false);

    useEffect(() => {
        document.getElementById('wrapper').addEventListener('scroll', () => {
            if (document.getElementById('wrapper').scrollTop > 350) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);

    const goToTop = () => {
        document.getElementById('wrapper').scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        showTopBtn && (
            <button type="button" className="fixed bottom-6 right-6 z-30 rounded-full bg-gray-10 p-2 opacity-70 hover:opacity-100" onClick={goToTop}>
                <svg className="h-8 w-8 fill-current" viewBox="0 0 24 24">
                    <path d="m12 9.414-6.293 6.293c-.39.39-1.024.39-1.414 0s-.39-1.024 0-1.414l7-7c.39-.391 1.024-.391 1.414 0l7 7c.39.39.39 1.024 0 1.414s-1.024.39-1.414 0z" />
                </svg>
            </button>
        )
    );
};
