import { t } from 'i18next';

import getEndingDays from './getEndingDays';

function getDayName(end_date, deferred_date) {
    let n = getEndingDays(end_date);
    const msNow = Date.parse(new Date());
    const defferedDate = deferred_date ? (Date.parse(deferred_date) - msNow) / 86400000 - Math.ceil(n) : 0;
    const defDate = deferred_date ? Date.parse(deferred_date) - msNow : 0;

    if (n <= 0 && defDate <= 0) {
        return t('APPLICATIONS_EXPIRED');
    }

    if (n < 0 && defferedDate > 0) {
        n = Math.ceil(n);
        return `${t('APPLICATIONS_DEADLINE')}: ${n + Math.ceil(defferedDate)}`;
    }

    if (n > 0 && n <= 1 && deferred_date === undefined) {
        return t('APPLICATIONS_EXPIRED_ONE_DAY');
    }
    if (n > 0 && n <= 1 && deferred_date !== undefined) {
        n = Math.ceil(n);

        return `${t('APPLICATIONS_DEADLINE')}: ${n + Math.ceil(defferedDate)}`;
    } else if (n > 1) {
        n = Math.ceil(n);
        return `${t('APPLICATIONS_DEADLINE')}: ${n + Math.ceil(defferedDate)}`;
    }
}

export default getDayName;
