import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { toastOptions } from '../../../../functions/toastOptions';
import {
    applyFetchingState,
    applyTasksAndSlots,
    applyTasksAndSupervisors,
    applyWorkersAndSupervisors,
    cleanersState,
    slotsState,
    tasksForSupervisorsState,
    tasksState,
} from '../../../../redux/slices/hospitalitySlice';
import { Button } from '../../../ui/button/Button.tsx';

export const ApplyButton = ({ sectionId }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const cleaners = useSelector(cleanersState);
    const tasksForCleaners = useSelector(tasksState);
    const tasksForSupervisors = useSelector(tasksForSupervisorsState);
    const slots = useSelector(slotsState);
    const applyFetching = useSelector(applyFetchingState);

    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        if (sectionId === 1) {
            setIsDisabled(!cleaners.find(cleaner => cleaner.isTouched));
        }
        if (sectionId === 2) {
            setIsDisabled(!tasksForCleaners.find(task => task.isTouched) && !slots.find(slot => slot.isTouched));
        }
        if (sectionId === 3) {
            setIsDisabled(!tasksForSupervisors.find(task => task.isTouched));
        }

        return () => setIsDisabled(true);
    }, [sectionId, cleaners, slots, tasksForCleaners, tasksForSupervisors]);

    const applyHandler = async () => {
        let result;

        if (sectionId === 1) {
            result = await dispatch(applyWorkersAndSupervisors());
        }
        if (sectionId === 2) {
            result = await dispatch(applyTasksAndSlots());
        }
        if (sectionId === 3) {
            result = await dispatch(applyTasksAndSupervisors());
        }

        if (result) {
            if (result.error) {
                toast.error(result.message, toastOptions);
            } else {
                toast.success(t('HOSPITALITY_SAVE_OK'), toastOptions);
            }
        }
    };

    return (
        <Button type="success" disabled={isDisabled} loading={applyFetching} onClick={applyHandler}>
            {t('HOSPITALITY_APPLY')}
        </Button>
    );
};
