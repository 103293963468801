import { createSlice } from '@reduxjs/toolkit';
import { t } from 'i18next';

import { adminSchedulerAPI } from '../../api/modules/adminSchedulerAPI';

export const adminSchedulerSlice = createSlice({
    name: 'admin_scheduler',

    initialState: {
        filter_fetch: {
            departments: [],
            creators: [],
        },
        filter: {
            filter_department: '',
            filter_creator: '',
            filter_active: '',
            filter_id_desc: '',
            page: 1,
        },

        //tasks
        tasks_fetching: false,
        tasks: {
            list: [],
            count: null,
            total_count: null,
        },

        task_fetching: false,
        task: {
            id: null,
        },
        detail_is_open: false,
        venues: [],
        task_created: false,
        task_created_error: false,

        change_status_fetching: false,
        change_status_error: null,
    },

    reducers: {
        //tasks
        set_tasks_fetching: (state, action) => {
            state.tasks_fetching = action.payload;
        },
        set_tasks: (state, action) => {
            state.tasks.list.push(...action.payload.list);
            state.tasks.count = action.payload.count;
            state.tasks.total_count = action.payload.total_count;
        },
        clear_tasks: state => {
            state.tasks.list = [];
            state.tasks.count = null;
            state.tasks.total_count = null;
        },

        //detail
        set_task_fetching: (state, action) => {
            state.task_fetching = action.payload;
        },
        set_detail_open: (state, action) => {
            state.detail_is_open = action.payload;
        },
        set_venues: (state, action) => {
            state.venues = action.payload;
        },
        set_task: (state, action) => {
            state.task = action.payload;
        },
        set_created: (state, action) => {
            state.task_created = action.payload;
        },
        set_created_error: (state, action) => {
            state.task_created_error = action.payload;
        },

        //filter
        set_filter_fetch_departments: (state, action) => {
            state.filter_fetch.departments = action.payload;
        },
        set_filter_fetch_creators: (state, action) => {
            state.filter_fetch.creators = action.payload;
        },
        set_filter: (state, action) => {
            state.filter[action.payload.type] = action.payload.value;
            state.filter.page = 1;
        },
        set_filter_page: (state, action) => {
            state.filter.page = action.payload;
        },
        clear_filter: state => {
            for (const item in state.filter) {
                state.filter[item] = '';
            }
            state.filter.page = 1;
        },

        //change status
        set_change_status_fetching: (state, action) => {
            state.change_status_fetching = action.payload;
        },
        set_change_status_error: (state, action) => {
            state.change_status_error = action.payload;
        },
        update_tasks: (state, action) => {
            state.tasks.list = state.tasks.list.map(el => {
                if (el.id === action.payload.id) {
                    return { ...el, ...action.payload };
                }
                return el;
            });
        },
    },
});

export const {
    set_tasks_fetching,
    set_tasks,
    clear_tasks,
    set_detail_open,
    set_venues,
    set_task_fetching,
    set_task,
    set_filter_fetch_departments,
    set_filter_fetch_creators,
    set_filter,
    set_filter_page,
    clear_filter,
    set_created,
    set_created_error,
    set_change_status_fetching,
    set_change_status_error,
    update_tasks,
} = adminSchedulerSlice.actions;

export const tasksFetch =
    ({ username, auth_key_calendar, filter, lang }) =>
    async dispatch => {
        await dispatch(set_tasks_fetching(true));

        try {
            const response = await adminSchedulerAPI.getScheduledTasks.fetch(username, auth_key_calendar, filter, lang);

            if (response.status === 200) {
                const res = await response.json();

                if (filter.page === 1) {
                    await dispatch(clear_tasks());
                }

                if (res.code === 0) {
                    const list = res.data.admin_tasks.map(task => ({
                        ...task,
                        edit: task.id,
                        user: task.created_by_last_name ? `${task.created_by_last_name} ${task.created_by_first_name}` : t('ADM_PLANNER_ERROR_NOT_SET'),
                        direction: task.department_name ? JSON.parse(task.department_name)[lang] : t('ADM_PLANNER_ERROR_NOT_SET'),
                        date: `${task.date_start ? new Date(task.date_start).toLocaleDateString('ru-RU') : '...'} - ${
                            task.date_end ? new Date(task.date_end).toLocaleDateString('ru-RU') : '...'
                        }`,
                    }));

                    const data = {
                        count: res.data.count,
                        total_count: res.data.total_count,
                        list,
                    };

                    await dispatch(set_tasks(data));
                } else {
                    await dispatch(clear_tasks());
                }
            } else {
                await dispatch(clear_tasks());
            }
        } catch (e) {
            console.log('tasksFetch error: ', e);
        } finally {
            await dispatch(set_tasks_fetching(false));
        }
    };

export const venueFetch =
    ({ username, auth_key_calendar, lang }) =>
    async dispatch => {
        try {
            const response = await adminSchedulerAPI.getVenues.fetch(username, auth_key_calendar, lang);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    await dispatch(set_venues(res.data));
                } else {
                    await dispatch(set_venues([]));
                }
            } else {
                await dispatch(set_venues([]));
            }
        } catch (e) {
            console.log('venueFetch error: ', e);
        } finally {
        }
    };

export const departmentsFetch = lang => async dispatch => {
    try {
        const response = await adminSchedulerAPI.getDepartments.fetch(lang);

        if (response.status === 200) {
            const res = await response.json();

            if (res.code === 0) {
                await dispatch(set_filter_fetch_departments(res.data));
            }
        }
    } catch (e) {
        console.log('departmentsFetch error: ', e);
    } finally {
    }
};

export const creatorsFetch = lang => async dispatch => {
    try {
        const response = await adminSchedulerAPI.getCreators.fetch(lang);

        if (response.status === 200) {
            const res = await response.json();

            if (res.code === 0) {
                await dispatch(set_filter_fetch_creators(res.data));
            }
        }
    } catch (e) {
        console.log('performersFetch error: ', e);
    } finally {
    }
};

export const addScheduledTask =
    ({ username, auth_key_calendar, data, lang }) =>
    async dispatch => {
        dispatch(set_task_fetching(true));

        try {
            const response = await adminSchedulerAPI.addScheduledTask.fetch(username, auth_key_calendar, data, lang);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    dispatch(set_created(true));
                } else if (res.code === 49) {
                    let error_line = '';
                    if (typeof res.data.errors === 'object') {
                        for (const error of Object.values(res.data.errors)) {
                            // eslint-disable-next-line no-loop-func
                            error.forEach(e => (error_line += `${e}\n`));
                        }
                    }
                    dispatch(set_created_error(error_line));
                } else {
                    dispatch(set_created_error(res.msg));
                }
            } else {
                dispatch(set_created_error(t('ADM_PLANNER_ERROR_SERVER')));
            }
        } catch (e) {
            console.log('addScheduledTask error: ', e);
        } finally {
            dispatch(set_task_fetching(false));
        }
    };

export const getScheduledTask =
    ({ username, auth_key_calendar, id, lang }) =>
    async dispatch => {
        try {
            const response = await adminSchedulerAPI.getScheduledTask.fetch(username, auth_key_calendar, id, lang);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    await dispatch(set_task(res.data));
                } else {
                    await dispatch(set_task([]));
                }
            } else {
                await dispatch(set_task([]));
            }
        } catch (e) {
            console.log('getScheduledTask error: ', e);
        } finally {
        }
    };

export const changeStatusScheduledTask =
    ({ username, auth_key_calendar, data, lang }) =>
    async dispatch => {
        dispatch(set_change_status_fetching(true));

        try {
            const response = await adminSchedulerAPI.changeStatusScheduledTask.fetch(username, auth_key_calendar, data, lang);
            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    dispatch(update_tasks(res.data));
                } else {
                    dispatch(set_change_status_error(res.msg));
                }
            } else {
                dispatch(set_change_status_error(t('ADM_PLANNER_ERROR_SERVER')));
            }
        } catch (e) {
            dispatch(set_change_status_error(t('ADM_PLANNER_ERROR_SERVER')));
            console.log('changeStatusSchedulerTask error: ', e);
        } finally {
            dispatch(set_change_status_fetching(false));
        }
    };

export const tasks_fetching = state => state.admin_scheduler.tasks_fetching;
export const tasks = state => state.admin_scheduler.tasks;
export const task_fetching = state => state.admin_scheduler.task_fetching;
export const task = state => state.admin_scheduler.task;
export const filter = state => state.admin_scheduler.filter;
export const filter_fetch = state => state.admin_scheduler.filter_fetch;
export const detail_is_open = state => state.admin_scheduler.detail_is_open;
export const venues = state => state.admin_scheduler.venues;
export const task_created = state => state.admin_scheduler.task_created;
export const task_created_error = state => state.admin_scheduler.task_created_error;
export const change_status_error = state => state.admin_scheduler.change_status_error;

export default adminSchedulerSlice.reducer;
