import { Field, Form, Formik, useField } from 'formik';
import { t } from 'i18next';
import { useSelector } from 'react-redux';

import { application as application_state } from '../../../redux/slices/applicationsSlice';

export const ApplicationChat = () => {
    const application = useSelector(application_state);

    const initValues = {
        task_message: '',
        task_message_files: '',
    };
    const formSubmit = async (values, { setSubmitting, resetForm }) => {
        // const data = {
        //     values,
        // };
        setSubmitting(false);
        resetForm(initValues);
    };

    const CustomInputFile = props => {
        const [, , helpers] = useField(props);
        const { setValue } = helpers;
        const getSelectFile = e => {
            setValue(e.target.files);
        };
        return (
            <div>
                <input type="file" className="hidden" id="inputMessageFile" onChange={e => getSelectFile(e)} />
                <label htmlFor="inputMessageFile" className="cursor-pointer">
                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" className="ml-6">
                        <path
                            d="M2.54578 16.688C1.46155 15.6037 0.919434 14.3074 0.919435 12.7989C0.919435 11.2904 1.46155 9.99402 2.54578 8.90979L9.9704 1.48517C10.7482 0.707348 11.691 0.318439 12.7988 0.318439C13.9066 0.318439 14.8494 0.707348 15.6273 1.48517C16.4051 2.26298 16.794 3.20579 16.794 4.31359C16.794 5.42139 16.4051 6.3642 15.6273 7.14202L8.90974 13.8595C8.41477 14.3545 7.82551 14.602 7.14197 14.602C6.45844 14.602 5.86918 14.3545 5.37421 13.8595C4.87923 13.3646 4.63175 12.7753 4.63175 12.0918C4.63175 11.4082 4.87923 10.819 5.37421 10.324L12.0917 3.60649L13.1524 4.66715L6.43487 11.3847C6.23452 11.585 6.13458 11.8207 6.13505 12.0918C6.13458 12.3628 6.23452 12.5985 6.43487 12.7989C6.63521 12.9992 6.87115 13.0994 7.14268 13.0994C7.41327 13.0994 7.64873 12.9992 7.84908 12.7989L14.5666 6.08136C15.0616 5.58638 15.3091 4.99713 15.3091 4.31359C15.3091 3.63006 15.0616 3.0408 14.5666 2.54583C14.0716 2.05085 13.4824 1.80336 12.7988 1.80336C12.1153 1.80336 11.526 2.05085 11.0311 2.54583L3.60644 9.97045C2.82862 10.7483 2.43971 11.6911 2.43971 12.7989C2.43971 13.9067 2.82862 14.8495 3.60644 15.6273C4.38426 16.4051 5.32707 16.794 6.43487 16.794C7.54267 16.794 8.48548 16.4051 9.26329 15.6273L16.6879 8.20268L17.7486 9.26334L10.324 16.688C9.23972 17.7722 7.94336 18.3143 6.43487 18.3143C4.92637 18.3143 3.63001 17.7722 2.54578 16.688Z"
                            fill="black"
                            fillOpacity="0.85"
                        />
                    </svg>
                </label>
            </div>
        );
    };
    return (
        <>
            <div className={`rounded-xl bg-white ${application.department_id === 6 ? 'min-h-[34rem]' : 'h-full'} relative mb-6`}>
                <h2 className="border-b p-4 text-sm font-semibold uppercase">{t('APPLICATIONS_CHAT')}</h2>
                <div className="absolute bottom-0 flex w-full flex-col-reverse">
                    <Formik initialValues={initValues} onSubmit={formSubmit}>
                        {({ values, isSubmitting }) => (
                            <Form>
                                <div className="flex w-full items-center border-t py-4">
                                    <CustomInputFile name="task_message_files" value={values.task_message_files} />

                                    <div className="messageArea flex w-full items-center">
                                        <Field
                                            name="task_message"
                                            component="textarea"
                                            value={values.task_message}
                                            placeholder="Сообщение..."
                                            className="!focus:shadow-white ml-4 box-border max-h-[40px] w-full resize-none rounded-1 border-none text-sm outline-none placeholder:opacity-50"
                                            disabled={isSubmitting}
                                        />
                                    </div>
                                    {/* <input type="text" placeholder="Сообщение..." className=" border-none focus:shadow-none placeholder:opacity-50 w-full" /> */}
                                    <button type="submit">
                                        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" className="ml-2 mr-7">
                                            <path
                                                d="M18.8089 7.21633L2.6724 0.520624C1.99259 0.238515 1.22459 0.362617 0.668141 0.844335C0.111696 1.32613 -0.121038 2.06848 0.0608368 2.78164L1.49713 8.41414H8.52939C8.85299 8.41414 9.11537 8.67648 9.11537 9.00012C9.11537 9.32371 8.85302 9.58609 8.52939 9.58609H1.49713L0.0608368 15.2186C-0.121038 15.9318 0.111657 16.6741 0.668141 17.1559C1.22572 17.6386 1.99381 17.7611 2.67244 17.4796L18.8089 10.7839C19.5436 10.4791 20 9.79555 20 9.00012C20 8.20469 19.5436 7.52113 18.8089 7.21633Z"
                                                fill="#D9D9D9"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                    <div>
                        <div className="">
                            <div className="ml-auto mr-4 w-9/12 rounded-2xl border bg-bg_message-executor px-3 py-1.5 text-white">
                                Let’s get lunch soon! When are you free? 🤗
                            </div>
                            <p className="mb-2 mr-4 mt-2 text-end text-xs font-normal opacity-60">Отправлено исполнителем 10:02</p>
                        </div>
                        <div>
                            <div className="ml-4 w-9/12 rounded-2xl border bg-bg_message-creator px-3 py-1.5">Let’s get lunch soon! When are you free? 🤗</div>
                            <p className="mb-4 ml-4 mt-2 text-xs font-normal opacity-60">Отправлено заявителем 09:59</p>
                        </div>
                    </div>
                    <div className="mb-10 ml-auto mr-auto opacity-70">12 августа 2022</div>
                </div>
            </div>
        </>
    );
};
