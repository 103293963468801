import { useDragLayer } from 'react-dnd';

import { BoxDragPreview } from './BoxDragPreview';

export const CustomDragLayer = () => {
    const { isDragging, item, difference } = useDragLayer(monitor => ({
        item: monitor.getItem(),
        isDragging: monitor.isDragging(),
        difference: monitor.getDifferenceFromInitialOffset(),
    }));

    if (!isDragging) {
        return null;
    }

    if (!item || (item && !item.hasOwnProperty('id'))) {
        return null;
    }

    return (
        <div className="pointer-events-none absolute left-0 top-0 z-60 h-full w-full">
            <BoxDragPreview item={item} difference={difference} />
        </div>
    );
};
