import { useDispatch } from 'react-redux';

import { setSelectedDocumentForCopy } from '../../../../redux/slices/bms/bmsSliceDocuments';

export const CancelCopiedDocumentButton = ({}) => {
    const dispatch = useDispatch();

    const handlerCancelCopiedDocument = () => {
        dispatch(setSelectedDocumentForCopy(null));
    };
    return (
        <button type="button" className="absolute right-2 top-2 transition-colors hover:fill-red-500" onClick={() => handlerCancelCopiedDocument()}>
            <svg height="16" width="16">
                <path d="M8 1C4.128 1 1 4.128 1 8s3.128 7 7 7 7-3.128 7-7-3.128-7-7-7zm0 1a6 6 0 0 1 6 6 6 6 0 0 1-6 6 6 6 0 0 1-6-6 6 6 0 0 1 6-6zM5.854 5.146l-.708.708L7.293 8l-2.147 2.146.708.708L8 8.707l2.146 2.147.708-.708L8.707 8l2.147-2.146-.708-.708L8 7.293z" />
            </svg>
        </button>
    );
};
