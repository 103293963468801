import '@fancyapps/ui/dist/carousel.css';
import { Carousel as NativeCarousel } from '@fancyapps/ui/dist/carousel.esm';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { new_application_info as new_application_info_state } from '../../redux/slices/applicationsSlice';

function ReactCarouselPhoto(props) {
    const [content, setContent] = useState(props.content || []);
    const wrapper = useRef(null);
    const selectedFancyPhoto = props.selectedFancyPhoto;

    const new_application_info = useSelector(new_application_info_state);

    const handlerPhotoClick = e => {
        if (e.target.getAttribute('src') === null) {
            return;
        }
        props.setSelect(e.target.getAttribute('src'));
    };

    useEffect(() => {
        if (Object.keys(new_application_info).length !== 0 && new_application_info.creatorPhoto) {
            const uploadPhoto = URL.createObjectURL(new_application_info?.creatorPhoto[0]);
            setContent([...content, uploadPhoto]);
        }
    }, [new_application_info]);

    useEffect(() => {
        const opts = props.options || {};

        const photoCarousel = document.querySelector('.carousel__photo');

        photoCarousel.addEventListener('click', e => handlerPhotoClick(e));
        opts.slides = [...content].map(file => {
            return {
                html: `<div class=slide__photo__box >
                          <img src=${file} alt="user-photo" class=carousel__slide_photo />
                      </div>`,
            };
        });

        const instance = new NativeCarousel(wrapper.current, opts);

        const slide_photo = document.querySelectorAll('.carousel__slide_photo');
        for (let i = 0; i < slide_photo.length; i++) {
            if (slide_photo[i].getAttribute('src') === selectedFancyPhoto) {
                slide_photo[i].parentElement.classList.add('select__photo');
            }
        }

        const slide_photo_arr = [...slide_photo];
        slide_photo_arr?.map(el => (el.getAttribute('src').includes('blob') ? el.parentElement.classList.add('uploadPhoto') : el));

        return () => {
            instance.destroy();
            photoCarousel.removeEventListener('click', e => handlerPhotoClick(e));
        };
    });

    return <div style={{ width: '100%' }} className={`carousel__photo ${props.class || ''}`} ref={wrapper}></div>;
}

export default ReactCarouselPhoto;
