export const ValueMaxInput = ({ nodeStatusInfo, callback }) => {
    return (
        <div className="w-[9%] shrink-0 px-1">
            <input
                type="number"
                disabled={!nodeStatusInfo.range}
                value={nodeStatusInfo.valueMax}
                className="h-10 w-full rounded border border-gray-10"
                onChange={e => {
                    callback(e.target.value, 'valueMax');
                }}
            />
        </div>
    );
};
