import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import { detail_is_open as detail_is_open_state, set_detail_open, set_task, taskEdit, task as task_state } from '../../redux/slices/calendarSlice';
import { auth_key_calendar as auth_key_calendar_state, username as username_state } from '../../redux/slices/loginSlice';

export const Detail = () => {
    const ref = useRef(null);
    const detail_is_open = useSelector(detail_is_open_state);
    const task = useSelector(task_state);
    const dispatch = useDispatch();
    const username = useSelector(username_state);
    const auth_key_calendar = useSelector(auth_key_calendar_state);
    const [cookies, _setCookie] = useCookies();

    const lang = cookies.i18next;

    //temporary, without formik
    const [active, setActive] = useState(null);
    useEffect(() => {
        if (task.id) {
            setActive(task.active);
        } else {
            setActive(null);
        }
    }, [task]);

    const closeHandler = () => {
        dispatch(set_detail_open(false));
        dispatch(set_task({ id: null }));
    };

    useEffect(() => {
        if (ref.current && task.id) {
            if (ref.current.offsetWidth + task.target.right > window.innerWidth) {
                ref.current.style.left = `${task.target.left - ref.current.offsetWidth}px`;
            } else {
                ref.current.style.left = `${task.target.right}px`;
            }

            if (ref.current.offsetHeight / 2 + task.target.top > window.innerHeight) {
                ref.current.style.bottom = `0px`;
                ref.current.style.top = `auto`;
            } else {
                ref.current.style.bottom = `auto`;
                if (task.target.top - ref.current.offsetHeight / 2 > 0) {
                    ref.current.style.top = `${task.target.top - ref.current.offsetHeight / 2}px`;
                } else {
                    ref.current.style.top = `0px`;
                }
            }
        }
    }, [task, ref]);

    const optionsActive = [
        { value: true, label: t('CALENDAR_DETAILS_ACTIVITY_ACTIVE') },
        { value: false, label: t('CALENDAR_DETAILS_ACTIVITY_NOT_ACTIVE') },
    ];

    const editHandler = async mode => {
        const params = {
            detail_id: task.id,
            from_date_work: `${task.start_date} 00:00:00`,
            until_date_work: `${task.start_date} 23:59:59`,
            changed: +task.changed,
            active_work: +active,
            delete_work: mode === 'delete' ? 1 : 0,
        };

        await dispatch(taskEdit({ username, auth_key_calendar, params, lang }));
    };

    if (!task.id) {
        return false;
    }

    return (
        <>
            <div className="fixed inset-0 z-40 bg-slate-900 bg-opacity-30 opacity-100 transition-opacity duration-200" onClick={closeHandler}></div>

            <div
                ref={ref}
                className={`fixed z-50 flex w-[30rem] max-w-full flex-col rounded-sm border border-gray-10 bg-white p-5 shadow-2xl transition-all duration-200 ease-in-out ${
                    !detail_is_open ? 'opacity-0' : ''
                }`}
            >
                <div className="flex w-full flex-row items-center justify-between">
                    <div className="flex flex-row items-center justify-start">
                        {task.week && (
                            <div className="mr-5 border-r border-r-gray-10 pr-5">
                                <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-blue text-white">{task.week}</span>{' '}
                                {t('CALENDAR_DETAILS_WEEK')}
                            </div>
                        )}
                        <div>
                            {task.start_date} - {task.end_date}
                        </div>
                    </div>
                    <button className="flex h-9 w-9 items-center justify-center rounded-full bg-transparent p-0 hover:bg-gray-100" onClick={closeHandler}>
                        <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
                            <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
                        </svg>
                    </button>
                </div>

                <div className="mt-5 flex w-full flex-col">
                    <div className="mb-5 last:mb-0">
                        <p className="mb-1 text-sm font-semibold text-gray-20">{t('CALENDAR_DETAILS_LIST_OF_WORKS')}</p>
                        <div className="max-h-40 overflow-y-auto text-sm">
                            {task.operation_list
                                ? task.operation_list.map(operation => <p key={operation.id.toString()}>{operation.name}</p>)
                                : t('CALENDAR_DETAILS_NOT_SET')}
                        </div>
                    </div>
                    <div className="mb-5 last:mb-0">
                        <p className="mb-1 text-sm font-semibold text-gray-20">{t('CALENDAR_DETAILS_EXECUTOR')}</p>
                        <div className="text-sm">{task.user_name}</div>
                    </div>
                    <div className="mb-5 last:mb-0">
                        <p className="mb-1 text-sm font-semibold text-gray-20">{t('CALENDAR_DETAILS_ACTIVITY')}</p>
                        {task.virtual_DEP ? (
                            <Select
                                onChange={v => setActive(v.value)}
                                options={optionsActive || []}
                                defaultValue={optionsActive.find(item => item.value === task.active)}
                                isClearable={false}
                                isSearchable={false}
                                placeholder={t('CALENDAR_DETAILS_ACTIVITY')}
                                classNamePrefix="filter"
                                className="select w-48 text-sm"
                            />
                        ) : (
                            <div className="text-sm">{task.active ? t('CALENDAR_DETAILS_ACTIVITY_ACTIVE') : t('CALENDAR_DETAILS_ACTIVITY_NOT_ACTIVE')}</div>
                        )}
                    </div>
                    <div className="mb-5 last:mb-0">
                        <p className="mb-1 text-sm font-semibold text-gray-20">{t('CALENDAR_DETAILS_MAINTENANCE_NUMBER')}</p>
                        <div className="text-sm">
                            {task.period_type_s_name} <span className="text-xs text-gray-30">{task.period_type_l_name}</span>
                        </div>
                    </div>
                    <div className="mb-5 last:mb-0">
                        <p className="mb-1 text-sm font-semibold text-gray-20">{t('CALENDAR_DETAILS_DATA_OF_START')}</p>
                        <div className="text-sm">{task.start_date}</div>
                    </div>
                </div>

                {task.virtual_DEP && (
                    <div className="mt-5 flex flex-row gap-4">
                        <button
                            type="button"
                            className="h-10 rounded-sm bg-blue px-14 text-sm font-medium text-white transition-colors duration-200 hover:bg-blue-500"
                            onClick={() => editHandler('save')}
                        >
                            {t('CALENDAR_BTN_SAVE')}
                        </button>
                        <button
                            type="button"
                            className="h-10 rounded-sm bg-white px-6 text-sm font-medium text-red-500 transition-colors duration-200 hover:bg-gray-100"
                            onClick={() => editHandler('delete')}
                        >
                            {t('CALENDAR_BTN_DEL')}
                        </button>
                    </div>
                )}
            </div>
        </>
    );
};
