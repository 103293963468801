import { useRef, useState } from 'react';

import { EditControlContextMenu } from './EditControlContextMenu';

export const ControlBox = ({ children, editNodeHandler, control }) => {
    const boxRef = useRef();

    const [contextMenuIsOpen, setContextMenuIsOpen] = useState(false);

    const rightButtonMouseClick = e => {
        e.preventDefault();
        setContextMenuIsOpen(true);
    };
    return (
        <div onContextMenu={e => rightButtonMouseClick(e)} ref={boxRef} className="flex items-center gap-2 border border-gray-30 p-1">
            {children}
            {contextMenuIsOpen && (
                <EditControlContextMenu
                    isOpen={contextMenuIsOpen}
                    setIsOpen={setContextMenuIsOpen}
                    buttonRef={boxRef}
                    nodeId={control?.cn_id}
                    node={control}
                    editNodeHandler={editNodeHandler}
                />
            )}
        </div>
    );
};
