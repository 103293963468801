import { Toggle } from '../../../../../common/widgets/Toggle';

export const ToggleControl = ({ control }) => {
    return (
        <div className="flex items-center">
            <span className="mr-2 text-sm">{control.data.values[0].title_off}</span>
            <Toggle disabled={true} />
            <span className="ml-2 text-sm">{control.data.values[0].title_on}</span>
        </div>
    );
};
