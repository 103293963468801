import { t } from 'i18next';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';

import { filter as filter_state, printExcel, print_fetching as print_fetching_state } from '../../../redux/slices/calendarSlice';
import { auth_key_calendar as auth_key_calendar_state, username as username_state } from '../../../redux/slices/loginSlice';
import { Loader } from '../../common/Loader';

export const CalendarPrintModal = ({ isOpen, setOpen, link, type }) => {
    const dispatch = useDispatch();
    const [cookies] = useCookies();

    const username = useSelector(username_state);
    const auth_key_calendar = useSelector(auth_key_calendar_state);
    const filter = useSelector(filter_state);
    const print_fetching = useSelector(print_fetching_state);

    const [checked, setChecked] = useState(false);

    let task_category_id, calendar_type;
    if (link === 'equipments') {
        task_category_id = 9;
    }
    if (link === 'apartments') {
        task_category_id = 11;
    }
    if (type === 'days') {
        calendar_type = 2;
    }
    if (type === 'weeks') {
        calendar_type = 3;
    }
    if (type === 'year') {
        calendar_type = 7;
    }

    const closeHandler = () => {
        setOpen(false);
    };

    const printHandler = () => {
        dispatch(
            printExcel({ username, auth_key_calendar, filter: { ...filter, task_category_id, calendar_type, without_empty: checked }, lang: cookies.i18next })
        );
        setOpen(false);
    };

    return (
        <>
            <div className={`fixed inset-0 z-50 flex h-full w-full items-center justify-center ${!isOpen ? 'hidden' : ''}`}>
                <div className="fixed inset-0 z-10 bg-slate-900 bg-opacity-30" onClick={closeHandler}></div>

                <div
                    className={`z-20 flex w-80 max-w-full flex-col rounded-sm border border-gray-10 bg-white p-8 opacity-100 shadow-2xl transition-opacity duration-200 ${
                        !isOpen ? 'opacity-0' : ''
                    }`}
                >
                    <div className="mb-2 flex flex-row items-center gap-4 font-bold">
                        <svg className="h-[22px] w-[22px] fill-blue-500" viewBox="0 0 22 22">
                            <g clipPath="url(#clip0_2917_10972)">
                                <path d="M11 0C4.92545 0 0 4.92545 0 11C0 17.0746 4.92545 22 11 22C17.0746 22 22 17.0746 22 11C22 4.92545 17.0746 0 11 0ZM11 20.1339C5.9567 20.1339 1.86607 16.0433 1.86607 11C1.86607 5.9567 5.9567 1.86607 11 1.86607C16.0433 1.86607 20.1339 5.9567 20.1339 11C20.1339 16.0433 16.0433 20.1339 11 20.1339Z" />
                                <path d="M9.82129 15.3214C9.82129 15.634 9.94546 15.9338 10.1665 16.1548C10.3875 16.3758 10.6873 16.5 10.9999 16.5C11.3124 16.5 11.6122 16.3758 11.8332 16.1548C12.0543 15.9338 12.1784 15.634 12.1784 15.3214C12.1784 15.0089 12.0543 14.7091 11.8332 14.4881C11.6122 14.267 11.3124 14.1429 10.9999 14.1429C10.6873 14.1429 10.3875 14.267 10.1665 14.4881C9.94546 14.7091 9.82129 15.0089 9.82129 15.3214V15.3214ZM10.4106 12.5714H11.5891C11.6972 12.5714 11.7856 12.483 11.7856 12.375V5.69643C11.7856 5.58839 11.6972 5.5 11.5891 5.5H10.4106C10.3025 5.5 10.2141 5.58839 10.2141 5.69643V12.375C10.2141 12.483 10.3025 12.5714 10.4106 12.5714Z" />
                            </g>
                            <defs>
                                <clipPath id="clip0_2917_10972">
                                    <rect width="22" height="22" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        {t('CALENDAR_EXCEL')}
                    </div>

                    <label className="mb-4 ml-[38px] inline-flex cursor-pointer select-none items-center">
                        <input type="checkbox" className="mr-2 cursor-pointer select-none" checked={checked} onChange={() => setChecked(!checked)} />
                        <p className="text-sm">{t('CALENDAR_NO_EMPTY')}</p>
                    </label>

                    <div className="ml-[38px] flex flex-row items-center gap-1">
                        <button
                            type="button"
                            className="h-10 rounded-sm bg-blue px-3 text-sm font-medium text-white transition-colors duration-200 hover:bg-blue-500"
                            onClick={printHandler}
                        >
                            {t('CALENDAR_PRINT_OK')}
                        </button>
                        <button
                            type="button"
                            className="h-10 rounded-sm bg-white px-3 text-sm font-medium text-gray-20 transition-colors duration-200 hover:bg-gray-100"
                            onClick={closeHandler}
                        >
                            {t('CALENDAR_CANCEL')}
                        </button>
                    </div>
                </div>
            </div>

            {print_fetching && (
                <div className="fixed bottom-0 left-0 right-0 top-0 z-50 flex h-screen w-screen items-center justify-center bg-gray-300 bg-opacity-50">
                    <Loader />
                </div>
            )}
        </>
    );
};
