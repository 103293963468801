import { ApplyButton } from './buttons/ApplyButton';
import { DistributeButton } from './buttons/DistributeButton';
import { ResetButton } from './buttons/ResetButton';
import { SupervisorsButton } from './buttons/SupervisorsButton';
import { CleanersFilterSection } from './cleanersFilter/CleanersFilterSection';
import { SwitchPanel } from './switchPanel/SwitchPanel';

export const SubHeader = ({ setSupervisorPaneIsOpen, activeSectionId, setActiveSectionId }) => {
    return (
        <div className="flex flex-col">
            <div className="flex w-full shrink-0 flex-row border-b border-l border-b-gray-10 border-l-gray-10 bg-white">
                <div className="box-border flex w-52 shrink-0 justify-center border-r border-r-gray-10 px-1.5 py-3">
                    {activeSectionId === 1 && <SupervisorsButton setSupervisorPaneIsOpen={setSupervisorPaneIsOpen} />}
                    {activeSectionId === 2 && <DistributeButton sectionId={activeSectionId} />}
                    {activeSectionId === 3 && <DistributeButton sectionId={activeSectionId} />}
                </div>

                <div className="flex w-full items-center justify-between gap-12 overflow-y-auto px-4 sm:px-6">
                    <SwitchPanel activeSectionId={activeSectionId} setActiveSectionId={setActiveSectionId} />

                    <div className="flex gap-3 py-3">
                        <ResetButton sectionId={activeSectionId} />
                        <ApplyButton sectionId={activeSectionId} />
                    </div>
                </div>
            </div>

            {activeSectionId === 2 && (
                <div className="z-20 flex w-full shrink-0 flex-row border-b border-l border-b-gray-10 border-l-gray-10 bg-white">
                    <div className="box-border h-full w-52 shrink-0 border-r border-r-gray-10"></div>
                    <div className="flex h-full w-full flex-row flex-wrap px-4 py-3 sm:px-6">
                        <CleanersFilterSection />
                    </div>
                </div>
            )}
        </div>
    );
};
