import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { toastOptions } from '../../../functions/toastOptions';
import {
    bms_error_servers_arr as bms_error_servers_arr_state,
    server_error as server_error_state,
    set_bms_server_error,
} from '../../../redux/slices/bms/bmsSliceServers';
import { Popup } from '../../common/popup/Popup';

export const ErrorPopup = () => {
    const params = useParams();

    const dispatch = useDispatch();

    const server_error = useSelector(server_error_state);
    const bms_error_servers_arr = useSelector(bms_error_servers_arr_state);

    const [popupIsOpen, setPopupIsOpen] = useState(false);

    useEffect(() => {
        if (server_error) {
            if (!params.hasOwnProperty('documentId')) {
                bms_error_servers_arr.map(server => toast.error(`BMS сервер ${server.title} в статусе ${server.state_str}`, toastOptions));
                dispatch(set_bms_server_error(false));
            } else {
                setPopupIsOpen(true);
                dispatch(set_bms_server_error(false));
            }
        }
    }, [server_error, params]);

    return (
        <Popup isOpen={popupIsOpen} setIsOpen={setPopupIsOpen} className="min-h-[150px] w-[750px] p-10">
            <div className="text-center font-bold text-red-600">
                {bms_error_servers_arr.map(server => (
                    <div key={server.id}>
                        BMS сервер {server.title} в статусе {server.state_str}
                    </div>
                ))}
            </div>
        </Popup>
    );
};
