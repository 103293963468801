export const VPNFields = ({ server, setServer }) => {
    return (
        <>
            <p>VPN сервер</p>
            <input
                onChange={e => setServer({ ...server, data: { ...server.data, vpn_server: e.target.value } })}
                value={server.data.vpn_server}
                placeholder="ip/dns"
                className="mb-2 h-8 w-56 border-gray-70 px-2 text-sm hover:border-gray-30"
                type="text"
            />
            <input
                onChange={e => setServer({ ...server, data: { ...server.data, vpn_port: e.target.value } })}
                value={server.data.vpn_port}
                placeholder="порт"
                className="mb-2 h-8 w-56 border-gray-70 px-2 text-sm hover:border-gray-30"
                type="text"
            />
            <input
                onChange={e => setServer({ ...server, data: { ...server.data, vpn_username: e.target.value } })}
                value={server.data.vpn_username}
                placeholder="логин"
                className="mb-2 h-8 w-56 border-gray-70 px-2 text-sm hover:border-gray-30"
                type="text"
            />
            <input
                onChange={e => setServer({ ...server, data: { ...server.data, vpn_password: e.target.value } })}
                value={server.data.vpn_password}
                placeholder="пароль"
                className="mb-2 h-8 w-56 border-gray-70 px-2 text-sm hover:border-gray-30"
                type="text"
            />
        </>
    );
};
