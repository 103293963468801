import { SimpleSelect } from '../../../../../../../common/widgets/SimpleSelect';

export const BooleanSelect = ({ data, setData, setValues }) => {
    const options = [
        { value: false, label: 'false' },
        { value: true, label: 'true' },
    ];
    return (
        <div className="flex items-center">
            <span className="text-sm">Значение</span>

            <SimpleSelect
                options={options}
                value={data.dataPointControl.dataPointControlValue}
                isClearable={false}
                isSearchable={false}
                className="ml-2 w-44 text-sm"
                callback={val => {
                    if (val === null) {
                        return;
                    }
                    setValues([{ ...data, dataPointControl: { ...data.dataPointControl, dataPointControlValue: val } }]);
                    setData({ ...data, dataPointControl: { ...data.dataPointControl, dataPointControlValue: val } });
                }}
            />
        </div>
    );
};
