import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

export const PopupFixed = ({ children, isOpen, setIsOpen, buttonRef, width = 500 }) => {
    const ref = useRef(null);

    useEffect(() => {
        if (ref.current && buttonRef.current) {
            if (isOpen) {
                const buttonSizes = buttonRef.current.getBoundingClientRect();

                ref.current.style.top = `${buttonSizes.height + buttonSizes.top}px`;
                ref.current.style.left = `${
                    buttonSizes.width + buttonSizes.left - ref.current.offsetWidth - 14 > 0
                        ? buttonSizes.width + buttonSizes.left - ref.current.offsetWidth
                        : 14
                }px`;
            } else {
                ref.current.style.top = '-9999px';
                ref.current.style.left = '-9999px';
            }
        }
    }, [ref, buttonRef, isOpen]);

    const closeHandler = () => {
        setIsOpen(false);
    };

    return createPortal(
        <>
            <div
                className={`fixed inset-0 z-40 bg-slate-900 bg-opacity-30 transition-opacity ${isOpen ? 'w-full opacity-100' : 'w-0 opacity-0'}`}
                onClick={closeHandler}
            ></div>

            <div
                className={`fixed z-50 mt-4 box-border flex max-w-full flex-col rounded-xl border border-gray-10 bg-white p-4 shadow-2xl transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0'}`}
                ref={ref}
                style={{ width }}
            >
                <button
                    className="absolute right-1 top-1 flex h-7 w-7 items-center justify-center rounded-full bg-transparent p-0 hover:bg-gray-100"
                    onClick={closeHandler}
                >
                    <svg className="h-5 w-5 fill-current" viewBox="0 0 24 24">
                        <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"></path>
                    </svg>
                </button>
                {children}
            </div>
        </>,
        document.querySelector('#modal-root')
    );
};
