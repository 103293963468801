import moment from 'moment/moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { bmsEventLogFiltersListState, getBMSJournalFiltersList, setBMSEventLogFilters } from '../../../../redux/slices/bms/bmsSliceEventLog';
import { EventLogCalendar } from './EventLogCalendar';
import { FilterSelect } from './FilterSelect';

const initialState = {
    calendar: null,
    venue: null,
    system: null,
    state: null,
    source: null,
    document: null,
    element: null,
};
export const FilterList = () => {
    const dispatch = useDispatch();
    const bmsEventLogFiltersList = useSelector(bmsEventLogFiltersListState);

    const [data, setData] = useState(initialState);
    const [filter, setFilter] = useState(null);

    useEffect(() => {
        if (!bmsEventLogFiltersList) {
            dispatch(getBMSJournalFiltersList());
        }
    }, []);

    useEffect(() => {
        if (filter) {
            if (filter.type === 'calendar') {
                if (filter.value) {
                    dispatch(
                        setBMSEventLogFilters({
                            type: filter.type,
                            value: JSON.stringify([moment(filter.value[0]).format('YYYY-MM-DD'), moment(filter.value[1]).format('YYYY-MM-DD')]),
                        })
                    );
                } else {
                    dispatch(setBMSEventLogFilters(filter));
                }
            } else {
                dispatch(setBMSEventLogFilters(filter));
            }
        }
    }, [filter]);

    const venueOptions = bmsEventLogFiltersList ? bmsEventLogFiltersList.venue : [];
    const systemOptions = bmsEventLogFiltersList ? bmsEventLogFiltersList.system : [];
    const stateOptions = bmsEventLogFiltersList ? bmsEventLogFiltersList.state : [];
    const soursOptions = bmsEventLogFiltersList ? bmsEventLogFiltersList.sours : [];
    const documentOptions = bmsEventLogFiltersList ? bmsEventLogFiltersList.document : [];
    const elementOptions = bmsEventLogFiltersList ? bmsEventLogFiltersList.element : [];

    return (
        <div className="flex h-14 w-full items-center justify-between gap-2 bg-slate-200 p-2">
            <EventLogCalendar data={data} setData={setData} type="calendar" setFilter={setFilter} />

            <FilterSelect options={venueOptions} data={data} setData={setData} setFilter={setFilter} type="venue" placeholder="Объект" />
            <FilterSelect options={systemOptions} data={data} setData={setData} setFilter={setFilter} type="system" placeholder="Раздел" />
            <FilterSelect options={documentOptions} data={data} setData={setData} setFilter={setFilter} type="document" placeholder="Система" />
            <FilterSelect options={elementOptions} data={data} setData={setData} setFilter={setFilter} type="element" placeholder="Элемент" />
            <FilterSelect options={stateOptions} data={data} setData={setData} setFilter={setFilter} type="state" placeholder="Состояние" />
            <FilterSelect options={soursOptions} data={data} setData={setData} setFilter={setFilter} type="source" placeholder="Источник" />
        </div>
    );
};
