import { useEffect, useMemo } from 'react';
import CustomScroll from 'react-custom-scroll';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { getTasksForSupervisors, objectState, tasksForSupervisorsFetchingState, tasksForSupervisorsState } from '../../../../../redux/slices/hospitalitySlice';
import { Loader } from '../../../../common/Loader';
import { Draggable } from '../../../../common/dnd/Draggable';
import { Droppable } from '../../../../common/dnd/Droppable';
import { Card } from '../../../card/Card';

export const TasksSection = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const object = useSelector(objectState);
    const tasks = useSelector(tasksForSupervisorsState);
    const tasksFetching = useSelector(tasksForSupervisorsFetchingState);

    useEffect(() => {
        if (object) {
            dispatch(getTasksForSupervisors());
        }
    }, [object]);

    useEffect(() => {
        if (tasks.length) {
            ReactTooltip.rebuild();
        }
    }, [tasks]);

    const unallocatedTasks = useMemo(() => tasks.filter(task => !task.userId), [tasks]);

    return (
        <div className="w-52 shrink-0 bg-white">
            {tasksFetching ? (
                <div className="flex w-full items-center justify-center p-6">
                    <Loader height={30} width={30} />
                </div>
            ) : (
                <>
                    <div className="px-3 pb-1 pt-3 text-sm font-medium text-gray-500">
                        {unallocatedTasks.length ? (
                            <div className="whitespace-nowrap">
                                {t('HOSPITALITY_UNALLOCATED_TASKS')}: <span className="font-semibold text-gray-20">{unallocatedTasks.length}</span>
                            </div>
                        ) : (
                            t('HOSPITALITY_NO_UNALLOCATED_TASKS')
                        )}
                    </div>

                    <CustomScroll allowOuterScroll={true}>
                        <Droppable id="default">
                            <div className="box-border flex flex-col gap-2 overflow-hidden bg-white px-3 pb-[50px] pt-3">
                                {!!unallocatedTasks.length &&
                                    unallocatedTasks.map(task => (
                                        <Draggable id={task.id} key={task.id} disabled={task.statusId === '3' || task.statusId === '8'}>
                                            <Card typeId={task.clearTypeId} statusId={task.statusId} description={task.description}>
                                                {task.title}
                                            </Card>
                                        </Draggable>
                                    ))}
                            </div>
                        </Droppable>
                    </CustomScroll>
                </>
            )}
        </div>
    );
};
