import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { toastOptions } from '../../../../../../functions/toastOptions';
import { SimpleSelect } from '../../../../../common/widgets/SimpleSelect';

export const ToggleControl = ({ nodeOptions, setValues, setWritePoint, setCheckPopupFields, control }) => {
    const [data, setData] = useState({ writePoint: null, title_on: '', value_on: '', title_off: '', value_off: '' });

    useEffect(() => {
        try {
            JSON.parse(control.data);
        } catch (e) {
            const values = control.data.values[0];

            setData({
                ...values,
            });
            setValues([...control.data.values]);
            setWritePoint(values.writePoint.value);
        }
    }, [control, nodeOptions]);

    useEffect(() => {
        const valuesArr = Object.values(data);
        const emptyValues = valuesArr.length && valuesArr.filter(el => el?.length === 0);

        if (emptyValues.length > 0) {
            setCheckPopupFields(false);
        } else {
            setCheckPopupFields(true);
        }
        if ((emptyValues.length === 0 && data.value_off === data.value_on) || (emptyValues.length === 0 && data.title_off === data.title_on)) {
            toast.error('Названия и значения должны быть различны', toastOptions);
            setCheckPopupFields(false);
        }
    }, [data]);

    const handlerInputChange = (value, type) => {
        // проверка на ввод числа больше чем с десятыми
        if (data.writePoint && data.writePoint.datatype === 'float' && type.includes('value')) {
            const floatCheck = value.split('.');
            if (floatCheck.length === 2 && floatCheck[1].length > 1) {
                return;
            }
        }
        // проверка на целое число
        if (data.writePoint && data.writePoint.datatype === 'int' && type.includes('value')) {
            const floatCheck = value.split('.');
            if (floatCheck.length === 2 && floatCheck[1].length >= 1) {
                setData({ ...data, [type]: floatCheck[0] });
                return;
            }
        }

        //   проверка на более двух нулей подряд
        if (type.includes('value') && value.length > 1 && value[0] === '0' && value[1] !== '.') {
            setValues([{ ...data, [type]: value[1] }]);
            setData({ ...data, [type]: value[1] });
            return;
        }
        // проверка отрицательного числа на ввод более двух нулей подряд
        if (type.includes('value') && value.length === 3 && value[0] === '-' && value[1] === '0' && value[2] !== '.') {
            const val = `${value[0]}${value[2]}`;

            setData({ ...data, [type]: val });
            setValues([{ ...data, [type]: val }]);
            return;
        }

        setData({ ...data, [type]: value });
        setValues([{ ...data, [type]: value }]);
    };
    return (
        <div className="flex items-center">
            <SimpleSelect
                isClearable={false}
                options={nodeOptions}
                placeholder="Выберите точку"
                value={data.writePoint}
                isSearchable={true}
                callback={val => {
                    if (val === null) {
                        return;
                    }
                    if (val.datatype === 'boolean') {
                        setWritePoint(val.value);
                        setValues([{ ...data, value_on: 'true', value_off: 'false' }]);
                        setData({ title_on: '', value_on: 'true', title_off: '', value_off: 'false', writePoint: val });
                        return;
                    }
                    setWritePoint(val.value);
                    setValues([]);
                    setData({ title_on: '', value_on: '', title_off: '', value_off: '', writePoint: val });
                }}
                className="mr-5 w-56"
            />

            <div className="flex gap-3">
                <div>
                    <span>Положение 1</span>

                    <label className="mb-3 flex flex-col text-sm">
                        <input
                            value={data.title_off}
                            disabled={!data.writePoint}
                            onChange={e => handlerInputChange(e.target.value, 'title_off')}
                            placeholder="Название"
                            type="text"
                            className="h-8 w-44 text-sm"
                        />
                    </label>
                    <label className="flex flex-col text-sm">
                        <input
                            value={data.writePoint?.datatype === 'boolean' ? 'false' : data.value_off}
                            disabled={!data.writePoint || data.writePoint?.datatype === 'boolean'}
                            onChange={e => handlerInputChange(e.target.value, 'value_off')}
                            placeholder="Значение"
                            type={data.writePoint?.datatype === 'boolean' ? 'text' : 'number'}
                            className="h-8 w-44 text-sm"
                        />
                    </label>
                </div>
                <div>
                    <span>Положение 2</span>

                    <label className="mb-3 flex flex-col text-sm">
                        <input
                            value={data.title_on}
                            disabled={!data.writePoint}
                            onChange={e => handlerInputChange(e.target.value, 'title_on')}
                            placeholder="Название"
                            type="text"
                            className="h-8 w-44 text-sm"
                        />
                    </label>
                    <label className="flex flex-col text-sm">
                        <input
                            value={data.writePoint?.datatype === 'boolean' ? 'true' : data.value_on}
                            disabled={!data.writePoint || data.writePoint?.datatype === 'boolean'}
                            onChange={e => handlerInputChange(e.target.value, 'value_on')}
                            placeholder="Значение"
                            type={data.writePoint?.datatype === 'boolean' ? 'text' : 'number'}
                            className="h-8 w-44 text-sm"
                        />
                    </label>
                </div>
            </div>
        </div>
    );
};
