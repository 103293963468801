import { t } from 'i18next';
import React, { useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import {
    dataFetch,
    data as data_state,
    fetching as fetching_state,
    filter as filter_state,
    set_detail_open,
    taskFetch,
    task as task_state,
} from '../../redux/slices/calendarSlice';
import { auth_key_calendar as auth_key_calendar_state, username as username_state } from '../../redux/slices/loginSlice';
import { Loader } from '../common/Loader';

export const ApartmentsWeeks = () => {
    const ref = useRef(null);
    const dispatch = useDispatch();
    const username = useSelector(username_state);
    const auth_key_calendar = useSelector(auth_key_calendar_state);
    const filter = useSelector(filter_state);
    const data = useSelector(data_state);
    const fetching = useSelector(fetching_state);
    const task_detail = useSelector(task_state);
    const [cookies, _setCookie] = useCookies();

    const lang = cookies.i18next;

    useEffect(() => {
        if (username && auth_key_calendar && !fetching) {
            dispatch(dataFetch({ username, auth_key_calendar, filter: { ...filter, month: null, task_category_id: 11, calendar_type: 3 }, lang }));
        }
    }, [dispatch, username, auth_key_calendar, filter, lang]);

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [fetching]);

    const openDetail = async (target, task) => {
        const params = {
            detail_id: task.detail_id,
            date_work: null,
            start_week: task.virtual ? task.start_week : null,
            filter_year: task.virtual ? +task.year : null,
            hash: task.hash,
            target,
        };

        await dispatch(taskFetch({ username, auth_key_calendar, params, lang }));
        await dispatch(set_detail_open(true));
    };

    //weeks
    const getDateOfWeek = (w, y) => {
        const simple = new Date(Date.UTC(y, 0, 1 + (w - 1) * 7));
        const dow = simple.getUTCDay();
        const weekStart = simple;
        if (dow <= 4) {
            weekStart.setUTCDate(simple.getUTCDate() - simple.getUTCDay() + 1);
        } else {
            weekStart.setUTCDate(simple.getUTCDate() + 8 - simple.getUTCDay());
        }

        const endOfWeek = new Date(weekStart.getTime());
        endOfWeek.setUTCDate(endOfWeek.getUTCDate() + 6); // six days
        endOfWeek.setUTCHours(23);
        endOfWeek.setUTCMinutes(59);
        endOfWeek.setUTCSeconds(59);

        return weekStart.toLocaleDateString('ru-RU') + ' - ' + endOfWeek.toLocaleDateString('ru-RU');
    };

    const width_full = data && data.week_in_year_count ? `${16 + 4 * data.week_in_year_count + 0.125}rem` : `100%`;
    const width_right = data && data.week_in_year_count ? `${4 * data.week_in_year_count}rem` : `100%`;

    return (
        <div className="w-full overflow-x-auto">
            <div className="flex flex-col rounded-lg border border-gray-10 bg-white" style={{ width: width_full }}>
                {/*first row*/}
                <div className="flex w-full flex-row">
                    <div className="sticky left-0 flex w-64 flex-shrink-0 items-center justify-center rounded-tl-lg border-b border-r border-b-gray-10 border-r-gray bg-white py-3">
                        <div className="flex items-center justify-center rounded-lg bg-gray-100 p-0.5">
                            <Link className="pointer-events-none rounded-lg bg-blue px-2 py-1 text-sm font-normal text-white" to="#">
                                {t('CALENDAR_ROOM')}
                            </Link>
                            <Link className="rounded-lg px-2 py-1 text-sm font-normal hover:text-gray-20" to={`?link=equipments&type=weeks`}>
                                {t('CALENDAR_EQUIPMENT')}
                            </Link>
                        </div>
                    </div>
                    <div className="flex flex-row border-b border-b-gray-10" style={{ width: width_right }}>
                        {data &&
                            data.week_in_year_count &&
                            [...Array(data.week_in_year_count)].map((item, index) => (
                                <div
                                    data-tip={getDateOfWeek(index + 1, filter.year)}
                                    data-place="bottom"
                                    data-type="info"
                                    className="flex w-16 flex-shrink-0 flex-col items-center justify-center border-r border-r-gray-10 last:border-r-0"
                                    key={`${index + 1}week`}
                                >
                                    <span>{index + 1}</span>
                                    <span>{t('CALENDAR_DETAILS_WEEK_SHORT')}</span>
                                </div>
                            ))}
                    </div>
                </div>

                {/*calendar*/}
                <div className="w-full">
                    {(!data || (!filter.venue && !filter.building)) && !fetching && (
                        <p className="flex h-20 w-full items-center px-4 text-sm">{t('CALENDAR_GET_STARTED')}</p>
                    )}

                    {fetching && (
                        <div className="flex h-20 w-screen items-center justify-center">
                            <Loader />
                        </div>
                    )}

                    {data && !data.result.length && !fetching && (
                        <p className="flex h-20 w-full items-center px-4 text-sm">{t('CALENDAR_NOTHING_FOR_REQUEST')}</p>
                    )}

                    {data &&
                        data.result &&
                        !fetching &&
                        filter.venue &&
                        filter.building &&
                        data.result.map(venue => (
                            <React.Fragment key={`${venue.item}${venue.id}`}>
                                {venue.building &&
                                    venue.building.map(building => (
                                        <React.Fragment key={`${building.item}${building.id}`}>
                                            <div className="flex w-full flex-row">
                                                <div
                                                    className="sticky left-0 z-10 flex w-64 flex-shrink-0 items-center border-b border-r border-b-gray-10 border-r-gray bg-white p-4"
                                                    data-tip={building.item}
                                                >
                                                    <span className="line-clamp-2 text-sm">{building.item}</span>
                                                </div>
                                                <div className="z-[9] flex flex-row border-b border-b-gray-10" style={{ width: width_right }}>
                                                    {building.tasks &&
                                                        building.tasks.map((tasks, index) => (
                                                            <div
                                                                className="flex flex-grow basis-0 flex-col items-center justify-center border-r border-r-gray-10 last:border-r-0"
                                                                key={`${building.item}${building.id}${index}`}
                                                            >
                                                                {tasks.map((task, index) => (
                                                                    <div
                                                                        className={`flex w-full flex-grow basis-0 select-none items-center justify-center py-1 transition ${
                                                                            task.detail_id ? 'cursor-pointer hover:bg-gray-10' : ''
                                                                        } relative z-10 border-b border-b-gray-10 text-xs last:border-b-0 ${
                                                                            !task.work_active && task_detail.hash !== task.hash ? 'bg-gray-40' : ''
                                                                        } ${task.status_id === 5 && task_detail.hash !== task.hash ? 'bg-green-300' : ''} ${
                                                                            task.overdue
                                                                                ? 'after:absolute after:top-[calc(50%+0.4rem)] after:h-1.5 after:w-1.5 after:rounded-full after:bg-red-600 after:content-[""]'
                                                                                : ''
                                                                        } ${
                                                                            task_detail.hash === task.hash
                                                                                ? 'bg-blue text-white hover:cursor-pointer hover:bg-blue'
                                                                                : ''
                                                                        }`}
                                                                        key={task.hash || `no_item${building.id}${index}`}
                                                                        onClick={e => openDetail(e.target, task)}
                                                                        data-tip={`${task.start_week} ${t('CALENDAR_DETAILS_WEEK_SHORT')}. (${getDateOfWeek(
                                                                            task.start_week,
                                                                            filter.year
                                                                        )})`}
                                                                        data-place="top"
                                                                        data-type="info"
                                                                        ref={task_detail.hash === task.hash ? ref : null}
                                                                    >
                                                                        {task.period_type_short_name}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        ))}
                                                </div>
                                            </div>

                                            {building.floor &&
                                                building.floor.map((floor, index) => (
                                                    <React.Fragment key={`${floor.item}${index}`}>
                                                        <div className="flex w-full flex-row">
                                                            <div
                                                                className="sticky left-0 z-10 flex w-64 flex-shrink-0 items-center border-b border-r border-b-gray-10 border-r-gray bg-white p-4"
                                                                data-tip={floor.item}
                                                            >
                                                                <span className="line-clamp-2 text-sm">{floor.item}</span>
                                                            </div>
                                                            <div className="z-[9] flex flex-row border-b border-b-gray-10" style={{ width: width_right }}>
                                                                {floor.tasks &&
                                                                    floor.tasks.map((tasks, index) => (
                                                                        <div
                                                                            className="flex flex-grow basis-0 flex-col items-center justify-center border-r border-r-gray-10 last:border-r-0"
                                                                            key={`${floor.item}${index}`}
                                                                        >
                                                                            {tasks.map((task, index) => (
                                                                                <div
                                                                                    className={`flex w-full flex-grow basis-0 select-none items-center justify-center py-1 transition ${
                                                                                        task.detail_id ? 'cursor-pointer hover:bg-gray-10' : ''
                                                                                    } relative z-10 border-b border-b-gray-10 text-xs last:border-b-0 ${
                                                                                        !task.work_active && task_detail.hash !== task.hash ? 'bg-gray-40' : ''
                                                                                    } ${
                                                                                        task.status_id === 5 && task_detail.hash !== task.hash
                                                                                            ? 'bg-green-300'
                                                                                            : ''
                                                                                    } ${
                                                                                        task.overdue
                                                                                            ? 'after:absolute after:top-[calc(50%+0.4rem)] after:h-1.5 after:w-1.5 after:rounded-full after:bg-red-600 after:content-[""]'
                                                                                            : ''
                                                                                    } ${
                                                                                        task_detail.hash === task.hash
                                                                                            ? 'bg-blue text-white hover:cursor-pointer hover:bg-blue'
                                                                                            : ''
                                                                                    }`}
                                                                                    key={task.hash || `no_item${floor.id}${index}`}
                                                                                    onClick={e => openDetail(e.target, task)}
                                                                                    data-tip={`${task.start_week} ${t(
                                                                                        'CALENDAR_DETAILS_WEEK_SHORT'
                                                                                    )}. (${getDateOfWeek(task.start_week, filter.year)})`}
                                                                                    data-place="top"
                                                                                    data-type="info"
                                                                                    ref={task_detail.hash === task.hash ? ref : null}
                                                                                >
                                                                                    {task.period_type_short_name}
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    ))}
                                                            </div>
                                                        </div>

                                                        {floor.office &&
                                                            floor.office.map((office, index) => (
                                                                <div className="flex w-full flex-row" key={`${office.item}${index}`}>
                                                                    <div
                                                                        className="sticky left-0 z-10 flex w-64 flex-shrink-0 items-center border-b border-r border-b-gray-10 border-r-gray bg-white p-4"
                                                                        data-tip={office.item}
                                                                    >
                                                                        <span className="line-clamp-2 text-sm">{office.item}</span>
                                                                    </div>
                                                                    <div
                                                                        className="z-[9] flex flex-row border-b border-b-gray-10"
                                                                        style={{ width: width_right }}
                                                                    >
                                                                        {office.tasks &&
                                                                            office.tasks.map((tasks, index) => (
                                                                                <div
                                                                                    className="flex flex-grow basis-0 flex-col items-center justify-center border-r border-r-gray-10 last:border-r-0"
                                                                                    key={`${office.item}${index}${index}`}
                                                                                >
                                                                                    {tasks.map((task, index) => (
                                                                                        <div
                                                                                            className={`flex w-full flex-grow basis-0 select-none items-center justify-center py-1 transition ${
                                                                                                task.detail_id ? 'cursor-pointer hover:bg-gray-10' : ''
                                                                                            } relative z-10 border-b border-b-gray-10 text-xs last:border-b-0 ${
                                                                                                !task.work_active && task_detail.hash !== task.hash
                                                                                                    ? 'bg-gray-40'
                                                                                                    : ''
                                                                                            } ${
                                                                                                task.status_id === 5 && task_detail.hash !== task.hash
                                                                                                    ? 'bg-green-300'
                                                                                                    : ''
                                                                                            } ${
                                                                                                task.overdue
                                                                                                    ? 'after:absolute after:top-[calc(50%+0.4rem)] after:h-1.5 after:w-1.5 after:rounded-full after:bg-red-600 after:content-[""]'
                                                                                                    : ''
                                                                                            } ${
                                                                                                task_detail.hash === task.hash
                                                                                                    ? 'bg-blue text-white hover:cursor-pointer hover:bg-blue'
                                                                                                    : ''
                                                                                            }`}
                                                                                            key={task.hash || `no_item${office.item}${index}`}
                                                                                            onClick={e => openDetail(e.target, task)}
                                                                                            data-tip={`${task.start_week} ${t(
                                                                                                'CALENDAR_DETAILS_WEEK_SHORT'
                                                                                            )}. (${getDateOfWeek(task.start_week, filter.year)})`}
                                                                                            data-place="top"
                                                                                            data-type="info"
                                                                                            ref={task_detail.hash === task.hash ? ref : null}
                                                                                        >
                                                                                            {task.period_type_short_name}
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            ))}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                    </React.Fragment>
                                                ))}
                                        </React.Fragment>
                                    ))}
                            </React.Fragment>
                        ))}
                </div>
            </div>
            <ReactTooltip effect="solid" place="right" className="max-w-prose" />
        </div>
    );
};
