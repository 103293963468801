import { t } from 'i18next';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useTable } from 'react-table';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

import {
    changeStatusScheduledTask,
    change_status_error as change_status_error_state,
    departmentsFetch,
    filter_fetch as filter_fetch_state,
    filter as filter_state,
    getScheduledTask,
    set_change_status_error,
    set_detail_open,
    set_filter_page,
    task as task_state,
    tasksFetch,
    tasks_fetching as tasks_fetching_state,
    tasks as tasks_state,
    venueFetch,
    venues as venues_state,
} from '../../redux/slices/adminSchedulerSlice';
import { auth_key_calendar as auth_key_calendar_state, username as username_state } from '../../redux/slices/loginSlice';
import { Loader } from '../common/Loader';

export const Table = ({ clearFilter }) => {
    const dispatch = useDispatch();
    const [cookies] = useCookies();

    const username = useSelector(username_state);
    const auth_key_calendar = useSelector(auth_key_calendar_state);
    const tasks_fetching = useSelector(tasks_fetching_state);
    const tasks = useSelector(tasks_state);
    const filter = useSelector(filter_state);
    const task = useSelector(task_state);
    const venues = useSelector(venues_state);
    const filter_fetch = useSelector(filter_fetch_state);
    const change_status_error = useSelector(change_status_error_state);

    const [editBlockIsOpen, setEditBlockIsOpen] = useState(false);
    const [activeBlockIsOpen, setActiveBlockIsOpen] = useState(false);
    const [listeningEdit, setListeningEdit] = useState(false);
    const [listeningActive, setListeningActive] = useState(false);

    const tableEditRef = useRef(null);
    const tableActiveRef = useRef(null);

    const lang = cookies.i18next;
    //change status state

    useEffect(() => {
        if (change_status_error) {
            toast.error(`${change_status_error}`, {
                position: 'bottom-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });

            dispatch(set_change_status_error(null));
        }
    }, [dispatch, change_status_error]);

    const editHandler = async id => {
        await dispatch(getScheduledTask({ username, auth_key_calendar, id, lang }));
    };

    const activeHandler = async (id, value) => {
        setActiveBlockIsOpen(false);
        await dispatch(changeStatusScheduledTask({ username, auth_key_calendar, data: { id, status: value }, lang }));
    };

    const openDetail = async () => {
        if (!venues.length) {
            await dispatch(venueFetch({ username, auth_key_calendar, lang }));
        }
        if (!filter_fetch.departments.length) {
            await dispatch(departmentsFetch(lang));
        }

        await dispatch(set_detail_open(true));
    };

    useEffect(() => {
        if (task.id) {
            openDetail();
        }
    }, [task]);

    useEffect(() => {
        if (username && auth_key_calendar && clearFilter) {
            dispatch(tasksFetch({ username, auth_key_calendar, filter, lang }));
        }
    }, [dispatch, username, auth_key_calendar, filter, clearFilter, lang]);

    const listenForOutsideClick = (listening, setListening, ref, setIsOpen) => () => {
        if (listening) return;
        setListening(true);

        document.addEventListener(`click`, evt => {
            const cur = ref.current;
            if (!ref.current) return;

            const node = evt.target;
            if (cur.contains(node)) return;
            setIsOpen(null);
        });
    };

    useEffect(listenForOutsideClick(listeningEdit, setListeningEdit, tableEditRef, setEditBlockIsOpen));
    useEffect(listenForOutsideClick(listeningActive, setListeningActive, tableActiveRef, setActiveBlockIsOpen));

    // table
    const columns = useMemo(
        () => [
            {
                Header: t('ADM_PLANNER_STATUS'),
                accessor: 'status',
            },
            {
                Header: t('ADM_PLANNER_ID'),
                accessor: 'id',
            },
            {
                Header: t('ADM_PLANNER_DESCRIPTION'),
                accessor: 'description',
            },
            {
                Header: t('ADM_PLANNER_ACTIVITY_PERIOD'),
                accessor: 'date',
            },
            {
                Header: t('ADM_PLANNER_CREATOR'),
                accessor: 'user',
            },
            {
                Header: t('ADM_PLANNER_DIRECTION'),
                accessor: 'direction',
            },
            {
                Header: '',
                accessor: 'edit',
            },
        ],
        [lang]
    );
    const tasks_list = useMemo(() => tasks?.list.map(task => task) || [], [tasks]);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data: tasks_list });

    if (tasks_fetching && filter.page === 1) {
        return (
            <div className="flex h-20 w-full items-center justify-center">
                <Loader />
            </div>
        );
    }

    if (!tasks_list.length) {
        return <div>{t('CALENDAR_NOTHING_FOUND')}</div>;
    }

    return (
        <>
            <div className="w-full">
                <InfiniteScroll
                    next={() => dispatch(set_filter_page(filter.page + 1))}
                    hasMore={tasks.count > 0 && tasks.total_count > 20}
                    loader={
                        <div className="flex h-20 w-full items-center justify-center">
                            <Loader />
                        </div>
                    }
                    dataLength={rows.length}
                    scrollableTarget="wrapper"
                >
                    <table {...getTableProps()} className="w-full border-separate border-spacing-0 text-sm">
                        <thead className="absolute top-16 w-0 overflow-hidden text-left uppercase text-black md:sticky md:z-10 md:w-full md:overflow-visible">
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th
                                            {...column.getHeaderProps()}
                                            className="border-y border-gray-10 bg-white px-2 py-5 font-medium first:rounded-tl-lg first:border-l first:pl-4 last:rounded-tr-lg last:border-r last:pr-4"
                                        >
                                            {column.render('Header')}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row, index) => {
                                prepareRow(row);
                                return (
                                    <tr
                                        {...row.getRowProps()}
                                        className="mb-4 block overflow-hidden rounded-lg border border-gray-10 md:mb-0 md:table-row md:overflow-visible md:border-0"
                                    >
                                        {row.cells.map(cell => {
                                            let additionally = '';
                                            let styles = {};

                                            //status
                                            if (cell.column.id === 'status') {
                                                let statusJSX;
                                                if (cell.value === 10) {
                                                    statusJSX = (
                                                        <div className="w-[inherit] uppercase text-green md:overflow-hidden md:overflow-ellipsis">
                                                            {t('ADM_PLANNER_TASK_ACTIVE')}
                                                        </div>
                                                    );
                                                } else if (cell.value === 0) {
                                                    statusJSX = (
                                                        <div className="w-[inherit] whitespace-nowrap uppercase text-gray md:overflow-hidden md:overflow-ellipsis">
                                                            {t('ADM_PLANNER_TASK_NOT_ACTIVE')}
                                                        </div>
                                                    );
                                                } else {
                                                    statusJSX = (
                                                        <div className="w-[inherit] whitespace-nowrap uppercase text-gray-300 md:overflow-hidden md:overflow-ellipsis">
                                                            {t('ADM_PLANNER_TASK_UNDEFINED')}
                                                        </div>
                                                    );
                                                }

                                                additionally = (
                                                    <div className="relative md:w-24" ref={activeBlockIsOpen === cell.row.original.id ? tableActiveRef : null}>
                                                        <button
                                                            className="flex flex-row items-center text-gray-30 hover:text-black"
                                                            onClick={() =>
                                                                activeBlockIsOpen === cell.row.original.id
                                                                    ? setActiveBlockIsOpen(false)
                                                                    : setActiveBlockIsOpen(cell.row.original.id)
                                                            }
                                                        >
                                                            {statusJSX}
                                                            <svg className="h-6 w-6 fill-current transition-colors" viewBox="0 0 24 24">
                                                                <path d="M7 10L12 15L17 10H7Z" />
                                                            </svg>
                                                        </button>
                                                        <div
                                                            className={`showed absolute right-0 z-20 w-36 rounded-sm bg-white opacity-100 shadow-3xl transition-opacity md:left-[75%] md:right-auto ${
                                                                activeBlockIsOpen === cell.row.original.id ? 'showed opacity-100' : 'hidden opacity-0'
                                                            }`}
                                                        >
                                                            <button
                                                                className="flex w-full cursor-pointer flex-row items-center justify-between whitespace-nowrap border-0 py-2 pl-3 pr-4 text-left text-sm font-normal text-green hover:bg-gray-100"
                                                                type="button"
                                                                onClick={() => activeHandler(cell.row.original.id, 1)}
                                                            >
                                                                {t('ADM_PLANNER_TASK_ACTIVE')}
                                                                {cell.value === 10 && (
                                                                    <svg className="h-4 w-4 fill-current" viewBox="0 0 17 17">
                                                                        <path d="M6.375 11.4537L3.42125 8.5L2.41542 9.49875L6.375 13.4583L14.875 4.95833L13.8763 3.95958L6.375 11.4537Z" />
                                                                    </svg>
                                                                )}
                                                            </button>
                                                            <button
                                                                className="flex w-full cursor-pointer flex-row items-center justify-between whitespace-nowrap border-0 py-2 pl-3 pr-4 text-left text-sm font-normal text-gray hover:bg-gray-100"
                                                                type="button"
                                                                onClick={() => activeHandler(cell.row.original.id, 0)}
                                                            >
                                                                {t('ADM_PLANNER_TASK_NOT_ACTIVE')}
                                                                {cell.value === 0 && (
                                                                    <svg className="h-4 w-4 fill-current" viewBox="0 0 17 17">
                                                                        <path d="M6.375 11.4537L3.42125 8.5L2.41542 9.49875L6.375 13.4583L14.875 4.95833L13.8763 3.95958L6.375 11.4537Z" />
                                                                    </svg>
                                                                )}
                                                            </button>
                                                        </div>
                                                    </div>
                                                );
                                            }

                                            //edit
                                            if (cell.column.id === 'edit') {
                                                additionally = (
                                                    <div className="relative" ref={editBlockIsOpen === cell.value ? tableEditRef : null}>
                                                        <button
                                                            className="hidden h-10 w-10 items-center justify-center rounded-full border-0 bg-transparent hover:bg-gray-100 md:flex"
                                                            type="button"
                                                            onClick={() =>
                                                                editBlockIsOpen === cell.value ? setEditBlockIsOpen(false) : setEditBlockIsOpen(cell.value)
                                                            }
                                                        >
                                                            <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
                                                                <path d="M6 10C4.9 10 4 10.9 4 12C4 13.1 4.9 14 6 14C7.1 14 8 13.1 8 12C8 10.9 7.1 10 6 10ZM18 10C16.9 10 16 10.9 16 12C16 13.1 16.9 14 18 14C19.1 14 20 13.1 20 12C20 10.9 19.1 10 18 10ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z" />
                                                            </svg>
                                                        </button>
                                                        <div
                                                            className={`relative right-0 transition-opacity md:absolute ${
                                                                editBlockIsOpen === cell.value ? 'md:showed md:opacity-100' : 'md:hidden md:opacity-0'
                                                            }`}
                                                        >
                                                            <button
                                                                className="cursor-pointer rounded-sm border-0 bg-white py-2 pl-3 pr-4 font-normal text-black shadow-3xl hover:bg-gray-100"
                                                                type="button"
                                                                onClick={() => editHandler(cell.value)}
                                                            >
                                                                {t('ADM_PLANNER_EDIT_BTN')}
                                                            </button>
                                                        </div>
                                                    </div>
                                                );

                                                styles = 'md:w-10';
                                            }

                                            //description
                                            if (cell.column.id === 'description') {
                                                additionally = (
                                                    <div
                                                        data-tip={cell.value}
                                                        className="w-[inherit] max-w-xs md:line-clamp-2 md:max-w-none md:overflow-ellipsis"
                                                    >
                                                        {cell.value}
                                                    </div>
                                                );
                                                styles = 'md:w-20 md:w-40 xl:w-80 2xl:w-[40rem]';
                                            }

                                            return (
                                                <td
                                                    {...cell.getCellProps()}
                                                    data-label={cell.column.Header}
                                                    className={`${styles} flex justify-between border-b border-gray-10 bg-white px-2 py-5 text-right font-normal before:font-medium before:uppercase before:content-[attr(data-label)] last:border-b-0 md:table-cell md:text-left md:before:hidden md:first:border-l md:first:pl-4 md:last:border-b md:last:border-r md:last:pr-4 ${
                                                        rows.length === index + 1 ? 'first:rounded-bl-lg last:rounded-br-lg' : ''
                                                    }`}
                                                >
                                                    {additionally || cell.render('Cell')}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </InfiniteScroll>
            </div>
            <ReactTooltip textColor="#FCFCFC" backgroundColor="#707183E5" effect="solid" className="max-w-prose !rounded-md !px-2.5 !py-2" />
        </>
    );
};
