import { useEffect, useState } from 'react';

export const NodeDataText = ({ setNodeStates, setCheckPopupFields, node }) => {
    const [data, setData] = useState({ value: '', fontSize: '', textColor: '#000000', bgColor: '#FFFFFF' });

    useEffect(() => {
        data.value.length && data.fontSize.length > 0 ? setCheckPopupFields(true) : setCheckPopupFields(false);
    }, [data]);

    useEffect(() => {
        if (node.hasOwnProperty('cn_id')) {
            setData(node.data.states[0]);
        }
    }, [node]);

    const handlerDataChange = (value, type) => {
        setData({ ...data, [type]: value });
        setNodeStates([{ ...data, [type]: value }]);
    };
    return (
        <div className="mt-4">
            <div className="mb-2 flex gap-2 text-sm">
                <span>Размер шрифта</span>
                <label>
                    9px
                    <input
                        checked={data.fontSize === '9px'}
                        onChange={e => handlerDataChange(e.target.value, 'fontSize')}
                        className="ml-1"
                        type="radio"
                        name="fontSize"
                        value="9px"
                    />
                </label>
                <label>
                    12px
                    <input
                        checked={data.fontSize === '12px'}
                        onChange={e => handlerDataChange(e.target.value, 'fontSize')}
                        className="ml-1"
                        type="radio"
                        name="fontSize"
                        value="12px"
                    />
                </label>
                <label>
                    16px
                    <input
                        checked={data.fontSize === '16px'}
                        onChange={e => handlerDataChange(e.target.value, 'fontSize')}
                        className="ml-1"
                        type="radio"
                        name="fontSize"
                        value="16px"
                    />
                </label>
            </div>
            <div className="mb-2 flex w-40 items-center justify-between text-sm">
                <span>Цвет шрифта</span>
                <input type="color" value={data.textColor} name="textColor" onChange={e => handlerDataChange(e.target.value, 'textColor')} />
            </div>
            <div className="mb-2 flex w-40 items-center justify-between text-sm">
                <span>Цвет фона</span>
                <input type="color" value={data.bgColor} name="bgColor" onChange={e => handlerDataChange(e.target.value, 'bgColor')} />
            </div>
            <textarea
                value={data.value}
                onChange={e => handlerDataChange(e.target.value, 'value')}
                placeholder="Введите текст"
                className="h-36 w-full p-2 text-sm"
                style={{ color: data.textColor, background: data.bgColor }}
            ></textarea>
        </div>
    );
};
