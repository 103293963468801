import { t } from 'i18next';
import { FC, useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';

import { url } from '../../api/config';
import { useLazyGetTokensQuery } from '../../api/endpoints/initApi.ts';
import { getUrlParams } from '../../functions/getUrlParams';
import i18n from '../../i18n';
import { setLanguageFetch, setUser } from '../../redux/slices/loginSlice';
import { AppDispatch } from '../../redux/store.ts';
import { Loader } from '../common/Loader';

export const InitLayout: FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const location = useLocation();
    const [cookies, setCookie] = useCookies();
    const { ready } = useTranslation();
    const [getTokens] = useLazyGetTokensQuery();

    const [isInitialized, setIsInitialized] = useState(false);

    // Извлечение параметров из URL
    const urlParams = getUrlParams(window.location.href);
    const usernameFromUrl = urlParams.username;
    const accessTokenFromUrl = urlParams.auth_key_calendar;
    const langFromUrl = urlParams.lang;
    const noRedirect = urlParams.noredirect;

    // Установка значений
    const username = useMemo(() => usernameFromUrl || cookies.username || '', [usernameFromUrl, cookies.username]);
    const accessToken = useMemo(() => accessTokenFromUrl || cookies.accessToken || '', [accessTokenFromUrl, cookies.accessToken]);
    const lang = useMemo(() => langFromUrl || cookies.i18next || 'ru', [langFromUrl, cookies.i18next]);

    // Логика записи кук
    useEffect(() => {
        if (usernameFromUrl) {
            setCookie('username', usernameFromUrl, { path: '/' });
        }
        if (accessTokenFromUrl) {
            setCookie('accessToken', accessTokenFromUrl, { path: '/' });
        }
        if (langFromUrl) {
            setCookie('i18next', langFromUrl, { path: '/' });
        }
    }, [usernameFromUrl, accessTokenFromUrl, langFromUrl, setCookie]);

    useEffect(() => {
        (async () => {
            if (username && accessToken && lang && !isInitialized) {
                dispatch(setUser({ auth_key_calendar: accessToken, accessToken, username, lang }));

                try {
                    // Проверка авторизации
                    await getTokens().unwrap();

                    // Поменять язык
                    await i18n.changeLanguage(lang);
                    await dispatch(setLanguageFetch({ lang }));

                    if (location.search) {
                        window.history.replaceState(null, '', location.pathname);
                    }

                    setIsInitialized(true);
                } catch (e) {
                    if (!noRedirect) {
                        window.location.href = url;
                    }
                }
            }
        })();
    }, [username, accessToken, lang, isInitialized]);

    useEffect(() => {
        if (isInitialized) {
            document.title = t('KAIZEN_TITLE');
        }
    }, [cookies.i18next, isInitialized]);

    if (!isInitialized || !ready) {
        return (
            <div className="fixed flex h-full w-full items-center justify-center bg-gray-100">
                <Loader height={45} width={45} />
            </div>
        );
    }

    return <Outlet />;
};
