import { useTranslation } from 'react-i18next';

import { SvgComponent } from '../../../common/svgComponent/SvgComponent';
import { Button } from '../../../ui/button/Button.tsx';

export const CreateNewsButton = ({ onClick }) => {
    const { t } = useTranslation();

    return (
        <Button type="info" onClick={onClick} className="border-blue bg-blue hover:bg-blue-700">
            <SvgComponent name="add" />
            {t('NEWS_AND_POLLS_CREATE_NEWS_AGREEMENT')}
        </Button>
    );
};
