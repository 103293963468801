import { BitMaskButton } from './BitMaskButton';
import { BooleanSelect } from './BooleanSelect';
import { DataInput } from './DataInput';
import { DataPointControlCheckbox } from './DataPointControlCheckbox';
import { DataPointSelect } from './DataPointSelect';
import { UseBitCheckbox } from './UseBitCheckbox';

export const ButtonData = ({ data, setData, nodeOptions, setValues, setWritePoint, setBitPopupIsOpen, setBitData, initialState }) => {
    const handlerInputChange = (value, type) => {
        // проверка на ввод числа больше чем с десятыми
        if (data.writePoint && data.writePoint.datatype === 'float' && type.includes('Value')) {
            const floatCheck = value.split('.');
            if (floatCheck.length === 2 && floatCheck[1].length > 1) {
                return;
            }
        }
        // проверка на целое число
        if (data.writePoint && data.writePoint.datatype === 'int' && type.includes('Value')) {
            const floatCheck = value.split('.');
            if (floatCheck.length === 2 && floatCheck[1].length >= 1) {
                setData({ ...data, [type]: floatCheck[0] });
                return;
            }
        }

        //   проверка на более двух нулей подряд
        if (type.includes('Value') && value.length > 1 && value[0] === '0' && value[1] !== '.') {
            setValues([{ ...data, [type]: value[1] }]);
            setData({ ...data, [type]: value[1] });
            return;
        }
        // проверка отрицательного числа на ввод более двух нулей подряд
        if (type.includes('value') && value.length === 3 && value[0] === '-' && value[1] === '0' && value[2] !== '.') {
            const val = `${value[0]}${value[2]}`;

            setData({ ...data, [type]: val });
            setValues([{ ...data, [type]: val }]);
            return;
        }

        setData({ ...data, [type]: value });
        setValues([{ ...data, [type]: value }]);
    };
    return (
        <div className={`flex items-center ${data.isUseDataPointControl ? 'border-b pb-3' : ''}`}>
            <div className="flex flex-col">
                <DataPointSelect
                    data={data}
                    setData={setData}
                    nodeOptions={nodeOptions}
                    setWritePoint={setWritePoint}
                    setValues={setValues}
                    initialState={initialState}
                />
                <UseBitCheckbox data={data} setData={setData} setValues={setValues} />
                <DataPointControlCheckbox data={data} setData={setData} setValues={setValues} initialState={initialState} />
            </div>

            <div className="flex flex-col gap-2">
                <div className="flex items-center justify-between">
                    <span className="mr-2 text-sm">Текущее значение:</span>
                    <input type="text" className="h-8 w-44 text-sm" value={data.writePoint ? data.writePoint.value_from_data_point : ''} disabled />
                </div>
                <div className="flex items-center justify-between">
                    {data.bit ? (
                        <BitMaskButton
                            data={data}
                            setBitPopupIsOpen={setBitPopupIsOpen}
                            setBitData={setBitData}
                            field="originValue"
                            title="Исходное значение"
                        />
                    ) : data.writePoint && data.writePoint.datatype === 'boolean' ? (
                        <BooleanSelect data={data} setData={setData} setValues={setValues} type="originValue" title="Исходное значение" />
                    ) : (
                        <DataInput data={data} handlerInputChange={handlerInputChange} type="originValue" title="Исходное значение" />
                    )}
                </div>
                <div className="flex items-center justify-between">
                    {data.bit ? (
                        <BitMaskButton
                            data={data}
                            setBitPopupIsOpen={setBitPopupIsOpen}
                            setBitData={setBitData}
                            field="passedValue"
                            title="Передаваемое значение"
                        />
                    ) : data.writePoint && data.writePoint.datatype === 'boolean' ? (
                        <BooleanSelect data={data} setData={setData} setValues={setValues} type="passedValue" title="Передаваемое значение" />
                    ) : (
                        <DataInput data={data} handlerInputChange={handlerInputChange} type="passedValue" title="Передаваемое значение" />
                    )}
                </div>
                <div className="flex items-center justify-between">
                    <DataInput data={data} handlerInputChange={handlerInputChange} type="delay" title="Задержка в мс" />
                </div>
            </div>
        </div>
    );
};
