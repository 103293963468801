export const SlidingPaneHeader = ({ closePaneHandler, title, textRight = '' }) => {
    return (
        <div className="sticky -top-6 z-20 -mt-6 flex w-full items-center gap-6 bg-white py-6">
            <div className="w-full text-xl font-bold text-black">{title}</div>

            {!!textRight && <div className="mr-2 shrink-0 font-medium text-neutral-800">{textRight}</div>}

            <button className="flex h-9 w-9 shrink-0 items-center justify-center rounded-full bg-transparent p-0 hover:bg-gray-100" onClick={closePaneHandler}>
                <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
                    <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
                </svg>
            </button>
        </div>
    );
};
