import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { objectState, objectsFetchingState, objectsState } from '../../../redux/slices/hospitalitySlice';
import { Loader } from '../../common/Loader';
import { SubHeader } from '../../common/subheader/SubHeader';
import { SubHeaderLeft } from '../../common/subheader/SubHeaderLeft';
import { SubHeaderRight } from '../../common/subheader/SubHeaderRight';
import { Title } from '../../common/subheader/Title';
import { CreateTaskButton } from './buttons/CreateTaskButton';
import { InformationButton } from './buttons/InformationButton';
import { RefreshButton } from './buttons/RefreshButton';
import { SettingsButton } from './buttons/SettingsButton';
import { WorkTypesButton } from './buttons/WorkTypesButton';
import { ChangeHotelButton } from './changeHotel/ChangeHotelButton';
import { ChangeHotelPopup } from './changeHotel/ChangeHotelPopup';

export const Header = ({ setTaskPaneIsOpen, setWorkTypesPaneIsOpen, setSettingsPaneIsOpen, setInfoPopupIsOpen, informationButtonRef }) => {
    const { t } = useTranslation();

    const objectsFetching = useSelector(objectsFetchingState);
    const object = useSelector(objectState);
    const objects = useSelector(objectsState);

    const [changeHotelPopupIsOpen, setChangeHotelPopupIsOpen] = useState(false);

    const changeHotelButtonRef = useRef(null);

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    {objectsFetching ? (
                        <Loader height={20} width={20} />
                    ) : (
                        <div className="flex flex-row items-center gap-1" ref={changeHotelButtonRef}>
                            <Title>{(object && object.title) || t('HOSPITALITY_NO_OBJECT')}</Title>
                            {objects.length > 1 ? <ChangeHotelButton setPopupIsOpen={setChangeHotelPopupIsOpen} /> : null}
                        </div>
                    )}
                </SubHeaderLeft>

                <SubHeaderRight>
                    <RefreshButton />
                    <SettingsButton setSettingsPaneIsOpen={setSettingsPaneIsOpen} />
                    <WorkTypesButton setWorkTypesPaneIsOpen={setWorkTypesPaneIsOpen} />
                    <InformationButton setPopupIsOpen={setInfoPopupIsOpen} buttonRef={informationButtonRef} />
                    <CreateTaskButton setTaskPaneIsOpen={setTaskPaneIsOpen} />
                </SubHeaderRight>
            </SubHeader>

            {objects.length > 1 ? (
                <ChangeHotelPopup isOpen={changeHotelPopupIsOpen} setIsOpen={setChangeHotelPopupIsOpen} buttonRef={changeHotelButtonRef} />
            ) : null}
        </>
    );
};
