import { useEffect, useMemo, useRef, useState } from 'react';
import CustomScroll from 'react-custom-scroll';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { cleanersState } from '../../../../../redux/slices/hospitalitySlice';
import { SvgComponent } from '../../../../common/svgComponent/SvgComponent';
import { Radio } from '../../../../common/widgets/Radio';

export const CleanerSelectionSection = ({ setCleanerHandler, isOpen, setClose, cleanerId }) => {
    const { t } = useTranslation();

    const cleanerInputRef = useRef(null);

    const cleaners = useSelector(cleanersState);

    const [searchLine, setSearchLine] = useState('');

    useEffect(() => {
        if (cleanerInputRef.current && isOpen) {
            cleanerInputRef.current.focus();
        }

        return () => setSearchLine('');
    }, [isOpen]);

    const allocatedCleaners = useMemo(
        () => cleaners.filter(cleaner => cleaner.supervisorId && cleaner.isApplied && cleaner.name.toLowerCase().indexOf(searchLine.toLowerCase()) >= 0),
        [cleaners, searchLine]
    );

    return (
        <div className={`relative -top-[72px] -mb-[72px] min-h-[128px] flex-col rounded bg-white pl-3 shadow-3xl ${isOpen ? 'flex' : 'hidden'}`}>
            <div className="flex flex-row items-center justify-start gap-1.5 border-b border-b-gray-10 py-3 pr-3">
                <div className="mr-1 h-3.5 w-3.5 shrink-0">
                    <SvgComponent name="search" />
                </div>
                <input
                    type="text"
                    className="bg-red h-5 w-full text-ellipsis border-0 p-0 text-xs font-medium focus:border-0 focus:outline-0 focus:ring-0"
                    placeholder={t('HOSPITALITY_ADD_MAID')}
                    ref={cleanerInputRef}
                    value={searchLine}
                    onChange={e => setSearchLine(e.target.value)}
                />
                <button className="mt-[1px] shrink-0 text-xs font-medium text-blue" onClick={setClose}>
                    {t('CANCEL')}
                </button>
            </div>
            <div className="h-full max-h-[180px]">
                <CustomScroll allowOuterScroll={true}>
                    <div className="overflow-hidden">
                        <div className="flex flex-col gap-2 py-3 pr-3">
                            {allocatedCleaners.length ? (
                                allocatedCleaners.map(cleaner => (
                                    <div className="flex py-1.5 text-xs" key={cleaner.id}>
                                        <Radio
                                            name={`cleaner${cleaner.id}`}
                                            title={cleaner.name}
                                            value={cleaner.id}
                                            checked={cleaner.id === cleanerId}
                                            disabled={cleaner.isAssigned}
                                            onClick={setCleanerHandler}
                                        />
                                    </div>
                                ))
                            ) : (
                                <div className="text-xs">{t('HOSPITALITY_NO_MAIDS_TO_DISTRIBUTE')}</div>
                            )}
                        </div>
                    </div>
                </CustomScroll>
            </div>
        </div>
    );
};
