import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { active_system as active_system_state, getBMSSystemList } from '../../../redux/slices/bms/bmsSliceSystems';

export const SystemName = () => {
    const dispatch = useDispatch();
    const active_system = useSelector(active_system_state);
    const { systemId, venueId } = useParams();

    useEffect(() => {
        if (!active_system.length) {
            dispatch(getBMSSystemList({ venueId }));
        }
    }, []);

    const systemName = active_system.find(venue => venue.id === Number(systemId));
    return systemName && <div className="mb-3">Раздел: {systemName.title}</div>;
};
