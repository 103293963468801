import { useTranslation } from 'react-i18next';

export const SwitchPanel = ({ activeSectionId, setActiveSectionId }) => {
    const { t } = useTranslation();

    return (
        <div className="flex h-14 flex-row gap-8">
            <button
                className={`flex h-full items-center justify-center gap-1.5 border-b-2 text-sm font-medium transition duration-150 hover:text-blue ${
                    activeSectionId === 1 ? 'border-blue text-blue' : 'border-white text-gray'
                }`}
                onClick={() => setActiveSectionId(1)}
            >
                <span className="truncate">{t('NEWS_AND_POLLS_NEWS_AND_AGREEMENTS')}</span>
            </button>

            <button
                className={`flex h-full items-center justify-center gap-1.5 border-b-2 text-sm font-medium transition duration-150 hover:text-blue ${
                    activeSectionId === 2 ? 'border-blue text-blue' : 'border-white text-gray'
                }`}
                onClick={() => setActiveSectionId(2)}
            >
                <span className="truncate">{t('NEWS_AND_POLLS_POLLS')}</span>
            </button>

            <button
                className={`flex h-full items-center justify-center gap-1.5 border-b-2 text-sm font-medium transition duration-150 hover:text-blue ${
                    activeSectionId === 3 ? 'border-blue text-blue' : 'border-white text-gray'
                }`}
                onClick={() => setActiveSectionId(3)}
            >
                <span className="truncate">{t('NEWS_AND_POLLS_REPORTS')}</span>
            </button>
        </div>
    );
};
