import '@fancyapps/ui/dist/carousel.css';
import '@fancyapps/ui/dist/fancybox.css';
import { Fancybox as NativeFancybox } from '@fancyapps/ui/dist/fancybox.esm';
import React, { useEffect, useState } from 'react';

export const FancyboxTask = props => {
    const delegate = props.delegate || '[data-fancybox]';
    const [selectPhoto, setSelectPhoto] = useState(props.photo);

    const setPhoto = props.setPhoto;

    useEffect(() => {
        const oo = {
            on: {
                load: fancybox => {
                    setSelectPhoto(fancybox.getSlide().src);
                },
            },
        };

        NativeFancybox.bind(delegate, oo);

        return () => {
            NativeFancybox.destroy();
        };
    }, []);

    useEffect(() => {
        setPhoto(selectPhoto);
    }, [selectPhoto]);

    return <>{props.children}</>;
};
