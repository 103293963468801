import { url } from '../../../../../api/config';

export const LibraryItem = ({ item, onClick }) => {
    return (
        <div
            className="relative flex h-40 w-40 cursor-pointer select-none flex-col gap-4 rounded-md border-2 border-gray-10 bg-white p-2 hover:border-blue hover:bg-blue-10"
            onClick={() => onClick(item)}
        >
            <div className="h-full">
                <p className="line-clamp-2 text-xs font-semibold">{item.title}</p>
            </div>

            {item.lib_data.hasOwnProperty('default_pic') && item.lib_data.default_pic && (
                <div className="h-20 flex-shrink-0">
                    <img src={`${url}${item.lib_data.default_pic}`} alt={item.title} className="block h-full w-full object-contain" />
                </div>
            )}
        </div>
    );
};
