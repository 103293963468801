import { SimpleSelect } from '../../../../common/widgets/SimpleSelect';

export const SelectKaizenVenue = ({ options, venue, callback }) => {
    return (
        <SimpleSelect
            closeMenuOnSelect={true}
            isSearchable={true}
            options={options}
            placeholder="Выберите объект"
            value={venue.kaizen_venue}
            callback={val => callback(val)}
            className="ml-auto mr-auto mt-3 w-80 text-sm"
        />
    );
};
