import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { url } from '../../api/config';
import { accessTokenState, refreshTokenState } from '../../redux/slices/loginSlice';

export const AuthLayout: FC = () => {
    const accessToken = useSelector(accessTokenState);
    const refreshToken = useSelector(refreshTokenState);

    useEffect(() => {
        if (!accessToken || !refreshToken) {
            window.location.href = url;
        }
    }, [refreshToken, accessToken]);

    return <Outlet />;
};
