import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SlidingPane from 'react-sliding-pane';
import { toast } from 'react-toastify';

import { toastOptions } from '../../../../functions/toastOptions';
import { getSupervisors, objectState, setSupervisorsAtWork, supervisorsState } from '../../../../redux/slices/hospitalitySlice';
import { SlidingPaneFooter } from '../../../common/slidingPane/SlidingPaneFooter';
import { SlidingPaneHeader } from '../../../common/slidingPane/SlidingPaneHeader';
import { Checkbox } from '../../../common/widgets/Checkbox';

export const SupervisorsPane = ({ isOpen, setIsOpen }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const object = useSelector(objectState);
    const supervisorsSt = useSelector(supervisorsState);

    const [searchLine, setSearchLine] = useState('');
    const [selectedSupervisorsId, setSelectedSupervisorsId] = useState([]);

    const supervisors = useMemo(
        () => supervisorsSt.filter(supervisor => supervisor.name.toLowerCase().indexOf(searchLine.toLowerCase()) >= 0),
        [supervisorsSt, searchLine]
    );

    useEffect(() => {
        if (object && isOpen && !supervisorsSt.length) {
            dispatch(getSupervisors());
        }
    }, [object, isOpen, supervisorsSt]);

    useEffect(() => {
        if (isOpen) {
            setSelectedSupervisorsId(supervisors.filter(supervisor => supervisor.state).map(supervisor => supervisor.id));
        }
    }, [supervisors, isOpen]);

    const closePaneHandler = () => {
        setIsOpen(false);
    };

    const setCleanerHandler = id => {
        if (selectedSupervisorsId.findIndex(supervisorId => supervisorId === id) > -1) {
            setSelectedSupervisorsId(selectedSupervisorsId.filter(supervisorId => supervisorId !== id));
        } else {
            setSelectedSupervisorsId([...selectedSupervisorsId, id]);
        }
    };

    const saveSupervisorsHandler = async () => {
        const result = await dispatch(
            setSupervisorsAtWork({
                supervisorIds: selectedSupervisorsId,
            })
        );

        if (result.error) {
            toast.error(result.message, toastOptions);
        }

        closePaneHandler();
    };

    return (
        <SlidingPane
            isOpen={isOpen}
            hideHeader={true}
            onRequestClose={closePaneHandler}
            overlayClassName="z-40 bg-slate-900 bg-opacity-30 transition-opacity duration-200 opacity-100"
            width="25rem"
        >
            <SlidingPaneHeader closePaneHandler={closePaneHandler} title={t('HOSPITALITY_ADD_EMPLOYEES')} />

            <div className="sticky top-[60px] w-full bg-white pb-6 pt-0.5">
                <svg width="18" height="18" viewBox="0 0 18 18" className="absolute left-2.5 top-3 fill-gray">
                    <path d="M16.6 18L10.3 11.7C9.8 12.1 9.225 12.4167 8.575 12.65C7.925 12.8833 7.23333 13 6.5 13C4.68333 13 3.14583 12.3708 1.8875 11.1125C0.629167 9.85417 0 8.31667 0 6.5C0 4.68333 0.629167 3.14583 1.8875 1.8875C3.14583 0.629167 4.68333 0 6.5 0C8.31667 0 9.85417 0.629167 11.1125 1.8875C12.3708 3.14583 13 4.68333 13 6.5C13 7.23333 12.8833 7.925 12.65 8.575C12.4167 9.225 12.1 9.8 11.7 10.3L18 16.6L16.6 18ZM6.5 11C7.75 11 8.8125 10.5625 9.6875 9.6875C10.5625 8.8125 11 7.75 11 6.5C11 5.25 10.5625 4.1875 9.6875 3.3125C8.8125 2.4375 7.75 2 6.5 2C5.25 2 4.1875 2.4375 3.3125 3.3125C2.4375 4.1875 2 5.25 2 6.5C2 7.75 2.4375 8.8125 3.3125 9.6875C4.1875 10.5625 5.25 11 6.5 11Z" />
                </svg>
                <input
                    type="text"
                    placeholder={t('HOSPITALITY_SEARCH_EMPLOYEES')}
                    className="box-border h-10 w-full rounded border-gray-10 bg-gray-100 pl-9 text-sm"
                    value={searchLine}
                    onChange={e => setSearchLine(e.target.value)}
                />
            </div>

            <div className="mb-16 box-border flex flex-col gap-4 px-4 py-0.5">
                {supervisors &&
                    supervisors.map(supervisor => (
                        <Checkbox
                            key={supervisor.id}
                            name="supervisor"
                            title={supervisor.name}
                            value={supervisor.id}
                            checked={supervisor.state}
                            onClick={() => setCleanerHandler(supervisor.id)}
                        />
                    ))}
            </div>

            <SlidingPaneFooter closePaneHandler={closePaneHandler} callback={saveSupervisorsHandler} />
        </SlidingPane>
    );
};
