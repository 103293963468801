import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CreateTaskButton } from '../components/admin_scheduler/CreateTaskButton';
import { Detail } from '../components/admin_scheduler/Detail';
import { Filter } from '../components/admin_scheduler/Filter';
import { Table } from '../components/admin_scheduler/Table';
import { ContentContainer } from '../components/common/ContentContainer';
import { SubHeader } from '../components/common/subheader/SubHeader';
import { SubHeaderLeft } from '../components/common/subheader/SubHeaderLeft';
import { SubHeaderRight } from '../components/common/subheader/SubHeaderRight';
import { Title } from '../components/common/subheader/Title';
import { book } from '../navigation/book';
import { clear_filter } from '../redux/slices/adminSchedulerSlice';

export const AdminSchedulerPage = () => {
    const dispatch = useDispatch();
    const [clearFilter, setClearFilter] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (!clearFilter) {
            dispatch(clear_filter());
            setClearFilter(true);
        }
    }, [dispatch, clearFilter]);

    useEffect(() => {
        navigate(book.admin_scheduler.link);
    }, []);

    return (
        <>
            <SubHeader>
                <SubHeaderLeft>
                    <Title>{t(book.admin_scheduler.key)}</Title>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <CreateTaskButton />
                </SubHeaderRight>
            </SubHeader>
            <ContentContainer>
                <Filter />
                <Table clearFilter={clearFilter} />
                <Detail />
            </ContentContainer>
        </>
    );
};
