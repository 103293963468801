export const BitCheckbox = ({ nodeStatusInfo, callback }) => {
    return (
        <div className="flex w-[6%] shrink-0 justify-center px-1">
            <input
                disabled={!nodeStatusInfo.node || nodeStatusInfo.type === 'boolean' || nodeStatusInfo.type === 'float' || !nodeStatusInfo.bitsize}
                className="block cursor-pointer hover:border-gray-30"
                name="range"
                type="checkbox"
                value={nodeStatusInfo.bit}
                checked={nodeStatusInfo.bit}
                onChange={() => {}}
                onClick={e => callback('bit', e.target.checked)}
            />
        </div>
    );
};
