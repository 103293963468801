export const LibraryTypesSwitcher = ({ types, isActive, setIsActive }) => {
    if (types.length) {
        return (
            <div className="mb-3 flex h-12 flex-row items-center gap-4">
                {types.map(type => (
                    <button
                        key={type.title}
                        onClick={() => setIsActive(type.title)}
                        className={`font-semibold uppercase text-blue ${isActive === type.title ? 'underline' : ''}`}
                    >
                        {type.title}
                    </button>
                ))}
            </div>
        );
    }

    return null;
};
