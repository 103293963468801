import { useCallback, useEffect, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';

import { url } from '../../../../../api/config';
import {
    documentState as documentState_state,
    selectedNodesForCopyState,
    setSelectedNodesForCopy,
    update_bms_document_node,
} from '../../../../../redux/slices/bms/bmsSliceDocuments';
import { Box } from './Box';
import { Equipment } from './elements/Equipment';
import { Output } from './elements/Output';
import { Text } from './elements/Text';

export const Container = ({ nodes, editNodeHandler }) => {
    const dispatch = useDispatch();
    const documentState = useSelector(documentState_state);
    const selectedNodesForCopy = useSelector(selectedNodesForCopyState);

    const [isCtrlPressed, setIsCtrlPressed] = useState(false);

    const selectedItemsRef = useRef([]);
    const containerSize = useRef({
        width: 1920,
        height: 1080,
    });

    const [resetSelectedNodes, setResetSelectedNodes] = useState(false);

    useEffect(() => {
        const handleKeyDown = event => {
            if (event.key === 'Control' || event.key === 'Meta') {
                // Проверяем, нажата ли клавиша Ctrl (или Command на Mac)
                setIsCtrlPressed(true);
            }
        };

        const handleKeyUp = event => {
            if (event.key === 'Control' || event.key === 'Meta') {
                // Проверяем, отжата ли клавиша Ctrl (или Command на Mac)
                setIsCtrlPressed(false);
            }
        };

        const handleMouseDown = event => {
            // Сбрасываем выбранные элементы если не зажат ctrl и клик мимо элемента
            if (!event.target.closest('.node-box-click') && !isCtrlPressed && !event.target.classList.contains('preventEvent')) {
                selectedItemsRef.current = [];
                setResetSelectedNodes(true);
            } else {
                setResetSelectedNodes(false);
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);
        window.addEventListener('mousedown', handleMouseDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
            window.removeEventListener('mousedown', handleMouseDown);
        };
    }, []);

    useEffect(() => {
        if (resetSelectedNodes && !selectedNodesForCopy.length) {
            dispatch(setSelectedNodesForCopy([]));
            setResetSelectedNodes(false);
        }
    }, [resetSelectedNodes]);

    const moveBox = useCallback(
        (id, left, top, leftPercent, topPercent, width, height, degree) => {
            const node = nodes.find(node => node.cn_id === id);
            const updateNode = {
                ...node,
                position: {
                    ...node.position,
                    left,
                    top,
                    leftPercent,
                    topPercent,
                    width: width === '0px' ? node.width : width,
                    height: height === '0px' ? node.height : height,
                    degree,
                    fontSize: node.fontSize,
                },
            };

            dispatch(update_bms_document_node(updateNode));
        },
        [nodes]
    );

    const [, drop] = useDrop(
        () => ({
            accept: 'box',
            drop(item, monitor) {
                const delta = monitor.getDifferenceFromInitialOffset();
                const left = Math.round(item.left + delta.x);
                const top = Math.round(item.top + delta.y);

                moveBox(
                    item.id,
                    left,
                    top,
                    (left * 100) / containerSize.current.width,
                    (top * 100) / containerSize.current.height,
                    item.width,
                    item.height,
                    item.degree
                );
                return undefined;
            },
        }),
        [moveBox, documentState]
    );

    return (
        <div
            ref={drop}
            className="relative z-0 border-2 border-gray-10 bg-contain bg-no-repeat"
            style={{
                width: containerSize.current.width,
                height: containerSize.current.height,
                backgroundImage: `url(${url}/${documentState?.background_image})`,
            }}
        >
            {nodes &&
                nodes
                    .filter(node => !node?.removed)
                    .map(node => {
                        return (
                            <Box
                                key={node.cn_id}
                                id={node.cn_id}
                                node={node}
                                editNodeHandler={editNodeHandler}
                                selectedItemsRef={selectedItemsRef}
                                isCtrlPressed={isCtrlPressed}
                            >
                                {!node.lib_data.hasOwnProperty('subtype') && <Equipment node={node} />}
                                {node.lib_data.hasOwnProperty('subtype') && node.lib_data.subtype === 'output' && <Output node={node} />}
                                {node.lib_data.hasOwnProperty('subtype') && node.lib_data.subtype === 'text' && <Text node={node} />}
                            </Box>
                        );
                    })}
        </div>
    );
};
