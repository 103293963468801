export const NotificationCheckbox = ({ nodeStatusInfo, setNodeStatusInfo, setNodeStates, initState, node, type }) => {
    return (
        <div className="flex w-[5%] shrink-0 justify-center px-1">
            <input
                disabled={!nodeStatusInfo.node}
                className="cursor-pointer hover:border-gray-30"
                name="range"
                type="checkbox"
                value={nodeStatusInfo.notifications ? nodeStatusInfo.notifications[type] : false}
                checked={nodeStatusInfo.notifications ? nodeStatusInfo.notifications[type] : false}
                onChange={() => {}}
                onClick={e => {
                    setNodeStatusInfo({
                        ...nodeStatusInfo,
                        notifications: { ...nodeStatusInfo.notifications, [type]: e.target.checked },
                    });

                    setNodeStates(prevState =>
                        prevState.map(el =>
                            el.id === node.id
                                ? {
                                      ...el,
                                      notifications: el.notifications
                                          ? { ...el.notifications, [type]: e.target.checked }
                                          : { ...initState.notifications, [type]: e.target.checked },
                                  }
                                : el
                        )
                    );
                }}
            />
        </div>
    );
};
