import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { useCheckRole } from '../../../../hooks/useCheckRole';
import { clearHistoryRemovedNodes, documentState } from '../../../../redux/slices/bms/bmsSliceDocuments';
import { ActionPane } from './actionPane/ActionPane';
import { SubmitButton } from './button/SubmitButton';
import { CanvasSection } from './canvas/CanvasSection';
import { CreateControlPopup } from './controlPopup/CreateControlPopup';
import { ControlsSection } from './controls/ControlsSection';
import { DocumentInformationForm } from './documentInformationForm/DocumentInformationForm';
import { LibrarySection } from './library/LibrarySection';
import { CreateNodePopup } from './nodePopup/CreateNodePopup';

export const DocumentEdit = () => {
    const { venueId, systemId } = useParams();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const document = useSelector(documentState);

    const infoFormRef = useRef(null);

    const [nodes, setNodes] = useState(document.nodes);
    const [controls, setControls] = useState(document.controls);
    const [popupIsOpen, setPopupIsOpen] = useState(false);
    const [createPopupIsOpen, setCreatePopupIsOpen] = useState(false);
    const [selectedElement, setSelectedElement] = useState(null);

    const isDocumentNodeAdd = useCheckRole('bms', 'bms.document_nodes.add');

    useEffect(() => {
        return () => dispatch(clearHistoryRemovedNodes());
    }, []);

    useEffect(() => {
        if (!document.id) {
            return navigate(`/bms/venues/${venueId}/system/${systemId}/documents/`);
        }

        setNodes(document.nodes);
        setControls(document.controls);
    }, [document]);

    const handlerNodeClick = node => {
        if (node.lib_data && node.lib_data.hasOwnProperty('type')) {
            setCreatePopupIsOpen(true);
        } else {
            setPopupIsOpen(true);
        }

        setSelectedElement(node);
    };

    if (!document.id) {
        return null;
    }

    return (
        <div>
            <p className="mb-6 text-xl font-semibold uppercase text-black">Информация о системе</p>
            <DocumentInformationForm
                info={{
                    title: document.title,
                    description: document.description,
                    background_image: document.background_image,
                }}
                formRef={infoFormRef}
            />

            <p className="mb-6 text-xl font-semibold uppercase text-black">Расстановка оборудования</p>
            <ActionPane />
            <div className="mb-6 h-full w-full overflow-x-auto">
                <ControlsSection controls={controls} editNodeHandler={handlerNodeClick} />
                <CanvasSection nodes={nodes} editNodeHandler={handlerNodeClick} />
            </div>

            <SubmitButton nodes={nodes} infoFormRef={infoFormRef} docId={document.id} />

            {isDocumentNodeAdd && <LibrarySection itemLibraryHandler={handlerNodeClick} />}

            {popupIsOpen && <CreateNodePopup setIsOpen={setPopupIsOpen} node={selectedElement} docId={document.id} />}

            {createPopupIsOpen && <CreateControlPopup setIsOpen={setCreatePopupIsOpen} control={selectedElement} docId={document.id} />}
        </div>
    );
};
