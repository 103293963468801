import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { toastOptions } from '../../../../../functions/toastOptions';
import { updateBMSFeedbackControl } from '../../../../../redux/slices/bms/bmsSliceDocuments';

export const FeedbackButtonControl = ({ control, callback }) => {
    const dispatch = useDispatch();

    const lastStatus = useRef();

    const status = control.data.controlState ? control.data.controlState.state : 'default';
    if (lastStatus.current !== status) {
        lastStatus.current = status;
        switch (status) {
            case 'process':
                dispatch(updateBMSFeedbackControl({ cnId: control.cn_id, controlState: '_process' }));
                return toast.info('Запрос на OPC сервер отправлен', toastOptions);

            case 'process_server':
                dispatch(updateBMSFeedbackControl({ cnId: control.cn_id, controlState: '_process_server' }));
                return toast.info('ОРС сервер начал обработку запроса', toastOptions);

            case 'success':
                dispatch(updateBMSFeedbackControl({ cnId: control.cn_id, controlState: '_success' }));
                return toast.success('Значение прописалось на ОРС сервер успешно', toastOptions);

            case 'error':
                dispatch(updateBMSFeedbackControl({ cnId: control.cn_id, controlState: '_error' }));
                return toast.error('Не удалось прописать значение на OPC сервер', toastOptions);

            default:
                break;
        }
    }

    const getColor = () => {
        if (status === 'default') {
            return 'bg-white';
        }
        if (status === 'process' || status === '_process') {
            return 'bg-yellow-400';
        }
        if (status === 'process_server' || status === '_process_server') {
            return 'bg-blue-400';
        }
        if (status === 'success' || status === '_success') {
            return 'bg-green-400';
        }
        if (status === 'error' || status === '_error') {
            return 'bg-red-400';
        }
    };
    const getBackground = () => {
        return status === 'default' ? 'bg-slate-300' : 'bg-white';
    };
    return (
        <button onClick={() => callback(control)} className="flex items-center" type="button" disabled={status === 'process' || status === 'process_server'}>
            <div className={`h-4 w-4 border ${getBackground()} bg-gre flex items-center justify-center border-black`}>
                <div className={`h-2 w-2 border border-gray-50 ${getColor()}`}></div>
            </div>
        </button>
    );
};
