import { useEffect, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useTable } from 'react-table';
import ReactTooltip from 'react-tooltip';

import { getPolls, pollsFetchingState, pollsFilterState, pollsState } from '../../../../../redux/slices/newsAndPollsSlice';
import { Loader } from '../../../../common/Loader';
import { SvgComponent } from '../../../../common/svgComponent/SvgComponent';

export const PollsSection = ({ setItem }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [cookies] = useCookies();

    const loading = useSelector(pollsFetchingState);
    const polls = useSelector(pollsState);
    const filter = useSelector(pollsFilterState);

    const lang = useMemo(() => cookies.i18next, [cookies]);

    const columns = useMemo(
        () => [
            {
                Header: t('NEWS_AND_POLLS_USERS'),
                accessor: 'users',
            },
            {
                Header: t('NEWS_AND_POLLS_OBJECTS'),
                accessor: 'objects',
            },
            {
                Header: t('NEWS_AND_POLLS_PUBLICATION_DATE'),
                accessor: 'date',
            },
            {
                Header: t('NEWS_AND_POLLS_TITLE'),
                accessor: 'title',
            },
        ],
        [lang]
    );

    const data = useMemo(() => polls.items, [polls]);

    const mainElement = useMemo(() => document.querySelector('#wrapper'), [polls]);

    useEffect(() => {
        dispatch(getPolls(filter));
    }, [filter]);

    useEffect(() => {
        const hasScroll = mainElement ? mainElement.scrollHeight > mainElement.clientHeight : false;
        const hasMore = polls.items.length < polls.totalCount;

        if (hasMore && !hasScroll && !loading) {
            setTimeout(() => {
                dispatch(getPolls({ ...filter, page: (polls.items.length / 10 + 1).toString() }));
            }, 200);
        }
    }, [mainElement, polls, loading]);

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [data]);

    const { getTableProps, getTableBodyProps, rows, prepareRow } = useTable({ columns, data });

    return (
        <div className="flex flex-col gap-6 p-6">
            {loading ? (
                <div className="flex flex-1 items-center justify-center">
                    <Loader width={24} height={24} />
                </div>
            ) : data.length ? (
                <InfiniteScroll
                    next={() => {
                        dispatch(getPolls({ ...filter, page: (rows.length / 10 + 1).toString() }));
                    }}
                    loader={
                        <div className="mt-2 flex justify-center">
                            <Loader width={24} height={24} />
                        </div>
                    }
                    dataLength={rows.length}
                    hasMore={rows.length < polls.totalCount}
                    scrollableTarget="wrapper"
                >
                    <div className="h-full w-full md:overflow-hidden md:rounded-lg md:border md:border-gray-10">
                        <table {...getTableProps()} className="w-full border-collapse border-spacing-0 text-xs font-medium text-gray-500">
                            <tbody {...getTableBodyProps()}>
                                {rows.map(row => {
                                    prepareRow(row);

                                    return (
                                        <tr
                                            {...row.getRowProps()}
                                            className="group mb-4 block overflow-hidden rounded-lg border border-gray-10 bg-white transition-colors md:mb-0 md:table-row md:cursor-pointer md:border-0 md:border-b md:last:border-b-0 md:hover:bg-neutral-50"
                                            onClick={() => {
                                                setItem(row.original);
                                            }}
                                        >
                                            {row.cells.map(cell => {
                                                let tdJSX = cell.render('Cell');

                                                if (cell.column.id === 'users') {
                                                    tdJSX = (
                                                        <div className="flex h-[30px] w-20 items-center justify-center gap-1.5 rounded-lg bg-neutral-100 text-xs font-medium text-gray-500">
                                                            <SvgComponent name="person" />
                                                            {cell.value.length}
                                                        </div>
                                                    );
                                                }

                                                if (cell.column.id === 'objects') {
                                                    tdJSX = (
                                                        <div className="flex h-[30px] w-20 items-center justify-center gap-1.5 rounded-lg bg-neutral-100 text-xs font-medium text-gray-500">
                                                            <SvgComponent name="home-work" />
                                                            {cell.value.length}
                                                        </div>
                                                    );
                                                }

                                                if (cell.column.id === 'date') {
                                                    tdJSX = (
                                                        <div className="flex h-[30px] w-40 items-center justify-center gap-1.5 rounded-lg bg-neutral-100 text-xs font-medium text-gray-500">
                                                            <SvgComponent name="calendar-today" />
                                                            {cell.render('Cell')}
                                                        </div>
                                                    );
                                                }

                                                if (cell.column.id === 'title') {
                                                    tdJSX = (
                                                        <div className="md:flex md:min-h-[30px] md:items-center md:border-l md:border-l-gray-10 md:pl-6">
                                                            <div
                                                                className="cursor-pointer text-sm text-gray-500 hover:text-blue hover:underline md:line-clamp-2 md:group-hover:text-blue md:group-hover:underline"
                                                                data-tip={cell.value}
                                                            >
                                                                {cell.render('Cell')}
                                                            </div>
                                                        </div>
                                                    );
                                                }

                                                return (
                                                    <td
                                                        {...cell.getCellProps()}
                                                        data-label={cell.column.Header}
                                                        className={`flex items-center justify-between gap-4 border-b border-gray-10 px-3 py-3 text-right before:uppercase before:content-[attr(data-label)] last:border-b-0 md:table-cell md:border-b-0 md:text-left md:before:hidden md:first:pl-4 md:last:pr-4 ${cell.column.id === 'title' && 'w-full'}`}
                                                    >
                                                        {tdJSX}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </InfiniteScroll>
            ) : (
                <div>Ничего не найдено</div>
            )}
        </div>
    );
};
