import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const MainPage: FC = () => {
    const { t } = useTranslation();

    return (
        <div className="mx-auto flex h-full w-full flex-col items-center justify-center px-5 text-center text-gray-20">
            <div className="mb-4 text-[40px] font-semibold">{t('WELCOME_TO_KAIZEN')}</div>
            <div className="mb-5 max-w-[950px] text-[35px] leading-[40px]">{t('WELCOME_TEXT_1')}</div>
            <div className="max-w-[400px] text-base leading-[18px]">{t('WELCOME_TEXT_2')}</div>
        </div>
    );
};
