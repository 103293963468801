import { useTranslation } from 'react-i18next';

import { DepartmentsSection } from '../sections/audienceSection/DepartmentsSection';
import { ExperienceSection } from '../sections/audienceSection/ExperienceSection';
import { ObjectsSection } from '../sections/audienceSection/ObjectsSection';
import { PositionsSection } from '../sections/audienceSection/PositionsSection';
import { UsersSection } from '../sections/audienceSection/UsersSection';
import { PeriodOfActivitySection } from '../sections/publicationTimeSection/PeriodOfActivitySection';

export const Step1 = ({
    selectedObjects,
    selectedPositions,
    selectedUsers,
    experience,
    selectedDepartments,
    periodOfActivity,
    setSelectedObjects,
    setSelectedPositions,
    setSelectedUsers,
    setExperience,
    setSelectedDepartments,
    setPeriodOfActivity,
    isDisabled,
}) => {
    const { t } = useTranslation();

    return (
        <div className="flex w-full flex-col gap-10">
            <div>
                <div className="mb-6 text-base font-medium text-neutral-900">{t('NEWS_AND_POLLS_AUDIENCE')}</div>

                <div className="flex flex-col gap-6">
                    <div className="flex flex-row justify-between">
                        <ObjectsSection
                            selectedObjects={selectedObjects}
                            setSelectedObjects={setSelectedObjects}
                            setSelectedPositions={setSelectedPositions}
                            setSelectedUsers={setSelectedUsers}
                            isDisabled={isDisabled}
                        />
                        <PositionsSection
                            selectedPositions={selectedPositions}
                            setSelectedObjects={setSelectedObjects}
                            setSelectedPositions={setSelectedPositions}
                            setSelectedUsers={setSelectedUsers}
                            isDisabled={isDisabled}
                        />
                        <UsersSection
                            selectedUsers={selectedUsers}
                            setSelectedObjects={setSelectedObjects}
                            setSelectedPositions={setSelectedPositions}
                            setSelectedUsers={setSelectedUsers}
                            experience={experience}
                            selectedDepartments={selectedDepartments}
                            isDisabled={isDisabled}
                        />
                    </div>

                    <ExperienceSection experience={experience} setExperience={setExperience} selectedUsers={selectedUsers} isDisabled={isDisabled} />

                    <DepartmentsSection
                        selectedDepartments={selectedDepartments}
                        setSelectedDepartments={setSelectedDepartments}
                        selectedUsers={selectedUsers}
                        isDisabled={isDisabled}
                    />
                </div>
            </div>

            <div>
                <div className="mb-6 text-base font-medium text-neutral-900">{t('NEWS_AND_POLLS_PUBLICATION_TIME')}</div>

                <PeriodOfActivitySection periodOfActivity={periodOfActivity} setPeriodOfActivity={setPeriodOfActivity} isDisabled={isDisabled} />
            </div>
        </div>
    );
};
