import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment/moment';

import { url } from '../../api/config';
import { newsAndPollsAPI } from '../../api/modules/newsAndPollsAPI';
import { throwException } from '../../functions/throwException';

export const newsAndPollsSlice = createSlice({
    name: 'newsAndPolls',

    initialState: {
        // Объекты
        objects: [],
        objectsFetching: false,

        // Департаменты
        departments: [],
        departmentsFetching: false,

        // Должности
        positions: [],
        positionsFetching: false,

        // Сотрудники
        users: [],
        usersFetching: false,

        // Новости и соглашения
        news: {
            items: [],
            totalCount: 0,
        },
        newsFetching: false,
        newsFilter: {
            type: ['n', 'a'],
            searchLine: '',
            page: '1',
        },

        // Опросы
        polls: {
            items: [],
            totalCount: 0,
        },
        pollsFetching: false,
        pollsFilter: {
            type: ['p'],
            searchLine: '',
            page: '1',
        },

        // Отчеты
        reports: {
            items: [],
            totalCount: 0,
        },
        reportsFetching: false,
        reportsFilter: {
            type: ['n', 'a', 'p'],
            searchLine: '',
            page: '1',
        },

        // Создание новости/соглашения/опроса
        createItemFetching: false,
    },

    reducers: {
        // Объекты
        setObjects: (state, action) => {
            state.objects = action.payload;
        },
        setObjectsFetching: (state, action) => {
            state.objectsFetching = action.payload;
        },

        // Департаменты
        setDepartments: (state, action) => {
            state.departments = action.payload;
        },
        setDepartmentsFetching: (state, action) => {
            state.departmentsFetching = action.payload;
        },

        // Должности
        setPositions: (state, action) => {
            state.positions = action.payload;
        },
        setPositionsFetching: (state, action) => {
            state.positionsFetching = action.payload;
        },

        // Сотрудники
        setUsers: (state, action) => {
            state.users = action.payload;
        },
        setUsersFetching: (state, action) => {
            state.usersFetching = action.payload;
        },

        // Новости и соглашений
        setNews: (state, action) => {
            state.news = action.payload;
        },
        updateNews: (state, action) => {
            state.news = { ...state.news, items: [...state.news.items, ...action.payload] };
        },
        setNewsFetching: (state, action) => {
            state.newsFetching = action.payload;
        },
        setNewsFilter: (state, action) => {
            state.newsFilter = { ...state.newsFilter, ...action.payload };
        },

        // Опросы
        setPolls: (state, action) => {
            state.polls = action.payload;
        },
        updatePolls: (state, action) => {
            state.polls = { ...state.polls, items: [...state.polls.items, ...action.payload] };
        },
        setPollsFetching: (state, action) => {
            state.pollsFetching = action.payload;
        },
        setPollsFilter: (state, action) => {
            state.pollsFilter = { ...state.pollsFilter, ...action.payload };
        },

        // Отчеты
        setReports: (state, action) => {
            state.reports = action.payload;
        },
        updateReports: (state, action) => {
            state.reports = { ...state.reports, items: [...state.reports.items, ...action.payload] };
        },
        setReportsFetching: (state, action) => {
            state.reportsFetching = action.payload;
        },
        setReportsFilter: (state, action) => {
            state.reportsFilter = { ...state.reportsFilter, ...action.payload };
        },

        // Создание новости/соглашения/опроса
        setCreateItemFetching: (state, action) => {
            state.createItemFetching = action.payload;
        },
    },
});

export const {
    setObjects,
    setObjectsFetching,
    setDepartments,
    setDepartmentsFetching,
    setPositions,
    setPositionsFetching,
    setUsers,
    setUsersFetching,
    setNews,
    updateNews,
    setNewsFetching,
    setNewsFilter,
    setPolls,
    updatePolls,
    setPollsFetching,
    setPollsFilter,
    setReports,
    updateReports,
    setReportsFetching,
    setReportsFilter,
    setCreateItemFetching,
} = newsAndPollsSlice.actions;

// Получить список объектов
export const getObjects = () => async (dispatch, getState) => {
    try {
        dispatch(setObjectsFetching(true));

        const state = getState();

        const response = await newsAndPollsAPI.getObjects.fetch(state.login.username, state.login.auth_key_calendar);

        if (!response.ok) {
            throwException(`Статус: ${response.status}`);
        }

        const result = await response.json();

        if (result.code) {
            throwException(result.msg ? result.msg : `Код: ${result.code}`);
        }

        if (result.data && result.data.data && result.data.data.objects) {
            dispatch(
                setObjects(
                    result.data.data.objects.map(object => ({
                        id: object.id.toString(),
                        name: object.title.toString(),
                        positions: [],
                        users: [],
                    }))
                )
            );
        } else {
            dispatch(setObjects([]));
        }
    } catch (error) {
        console.error('getObjects:', error.message);
    } finally {
        dispatch(setObjectsFetching(false));
    }
};

// Получить список департаментов
export const getDepartments = () => async (dispatch, getState) => {
    try {
        dispatch(setDepartmentsFetching(true));

        const state = getState();

        const response = await newsAndPollsAPI.getDepartments.fetch(state.login.username, state.login.auth_key_calendar);

        if (!response.ok) {
            throwException(`Статус: ${response.status}`);
        }

        const result = await response.json();

        if (result.code) {
            throwException(result.msg ? result.msg : `Код: ${result.code}`);
        }

        if (result.data) {
            const departments = [];
            for (let [key, value] of Object.entries(result.data)) {
                departments.push({
                    id: key,
                    name: value,
                });
            }

            dispatch(setDepartments(departments));
        } else {
            dispatch(setDepartments([]));
        }
    } catch (error) {
        console.error('getDepartments:', error.message);
    } finally {
        dispatch(setDepartmentsFetching(false));
    }
};

// Получить список должностей
export const getPositions = () => async (dispatch, getState) => {
    try {
        dispatch(setPositionsFetching(true));

        const state = getState();

        const response = await newsAndPollsAPI.getPositions.fetch(state.login.username, state.login.auth_key_calendar);

        if (!response.ok) {
            throwException(`Статус: ${response.status}`);
        }

        const result = await response.json();

        if (result.code) {
            throwException(result.msg ? result.msg : `Код: ${result.code}`);
        }

        if (result.data) {
            dispatch(
                setPositions(
                    result.data.map(object => ({
                        id: object.id.toString(),
                        name: object.name.toString(),
                        objects: object.venues.map(objectId => objectId.toString()),
                        users: [],
                    }))
                )
            );

            dispatch(
                setObjects(
                    state.newsAndPolls.objects.map(object => ({
                        ...object,
                        positions: result.data.filter(position => position.venues.includes(+object.id)).map(position => position.id.toString()),
                    }))
                )
            );
        } else {
            dispatch(setPositions([]));
        }
    } catch (error) {
        console.error('getPositions:', error.message);
    } finally {
        dispatch(setPositionsFetching(false));
    }
};

// Получить список сотрудников
export const getUsers = () => async (dispatch, getState) => {
    try {
        dispatch(setUsersFetching(true));

        const state = getState();

        const response = await newsAndPollsAPI.getUsers.fetch(state.login.username, state.login.auth_key_calendar);

        if (!response.ok) {
            throwException(`Статус: ${response.status}`);
        }

        const result = await response.json();

        if (result.code) {
            throwException(result.msg ? result.msg : `Код: ${result.code}`);
        }

        if (result.data) {
            dispatch(
                setUsers(
                    result.data.map(user => ({
                        id: user.id.toString(),
                        name: `${user.last_name} ${user.first_name} ${user.patronymic}`.trim(),
                        objects: user.venue
                            ? user.venue.map(obj => ({
                                  id: obj.venue_id.toString(),
                                  position: obj.position_id.toString(),
                              }))
                            : [],
                        departments: user.deps ? user.deps.map(item => item.toString()) : [],
                        experience: user.experience ? user.experience.toString() : '',
                    }))
                )
            );

            dispatch(
                setObjects(
                    state.newsAndPolls.objects.map(object => ({
                        ...object,
                        users: result.data.filter(user => user.venue.find(obj => obj.venue_id.toString() === object.id)).map(user => user.id.toString()),
                    }))
                )
            );

            dispatch(
                setPositions(
                    state.newsAndPolls.positions.map(position => ({
                        ...position,
                        users: result.data.filter(user => user.venue.find(obj => obj.position_id.toString() === position.id)).map(user => user.id.toString()),
                    }))
                )
            );
        } else {
            dispatch(setUsers([]));
        }
    } catch (error) {
        console.error('setUsers:', error.message);
    } finally {
        dispatch(setUsersFetching(false));
    }
};

// Получить список новостей и соглашений
export const getNews = data => async (dispatch, getState) => {
    try {
        if (data.page === '1') {
            dispatch(setNewsFetching(true));
        }

        const state = getState();

        const response = await newsAndPollsAPI.getList.fetch(state.login.username, state.login.auth_key_calendar, data);

        if (!response.ok) {
            throwException(`Статус: ${response.status}`);
        }

        const result = await response.json();

        if (result.code) {
            throwException(result.msg ? result.msg : `Код: ${result.code}`);
        }

        if (result.data && result.data.items) {
            const items = result.data.items.map(item => {
                const data = item.data ? JSON.parse(item.data) : {};

                return {
                    id: item.id.toString(),
                    key: item.id.toString(),
                    title: item.title,
                    type: item.nap_type === 'a' ? 'agreement' : 'news',
                    date: item.date_active_from ? moment(item.date_active_from, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY | HH:mm') : '',
                    dateFrom: item.date_active_from,
                    dateTo: item.date_active_to,
                    objects: data.objects ? data.objects : [],
                    positions: data.positions ? data.positions : [],
                    users: data.users ? data.users : [],
                    experience: data.experience ? data.experience : null,
                    departments: data.departments ? data.departments : [],
                    description: item.nap_description || '',
                    image: data.cover ? `${url}${data.cover}` : null,
                    file: data.file ? `${url}${data.file}` : null,
                    fileName: data.file_srcname ? data.file_srcname : '',
                };
            });

            if (data.page === '1') {
                dispatch(
                    setNews({
                        items,
                        totalCount: +result.data?.stat?.total_count || 0,
                    })
                );
            } else {
                dispatch(updateNews(items));
            }
        } else {
            if (data.page === '1') {
                dispatch(
                    setNews({
                        items: [],
                        totalCount: 0,
                    })
                );
            }
        }
    } catch (error) {
        console.error('getNews:', error.message);
    } finally {
        if (data.page === '1') {
            dispatch(setNewsFetching(false));
        }
    }
};

// Получить список опросов
export const getPolls = data => async (dispatch, getState) => {
    try {
        if (data.page === '1') {
            dispatch(setPollsFetching(true));
        }

        const state = getState();

        const response = await newsAndPollsAPI.getList.fetch(state.login.username, state.login.auth_key_calendar, data);

        if (!response.ok) {
            throwException(`Статус: ${response.status}`);
        }

        const result = await response.json();

        if (result.code) {
            throwException(result.msg ? result.msg : `Код: ${result.code}`);
        }

        if (result.data && result.data.items) {
            const items = result.data.items.map(item => {
                const data = item.data ? JSON.parse(item.data) : {};

                return {
                    id: item.id.toString(),
                    key: item.id.toString(),
                    title: item.title,
                    type: 'poll',
                    date: item.date_active_from ? moment(item.date_active_from, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY | HH:mm') : '',
                    dateFrom: item.date_active_from,
                    dateTo: item.date_active_to,
                    objects: data.objects ? data.objects : [],
                    positions: data.positions ? data.positions : [],
                    users: data.users ? data.users : [],
                    experience: data.experience ? data.experience : null,
                    departments: data.departments ? data.departments : [],
                    description: item.nap_description || '',
                    file: data.cover ? `${url}${data.cover}` : null,
                    questions: item.questions ? JSON.parse(item.questions) : [],
                };
            });

            if (data.page === '1') {
                dispatch(
                    setPolls({
                        items,
                        totalCount: +result.data?.stat?.total_count || 0,
                    })
                );
            } else {
                dispatch(updatePolls(items));
            }
        } else {
            if (data.page === '1') {
                dispatch(
                    setPolls({
                        items: [],
                        totalCount: 0,
                    })
                );
            }
        }
    } catch (error) {
        console.error('getPolls:', error.message);
    } finally {
        if (data.page === '1') {
            dispatch(setPollsFetching(false));
        }
    }
};

// Получить список отчетов
export const getReports = data => async (dispatch, getState) => {
    try {
        if (data.page === '1') {
            dispatch(setReportsFetching(true));
        }

        const state = getState();

        const response = await newsAndPollsAPI.getList.fetch(state.login.username, state.login.auth_key_calendar, data);

        if (!response.ok) {
            throwException(`Статус: ${response.status}`);
        }

        const result = await response.json();

        if (result.code) {
            throwException(result.msg ? result.msg : `Код: ${result.code}`);
        }

        if (result.data && result.data.items) {
            const getType = letter => {
                switch (letter) {
                    case 'n':
                        return 'NEWS_AND_POLLS_NEWS_ITEM';
                    case 'a':
                        return 'NEWS_AND_POLLS_AGREEMENT';
                    case 'p':
                        return 'NEWS_AND_POLLS_POLL';
                    default:
                        return '';
                }
            };

            const items = result.data.items.map(item => {
                const data = item.data ? JSON.parse(item.data) : {};

                let stat = '-';

                if (item.summary?.params) {
                    const { accepted, answered } = item.summary.params;

                    if (['n', 'a'].includes(item.nap_type) && accepted) {
                        stat = accepted;
                    } else if (item.nap_type === 'p' && answered) {
                        stat = accepted;
                    }
                }

                return {
                    id: item.id.toString(),
                    key: item.id.toString(),
                    title: item.title,
                    type: getType(item.nap_type),
                    dateStart: item.date_active_from ? moment(item.date_active_from, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY') : '',
                    dateEnd: item.date_active_to ? moment(item.date_active_to, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY') : '',
                    users: '-',
                    objects: data.objects ? data.objects : [],
                    download: item.id.toString(),
                    stat,
                };
            });

            if (data.page === '1') {
                dispatch(
                    setReports({
                        items,
                        totalCount: +result.data?.stat?.total_count || 0,
                    })
                );
            } else {
                dispatch(updateReports(items));
            }
        } else {
            if (data.page === '1') {
                dispatch(
                    setReports({
                        items: [],
                        totalCount: 0,
                    })
                );
            }
        }
    } catch (error) {
        console.error('getReports:', error.message);
    } finally {
        if (data.page === '1') {
            dispatch(setReportsFetching(false));
        }
    }
};

// Создание новости/соглашения/опроса
export const createItem =
    ({ data }) =>
    async (dispatch, getState) => {
        try {
            dispatch(setCreateItemFetching(true));

            const state = getState();

            const response = await newsAndPollsAPI.createItem.fetch(state.login.username, state.login.auth_key_calendar, state.login.lang, data);

            if (!response.ok) {
                throwException(`Статус: ${response.status}`);
            }

            const result = await response.json();

            if (result.code) {
                if (result.code === 49 && result.data) {
                    const errorArr = [];
                    for (const val in result.data) {
                        result.data[val].forEach(err => errorArr.push(err));
                    }
                    if (errorArr.length) {
                        throwException(errorArr);
                    }

                    throwException(result.msg ? result.msg : `Код: ${result.code}`);
                } else {
                    throwException(result.msg ? result.msg : `Код: ${result.code}`);
                }
            }

            if (result?.data?.journal?.id) {
                let message;
                if (data.type === 'n') {
                    message = 'Новость успешно создана.';
                } else if (data.type === 'a') {
                    message = 'Соглашение успешно создано.';
                } else if (data.type === 'p') {
                    message = 'Опрос успешно создан.';
                }

                return {
                    error: false,
                    message,
                };
            } else {
                return {
                    error: true,
                    message: 'Что-то пошло не так. Пожалуйста, попробуйте еще раз.',
                };
            }
        } catch (error) {
            console.error('createItem:', error.message);

            return {
                error: true,
                message: error.message,
            };
        } finally {
            dispatch(setCreateItemFetching(false));
        }
    };

export const objectsState = state => state.newsAndPolls.objects;
export const objectsFetchingState = state => state.newsAndPolls.objectsFetching;
export const departmentsState = state => state.newsAndPolls.departments;
export const departmentsFetchingState = state => state.newsAndPolls.departmentsFetching;
export const positionsState = state => state.newsAndPolls.positions;
export const positionsFetchingState = state => state.newsAndPolls.positionsFetching;
export const usersState = state => state.newsAndPolls.users;
export const usersFetchingState = state => state.newsAndPolls.usersFetching;
export const newsState = state => state.newsAndPolls.news;
export const newsFetchingState = state => state.newsAndPolls.newsFetching;
export const newsFilterState = state => state.newsAndPolls.newsFilter;
export const pollsState = state => state.newsAndPolls.polls;
export const pollsFetchingState = state => state.newsAndPolls.pollsFetching;
export const pollsFilterState = state => state.newsAndPolls.pollsFilter;
export const reportsState = state => state.newsAndPolls.reports;
export const reportsFetchingState = state => state.newsAndPolls.reportsFetching;
export const reportsFilterState = state => state.newsAndPolls.reportsFilter;
export const createItemFetchingState = state => state.newsAndPolls.createItemFetching;

export default newsAndPollsSlice.reducer;
