export const DeleteEmployeeButton = ({ title, callback, employee }) => {
    return (
        <button
            onClick={() => callback(employee.employeeId)}
            className="ml-3 rounded-md border bg-slate-200 px-1 py-0.5 text-xs hover:bg-red-500 hover:text-black"
            type="button"
        >
            {title}
        </button>
    );
};
