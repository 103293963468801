import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { bmsEventLogListState, setBMSEventLogFilters } from '../../../redux/slices/bms/bmsSliceEventLog';

export const Pagination = ({ searchParams }) => {
    const dispatch = useDispatch();
    const navigationData = useSelector(bmsEventLogListState);

    const [inputData, setInputData] = useState(null);

    useEffect(() => {
        let timeOutId;
        if (inputData && inputData <= navigationData.navigation.page_count && inputData > 0) {
            timeOutId = setTimeout(() => {
                dispatch(setBMSEventLogFilters({ type: 'page', value: inputData }));
            }, 1000);
        }

        return () => clearTimeout(timeOutId);
    }, [inputData]);

    return (
        <div className="mt-6 flex items-center p-1">
            <button
                className="mr-1 rounded-full border border-black bg-slate-200 px-1 font-bold"
                onClick={() => dispatch(setBMSEventLogFilters({ type: 'page', value: 1 }))}
                disabled={searchParams.page === 1}
            >
                {'<<'}
            </button>
            <button
                className="mr-1 rounded-full border border-black bg-slate-200 px-2 font-bold"
                onClick={() => dispatch(setBMSEventLogFilters({ type: 'page', value: searchParams.page - 1 }))}
                disabled={searchParams.page === 1}
            >
                {'<'}
            </button>
            <button
                className="mr-1 rounded-full border border-black bg-slate-200 px-2 font-bold"
                onClick={() => {
                    dispatch(setBMSEventLogFilters({ type: 'page', value: searchParams.page + 1 }));
                }}
                disabled={navigationData ? searchParams.page === navigationData.navigation.page_count : true}
            >
                {'>'}
            </button>
            <button
                className="mr-1 rounded-full border border-black bg-slate-200 px-1 font-bold"
                onClick={() => dispatch(setBMSEventLogFilters({ type: 'page', value: navigationData.navigation.page_count }))}
                disabled={navigationData ? searchParams.page === navigationData.navigation.page_count : true}
            >
                {'>>'}
            </button>
            <span className="mr-2">
                Страница:
                <strong className="ml-1">
                    {searchParams.page} из {navigationData ? navigationData.navigation.page_count : ''}
                </strong>
            </span>
            <span className="mr-2">
                | Перейти к странице:
                <input
                    type="number"
                    defaultValue={searchParams ? searchParams.page : ''}
                    onChange={e => setInputData(Number(e.target.value))}
                    className="ml-1 h-9 w-16 rounded"
                />
            </span>
            <select
                value={searchParams.page_size}
                onChange={e => {
                    dispatch(setBMSEventLogFilters({ type: 'page_size', value: e.target.value }));
                }}
                className="w-26 h-9 rounded py-1 pl-3 pr-6 text-sm"
            >
                {[10, 50, 100, 500].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                        Показывать по: {pageSize}
                    </option>
                ))}
            </select>
        </div>
    );
};
