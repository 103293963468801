import { BitDataPointValue } from './BitDataPointValue';
import { BooleanSelect } from './BooleanSelect';
import { DataPointSelect } from './DataPointSelect';
import { OperandSelect } from './OperandSelect';
import { RangeDataPointValue } from './RangeDataPointValue';
import { SimpleDataPointValue } from './SimpleDataPointValue';
import { UseBitCheckbox } from './UseBitCheckbox';
import { UseRangeCheckbox } from './UseRangeCheckbox';

export const ControlDataPoint = ({ initialState, nodeControlOptions, data, setData, setValues, setBitPopupIsOpen, setBitData }) => {
    return (
        <div className="mt-3">
            <div className="flex">
                <DataPointSelect nodeControlOptions={nodeControlOptions} data={data} setData={setData} setValues={setValues} initialState={initialState} />

                <OperandSelect data={data} setData={setData} setValues={setValues} />
            </div>
            <div className="mt-4 flex">
                <div className="mr-5 w-56">
                    <UseBitCheckbox data={data} setData={setData} setValues={setValues} initialState={initialState} />

                    <UseRangeCheckbox data={data} setData={setData} setValues={setValues} initialState={initialState} />
                </div>
                <div>
                    {!data.dataPointControl.bit && !data.dataPointControl.range && data.dataPointControl.dataPointControlObj.datatype !== 'boolean' && (
                        <SimpleDataPointValue data={data} setData={setData} setValues={setValues} />
                    )}
                    {data.dataPointControl.dataPointControlObj.datatype === 'boolean' && <BooleanSelect data={data} setData={setData} setValues={setValues} />}
                    {data.dataPointControl.bit && <BitDataPointValue data={data} setBitPopupIsOpen={setBitPopupIsOpen} setBitData={setBitData} />}
                    {data.dataPointControl.range && <RangeDataPointValue data={data} setData={setData} setValues={setValues} />}
                </div>
            </div>
        </div>
    );
};
