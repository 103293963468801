import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { logout, setUser } from '../redux/slices/loginSlice';
import { RootState } from '../redux/store.ts';
import { AuthTokens } from '../types/init';
import { url } from './config';

export const baseQuery = fetchBaseQuery({
    baseUrl: url,
    prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).login.accessToken;

        if (token && !headers.has('Authorization')) {
            headers.set('Authorization', `Bearer ${token}`);
        }

        return headers;
    },
});

export const baseQueryWithReAuth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        const refreshToken = api.getState().auth.refreshToken;

        if (!refreshToken) {
            api.dispatch(logout());

            return result;
        }

        const refreshResult: any = await baseQuery(
            {
                url: '/rest/auth/refresh',
                method: 'POST',
                body: { ['refresh_token']: refreshToken },
            },
            api,
            extraOptions
        );

        if (refreshResult.data && refreshResult.data.access_token && refreshResult.data.refresh_token) {
            const tokens: AuthTokens = { accessToken: refreshResult.data.access_token, refreshToken: refreshResult.data.refresh_token };

            api.dispatch(setUser(tokens));

            const retryArgs = {
                ...args,
                headers: {
                    ...args.headers,
                    Authorization: `Bearer ${tokens.accessToken}`,
                },
            };

            result = await baseQuery(retryArgs, api, extraOptions);
        } else {
            api.dispatch(logout());
        }
    }

    return result;
};
