import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SlidingPane from 'react-sliding-pane';
import { toast } from 'react-toastify';

import { inputOnlyInteger } from '../../../../functions/inputOnlyInteger';
import { toastOptions } from '../../../../functions/toastOptions';
import { settingsState, updateSettings } from '../../../../redux/slices/hospitalitySlice';
import { SlidingPaneFooter } from '../../../common/slidingPane/SlidingPaneFooter';
import { SlidingPaneHeader } from '../../../common/slidingPane/SlidingPaneHeader';
import { Toggle } from '../../../common/widgets/Toggle';
import { Tooltip } from './Tooltip';

export const SettingsPane = ({ isOpen, setIsOpen }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const settingsSt = useSelector(settingsState);

    const [settings, setSettings] = useState(settingsSt);

    useEffect(() => {
        if (isOpen) {
            setSettings(settingsSt);
        }
    }, [settingsSt, isOpen]);

    const closePaneHandler = () => {
        setIsOpen(false);
    };

    const updateHandler = () => {
        const { pms_task_types, ...filteredSettings } = settings;

        if (filteredSettings.hasOwnProperty('worker_worktime_kill_timer') && filteredSettings.worker_worktime_kill_timer === '') {
            toast.error(`"${t('HOSPITALITY_RETURN_OF_REQUESTS')}" ${t('HOSPITALITY_IS_REQUIRED')}`, toastOptions);
        } else if (
            filteredSettings.hasOwnProperty('worker_worktime_kill_timer') &&
            (filteredSettings.worker_worktime_kill_timer < 10 || filteredSettings.worker_worktime_kill_timer > 999)
        ) {
            toast.error(`"${t('HOSPITALITY_RETURN_OF_REQUESTS')}" ${t('HOSPITALITY_MUST_BE_IN_RANGE')} 10 - 999`, toastOptions);
        } else if (filteredSettings.hasOwnProperty('personal_task_limit') && filteredSettings.personal_task_limit === '') {
            toast.error(`"${t('HOSPITALITY_REQUESTS_QUANTITY')}" ${t('HOSPITALITY_IS_REQUIRED')}`, toastOptions);
        } else if (
            filteredSettings.hasOwnProperty('personal_task_limit') &&
            (filteredSettings.personal_task_limit < 1 || filteredSettings.personal_task_limit > 99)
        ) {
            toast.error(`"${t('HOSPITALITY_REQUESTS_QUANTITY')}" ${t('HOSPITALITY_MUST_BE_IN_RANGE')} 1 - 99`, toastOptions);
        } else if (filteredSettings.hasOwnProperty('auto_apply_time') && filteredSettings.auto_apply_time === '') {
            toast.error(`"${t('HOSPITALITY_PLANNED_DISTRIBUTION')}" ${t('HOSPITALITY_IS_REQUIRED')}`, toastOptions);
        } else if (filteredSettings.hasOwnProperty('auto_apply_time') && (filteredSettings.auto_apply_time < 0 || filteredSettings.auto_apply_time > 120)) {
            toast.error(`"${t('HOSPITALITY_PLANNED_DISTRIBUTION')}" ${t('HOSPITALITY_MUST_BE_IN_RANGE')} 0 - 120`, toastOptions);
        } else {
            dispatch(updateSettings({ data: { options: JSON.stringify(filteredSettings) } }));
            closePaneHandler();
            toast.info(t('HOSPITALITY_UPDATED_SUCCESSFULLY'), toastOptions);
        }
    };

    return (
        <SlidingPane
            isOpen={isOpen}
            hideHeader={true}
            onRequestClose={closePaneHandler}
            overlayClassName="z-40 bg-slate-900 bg-opacity-30 transition-opacity duration-200 opacity-100"
            width="25rem"
        >
            <SlidingPaneHeader closePaneHandler={closePaneHandler} title={t('HOSPITALITY_SETTINGS')} />

            <div className="mb-16 mt-1 flex flex-col gap-6">
                <div className="flex flex-row gap-4">
                    <div className="flex flex-col gap-2">
                        <div className="flex flex-row items-center">
                            <p className="mr-0.5 text-xs text-neutral-900">{t('HOSPITALITY_RETURN_OF_REQUESTS')}</p>
                            <Tooltip tip={t('HOSPITALITY_RETURN_OF_REQUESTS_TOOLTIP')} />
                        </div>
                        <div className="relative w-full">
                            <input
                                type="text"
                                value={settings && settings.hasOwnProperty('worker_worktime_kill_timer') ? settings.worker_worktime_kill_timer : ''}
                                onChange={e => setSettings({ ...settings, worker_worktime_kill_timer: inputOnlyInteger(e.target.value) })}
                                placeholder={t('HOSPITALITY_ENTER_TIME')}
                                className="h-10 w-full rounded border border-gray-200 pr-12 text-sm"
                            />
                            <div className="absolute right-3 top-[15px] text-xs font-normal leading-none text-black text-opacity-40">
                                {t('HOSPITALITY_MIN')}
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-2">
                        <div className="flex flex-row items-center">
                            <p className="mr-0.5 text-xs text-neutral-900">{t('HOSPITALITY_REQUESTS_QUANTITY')}</p>
                            <Tooltip tip={t('HOSPITALITY_REQUESTS_QUANTITY_TOOLTIP')} />
                        </div>
                        <div className="relative w-full">
                            <input
                                type="text"
                                value={settings && settings.hasOwnProperty('personal_task_limit') ? settings.personal_task_limit : ''}
                                onChange={e => setSettings({ ...settings, personal_task_limit: inputOnlyInteger(e.target.value) })}
                                placeholder={t('HOSPITALITY_REQUESTS_QUANTITY')}
                                className="h-10 w-full rounded border border-gray-200 pr-12 text-sm"
                            />
                            <div className="absolute right-3 top-[15px] text-xs font-normal leading-none text-black text-opacity-40">{t('HOSPITALITY_PC')}</div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-2">
                    <div className="flex flex-row items-center">
                        <p className="mr-0.5 text-xs text-neutral-900">{t('HOSPITALITY_ADDITIONAL_FUNCTIONS')}</p>
                    </div>
                    <div className="rounded border border-dashed border-black border-opacity-5 bg-neutral-100 bg-opacity-90">
                        <div className="flex flex-row items-center gap-4 border-b border-black border-opacity-10 p-4 opacity-50">
                            <Toggle
                                onChange={value => setSettings({ ...settings, isAuto: value })}
                                checked={settings && settings.hasOwnProperty('isAuto') && settings.isAuto}
                                disabled={true}
                            />
                            <div className="mr-auto text-xs font-normal text-neutral-800 text-opacity-60">{t('HOSPITALITY_AUTO_DISTRIBUTION')}</div>
                            <Tooltip tip={t('HOSPITALITY_AUTO_DISTRIBUTION_TOOLTIP')} />
                        </div>
                        <div className="flex flex-row items-center gap-4 p-4 opacity-50">
                            <Toggle
                                onChange={value => setSettings({ ...settings, supervisors: value })}
                                checked={settings && settings.hasOwnProperty('supervisors') && settings.supervisors}
                                disabled={true}
                            />
                            <div className="mr-auto text-xs font-normal text-neutral-800 text-opacity-60">
                                {t('HOSPITALITY_ATTACHING_MAIDS_TO_SUPERVISORS')}
                            </div>
                            <Tooltip tip={t('HOSPITALITY_ATTACHING_MAIDS_TO_SUPERVISORS_TOOLTIP')} />
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-2">
                    <div className="flex flex-row items-center">
                        <p className="mr-0.5 text-xs text-neutral-900">{t('HOSPITALITY_DISTRIBUTION_PRINCIPLES')}</p>
                    </div>
                    <div className="rounded border border-dashed border-black border-opacity-5 bg-neutral-100 bg-opacity-90">
                        <div className="flex flex-row items-center gap-4 border-b border-black border-opacity-10 p-4">
                            <label className="inline-flex w-full cursor-pointer select-none items-center gap-3 opacity-50">
                                <input type="checkbox" checked={true} readOnly={true} disabled={true} />
                                <div className="mr-auto text-xs font-normal text-neutral-800 text-opacity-60">
                                    {t('HOSPITALITY_DISTRIBUTION_BY_TYPE_OF_CLEANING')}
                                </div>
                                <Tooltip tip={t('HOSPITALITY_DISTRIBUTION_BY_TYPE_OF_CLEANING_TOOLTIP')} />
                            </label>
                        </div>
                        <div className="flex flex-row items-center gap-4 border-b border-black border-opacity-10 p-4">
                            <label className="inline-flex w-full cursor-pointer select-none items-center gap-3">
                                <input
                                    type="checkbox"
                                    checked={
                                        settings && settings.hasOwnProperty('use_rooms_for_tasks_distribution') && settings.use_rooms_for_tasks_distribution
                                    }
                                    onChange={e => setSettings({ ...settings, use_rooms_for_tasks_distribution: +e.target.checked })}
                                    className="cursor-pointer"
                                />
                                <div className="mr-auto text-xs font-normal text-neutral-800 text-opacity-60">
                                    {t('HOSPITALITY_DISTRIBUTION_BY_ATTACHMENT_TO_ROOMS')}
                                </div>
                                <Tooltip tip={t('HOSPITALITY_DISTRIBUTION_BY_ATTACHMENT_TO_ROOMS_TOOLTIP')} />
                            </label>
                        </div>
                        <div className="flex flex-row items-center gap-4 p-4">
                            <label className="inline-flex w-full cursor-pointer select-none items-center gap-3">
                                <input
                                    type="checkbox"
                                    checked={settings && settings.hasOwnProperty('use_distance_for_appendix_tasks') && settings.use_distance_for_appendix_tasks}
                                    onChange={e => setSettings({ ...settings, use_distance_for_appendix_tasks: +e.target.checked })}
                                    className="cursor-pointer"
                                />

                                <div className="mr-auto text-xs font-normal text-neutral-800 text-opacity-60">
                                    {t('HOSPITALITY_DISTRIBUTION_BY_LEAST_REMOTENESS')}
                                </div>
                                <Tooltip tip={t('HOSPITALITY_DISTRIBUTION_BY_LEAST_REMOTENESS_TOOLTIP')} />
                            </label>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col">
                    <div className="mb-1 flex flex-row items-center">
                        <p className="mr-0.5 text-xs text-neutral-900">{t('HOSPITALITY_PLANNED_DISTRIBUTION')}</p>
                        <Tooltip tip={t('HOSPITALITY_PLANNED_DISTRIBUTION_TOOLTIP')} />
                    </div>
                    <div className="mb-3 text-xs font-normal text-zinc-500">{t('HOSPITALITY_PLANNED_DISTRIBUTION_DESCRIPTION')}</div>
                    <div className="relative w-full">
                        <input
                            type="text"
                            placeholder={t('HOSPITALITY_ENTER_TIME')}
                            value={settings && settings.hasOwnProperty('auto_apply_time') ? settings.auto_apply_time : ''}
                            onChange={e => setSettings({ ...settings, auto_apply_time: inputOnlyInteger(e.target.value) })}
                            className="h-10 w-full rounded border border-gray-200 pr-12 text-sm"
                        />
                        <div className="absolute right-3 top-[15px] text-xs font-normal leading-none text-black text-opacity-40">{t('HOSPITALITY_MIN')}</div>
                    </div>
                </div>
            </div>

            <SlidingPaneFooter closePaneHandler={closePaneHandler} callback={updateHandler} />
        </SlidingPane>
    );
};
