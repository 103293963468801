import { t } from 'i18next';
import { useDispatch } from 'react-redux';

import { set_detail_open } from '../../redux/slices/qualificationSlice';

export const CreateQualificationBtn = () => {
    const dispatch = useDispatch();

    const handlerClick = async () => {
        await dispatch(set_detail_open(true));
    };
    return (
        <>
            <div className="flex items-center">
                <button
                    onClick={handlerClick}
                    className="flex items-center gap-2 rounded-[30px] border bg-blue px-6 py-2 text-sm font-normal text-white transition-colors duration-200 hover:border hover:border-blue hover:bg-white hover:text-blue"
                >
                    <svg width="14" height="16" viewBox="0 0 14 16" fill="none">
                        <path
                            d="M1.33333 14C0.966667 14 0.652667 13.8695 0.391333 13.6087C0.130444 13.3473 0 13.0333 0 12.6667V3.33332C0 2.96666 0.130444 2.65266 0.391333 2.39132C0.652667 2.13043 0.966667 1.99999 1.33333 1.99999H4.13333C4.27778 1.59999 4.51956 1.27777 4.85867 1.03332C5.19733 0.788879 5.57778 0.666656 6 0.666656C6.42222 0.666656 6.80289 0.788879 7.142 1.03332C7.48067 1.27777 7.72222 1.59999 7.86667 1.99999H10.6667C11.0333 1.99999 11.3473 2.13043 11.6087 2.39132C11.8696 2.65266 12 2.96666 12 3.33332V7.79999C11.7889 7.69999 11.5722 7.61377 11.35 7.54132C11.1278 7.46932 10.9 7.41666 10.6667 7.38332V3.33332H1.33333V12.6667H5.36667C5.4 12.9111 5.45289 13.1444 5.52533 13.3667C5.59733 13.5889 5.68333 13.8 5.78333 14H1.33333ZM1.33333 12.6667V3.33332V7.38332V7.33332V12.6667ZM2.66667 11.3333H5.38333C5.41667 11.1 5.46933 10.8722 5.54133 10.65C5.61378 10.4278 5.69444 10.2111 5.78333 9.99999H2.66667V11.3333ZM2.66667 8.66666H6.73333C7.08889 8.33332 7.486 8.05555 7.92467 7.83332C8.36378 7.6111 8.83333 7.4611 9.33333 7.38332V7.33332H2.66667V8.66666ZM2.66667 5.99999H9.33333V4.66666H2.66667V5.99999ZM6 2.83332C6.14444 2.83332 6.264 2.78599 6.35867 2.69132C6.45289 2.5971 6.5 2.47777 6.5 2.33332C6.5 2.18888 6.45289 2.06932 6.35867 1.97466C6.264 1.88043 6.14444 1.83332 6 1.83332C5.85556 1.83332 5.73622 1.88043 5.642 1.97466C5.54733 2.06932 5.5 2.18888 5.5 2.33332C5.5 2.47777 5.54733 2.5971 5.642 2.69132C5.73622 2.78599 5.85556 2.83332 6 2.83332ZM10 15.3333C9.07778 15.3333 8.29178 15.0084 7.642 14.3587C6.99178 13.7084 6.66667 12.9222 6.66667 12C6.66667 11.0778 6.99178 10.2915 7.642 9.64132C8.29178 8.99155 9.07778 8.66666 10 8.66666C10.9222 8.66666 11.7084 8.99155 12.3587 9.64132C13.0084 10.2915 13.3333 11.0778 13.3333 12C13.3333 12.9222 13.0084 13.7084 12.3587 14.3587C11.7084 15.0084 10.9222 15.3333 10 15.3333ZM9.66667 14H10.3333V12.3333H12V11.6667H10.3333V9.99999H9.66667V11.6667H8V12.3333H9.66667V14Z"
                            className="fill-current"
                        />
                    </svg>

                    {t('QUALIFICATION_CREATE')}
                </button>
            </div>
        </>
    );
};
