// Проверка одинаковых значений у ноды/проверка корректности min и max значений
export const checkDataPointValue = states => {
    for (let i = 0; i < states.length; i++) {
        const nodeI = states[i];
        if (!nodeI.bit) {
            for (let j = i + 1; j < states.length; j++) {
                const nodeJ = states[j];
                if (nodeI.node === nodeJ.node && nodeI.value === nodeJ.value) {
                    return 'Нельзя добавить два или более одинаковых значения у точки данных';
                }
            }
        } else {
            for (let j = i + 1; j < states.length; j++) {
                const nodeJ = states[j];
                if (nodeI.node === nodeJ.node && nodeI.bitValue === nodeJ.bitValue) {
                    return 'Нельзя добавить два или более одинаковых побитных значения у точки данных';
                }
            }
        }
    }

    // проверка min и max
    for (let i = 0; i < states.length; i++) {
        const node = states[i];
        if (node.range) {
            if (Number(node.value) >= Number(node.valueMax)) {
                return 'Минимальное значение не может быть больше или равно максимальному';
            }
        }
    }
};
