import { SimpleSelect } from '../../../../../../../common/widgets/SimpleSelect';

export const BooleanSelect = ({ data, setData, setValues, type, title }) => {
    const options = [
        { value: false, label: 'false' },
        { value: true, label: 'true' },
    ];
    return (
        <>
            <span className="mr-2 text-sm">{title}:</span>
            <SimpleSelect
                options={options}
                value={data[type]}
                isClearable={false}
                isSearchable={false}
                className="w-44 text-sm"
                callback={val => {
                    if (val === null) {
                        return;
                    }
                    setValues([{ ...data, [type]: val }]);
                    setData({ ...data, [type]: val });
                }}
            />
        </>
    );
};
